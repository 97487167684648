// EXCEPTIONS
// #1: ignore type error to check field name is available on the form type

// React
import { Fragment } from "react"

// Components
import { ActionButton, Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { FormType, TaxCodeType } from "../types"

// Utils
import { useApi, useToggle } from "../../../../../utils"

export function Delete(props: {
	item: TaxCodeType
	onSuccess: () => void
}) {
	// Hooks
	const { isActive, toggle } = useToggle(false)

	return (
		<Fragment>
			<ActionButton.Delete onClick={toggle} />
			{isActive && <ModalSection item={props.item} toggle={toggle} onSuccess={props.onSuccess} />}
		</Fragment>
	)
}

function ModalSection(props: {
	item: TaxCodeType
	onSuccess: () => void
	toggle: () => void
}) {
	// Hooks
	const api = useApi()

	// Form
	const defaultValues = {
		tax_code: props.item.tax_code,
		tax_name: props.item.tax_name,
		type_tax_id: props.item.type_tax_id.toString(),
		rate: props.item.rate,
		coa_collect_id: props.item.coa_collect_id.toString(),
		coa_paid_id: props.item.coa_paid_id.toString(),
		note: props.item.note
	}
	const onSubmit = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete(`/taxcode/delete`, { params: { tax_id: props.item.tax_id } }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props.onSuccess()
      }).catch(() => {}).finally(resolve)
    })
  }
	const methods = useForm<FormType>({ defaultValues })

	return (
		<Modal isOpen title="DELETE TAX CODE" closeModal={props.toggle}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<Modal.Body>
					<h5 className="text-error">Are you sure want to delete this data?</h5>

					<FormProvider {...methods}>
						<FormSection isDisabled />
					</FormProvider>
				</Modal.Body>

				<Modal.Footer className="text-end">
					<Button type="submit" color="error" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}