// React
import { createContext } from "react"

// Types
import type { DataCountType } from "../../types"

export const DetailDataContext = createContext<DataCountType>({
  countdetail: [],
  invcount: {
    count_id: 0,
    employee_id: 0,
    employee_name: "",
    location_id: 0,
    location_name: "",
    memo: "",
    transaction_date: "",
    trx_code: "",
  }
})