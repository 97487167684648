import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { ListBank } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render, validationSchema } from "../../../../../utils"
import { useDetail } from "../utils"
import { IsActive } from "./IsActive"

export function Update(props: { id: ListBank, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
    <ActionButton.Update className="join-item" onClick={toggle} />
    {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
  </Fragment>
  )
}

function ModalSection(props: {
  id: ListBank
  toggle: () => void
  refetch: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title="UPDATE COA TYPE" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: ListBank
  id: ListBank
  toggle: () => void
  refetch: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const onSubmit = ( value: ListBank ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/acctype/edit?account_type_id=${props.id.account_type_id}`, 
        { 
          group_coa_id: value.group_coa_id, 
          account_type_code: value.account_type_code, 
          account_type_name: value.account_type_name, 
          description: value.description, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: props.id,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormSection isUpdate />
        </Modal.Body>

        <Modal.Footer className="flex justify-between">
        <IsActive id={props.id} refetch={props.refetch} />
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}