import { useState } from "react"
import { BaseTable, Button, Card, FooterMenu, HeaderMenu, Input, PaginationData, TableHeaderSort } from "../../../../components"
import { CreateModal } from "./components"
import { TbChevronRight } from "react-icons/tb"
import { default_pagination_value, useToggle } from "../../../../utils"
import { RenderProvider, useList } from "./utils"
import {PaginationState } from "types"
import { debounce } from "lodash"
import { TableSortType, dataContext } from "./types"
import { DeleteAll, SelectAll, TableSection } from "./components/TableSection"
import { DeleteContext } from "pages/Setup/contexts"

export default function GroupTask() {
  const [deleteList, setDeleteList] = useState<number[]>([])
  const [isDeleteAll, setDeleteAll] = useState<boolean>(false)
  const { isActive, toggle } = useToggle(false)
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const onDebounce = debounce((e: any) => setPagination(prev => { return { ...prev, keyword: e.target.value } }))

  const header: TableSortType[] = [
    { title: "CODE", sort: true },
    { title: "GROUP TASK", sort: true },
    { title: "DESCRIPTION", sort: true }
  ]

  const sortMapping: Record<string, string> = {
    'CODE': 'code',
    'GROUP TASK': 'name',
    'DESCRIPTION': 'description'
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }

  return (
    <RenderProvider>
      <section className="container gap-5 flex flex-col">
        <HeaderMenu title="GROUP TASK" />

        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <DeleteAll
              deleteList={deleteList}
              isDeleteAll={isDeleteAll}
              onSuccess={() => {
                refetch()
                setDeleteAll(false)
                setDeleteList([])
              }}
            />
            <Button color="primary" onClick={toggle}>
              CREATE NEW <TbChevronRight />
            </Button>
          </Card.Body>

          <Card.Body>
            <div className="flex">
              <div className="mb-3">
                <Input
                  label="SEARCH"
                  className="md:max-w-[300px] w-full"
                  placeholder="Search..."
                  onChange={onDebounce}
                />
              </div>
            </div>
              <BaseTable>
                <thead>
                  <tr>
                    <th>NO</th>
                    <SelectAll
                      deleteList={deleteList}
                      isDeleteAll={isDeleteAll}
                      toggle={setDeleteList}
                      toggleDeleteAll={setDeleteAll}
                    />
                    <TableHeaderSort
                      title={header}
                      onSort={(item, sortType) => handleSort(item, sortType)}
                    />
                    <th className="w-[150px]">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <DeleteContext.Provider
                    value={{
                      value: deleteList,
                      toggleDeleteData(value) {
                        // Vars
                        const list = [...deleteList]
                        const findIndex = list.findIndex(item => item === value)

                        if (findIndex !== -1) {
                          list.splice(findIndex, 1)
                        } else {
                          list.push(value)
                        }

                        return setDeleteList(list)
                      }
                    }}
                  >
                    <dataContext.Provider
                      value={{
                        currentPage: pagination.page,
                        limit: pagination.limit,
                        size: data.pagination_data.size,
                        total: pagination.total
                      }}
                    >
                      <TableSection data={data} isLoading={isLoading} refetch={refetch} />
                    </dataContext.Provider>
                  </DeleteContext.Provider>
                </tbody>
              </BaseTable>
            <div className="mt-5">
              <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
            </div>
          </Card.Body>
        </Card>
        <FooterMenu/>
      </section>

      {isActive && <CreateModal toggleProps={toggle} refetch={refetch} />}
    </RenderProvider>
  )
}