export const getExtensionFile = (url: string) => {
  if (!url) return '';
  return url?.split('.')?.pop()?.toLowerCase();
}

export const isImageFile = (url: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
  const extension = getExtensionFile(url);

  if (!extension) return false

  return imageExtensions.includes(extension);
}
