import { Modal } from "components"
import { useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useToggle } from "utils"
import { AttachmentType } from "../types"
import { isImageFile, saveFile } from "../utils/functions"

export function PicturesSection(props: {
  items?: number
  dataPicture?: AttachmentType[]
  className?: string
  width?: string
  height?: string
}) {
  const { isActive, toggle } = useToggle(false)
  const [imageDetail, setImageDetail] = useState<string>()
  const defaultWidth = props?.width ? props?.width : "100%"
  const defaultHeight = props?.height ? props?.height : "100%"
  const style = { width: defaultWidth, height: defaultHeight }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: props.items ?? 1,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30
    }
  }

  const selectedImage = (value: string) => {
    const isImage = isImageFile(value)

    if (isImage) {
      setImageDetail(value)
      toggle()
      return
    }

    saveFile(value)
  }

  return (
    <div style={style}>
      <Carousel
        additionalTransfrom={0}
        arrows={true}
        autoPlaySpeed={3000}
        centerMode={false}
        className="w-full text-center"
        containerClass=""
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={true}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        customLeftArrow={
          <div className="absolute top-[50%] transform translate-y-[-50%] left-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg ml-2">
            ❮
          </div>
        }
        customRightArrow={
          <div className="absolute top-[50%] transform translate-y-[-50%] right-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg mr-2">
            ❯
          </div>
        }>
        {props?.dataPicture?.length ? (
          props.dataPicture.map((item, i) => {
            const isImage = isImageFile(item.url)

            return (
              <div key={item.url} className={`image-full`}>
                {isImage ? (
                  <div className="cursor-pointer w-full max-h-[400px] ">
                    <img
                      src={item.url}
                      alt=""
                      onClick={() => selectedImage(item?.url)}
                      className="object-cover w-full h-full"
                      style={style}
                    />
                  </div>
                ) : (
                  <div
                    className="flex flex-col justify-center items-center h-[400px] text-center"
                    onClick={() => selectedImage(item?.url)}>
                    <div>Preview Not Available</div>
                    <div className="hover:cursor-pointer">
                      Click to Save Attachment
                    </div>
                  </div>
                )}
              </div>
            )
          })
        ) : (
          <div></div>
        )}
      </Carousel>

      {isActive && <DetailImage toggle={toggle} image={imageDetail} />}
    </div>
  )
}

function DetailImage(props: { toggle: () => void; image: string | undefined }) {
  return (
    <Modal isOpen title="" size="lg" closeModal={props.toggle}>
      <Modal.Body>
        <img src={props.image} alt="" className="w-full h-auto object-cover" />
      </Modal.Body>
    </Modal>
  )
}
