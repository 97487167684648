import { useContext } from "react"
import { Card, FooterMenu, HeaderMenu } from "../../../../components"
import { Render, RenderProvider } from "./utils"
import { ChildItem } from "./components"

export default function LinkAccountSales() {
    return (
        <RenderProvider>
            <section className="container pt-5  gap-5 flex flex-col">
            <HeaderMenu title="LINK ACCOUNT SALES" />

                <Card>
                    <Card.Body>
                        <div className="overflow-x-auto">
                            <TableContent />
                        </div>
                    </Card.Body>
                </Card>
                <FooterMenu/>
            </section>
        </RenderProvider>
    )
}

function TableContent() {
    const { render } = useContext(Render)

    if (render) {
        return <ChildItem />
    } else {
        return <div />
    }
}