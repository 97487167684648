export const normal_post_list: any = {
  "01": "1",
  "02": "2",
  "03": "2",
  "04": "2",
  "05": "1",
  "06": "1",
  "07": "2",
  "08": "1",
  "09": "1"
}