// React
import { PropsWithChildren, createContext } from "react"

// Types
import type { ProductType } from "types"

// Utils
import { useProduct } from "utils"


export const ProductContext = createContext<{
  data: ProductType[]
  isLoading: boolean
}>({
  data: [],
  isLoading: false
})

export function ProductProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Hooks
  const { data, isLoading } = useProduct()

  return (
    <ProductContext.Provider value={{ data, isLoading }}>
      {params.children}
    </ProductContext.Provider>
  )
}