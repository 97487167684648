// React
import { useContext } from "react"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Components
import { Select } from "components"

// Contexts
import { ProductContext } from "../contexts"

// Utils
import { DetailFormType } from "../../../utils"

export function ItemOrder() {
  // Hooks
  const { data, isLoading } = useContext(ProductContext)

  // Form
  const { control, setValue } = useFormContext<DetailFormType>()

  return (
    <Controller
      name="product_id"
      control={control}
      render={({ field, fieldState }) => (
        <Select
          placeholder="Select Item"
          error={fieldState.error?.message}
          isLoading={isLoading}
          options={data}
          ref={field.ref}
          value={data.find(item => item.value === field.value) ?? null}
          onChange={res => {
            if (res) {
              field.onChange(res.value)
              // @ts-expect-error
              setValue("mr_unit_id", res.weight_unit_id)
              // @ts-expect-error
              setValue("tax_id", res.tax_id)
            }
          }}
        />
      )}
    />
  )
}