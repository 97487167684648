/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { CoaList, SuccessFetch } from "types"
import { useApi } from "utils"

type Response = {
  payload: CoaList[]
}

export function useCoaList() {
  const api = useApi()
  const [data, setData] = useState<CoaList[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("coa/")
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      cheque_coa: item.cheque_coa,
      value: item.coa_id.toString(),
      label: `${item.coa_code} ${item.coa_name}`,
      opening_balance: item.opening_balance
    }))
  }
}
