import { useFieldArray, useFormContext } from "react-hook-form"
import { BaseTable, Loading } from "components"
import { EditDataTable } from "./EditDataTable"
import { DataForm } from "./DataForm"
import { SKUList } from "../types"

export const DataTable =({ action, isLoading, refetch }: { action?: "CREATE" | "UPDATE" | "DETAIL", isLoading?: boolean, refetch?: () => void  }) => {
  const { control } = useFormContext<SKUList | any>()
  const { fields } = useFieldArray({ control, name: "datavalue" })
  
  return (
    <div className="overflow-x-auto z-[100]!">
      <BaseTable>
        <thead>
          <tr>
            <th>NO</th>
            <th>SKU</th>
            <th>BARCODE</th>
            <th>KPID</th>
            <th>QTY</th>
            <th>RETAIL PRICE</th>
            <th>CREATED</th>
            <th>EXPIRED</th>
            {action !== "DETAIL" && <th>ACTION</th>}
          </tr>
        </thead>
        <tbody>
          {fields?.length === 0 || isLoading
            ? <tr><td className="text-center" colSpan={action !== "DETAIL" ? 9 : 8}><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr> 
            : fields.map((field, key) => <EditDataTable
                key={field.id} 
                action={action}
                index={key} 
                refetch={refetch}
              />)
          }
          {action !== "DETAIL" && <DataForm key={fields.length} method="add" refetch={refetch} />}
        </tbody>
      </BaseTable>
    </div>
  )
}