// Components
import { PermissionLink } from "components/navigations"

// Configs
import { analyticsMenu } from "configs"

// Third-Party Libraries
import { Outlet } from "react-router-dom"

export function AnalyticsLayout() {
  return (
    <section className="h-full flex flex-col">
      <section className="pt-1 z-10 bg-base-100">
        <header className="w-full p-1 flex bg-black text-white items-center">
          <label htmlFor="my-drawer-2" aria-label="open sidebar" className="btn btn-square btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              viewBox="0 0 24 24"
              className="inline-block h-6 w-6 stroke-current">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                color="white"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </label>

          <div className="grow">
            Helping to Acquire Wealth Through Knowledge
          </div>
        </header>
      </section>

      <section className="grow">
        <div className="drawer lg:drawer-open h-full">
          <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />

          <div className="drawer-content p-3">
            <Outlet />
          </div>

          <div className="drawer-side !h-full lg:z-0 z-[100]">
            <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>

            <ul className="menu bg-black min-h-full w-60 p-4 overflow-auto divide-y">
              {analyticsMenu.menu.map((item, key) => (
                <li key={key}>
                  <PermissionLink
                    to={item.link}
                    permission={item.permission}
                    className="!text-white"
                  >
                    {item.label}
                  </PermissionLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </section>
  )
}