import { Fragment, useContext } from "react"
import { ActionButton, Button, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render, validationSchema } from "../../../../../utils"
import { menuTitle } from "./MenuTitle"
import { IsActive } from "./IsActive"

export function Update(props: { id: DataList, refetch: () => void }) {
    const { isActive, toggle } = useToggle(false)

    return (
        <Fragment>
            <ActionButton.Update className="join-item" onClick={toggle} />
            {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch} />}
        </Fragment>
    )
}

function ModalSection(props: {
    id: DataList
    toggle: () => void
    refetch: () => void
}) {

    return (
        <Modal isOpen title={`UPDATE ${menuTitle.title}`} closeModal={props.toggle}>
            <DataForm data={props.id} id={props.id} toggle={props.toggle} refetch={props.refetch} />
        </Modal>
    )
}

function DataForm(props: {
    data: DataList
    id: DataList
    toggle: () => void
    refetch: () => void
}) {
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: DataList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put(`/comparation/edit?mr_comparation_id=${props.id.mr_comparation_id}`,
                    {
                        mr_comparation_code: value.mr_comparation_code,
                        mr_comparation_name: value.mr_comparation_name,
                        description: value.description,
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
                props.toggle()
                props?.refetch && props.refetch()
                toggle()
            }).catch(() => { }).finally(resolve)
        })
    }
    const methods = useForm({
        defaultValues: props.id,
        resolver: yupResolver(validationSchema)
    })

    return (
        <FormProvider {...methods}>
            {/* @ts-ignore */}
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FormSection isUpdate />
                </Modal.Body>

                <Modal.Footer className="flex justify-between gap-1">
                    <IsActive id={props.data} refetch={props.refetch} />
                    <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
                </Modal.Footer>
            </form>
        </FormProvider>
    )
}