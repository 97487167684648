// Components
import { FooterMenu, Loading } from "components"
import { FormSection } from "../components"

// Contexts
import { DisabledContext } from "pages/Purchase/Transaction/contexts"
import { DetailDataContext } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"

// Types
import { DataCountType } from "../../types"

// Utils
import { type FormType, validationSchema, useDataAdjustmentDetail } from "../utils"

export function Detail(params: {
  adjustment_id: string
}): JSX.Element {
  // Hooks
  const { data, formData, isLoading } = useDataAdjustmentDetail(params.adjustment_id)
  console.log(isLoading)

  if (isLoading || !Boolean(data && formData)) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <ViewSection
      data={data!}
      formData={formData!}
    />
  )
}

function ViewSection(params: {
  data: DataCountType
  formData: FormType
}): JSX.Element {
  // Vars
  const formData = params.formData

  // Form
  const defaultValues: FormType = {
    ...formData,
    transaction_date: moment(formData.transaction_date).format("YYYY-MM-DD")
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <DetailDataContext.Provider value={params.data}>
      <DisabledContext.Provider value={true}>
        <FormProvider {...methods}>
          <FormSection
            footerElement={(
              <FooterMenu
                attachment={{ actiontype: "attachment" }}
                email={{ actiontype: "email" }}
                export={{ actiontype: "export" }}
                journal={{ actiontype: "journal" }}
                print={{ actiontype: "print" }}
              />
            )}
          />
        </FormProvider>
      </DisabledContext.Provider>
    </DetailDataContext.Provider>
  )
}