// Third-Party Libraries
import { jwtDecode } from "jwt-decode"

export const getCompanyName = (): string => {
  // Vars
  const token: string | null = window.localStorage.getItem("token")

  if (token) {
    // Vars
    const res: { company_name: string } = jwtDecode(token)

    return res.company_name
  }

  return ""
}