// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useLocation() {
  // Types
  interface Type extends SelectOptionType {
    address?: string
    village_name?: string
    subdistrict_name?: string
    regency_name?: string
    province_name?: string
    country_name?: string
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<Type[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  function refetch() {
    setLoading(true)

    api.get("/location/").then((res: SuccessFetch<{
      payload: {
        location_id: number
        location_name: string
        address?: string
        village_name?: string
        subdistrict_name?: string
        regency_name?: string
        province_name?: string
        country_name?: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.location_name,
          value: item.location_id.toString(),
          address: item.address,
          village_name: item.village_name,
          subdistrict_name: item.subdistrict_name,
          regency_name: item.regency_name,
          province_name: item.province_name,
          country_name: item.country_name
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}