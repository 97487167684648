import { BaseTable, Card, FooterMenu, HeaderMenu, Loading } from "components";
import moment from "moment";
import { Fragment } from "react";
import {
  prevYear,
  thisMonth,
  thisWeek,
  thisYear,
  today
} from "utils/functions";
import Filter from "./components/Filter";
import FilterByDate from "./components/FilterByDate";
import Item from "./components/Item";
import { useFilter, useJournalList } from "./utils/hooks";
import { dataFilter } from "./utils/vars";
import { useLocation } from "react-router-dom";

export default function Journal() {
  const todayDate = today();
  const prevYearDate = prevYear();
  const thisYearDate = thisYear();
  const thisMonthDate = thisMonth();
  const thisWeekDate = thisWeek();
  const location = useLocation()
  const path = location?.pathname.split('/');
  const firstPath = path[1];

  const { filter, handleChangeFilter } = useFilter({
    typeFilter: firstPath === "accounts" ? 3 : firstPath === "finance" ? 4 : firstPath === "sales" ? 5 : firstPath === "purchase" ? 6 : firstPath === "inventory" ? 7 : 0,
    dateFrom: thisMonthDate.fromDate,
    dateTo: todayDate
  });

  const { data, isLoading, refetch } = useJournalList({
    DataSource: filter.typeFilter ?? 0,
    StartDate: filter.dateFrom,
    EndDate: filter.dateTo
  });

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title={`${firstPath === "accounts"
              ? "ACCOUNT"
              : firstPath === "sales"
                ? "SALES"
                : firstPath === "purchase"
                  ? "PURCHASE"
                  : firstPath === "inventory"
                    ? "INVENTORY"
                    : firstPath === "cards"
                      ? "CARDS"
                      : "FINANCE"} JOURNAL`} 
          />

        <Filter
          filterValue={filter.typeFilter}
          dataFilter={dataFilter}
          onClick={(filter) => handleChangeFilter({ typeFilter: filter })}
        />

        <section className="mt-2">
          <Card>
            <Card.Body className="flex flex-col gap-5">
              <FilterByDate
                dateFrom={filter.dateFrom}
                dateTo={filter.dateTo}
                onChangeDateFrom={(dateFrom) => {
                  const isValid = moment(dateFrom).isBefore(filter.dateTo);
                  handleChangeFilter({
                    dateFrom,
                    dateTo: isValid ? filter.dateTo : dateFrom
                  });
                }}
                onChangeDateTo={(dateTo) => handleChangeFilter({ dateTo })}
                onClickPrevYear={() =>
                  handleChangeFilter({
                    dateFrom: prevYearDate.fromDate,
                    dateTo: prevYearDate.toDate
                  })
                }
                onClickThisYear={() =>
                  handleChangeFilter({
                    dateFrom: thisYearDate.fromDate,
                    dateTo: thisYearDate.toDate
                  })
                }
                onClickThisMonth={() =>
                  handleChangeFilter({
                    dateFrom: thisMonthDate.fromDate,
                    dateTo: thisMonthDate.toDate
                  })
                }
                onClickThisWeek={() =>
                  handleChangeFilter({
                    dateFrom: thisWeekDate.fromDate,
                    dateTo: thisWeekDate.toDate
                  })
                }
                onClickToday={() =>
                  handleChangeFilter({
                    dateFrom: todayDate,
                    dateTo: todayDate
                  })
                }
              />
            </Card.Body>
          </Card>
        </section>

        <section className="mt-2">
          <Card>
            <Card.Body>
              <BaseTable className="border-collapse border-transparent">
                <thead>
                  <tr className="uppercase">
                    <th>Date</th>
                    <th>Id</th>
                    <th>Account</th>
                    <th>Memo</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Job Id</th>
                  </tr>
                </thead>

                <tbody>
                  {!data.length || isLoading ? (
                    <tr>
                      <td colSpan={10} className="text-center">
                        <Loading
                          loading={isLoading}
                          errorText={isLoading ? "" : "No data available"}
                        />
                      </td>
                    </tr>
                  ) : (
                    data.map((item, key: number) => (
                      <Item
                        key={key}
                        item={item}
                        indexParent={key}
                        refetchJournal={refetch}
                      />
                    ))
                  )}
                </tbody>
              </BaseTable>
            </Card.Body>
          </Card>
        </section>

        <section className="mt-2">
          <FooterMenu />
        </section>
      </section>
    </Fragment>
  );
}
