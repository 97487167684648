import { useEffect, useState } from "react"
import type { ErrorFetch, SuccessFetch } from "../../../../../../../../../../types"
import { AddressList, BankList, EducationList, PersonalList, ProfileList, WorkStatusList } from "../../../../../../types"
import { useApi } from "../../../../../../../../../../utils"

export function useDetail() {
  const api = useApi()
  const [data, setData] = useState<PersonalList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/productcode")
    .then((res: SuccessFetch<{ payload: PersonalList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}
export function useDetailPersonalData(id: number) {
  const api = useApi()
  const [dataDetail, setData] = useState<PersonalList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/byid", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: PersonalList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataDetail, dataError, isLoading }
}
export function useDetailProfileData(id: number) {
  const api = useApi()
  const [dataProfile, setData] = useState<ProfileList | null>(null)
  const [errorProfile, setError] = useState<string | undefined>(undefined)
  const [isLoadingProfile, setLoading] = useState(true)

  const refetchProfile = () => {
    setLoading(true)

    api.get("/employee/profile", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/employee/profile", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataProfile, errorProfile, isLoadingProfile, refetchProfile }
}
export function useDetailAddressData(id: number) {
  const api = useApi()
  const [dataAddress, setData] = useState<AddressList | null>(null)
  const [errorAddress, setError] = useState<string | undefined>(undefined)
  const [isLoadingAddress, setLoading] = useState(true)

  const refetchAddress = () => {
    setLoading(true)

    api.get("/employee/address", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: AddressList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/employee/address", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: AddressList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataAddress, errorAddress, isLoadingAddress, refetchAddress }
}
export function useDetailWorkStatusData(id: number) {
  const api = useApi()
  const [dataWorkStatus, setData] = useState<WorkStatusList | null>(null)
  const [errorWorkStatus, setError] = useState<string | undefined>(undefined)
  const [isLoadingWorkStatus, setLoading] = useState(true)

  const refetchWorkStatus = () => {
    setLoading(true)

    api.get("/employee/workstatus", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: WorkStatusList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/employee/workstatus", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: WorkStatusList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataWorkStatus, errorWorkStatus, isLoadingWorkStatus, refetchWorkStatus }
}
export function useDetailEducationData(id: number) {
  const api = useApi()
  const [dataEducation, setData] = useState<EducationList | null>(null)
  const [errorEducation, setError] = useState<string | undefined>(undefined)
  const [isLoadingEducation, setLoading] = useState(true)

  const refetchEducation = () => {
    setLoading(true)

    api.get("/employee/education", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: EducationList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/employee/education", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: EducationList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataEducation, errorEducation, isLoadingEducation, refetchEducation }
}
export function useDetailBankData(id: number) {
  const api = useApi()
  const [dataBank, setData] = useState<BankList | null>(null)
  const [errorBank, setError] = useState<string | undefined>(undefined)
  const [isLoadingBank, setLoading] = useState(true)

  const refetchBank = () => {
    setLoading(true)

    api.get("/employee/bank", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: BankList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/employee/bank", { params: { employee_id: id } })
    .then((res: SuccessFetch<{ payload: BankList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataBank, errorBank, isLoadingBank, refetchBank }
}
export function useDetailPrivillegeData(id: number) {
  const api = useApi()
  const [dataPrivillege, setData] = useState<any | null>(null)
  const [errorPrivillege, setError] = useState<string | undefined>(undefined)
  const [isLoadingPrivillege, setLoading] = useState(true)

  const refetchPrivillege = () => {
    setLoading(true)

    api.get(`/employee/access/${id}`) 
    .then((res: SuccessFetch<{ payload: any }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  useEffect(() => {
    api.get(`/employee/access/${id}`)
    .then((res: SuccessFetch<{ payload: any }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [id])

  return { dataPrivillege, errorPrivillege, isLoadingPrivillege, refetchPrivillege }
}
export function useIsSubscribe(id: number) {
  const api = useApi()
  const [dataSubscribe, setData] = useState<any | null>(null)
  const [errorSubscribe, setError] = useState<string | undefined>(undefined)
  const [isLoadingSubscribe, setLoading] = useState(true)

  const refetchSubscribe = () => {
    setLoading(true)

    api.get(`/employee/${id}/admin`) 
    .then((res: SuccessFetch<{ payload: any }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  useEffect(() => {
    api.get(`/employee/${id}/admin`) 
    .then((res: SuccessFetch<{ payload: any }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [id])

  return { dataSubscribe, errorSubscribe, isLoadingSubscribe, refetchSubscribe }
}
export function useDetailMapData(id: number) {
  const api = useApi()
  const [dataMap, setData] = useState<ProfileList | null>(null)
  const [errorMap, setError] = useState<string | undefined>(undefined)
  const [isLoadingMap, setLoading] = useState(true)

  useEffect(() => {
    api.get("/vendor/linkmap", { params: { vendor_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataMap, errorMap, isLoadingMap }
}
// export function useCheckAccount(): void {
//   const api = useApi()
//   const [dataChecking, setData] = useState<string | undefined>(undefined)
//   const [errorCheck, setError] = useState<string | undefined>(undefined)
//   const [isLoadingCheck, setLoading] = useState(false)

//   const refetchChecked = (account?: string, id?: number) => {
//     setLoading(true)

//     api.get("/employee/checkaccount", { params: { account: account, employee_id: id } })
//     .then((res: SuccessFetch<{ status: string}>) => setData(res?.data?.status))
//     .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
//     .finally(() => setLoading(false))

//   return { dataChecking, errorCheck, isLoadingCheck, refetchChecked }
// }}

export function usePersonalCode() {
  const api = useApi()
  const [dataCode, setData] = useState<PersonalList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/employee/empcode")
    .then((res: SuccessFetch<{ payload: PersonalList }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataCode, dataError, isLoading }
}