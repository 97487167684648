// React
import { forwardRef } from "react"

// Quill
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

export const Texteditor = forwardRef((params: {
  disabled?: boolean
  error?: string
  label?: string
  onChange: (value: string) => void
  value?: string
}, ref): JSX.Element => (
  <label className="form-control">
    {params.label && (
      <label className="label">
        <span className={`label-text font-bold ${params.error && "text-error"}`}>{params.label}</span>
      </label>
    )}

    <ReactQuill
      // @ts-expect-error usable for react-hook-form
      ref={ref}
      readOnly={params.disabled}
      value={params.value}
      onChange={params.onChange}
    />

    {params.error && (
      <label className="label">
        <span className={`label-text-alt font-bold ${params.error && "text-error"}`}>{params.error}</span>
      </label>
    )}
  </label>
))