/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Label, Modal, Select } from "components"
import { CreatePage as NewEmployee } from "pages/Card/Transaction/CreateNewEmployee/components"
import { Fragment, useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useToggle } from "utils"
import { useDropdownEmployee } from "../utils/hooks/useDropdownEmployee"

export type DropdownEmployeeProps = {
  approvalView?: boolean
  isEdit?: boolean
  label?: string
  disabled?: boolean
  name?: string
  showCreate?: boolean
  onChange?: (e: any) => void
}

export const DropdownEmployee = ({
  approvalView,
  isEdit = false,
  disabled,
  label = "EMPLOYEE",
  name = "employee_id",
  showCreate = true,
  onChange
}: DropdownEmployeeProps) => {
  const { dropdown, isLoading, refetch } = useDropdownEmployee()
  const { control } = useFormContext()
  const modalCreateEmployee = useToggle(false)

  useEffect(() => {
    if (!modalCreateEmployee.isActive) {
      refetch()
    }
  }, [modalCreateEmployee.isActive])

  return (
    <>
      <Label text={label} />
      <section className="flex gap-3">
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              className="grow"
              placeholder="Select Employee"
              isLoading={isLoading}
              options={dropdown}
              value={
                field.value
                  ? dropdown.find((e) => field.value.toString() === e.value)
                  : null
              }
              isDisabled={isEdit || approvalView || disabled}
              onChange={(e: any) => {
                field.onChange(e?.value)
                onChange && onChange(e)
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        <Button
          color="primary"
          className={`px-2 ${
            approvalView || !showCreate ? "hidden" : "false"
          } `}
          onClick={modalCreateEmployee.toggle}>
          <TbPlus size={24} />
        </Button>
      </section>

      <ModalCreateEmployee
        isOpen={modalCreateEmployee.isActive}
        onClose={modalCreateEmployee.toggle}
      />
    </>
  )
}

type ModalCreateEmployeeProps = {
  isOpen: boolean
  onClose?: () => void
  refetch?: () => void
}

function ModalCreateEmployee({ isOpen, onClose }: ModalCreateEmployeeProps) {
  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        title="Create New Employee"
        size="full"
        closeModal={onClose}>
        <Modal.Body>
          <NewEmployee asModal />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
