import moment from "moment";

export const defaultFilter = {
  start_date: moment().startOf('month').format("YYYY-MM-DD"),
  end_date: moment().endOf('month').format("YYYY-MM-DD"),
  total: 0,
  page: 1,
  limit: 10,
  pages: 1,
  keyword: '',
  from_location_id: '',
  to_location_id: '',
  received_status: '',
  approval_status: ''
}