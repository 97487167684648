import { useEffect, useState } from "react"
import type { ErrorFetch, SuccessFetch } from "types"
import { DataList } from "../../../../../../types"
import { useApi } from "utils"

export function useDetail(job_position_id: number) {
  const api = useApi()
  const [data, setData] = useState<DataList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  const refetch = () => {
    setLoading(true)

    api.get("/jobposition/" + job_position_id)
    .then((res: SuccessFetch<{ payload: DataList }>) => {
      const data = res?.data?.payload
      setData(data)
    })
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/jobposition/" + job_position_id)
    .then((res: SuccessFetch<{ payload: DataList }>) => {
      const data = res?.data?.payload
      setData(data)
    })
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [job_position_id])

  return { data, dataError, isLoading, refetch }
}