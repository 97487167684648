import { CommandButton, FooterMenu } from "components"
import { Fragment } from "react"
import { useToggle } from "utils"

type CommandButtonsProps = {
  approvalView?: boolean
  showSaveButton?: boolean
  showDeleteButton?: boolean
  onDelete?: () => void
  onSave?: () => void
}

export const CommandButtons = ({
  approvalView,
  showSaveButton,
  showDeleteButton,
  onDelete,
  onSave
}: CommandButtonsProps) => {
  const modalJournalInvoice = useToggle(false)

  return (
    <div className={approvalView ? "hidden" : ""}>
      <FooterMenu
        journal={{ actiontype: "journal", onClick: modalJournalInvoice.toggle }}
        customElement={{
          save: (
            <Fragment>
              {showSaveButton && (
                <CommandButton
                  actiontype="save"
                  type="button"
                  color="primary"
                  permission="ST121"
                  onClick={onSave}
                />
              )}
            </Fragment>
          ),

          delete: (
            <Fragment>
              {showDeleteButton && (
                <CommandButton actiontype="delete" onClick={onDelete} />
              )}
            </Fragment>
          ),

          attachment: <CommandButton actiontype="attachment" />
        }}
      />
    </div>
  )
}
