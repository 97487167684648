/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import {
  BaseTable,
  Button,
  Card,
  DateInput,
  FooterMenu,
  HeaderMenu,
  Input,
  Loading,
  NumberInput
} from "components"
import moment from "moment"
import { Fragment, useEffect } from "react"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import toast from "react-hot-toast"
import { FaSync } from "react-icons/fa"
import { FaSackDollar } from "react-icons/fa6"
import { useApi, useToggle } from "utils"
import { today } from "utils/functions"
import {
  DropdownAccount,
  Item,
  ModalBankEntry,
  ModalErrorReconcile,
  ModalReconcileConfirmation
} from "./components"
import { FormReconcileType, ReconcileList, formReconcileSchema } from "./types"
import { calculateForm, submitMap } from "./utils/function"
import {
  useReconciliationCode,
  useReconciliationJournal,
  useReconciliationLastBalance,
  useReconciliationLastDate
} from "./utils/hooks"

export function ReconcileAccount() {
  const api = useApi()
  const modalToggle = useToggle(false)
  const modalError = useToggle(false)
  const modalConfirmation = useToggle(false)

  const defaultValues: FormReconcileType = {
    coa_id: "",
    reconciliation_id: "",
    new_statement_balance: 0,
    calculate_statement_balance: 0,
    out_of_balance: 0,
    last_reconciliation_date: "",
    bank_statement_date: today(),
    last_statement_balance: 0,
    total_cleared_credit: 0,
    total_cleared_debet: 0,
    data: []
  }

  const onSubmit = async (value: FormReconcileType) => {
    await toast
      .promise(api.post("/reconciliation", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        methods.reset(defaultValues)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<FormReconcileType>({
    defaultValues,
    resolver: yupResolver(formReconcileSchema)
  })

  const [
    data,
    new_statement_balance,
    last_statement_balance,
    bank_statement_date,
    coa_id,
    last_reconciliation_date,
    out_of_balance
  ] = useWatch({
    control: methods.control,
    name: [
      "data",
      "new_statement_balance",
      "last_statement_balance",
      "bank_statement_date",
      "coa_id",
      "last_reconciliation_date",
      "out_of_balance"
    ]
  })

  const disableReconcile = data?.filter((item) => item.rc).length === 0

  useReconciliationCode({
    trx_date: bank_statement_date,
    onSuccess: (code) => methods.setValue("reconciliation_id", code)
  })

  useReconciliationLastDate({
    params: { coa_id },
    onSuccess: (date) => methods.setValue("last_reconciliation_date", date)
  })

  useReconciliationLastBalance({
    params: { coa_id, transaction_date: bank_statement_date },
    onSuccess: (balance) => methods.setValue("last_statement_balance", balance)
  })

  const { isLoading, refetch } = useReconciliationJournal({
    params: { coa_id, last_reconciliation_date, bank_statement_date },
    onSuccess: (responseData) => {
      if (data.length) {
        const concatData = responseData.map((item) => {
          const findDataCheck =
            data.find((d) => d.journal_id === item.journal_id)?.rc ?? false

          return { ...item, rc: findDataCheck }
        })

        return methods.setValue("data", concatData)
      }
      methods.setValue("data", responseData)
    }
  })

  const handleChangeRc = (
    value: boolean,
    _item: ReconcileList,
    index: number
  ) => {
    methods.setValue(`data.${index}.rc`, value)
  }

  useEffect(() => {
    const { setValue } = methods

    const calculate = calculateForm(data, new_statement_balance, last_statement_balance)

    setValue("total_cleared_debet", calculate.debit)
    setValue("total_cleared_credit", calculate.credit)
    setValue("calculate_statement_balance", calculate.calculateStatementBalance)
    setValue("out_of_balance", calculate.outOfBalance)
  }, [data.filter((item) => item.rc).length, new_statement_balance, last_statement_balance])

  return (
    <Fragment>
      <FormProvider {...methods}>
        <section className="container">
          <section className="container mt-5 flex flex-col">
            <HeaderMenu title="DATA ENTRY | RECONCILE ACCOUNT">
              <div />
            </HeaderMenu>
          </section>

          <section className="container mt-2 ">
            <Card>
              <Card.Body>
                <div className="w-full flex gap-2 ">
                  <div className="w-[33%]">
                    <Controller
                      control={methods.control}
                      name="reconciliation_id"
                      render={({ field, fieldState }) => (
                        <Input
                          disabled
                          textRight
                          label="Reconcilliation ID"
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>

                  <div className="w-[50%]">
                    <Controller
                      control={methods.control}
                      name="coa_id"
                      render={({ field, fieldState }) => (
                        <DropdownAccount
                          label="Account ID"
                          value={field.value}
                          error={fieldState.error?.message}
                          displayBalance={false}
                          onChange={(account) => field.onChange(account?.value)}
                        />
                      )}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </section>

          <section className="container flex-col lg:flex lg:flex-row justify-center items-start lg:justify-between">
            <div className="w-full lg:w-[33%] flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center h-full">
              <section className="container my-5 me-4">
                <Card>
                  <Card.Body>
                    <Controller
                      control={methods.control}
                      name="new_statement_balance"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          textRight
                          label="New Statement Balance"
                          thousandSeparator=","
                          decimalSeparator="."
                          value={field.value}
                          onValueChange={(value) =>
                            field.onChange(value.floatValue)
                          }
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={methods.control}
                      name="calculate_statement_balance"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          disabled
                          textRight
                          thousandSeparator=","
                          decimalSeparator="."
                          label="Calculate Statement Balance"
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={methods.control}
                      name="out_of_balance"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          disabled
                          textRight
                          label="Out of Balance"
                          thousandSeparator=","
                          decimalSeparator="."
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <hr className="mt-3" />

                    <Controller
                      control={methods.control}
                      name="last_reconciliation_date"
                      render={({ field, fieldState }) => (
                        <DateInput
                          label="Last Reconcilliation Date"
                          onChange={(date) =>
                            field.onChange(moment(date).format("YYYY-MM-DD"))
                          }
                          selected={
                            field.value ? moment(field.value).toDate() : null
                          }
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={methods.control}
                      name="bank_statement_date"
                      render={({ field, fieldState }) => (
                        <DateInput
                          label="Bank Statement Date"
                          onChange={(date) =>
                            field.onChange(moment(date).format("YYYY-MM-DD"))
                          }
                          selected={moment(field.value).toDate()}
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={methods.control}
                      name="last_statement_balance"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          textRight
                          label="Last Statement Balance"
                          thousandSeparator=","
                          decimalSeparator="."
                          value={field.value}
                          onValueChange={(e) => field.onChange(e.floatValue)}
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <hr className="mt-3" />

                    <Controller
                      control={methods.control}
                      name="total_cleared_credit"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          disabled
                          textRight
                          label="Total Cleared Credit"
                          thousandSeparator=","
                          decimalSeparator="."
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={methods.control}
                      name="total_cleared_debet"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          disabled
                          textRight
                          thousandSeparator=","
                          decimalSeparator="."
                          label="Total Cleared Debet"
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <section className="flex flex-wrap justify-between gap-2 mt-5">
                      <section className="flex flex-wrap gap-2">
                        <Button
                          type="button"
                          color="primary"
                          permission="FT031"
                          onClick={() => modalToggle.setActive(true)}>
                          <FaSackDollar size={24} /> Bank Entry
                        </Button>

                        <Button type="button" color="primary" onClick={refetch}>
                          <FaSync size={20} /> Refresh
                        </Button>
                      </section>
                    </section>
                  </Card.Body>
                </Card>
              </section>
            </div>

            <div className="w-full lg:w-[67%] flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center h-full">
              <section className="container my-5">
                <Card>
                  <Card.Body className="min-h-[878px] max-h-[878px] overflow-y-scroll no-scrollbar">
                    <div>
                      <BaseTable className="border-collapse border-transparent overflow-scroll">
                        <thead>
                          <tr className="uppercase">
                            <th>Source</th>
                            <th>Date</th>
                            <th>RC</th>
                            <th>Card</th>
                            <th>Name</th>
                            <th>Reference</th>
                            <th>Memo</th>
                            <th>Money Out</th>
                            <th>Money In</th>
                          </tr>
                        </thead>

                        <tbody>
                          {!data?.length || isLoading ? (
                            <tr>
                              <td colSpan={10} className="text-center">
                                <Loading
                                  loading={isLoading}
                                  errorText={
                                    isLoading ? "" : "No data available"
                                  }
                                />
                              </td>
                            </tr>
                          ) : (
                            data.map((item, key: number) => (
                              <Item
                                key={item.journal_id}
                                item={item}
                                index={key}
                                onChangeRc={handleChangeRc}
                              />
                            ))
                          )}
                        </tbody>
                      </BaseTable>
                    </div>
                    <div className="flex justify-end items-center mt-3">
                      <Button
                        type="button"
                        color="primary"
                        permission="FT031"
                        disabled={disableReconcile}
                        loading={methods.formState.isSubmitting ? "true" : undefined}
                        onClick={() => {
                          if (out_of_balance === 0) {
                            modalConfirmation.setActive(true)
                          } else {
                            modalError.setActive(true)
                          }
                        }}>
                        RECONCILE
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </section>
            </div>
          </section>

          <section className="mb-7">
            <FooterMenu />
          </section>
        </section>

        <ModalBankEntry
          isOpen={modalToggle.isActive}
          onCloseModal={modalToggle.toggle}
        />

        <ModalReconcileConfirmation
          modal={modalConfirmation}
          onConfirm={() => {
            methods.handleSubmit(onSubmit)()
            modalConfirmation.setActive(false)
          }}
          onPrint={() => {
            modalConfirmation.setActive(false)
          }}
        />

        <ModalErrorReconcile modal={modalError} outOfBalance={out_of_balance} />
      </FormProvider>
    </Fragment>
  )
}
