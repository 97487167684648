import { AttachmentType, TaskActionFile } from "pages/Task/types"

export const getPathOnly = (path: string) => {
  return path.split("=").pop()
}

export const generatePath = (path: string) => {
  const { REACT_APP_BASE_URL_API } = process.env
  return `${REACT_APP_BASE_URL_API}/getimage/?imgpath=${path}`
}

export const mapAsetPath = (taskActionFile: TaskActionFile[]) => {
  return taskActionFile.map((file) => {
    return {
      ...file,
      path: generatePath(file.path)
    }
  })
}

export const generateAsetFile = (
  taskActionFile: TaskActionFile[]
): AttachmentType[] => {
  if (!taskActionFile.length) return []

  return taskActionFile.map(
    (file) =>
      ({
        id: file.task_action_file_id,
        url: file.path,
        name: file.file_name
      } as AttachmentType)
  )
}
