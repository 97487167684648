// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import axios from "axios"

// Types
import type { SuccessFetch } from "types"
import type { DataBillType, DetailBillType, PoFetchType } from "../../types"

// Utils
import { useApi } from "utils"
import { default_option } from "pages/Purchase/utils"
import { billStatusList } from "../vars"

export function useBillDetail(
  id: string,
  bill_id: string
) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<DetailBillType | null>(null)
  const [billData, setBillData] = useState<DataBillType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios.all([
      api.get("/probill/detailbill_bypo", {
        params: { purchase_order_id: id }
      }),
      api.get("/probill/detailbill", {
        params: { bill_id }
      })
    ]).then(axios.spread((respo, res) => {
      // Vars
      const datapo: SuccessFetch<{
        payload: PoFetchType
      }> = respo
      const data: SuccessFetch<{
        payload: {
          databill: {
            bill_status: number
            delivery_no: number
            trx_code: string
            freight: number
            address: string
            location_id: number
            memo: string
            promise_date: string
            purchase_order_id: number
            referensi: string
            transaction_date: string
            vendor_id: number
            approve_status: number
            approve_date: null | string
            approve_by: null | number
            company_code: string
            vendor_name: string
            location_name: string
            approve_by_name: string
          }
          detailbill: {
            bill_detail_id: number
            quantity_bill: number
            quantity_unit: number
            product_id: number
            discount: number
            total: number
            tax_id: number
            price_bill: number
          }[]
        }
      }> = res
      const payloadpo = datapo.data.payload
      const payload = data.data.payload

      setBillData({
        approval_status: default_option[payload.databill.approve_status].label,
        approved_by: payload.databill.approve_by_name,
        bill_status: billStatusList[`${payload.databill.bill_status ?? ""}`]
      })
      setData({
        datapo: {
          address: payload.databill.address,
          company_code: payload.databill.company_code,
          delivered: payload.databill.delivery_no,
          freight: payload.databill.freight,
          location_id: payload.databill.location_id.toString(),
          location_name: payload.databill.location_name,
          memo: payload.databill.memo,
          payment_term: payloadpo.datapo.payment_term.toString(),
          po_delivery_status: payloadpo.datapo.po_delivery_status,
          po_payment_status: payloadpo.datapo.po_payment_status,
          po_status: payloadpo.datapo.po_status,
          promize_date: payload.databill.promise_date,
          purchase_order_id: payload.databill.purchase_order_id.toString(),
          referensi: payload.databill.referensi,
          tax_inclusive: payloadpo.datapo.tax_inclusive,
          transaction_date: payload.databill.transaction_date,
          trx_code: payload.databill.trx_code,
          vendor_code: payloadpo.datapo.vendor_code,
          vendor_id: payload.databill.vendor_id.toString(),
          vendor_name: payload.databill.vendor_name
        },
        detailbill: payloadpo.detailbill.map(item => {
          return {
            ...item,
            delivered: parseInt(item.delivered),
            discount: parseInt(item.discount),
            mr_unit_id: item.mr_unit_id.toString(),
            product_id: item.product_id.toString(),
            project_id: item.project_id.toString(),
            tax_id: item.tax_id.toString()
          }
        }),
        reminder: null,
        freight: payload.databill.freight,
        trx_code: payload.databill.trx_code,
        probildetail: payload.detailbill.map(item => {
          // Vars
          const podetail = payloadpo.detailbill.find(i => i.product_id === item.product_id)

          return {
            bill_detail_id: item.bill_detail_id,
            mr_unit_id: podetail?.mr_unit_id.toString() ?? "",
            project_id: podetail?.project_id.toString() ?? "",
            project_name: podetail?.project_name ?? "",
            qty_multiply: item.quantity_unit / item.quantity_bill,
            qty_order: podetail ? (podetail.qty_order - parseInt(podetail.delivered ?? 0)) : 0,
            discount: item.discount,
            price_bill: item.price_bill,
            product_id: item.product_id.toString(),
            quantity_bill: item.quantity_bill,
            quantity_unit: item.quantity_unit,
            tax_id: item.tax_id.toString(),
            total: item.total
          }
        })
      })
    })).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { billData, data, isLoading }
}