export const arraysEqual = (
  arr1: number[],
  arr2: number[]
): boolean => {
  if (arr1.length !== arr2.length) return false

  let countMap = new Map()

  for (let elem of arr1) {
      countMap.set(elem, (countMap.get(elem) || 0) + 1)
  }

  for (let elem of arr2) {
      if (!countMap.has(elem)) return false
      countMap.set(elem, countMap.get(elem) - 1)
      if (countMap.get(elem) < 0) return false
  }

  return true
}