// React
import { Fragment, useState } from "react"

// Components
import { ModalDeleteJournal } from "../ModalDeleteJournal"

// Icons
import { IoSendSharp, IoTrash } from "react-icons/io5"

// Third-Party Libraries
import moment from "moment"
import { Link } from "react-router-dom"

// Types
import type { MapJournalList } from "../../types"

// Utils
import { convertNumber } from "utils"
import { generateLinkTransaction } from "../../utils/functions"

interface ItemProps {
  indexParent: number
  item: MapJournalList
  refetchJournal?: () => void
}

export default function Item({ item, refetchJournal }: ItemProps) {
  const dividerClassName = "border-b-[2px] border-b-black"
  const [deleteJournal, setDeleteJournal] = useState<null | {
    trxCode: null | string
    journalCode: null | string
  }>({
    trxCode: null,
    journalCode: null
  })
  const toggleDeleteJournal = () => setDeleteJournal(null)

  return (
    <Fragment>
      {item?.data?.data?.map((itemData, index: number) => {
        const lastItem = item?.data.data.length - 2 === index
        const hasPosition = itemData.position
        const isMemoHeader = index === 0
        const isDebit = itemData.position === 1
        const isLastTransaction = item?.data.data.length - 1 === index
        const navigateTo = generateLinkTransaction({
          data_source: itemData.data_source,
          data_source_id: itemData.data_source_id
        })

        return (
          <tr
            key={index}
            className={
              isLastTransaction
                ? "border-black dark:border-white text-[13px]"
                : "border-opacity-0 text-[13px]"
            }>
            <td>
              {isMemoHeader && (
                <>
                  <Link
                    to={navigateTo}
                    target="_blank"
                    rel="noopener noreferrer">
                    <button
                      color="primary"
                      className="pe-4 rounded-full"
                      title="Collapse">
                      <IoSendSharp />
                    </button>
                  </Link>
                  <span className="font-bold text-[13px]">
                    {moment(item?.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                  </span>
                </>
              )}

              {isLastTransaction && !itemData.reconsiled && (
                <button
                  color="primary"
                  className="pe-4 rounded-full"
                  title="Collapse"
                  onClick={() => {
                    console.log(itemData)
                    setDeleteJournal({
                      journalCode: itemData.journal_code,
                      trxCode: itemData.trx_code
                    })
                  }}>
                  <IoTrash />
                </button>
              )}
            </td>

            <td className={isMemoHeader ? "font-bold" : "font-normal "}>
              {isMemoHeader ? (
                <span className="ps-12">{itemData.source_memo}</span>
              ) : (
                <>
                  <span className="pe-2">{itemData.source_code}</span>

                  {!isLastTransaction && (
                    <span className="ps-4">{itemData.trx_code}</span>
                  )}
                </>
              )}
            </td>

            <td>
              {itemData.coa_code} {itemData.coa_name}
            </td>

            <td className={lastItem ? dividerClassName : ""}>
              {itemData.memo}
            </td>

            <td
              className={`
              text-center 
              ${lastItem ? dividerClassName : ""}
              ${hasPosition ? "font-normal" : "font-bold"}
              `}>
              {hasPosition
                ? isDebit
                  ? convertNumber(itemData.amount).intoCurrency
                  : ""
                : itemData.checkerDebit &&
                  convertNumber(itemData.checkerDebit).intoCurrency}
            </td>

            <td
              className={`
              text-center 
              text-red-400
              ${lastItem ? dividerClassName : ""}
              ${hasPosition ? "font-normal" : "font-bold"}
              `}>
              {hasPosition
                ? isDebit
                  ? ""
                  : convertNumber(itemData.amount).intoCurrency
                : itemData.checkerCredit &&
                  convertNumber(itemData.checkerCredit).intoCurrency}
            </td>

            <td className="text-right">{itemData.job_id ?? ""}</td>
          </tr>
        )
      })}

      {Boolean(deleteJournal?.journalCode && deleteJournal?.trxCode) && (
        <ModalDeleteJournal
          loadingData={false}
          isOpen={Boolean(deleteJournal)}
          toggle={toggleDeleteJournal}
          date={item?.date}
          journalCode={deleteJournal?.journalCode ?? ""}
          refetchJournal={refetchJournal ? refetchJournal : () => null}
          journalList={item?.data?.data?.filter(
            (item) => item.trx_code === deleteJournal?.trxCode
          )}
        />
      )}
    </Fragment>
  )
}
