// React
import { PropsWithChildren } from "react"

// Components
import { Card, DashboardFilter, HeaderMenu } from "components"

export default function Dashboard() {
    return (
        <section className="container my-5">
            <section className="flex flex-col gap-3">
                <HeaderMenu title="SALES DASHBOARD" />
                <DashboardFilter onChangeFilter={() => {}} />

                <Card>
                    <Card.Body>
                        <div className="mx-[80px]">
                            <div className="mb-7">
                                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                                    <TopSection label="SALES BUDGET WEEK | YEAR :" >
                                        <BodyTopSection label="REVENUE" value="" />
                                        <BodyTopSection label="AVERAGE" value="" />
                                        <BodyTopSection label="QTY" value="" />
                                        <BodyTopSection label="NUMBER OF INVOICE" value="" />
                                    </TopSection>
                                    <TopSection label="SALES ACTUAL WEEK | YEAR :">
                                        <BodyTopSection label="REVENUE" value="" />
                                        <BodyTopSection label="AVERAGE" value="" />
                                        <BodyTopSection label="QTY" value="" />
                                        <BodyTopSection label="NUMBER OF INVOICE" value="" />
                                    </TopSection>
                                    <TopSection label="SALES ACTUAL MONTH : CURRENT MONTH">
                                        <BodyTopSection label="REVENUE" value="" />
                                        <BodyTopSection label="AVERAGE" value="" />
                                        <BodyTopSection label="QTY" value="" />
                                        <BodyTopSection label="NUMBER OF INVOICE" value="" />
                                    </TopSection>
                                </div>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-6 md:grid-cols-2 sm:grid-cols-1 gap-1">
                            <BottomSection label="W 01">
                                <BodyBottomSection label="REVENUE" value="25,000,000"/>
                                <BodyBottomSection label="AVERAGE" value="1,250,000"/>
                                <BodyBottomSection label="QTY" value="75"/>
                                <BodyBottomSection label="INVOICES" value="20"/>
                                <BodyBottomSection label="NEW CUSTOMER" value="5"/>
                                <BodyBottomSection label="OLD CUSTOMER" value="15"/>
                            </BottomSection>
                            <BottomSection label="W 02">
                                <BodyBottomSection label="REVENUE" value=""/>
                                <BodyBottomSection label="AVERAGE" value=""/>
                                <BodyBottomSection label="QTY" value=""/>
                                <BodyBottomSection label="INVOICES" value=""/>
                                <BodyBottomSection label="NEW CUSTOMER" value=""/>
                                <BodyBottomSection label="OLD CUSTOMER" value=""/>
                            </BottomSection>
                            <BottomSection label="W 03">
                                <BodyBottomSection label="REVENUE" value=""/>
                                <BodyBottomSection label="AVERAGE" value=""/>
                                <BodyBottomSection label="QTY" value=""/>
                                <BodyBottomSection label="INVOICES" value=""/>
                                <BodyBottomSection label="NEW CUSTOMER" value=""/>
                                <BodyBottomSection label="OLD CUSTOMER" value=""/>
                            </BottomSection>
                            <BottomSection label="W 04">
                                <BodyBottomSection label="REVENUE" value=""/>
                                <BodyBottomSection label="AVERAGE" value=""/>
                                <BodyBottomSection label="QTY" value=""/>
                                <BodyBottomSection label="INVOICES" value=""/>
                                <BodyBottomSection label="NEW CUSTOMER" value=""/>
                                <BodyBottomSection label="OLD CUSTOMER" value=""/>
                            </BottomSection>
                            <BottomSection label="CURRENT MONTH:">
                                <BodyBottomSection label="REVENUE" value=""/>
                                <BodyBottomSection label="AVERAGE" value=""/>
                                <BodyBottomSection label="QTY" value=""/>
                                <BodyBottomSection label="INVOICES" value=""/>
                                <BodyBottomSection label="NEW CUSTOMER" value=""/>
                                <BodyBottomSection label="OLD CUSTOMER" value=""/>
                            </BottomSection>
                            <BottomSection label="TOTAL AR | YTD">
                                <BodyBottomSection label="TOTAL" value="1,250,000,000"/>
                                <BodyBottomSection label="AR 7 DAYS" value="125,000,000"/>
                                <BodyBottomSection label="AR 15 DAYS" value="375,000,000"/>
                                <BodyBottomSection label="AR 21 DAYS" value="475,000,000"/>
                                <BodyBottomSection label="AR 30 DAYS" value="45,000,000"/>
                                <BodyBottomSection label="AR 45 + DAYS" value="230,000,000"/>
                            </BottomSection>
                        </div>
                    </Card.Body>
                </Card>
            </section>
        </section>
    )
}

function TopSection(props: PropsWithChildren<{
    label?: string
    className?: string
}>): JSX.Element {
    return (
        <div className={`card card-compact dark:bg-black border-[1px] border-[#dadada] pb-3 ${props.className}`}>
            <div className="p-3 bg-black flex justify-center dark:border dark:border-white">
                <span className={`font-bold text-white `}>{props.label}</span>
            </div>
            <div className="mx-3">
                {props.children}
            </div>
        </div>
    )
}

function BodyTopSection(props: {
    label?: string
    value?: string
}) {
    return (
        <div className="flex flex-col gap-2 my-2">
            <div className="flex">
                <span className="w-[120px] text-[12px] font-bold">{props.label}</span>
                <span className="mx-2 w-[10px] text-center">:</span>
                <span className="w-[80px] text-[12px] font-bold">{props.value}</span>
            </div>
        </div>
    )
}

function BottomSection(props: PropsWithChildren<{
    label?: string
}>) {
    return (
        <div className="card card-compact dark:bg-black border-[1px] border-[#dadada] pb-3">
            <div className="p-2 bg-black flex justify-center dark:border dark:border-white">
                <span className="font-bold text-white">{props?.label}</span>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}

function BodyBottomSection(props:{
    label?: string
    value?: string
}) {
    return (
        <div className="flex flex-col">
            <div className="border-t border-[#dadada] mb-2">
                <div className="text-center my-2 text-[14px] font-bold text-slate-400">{props?.label}</div>
                <div className="my-2 font-bold text-center text-2xl h-[40px]">
                    {props?.value}
                </div>
            </div>
        </div>
    )
}



