/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { today, useApi } from "utils"
import { useDetailInvoice } from "../Invoice/utils/hooks/useDetailInvoice"
import { FormSection } from "./components"
import { paymentInvoiceSchema, PaymentInvoiceType } from "./types"
import { submitMap } from "./utils/functions"
import { useDetailPaymentInvoice } from "./utils/hooks"

export default function PaymentInvoiceEdit() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const paymentInvoice = useDetailPaymentInvoice({
    payment_invoice_id: params?.payment_invoice_id ?? ""
  })
  const invoice = useDetailInvoice({
    invoice_id: paymentInvoice.data?.invoice_id?.toString() ?? ""
  })

  const defaultValues: PaymentInvoiceType = useMemo(() => {
    const balanceInvoice = invoice.data?.datainvoice.balance ?? 0
    const paymentAmount = paymentInvoice.data?.payment_amount ?? 0
    const totalDeposit = paymentInvoice.data?.deposit_amount ?? 0

    return {
      payment_invoice_id: paymentInvoice.data?.payment_invoice_id,
      invoice_id: invoice.data?.datainvoice.invoice_id,
      customer_id: paymentInvoice.data?.customer_id ?? NaN,
      location_id: paymentInvoice.data?.location_id ?? NaN,
      coa_id: paymentInvoice.data?.coa_id ?? NaN,
      coa_name: paymentInvoice.data?.coa_name ?? "",
      coa_balance: 0,
      balance_invoice: balanceInvoice,
      balance: 0,
      link: invoice.data?.datainvoice?.trx_code ?? "",
      trx_code: paymentInvoice.data?.trx_code,
      no_cheque: paymentInvoice.data?.no_cheque ?? "",
      transaction_date: paymentInvoice.data?.transaction_date ?? today(),
      deposit_amount: totalDeposit,
      payment_amount: paymentAmount,
      memo: paymentInvoice.data?.memo ?? "Payment Invoice transaction",
      shipped: paymentInvoice.data?.address ?? "",
      referensi: paymentInvoice.data?.referensi ?? ""
    }
  }, [invoice.data])

  const onSubmit = async (value: PaymentInvoiceType) => {
    await toast
      .promise(api.put("/paymentinvoice/update", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate(-1)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<PaymentInvoiceType>({
    defaultValues,
    resolver: yupResolver(paymentInvoiceSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection isEdit onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
