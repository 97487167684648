import { DateInput, Label } from "components"
import moment from "moment"
import { useFormContext, useWatch } from "react-hook-form"

export const DateTask = () => {
  const { control, setValue } = useFormContext()
  const [date_start, date_end] = useWatch({
    control,
    name: ["date_start", "date_end"]
  })

  return (
    <>
      <Label text="DATE" />

      <div className="w-full flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
        <DateInput
          className="w-[40%]"
          placeholderText="Date Start"
          onChange={(date) =>
            setValue("date_start", moment(date).format("YYYY-MM-DD"))
          }
          selected={moment(date_start).toDate()}
        />

        <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold">To</div>
        <DateInput
          className="w-[40%]"
          placeholderText="Date End"
          minDate={moment(date_start).toDate()}
          selected={moment(date_end).toDate()}
          onChange={(date) =>
            setValue("date_end", moment(date).format("YYYY-MM-DD"))
          }
        />
      </div>
    </>
  )
}
