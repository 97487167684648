import { Input, Label, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ListDepartement } from "../../types"
import { useDivisi } from "pages/Card/Transaction/CreateNewEmployee/components/FormSection/utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<ListDepartement>()

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="department_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Code" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Divisi isDisabled={props.action === "DETAIL" || props.action === "DELETE"} />

        <Controller
          control={control}
          name="department_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DEPARTMENT" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input department" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" /></td>
              <td>
                <Textarea 
                  {...field} 
                  placeholder="Input Description" 
                  value={field.value ?? ""}
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  error={fieldState.error?.message}
                />
              </td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}

const Divisi = ({ isDisabled }: { isDisabled: boolean }) => {
  const { control } = useFormContext<ListDepartement>()
  const { data, isLoading, refetch } = useDivisi()

  return (
    <Controller
      control={control}
      name="divisi_id"
      render={({ field, fieldState }) => (
        <tr>
          <td><Label text="DIVISI" error={Boolean(fieldState.error)} /></td>
          <td>
            <Select
              placeholder="Select divisi..."
              isDisabled={isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => {
                field.onChange(e?.value)
                refetch()
              }}
            />
          </td>
        </tr>
      )}
    />
  )
}