import { ListGroupTask } from "pages/Setup/Preference/GroupTask/types"
import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"

export const useDropdownGroupTask = () => {
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/group-task/")
    .then((res: SuccessFetch<{ payload: ListGroupTask[] }>) => { 
      setData(res.data.payload.map(item => { return { label: item.mr_group_task_name, value: item.mr_group_task_id.toString() }}))
    })
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}