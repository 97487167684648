import { Checkbox } from "components"
import { FilterReport } from "pages/Finance/Report/types"
import { createRef } from "react"
import ReactDatePicker from "react-datepicker"
import { useFormContext } from "react-hook-form"
import { useToggle } from "utils"
import { FilterType } from "../../../FilterType"
import { Periode } from "../../../Periode"
import { Date } from "./components"

export type FilterBSProps = {
  toggleAccountNumber: ReturnType<typeof useToggle>
  toggleZeroValue: ReturnType<typeof useToggle>
  toggleSubHeader: ReturnType<typeof useToggle>
  onSubmit: (data: FilterReport) => void
}

export function FilterBS({ toggleAccountNumber, toggleZeroValue, toggleSubHeader, onSubmit }: FilterBSProps) {
  const inputDateRef = createRef<ReactDatePicker>()
  const buttonDateRef = createRef<HTMLDivElement>()
  const toggleReadOnly = useToggle(true)
  const toggleButtonDate = useToggle(false)
  const toggleButtonPeriod = useToggle(false)
  const methods = useFormContext<FilterReport>()

  return (
    <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between items-end pt-5">
      <div className="w-full lg:w-4/6 flex-col lg:flex lg:flex-row justify-start md:justify-start items-end">
        <div className="w-full relative">
          <Date
            buttonDateRef={buttonDateRef}
            inputDateRef={inputDateRef}
            toggleButtonDate={toggleButtonDate}
            toggleReadOnly={toggleReadOnly}
          />
        </div>

        <div className="w-full lg:ms-4 flex justify-start items-end ">
          <Periode
            buttonDateRef={buttonDateRef}
            toggleButtonPeriod={toggleButtonPeriod}
          />
        </div>

        <div className="w-full lg:ms-4 lg:w-2/4">
          <FilterType type="balanceSheet" />
        </div>

        <div className="w-full lg:ms-4 mt-8 lg:w-1/4">
          <button
            type="button"
            disabled={methods.formState.isSubmitting}
            onClick={() => methods.handleSubmit((data) => onSubmit(data))()}
            className="w-full btn bg-black dark:bg-primary text-white dark:btn-primary rounded-lg">
            {methods.formState.isSubmitting && (
              <span className="loading loading-spinner" />
            )}
            Update
          </button>
        </div>
      </div>

      <div className="w-full lg:w-2/6 lg:ms-4 mt-8 flex pb-1 justify-end items-end">
        <section className="px-2">
          <Checkbox
            defaultChecked={toggleSubHeader.isActive}
            onChange={(e) => toggleSubHeader.setActive(e.target.checked)}>
            Sub Header
          </Checkbox>
        </section>

        <section className="px-2">
          <Checkbox
            defaultChecked={toggleZeroValue.isActive}
            onChange={(e) => toggleZeroValue.setActive(e.target.checked)}>
            Zero Value
          </Checkbox>
        </section>

        <section className="px-2">
          <Checkbox
            defaultChecked={toggleAccountNumber.isActive}
            onChange={(e) => toggleAccountNumber.setActive(e.target.checked)}>
            Account Number
          </Checkbox>
        </section>
      </div>
    </section>
  )
}
