import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { ListCategoryVendor } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"

export function CreateModal(props: {
  category_vendor_code?: string
  category_vendor_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("category_vendor_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateListCategoryVendor = Omit<ListCategoryVendor, "category_vendor_id"> 

  const defaultValues:CreateListCategoryVendor= {
    category_vendor_code: "",
    category_vendor_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateListCategoryVendor) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/categoryvendor/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListCategoryVendor>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE CATEGORY VENDOR" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for the "Vendor Category" in an ERP system, it is important to clearly specify the criteria for classifying different types of vendors (suppliers). This categorization helps organizations manage vendor relationships, optimize procurement strategies, streamline purchasing processes, and enhance reporting and analysis capabilities. Here is a comprehensive explanation of the data attributes for the "Vendor Category":`}
                detail={`These data attributes are critical for accurately defining and managing vendor categories in the ERP system. They help improve procurement efficiency, ensure strategic supplier relationships, and support better decision-making through organized and consistent vendor information.`}
                title=""
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each vendor category."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a simple and consistent reference for identifying different vendor categories. It aids in data entry, searching, reporting, and ensures that there is no duplication or ambiguity when categorizing vendors."
                      },
                      {
                        label: "Example",
                        value: `Codes like "VC01" for "Raw Material Supplier," "VC02" for "Service Provider," or "VC03" for "Equipment Supplier" help differentiate between various vendor types.`
                      }
                    ]
                  },
                  {
                    label: "Vendor Category",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the vendor category that describes the type or nature of the vendor."
                      },
                      {
                        label: "Purpose",
                        value: "The vendor category helps group vendors according to their primary function or the type of goods/services they provide. This grouping is essential for managing vendor relationships, optimizing supply chains, and implementing targeted procurement strategies."
                      },
                      {
                        label: "Example",
                        value: `-"Raw Material Supplier" — Vendors that supply essential raw materials needed for production. 
                                -"Service Provider" — Vendors that provide services like consulting, maintenance, logistics, etc. 
                                -"Equipment Supplier" — Vendors that supply machinery, tools, and other equipment. 
                                -"Office Supplies Vendor" — Vendors that provide general office supplies like stationery, furniture, and computers.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the vendor category."
                      },
                      {
                        label: "Purpose",
                        value: "The description provides clarity about the criteria, conditions, or characteristics defining each vendor category. It helps users understand the scope and purpose of each category, which aids in vendor management, negotiation, and reporting."
                      },
                      {
                        label: "Example",
                        value: `-For "Raw Material Supplier," the description might be: "Vendors that supply base materials such as steel, plastic, wood, and other inputs essential for manufacturing processes." 
                                -For "Service Provider," it could read: "Vendors that offer professional services, including legal, accounting, IT support, and logistics." 
                                -For "Equipment Supplier," it might state: "Vendors that supply capital equipment, tools, and machinery used in production or operations." 
                                -For "Office Supplies Vendor," it might note: "Vendors that provide general office supplies, including stationery, furniture, and office electronics."`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}