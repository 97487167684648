import { useApi } from "utils";
import { FillterType, GraphValueType } from "../../types";
import { useEffect, useState } from "react";
import { DataTypePaging, SuccessFetch } from "types";

export function useIncome({type, month, year, quarter, start_date, end_date, location_id}: FillterType) {

    const defaultValue = {
        payload: [],
        pagination_data: {
            page: 0,
            pages: 0,
            size: 0,
            total: 0
        }
    }

    const api = useApi();
    const [dataIncome, setData] = useState<DataTypePaging<GraphValueType>>(defaultValue);
    const [isLoading, setLoading] = useState(true);
    

    const params: { [key: string]: number | string | undefined } = { 
        ...(type === "Date" && {
            start_date,
            end_date,
            aggreate_by: 'daily',
            ...(location_id !== "" ? { location_id } : {})
        }),
        ...(type === "Month" && {
            month,
            year,
            aggreate_by: 'monthly',
            ...(location_id !== "" ? { location_id } : {})
        }),
        ...(type === "Quarter" && {
            quarter,
            year,
            aggreate_by: 'quarterly',
            ...(location_id !== "" ? { location_id } : {})
        }),
        ...(type === "Year" && {
            year,
            aggreate_by: 'yearly',
            ...(location_id !== "" ? { location_id } : {})
        }),
    };

    const fetchUrl = () => {
        return api.get("/dashboard/profitability/income-graph/?", { params })
    }

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<DataTypePaging<GraphValueType>>) => {
            setData(res.data);
        }).catch(() => {
            setData(defaultValue);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
        return () => {
            setData(defaultValue);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [type, start_date, end_date, month, year, quarter, location_id]);

    return { fetchData, dataIncome, isLoading }
}

export function useExpense({type, month, year, quarter, start_date, end_date, location_id}: FillterType) {

    const defaultValue = {
        payload: [],
        pagination_data: {
            page: 0,
            pages: 0,
            size: 0,
            total: 0
        }
    }

    const api = useApi();
    const [dataExpense, setData] = useState<DataTypePaging<GraphValueType>>(defaultValue);
    const [isLoading, setLoading] = useState(true);
    

    const params: { [key: string]: number | string | undefined } = { 
        ...(type === "Date" && {
            start_date,
            end_date,
            aggreate_by: 'daily',
            ...(location_id !== "" ? { location_id } : {})
        }),
        ...(type === "Month" && {
            month,
            year,
            aggreate_by: 'monthly',
            ...(location_id !== "" ? { location_id } : {})
        }),
        ...(type === "Quarter" && {
            quarter,
            year,
            aggreate_by: 'quarterly',
            ...(location_id !== "" ? { location_id } : {})
        }),
        ...(type === "Year" && {
            year,
            aggreate_by: 'yearly',
            ...(location_id !== "" ? { location_id } : {})
        }),
    };

    const fetchUrl = () => {
        return api.get("/dashboard/profitability/expenses-graph/?", { params })
    }

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<DataTypePaging<GraphValueType>>) => {
            setData(res.data);
        }).catch(() => {
            setData(defaultValue);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
        return () => {
            setData(defaultValue);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [type, start_date, end_date, month, year, quarter, location_id]);

    return { fetchData, dataExpense, isLoading }
}