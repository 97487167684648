// Components
import { Input, Label, Select, Texteditor } from "../../../../../../components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { DataList } from "../../types"

// Utils
import { useDivisi } from "pages/Card/Transaction/CreateNewEmployee/components/FormSection/utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {
  const { control } = useFormContext<DataList>()

  return (
    <table className="table form-table">
      <tbody>
        <Controller
          control={control}
          name="job_position_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Code" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Divisi disabled={props.action === "DETAIL" || props.action === "DELETE"} />

        <Controller
          control={control}
          name="job_position_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="JOB POSITION" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input job position" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" error={Boolean(fieldState.error)} /></td>
              <td>
                <Texteditor
                  {...field}
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  error={fieldState.error?.message} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="kpi"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="KPI" error={Boolean(fieldState.error)} /></td>
              <td>
                <Texteditor
                  {...field}
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  error={fieldState.error?.message} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="qualification"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="SKILL QUALIFICATION" error={Boolean(fieldState.error)} /></td>
              <td>
                <Texteditor
                  {...field}
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  error={fieldState.error?.message} 
                />
              </td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}

function Divisi(params: {
  disabled: boolean
}): JSX.Element {
  // Hooks
  const { data, isLoading } = useDivisi()

  // Form
  const { control } = useFormContext<DataList>()

  return (
    <Controller
      name="divisi_id"
      control={control}
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="DIVISI"
              error={Boolean(fieldState.error)}
            />
          </td>
    
          <td>
            <Select
              placeholder="Select Divisi"
              error={fieldState.error?.message}
              isDisabled={params.disabled}
              isLoading={isLoading}
              options={data}
              value={data.find(item => item.value?.toString() === field.value?.toString()) ?? null}
              onChange={res => {
                if (res) {
                  field.onChange(res.value)
                }
              }}
            />
          </td>
        </tr>
      )}
    />
  )
}