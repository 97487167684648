// Components
import { Card, DashboardFilter, FooterMenu, HeaderMenu } from "components"

export default function Dashboard() {
    const TopSection = (props: {
        value: string,
        label: string
    }) => (
        <div className="flex justify-center items-center">
            <div className="flex flex-col gap-2 my-1">
                <div className="grid grid-cols-2 gap-3">
                    <span className="text-right text-[12px] font-bold">{props?.label}</span>
                    <span className="text-[12px] font-bold" >: {props.value}</span>
                </div>
            </div>
        </div>
    )

    const MiddleSection = (props: {
        label: string
        value: string
    }) => (
        <tr className=" border-[#3c3c3c] border-b dark:border-[#3c3c3c]">
            <td className="text-right w-[60%] font-bold text-nowrap">{props?.label} : </td>
            <td className="w-[40%] font-bold">{props?.value}</td>
        </tr>
    )

    return (
        <section className="container my-5 flex flex-col gap-3">
            <HeaderMenu title="PURCHASE DASHBOARD" />
            <DashboardFilter onChangeFilter={() => {}} />

            <Card>
                <Card.Body>
                    <div className="mx-[100px]">
                        <div className="mb-7">
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                                <Card className=" dark:bg-black pb-3">
                                    <div className="p-3 bg-black flex justify-center dark:border dark:border-white">
                                        <span className="font-bold text-white">TOTAL PURCHASE THIS WEEK</span>
                                    </div>
                                    <TopSection label="ON ORDER" value="" />
                                    <TopSection label="QTY ON ORDER" value="" />
                                    <TopSection label="ON DELIVERED" value="" />
                                    <TopSection label="QTY BACK ORDER" value="" />
                                </Card>
                                <Card className=" dark:bg-black pb-3">
                                    <div className="p-3 bg-black flex justify-center dark:border dark:border-white">
                                        <span className="font-bold text-white">TOTAL PURCHASE THIS MONTH</span>
                                    </div>
                                    <TopSection label="ON ORDER" value="" />
                                    <TopSection label="QTY ON ORDER" value="" />
                                    <TopSection label="ON DELIVERED" value="" />
                                    <TopSection label="QTY BACK ORDER" value="" />
                                </Card>
                                <Card className=" dark:bg-black pb-3">
                                    <div className="p-3 bg-black flex justify-center dark:border dark:border-white">
                                        <span className="font-bold text-white">TOTAL PURCHASE YTD</span>
                                    </div>
                                    <TopSection label="ON ORDER" value="" />
                                    <TopSection label="QTY ON ORDER" value="" />
                                    <TopSection label="ON DELIVERED" value="" />
                                    <TopSection label="QTY BACK ORDER" value="" />
                                </Card>
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-2">
                                <div className="lg:w-[370px] w-full ">
                                    <div className="text-lg"><b>PURCHASE ACTIVITIES</b></div>
                                    <table className="table table-sm w-[100%]">
                                        <tbody>
                                            <MiddleSection label="Total Purchase" value="-" />
                                            <MiddleSection label="Purchase by Supplier" value="-" />
                                            <MiddleSection label="Purchase by Category or Item" value="-" />
                                            <MiddleSection label="Purchase Order Cycle Time" value="-" />
                                            <MiddleSection label="Inventory Turn Over Ratio" value="-" />
                                            <MiddleSection label="Purchase by Category or Item" value="-" />
                                        </tbody>
                                    </table>
                                </div>
                                <div className="lg:w-[370px] w-full">
                                    <div className="text-lg"><b>SUPPLIER PERFORMANCE METRICS</b></div>
                                    <table className="table table-sm w-[100%]">
                                        <tbody>
                                            <MiddleSection label="Select Vendor Name" value="-" />
                                            <MiddleSection label="Quality Product Deliver" value="-" />
                                            <MiddleSection label="On Time Deliver" value="-" />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div>
                                <div className="text-lg"><b>ACCOUNT PAYABLE</b></div>
                                <div className="my-3 w-[250px]">
                                    <div className="border-b border-[#3c3c3c] dark:border-[#3c3c3c] pb-2 flex justify-center">
                                        <span><b>Turnover Ratio :</b></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="overflow-x-auto mt-5">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>INV ID#</th>
                                                <th>VENDOR NAME</th>
                                                <th>BALANCE</th>
                                                <th>7 DAYS</th>
                                                <th>15 DAYS</th>
                                                <th>21 DAYS</th>
                                                <th>30 DAYS</th>
                                                <th>40 DAYS</th>
                                                <th>60 DAYS</th>
                                                <th>90 DAYS</th>
                                                <th>MEMO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="w-[200px]">-</td>
                                                <td className="w-[200px]">-</td>
                                                <td>-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[100px]">-</td>
                                                <td className="w-[200px]">-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <FooterMenu/>
        </section>
    )
}