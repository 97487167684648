import { ActionButton, Button, Input } from "components"
import { Fragment, useRef, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import toast from "react-hot-toast"
import { TbTrash } from "react-icons/tb"
import { useApi } from "utils"
import { AttachmentType } from "../types"
import { generatePath } from "../utils/functions"
import { PicturesSection } from "./PictureSection"

export const FormAttachments = () => {
  const api = useApi()
  const [file, setFile] = useState<AttachmentType | null>(null)
  const attachmentRef = useRef<HTMLInputElement>(null)
  const methods = useFormContext()
  const attachments = useWatch({
    control: methods.control,
    name: "attachments"
  })

  const handleClickAttachment = () => {
    if (attachmentRef?.current && !file) {
      attachmentRef.current.click()
    }
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]

    if (file) {
      const url = await api
        .postForm("/task_action/uploadfile", { filefile: file })
        .then((res) => generatePath(res.data.payload?.namafile))

      setFile({
        url,
        name: file?.name
      })
    }
  }

  const handleDeleteAttachment = (att: AttachmentType, index: number) => {
    if (att?.file) {
      return methods.setValue(
        "attachments",
        methods.getValues()?.attachments.toSpliced(index, 1)
      )
    }

    const path = att?.url?.split("=").pop()

    api
      .post("/task_action/removefile?strPath=" + path)
      .then(() =>
        methods.setValue(
          "attachments",
          methods.getValues()?.attachments.toSpliced(index, 1)
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  const onAppendAttachment = () => {
    if (file) {
      methods.setValue(
        "attachments",
        methods.getValues().attachments.concat(file)
      )
      setFile(null)
    }
  }

  return (
    <Fragment>
      <section className="flex flex-col justify-start items-start gap-3">
        <section className="flex justify-start items-start gap-3">
          <div className="hidden">
            <input
              ref={attachmentRef}
              type="file"
              placeholder="Attachment"
              onChange={handleFileChange}
            />
          </div>

          <div className="flex gap-x-3 justify-center items-center">
            <Input
              onClick={handleClickAttachment}
              label="Attachment"
              placeholder="Choose file"
              value={file?.url ? file?.url : ""}
            />

            <Input
              label="Name"
              placeholder="Insert name"
              value={file?.name ? file?.name : ""}
              onChange={(e) => {
                if (file) {
                  setFile((prev) => ({
                    file: prev?.file,
                    url: prev?.url ?? "",
                    name: e.target.value
                  }))
                }
              }}
            />

            <ActionButton.Add
              disabled={!file}
              className="mt-9 mx-3 bg-primary"
              onClick={onAppendAttachment}
            />
          </div>
        </section>

        {attachments?.length ? (
          <div className="max-h-[400px] w-full">
            <PicturesSection
              items={1}
              dataPicture={attachments}
              className="h-full w-full"
            />
          </div>
        ) : null}

        <section className="mt-3">
          {attachments?.map((attachment: AttachmentType, index: number) => (
            <div
              key={attachment.url}
              className="flex justify-start items-center">
              <div className="me-1">{index + 1}.</div>
              <div className="w-full">{attachment.name}</div>
              <div className="mt-2">
                <Button
                  color="ghost"
                  title="Delete Data"
                  className="text-error btn-sm"
                  onClick={() => handleDeleteAttachment(attachment, index)}>
                  <TbTrash className="size-5" />
                </Button>
              </div>
            </div>
          ))}
        </section>
      </section>
    </Fragment>
  )
}
