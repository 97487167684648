import moment from "moment"
import { Fragment } from "react"
import { TaskActionList } from "../types"
import { ACTION_STATUS } from "../utils/vars"
import { PicturesSection } from "./PictureSection"
import { generateAsetFile } from "../utils/functions"

type CardHistoryTaskProps = {
  actionTask: TaskActionList
  no: number
}

export const CardHistoryTask = ({ actionTask, no }: CardHistoryTaskProps) => {
  const bgColor =
    actionTask.status === 1
      ? "bg-notstarted"
      : actionTask.status === 2
        ? "bg-inprogress"
        : actionTask.status === 3
          ? "bg-completed"
          : "bg-cancelled"

  return (
    <Fragment>
      <div className={`${bgColor} p-3 text-black`}>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <section>
            <table>
              <tbody>
                <tr>
                  <td>No</td>
                  <td>: {no}</td>
                </tr>
                <tr>
                  <td>Action ID</td>
                  <td>: {actionTask.trx_code}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>: {ACTION_STATUS[actionTask.status ?? 1]}</td>
                </tr>
                <tr>
                  <td>Action</td>
                  <td>: {actionTask.mr_action_task_name ?? ""}</td>
                </tr>
                <tr>
                  <td>Assign To</td>
                  <td>: {actionTask.assign_to_name}</td>
                </tr>
                <tr>
                  <td>Action Date</td>
                  <td>
                    :{" "}
                    <span className="text-red-500">
                      {actionTask?.date_start
                        ? moment(actionTask?.date_start).format("ddd, DD MMM YYYY")
                        : ""}
                    </span>{" "}
                    |{" "}
                    <span className="text-blue-500">
                      {actionTask?.date_end
                        ? moment(actionTask?.date_end).format("ddd, DD MMM YYYY")
                        : ""}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="font-bold mt-5">Description: </div>
            <p className="mb-3">{actionTask.description}</p>
          </section>

          <section>
            {actionTask?.task_action_files?.length ? (
              <PicturesSection
                height="450px"
                dataPicture={
                  generateAsetFile(actionTask?.task_action_files ?? []) ?? []
                }
              />
            ) : null}
          </section>
        </div>

        <div className="w-full flex flex-col gap-1 justify-end items-end mt-3 text-sm">
          <span>
            C:{" "}
            {actionTask.create_at
              ? moment(actionTask.create_at).format("DD/MM/YYYY")
              : "-"}{" "}
            {actionTask.create_by_name ? `| ${actionTask.create_by_name}` : ""}
          </span>
          <span>
            M:{" "}
            {actionTask.update_at
              ? moment(actionTask.update_at).format("DD/MM/YYYY")
              : "-"}{" "}
            {actionTask.update_by_name ? `| ${actionTask.update_by_name}` : ""}{" "}
          </span>
        </div>
      </div>
    </Fragment>
  )
}
