// React
import { useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { ProductList } from "pages/Inventori/Transaction/AddNewItem/types"

// Utils
import { useApi } from "utils"

export function useProductDetail(id: string) {
  // Hooks
  const api = useApi()
  const [isLoading, setLoading] = useState(false)

  const fetch = (): Promise<ProductList | null> => {
    return new Promise<ProductList | null>((resolve, reject) => {
      setLoading(true)

      api.get("/product/byid", { params: { product_id: id } })
      .then((res: SuccessFetch<{ payload: ProductList }>) => resolve(res.data.payload))
      .catch(() => reject(null))
      .finally(() => setLoading(false))
    })
  }

  return { isLoading, fetch }
}