import { useEffect, useState } from "react"
import type { ErrorFetch, SuccessFetch } from "../../../../../../../../../../types"
import { AddressList, PersonalList, ProfileList } from "../../../../../../types"
import { useApi } from "../../../../../../../../../../utils"

export function useDetail() {
  const api = useApi()
  const [data, setData] = useState<PersonalList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/productcode")
    .then((res: SuccessFetch<{ payload: PersonalList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}
export function useDetailPersonalData(id: number) {
  const api = useApi()
  const [dataDetail, setData] = useState<PersonalList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/byid", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: PersonalList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataDetail, dataError, isLoading }
}
export function useDetailProfileData(id: number) {
  const api = useApi()
  const [dataProfile, setData] = useState<ProfileList | null>(null)
  const [errorProfile, setError] = useState<string | undefined>(undefined)
  const [isLoadingProfile, setLoading] = useState(true)

  const refetchProfile = () => {
    setLoading(true)

    api.get("/customer/profile", { params: { customer_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/customer/profile", { params: { customer_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataProfile, errorProfile, isLoadingProfile, refetchProfile }
}
export function useDetailAddressData(id: number) {
  const api = useApi()
  const [dataAddress, setData] = useState<AddressList | null>(null)
  const [errorAddress, setError] = useState<string | undefined>(undefined)
  const [isLoadingAddress, setLoading] = useState(true)

  const refetchAddress = () => {
    setLoading(true)

    api.get("/customer/address", { params: { customer_id: id } })
    .then((res: SuccessFetch<{ payload: AddressList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/customer/address", { params: { customer_id: id } })
    .then((res: SuccessFetch<{ payload: AddressList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataAddress, errorAddress, isLoadingAddress, refetchAddress }
}
export function useDetailMapData(id: number) {
  const api = useApi()
  const [dataMap, setData] = useState<ProfileList | null>(null)
  const [errorMap, setError] = useState<string | undefined>(undefined)
  const [isLoadingMap, setLoading] = useState(true)

  const refetchMap = () => {
    setLoading(true)

    api.get("/customer/linkmap", { params: { customer_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/customer/linkmap", { params: { customer_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataMap, errorMap, isLoadingMap, refetchMap }
}

export function usePersonalCode() {
  const api = useApi()
  const [dataCode, setData] = useState<PersonalList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/customer/gencode")
    .then((res: SuccessFetch<{ payload: PersonalList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataCode, dataError, isLoading }
}