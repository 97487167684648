import { yupResolver } from "@hookform/resolvers/yup"
import { Button, HintList, Modal } from "components"
import { useContext, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useApi } from "utils"
import type { DataList } from "../types"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"
import { FormSection } from "./FormSection"

export function CreateModal(props: {
  mr_product_size_code?: string
  mr_product_size_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_size_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_size_id">

  const defaultValues: CreateData = {
    mr_product_size_code: "",
    mr_product_size_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productsize/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => { }).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`Defining data attributes for "Product/Item Size" in an ERP system is crucial for inventory management, order fulfillment, and ensuring customer satisfaction. Accurate size categorization allows for efficient storage, sorting, and retrieval of inventory, facilitating precise operations. Here’s a comprehensive explanation of the data attributes for "Product/Item Size":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each size."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a quick and standardized reference for identifying different sizes within the ERP system. It simplifies data entry, enhances searchability, and ensures consistency in recording and tracking products by size."
                      },
                      {
                        label: "Example",
                        value: `Codes like "SZ01" for "Small," "SZ02" for "Medium," or "SZ03" for "Large" enable users to efficiently differentiate and manage inventory based on size specifications.`
                      }
                    ]
                  },
                  {
                    label: "Product Size",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title that indicates the size of the product."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the physical dimensions or size category of the product, crucial for logistics, sales, and customer service. Accurate size identification helps in fulfilling customer orders accurately, managing warehouse space, and planning product displays."
                      },
                      {
                        label: "Example",
                        value: `"Small" — Suitable for products that fall into the smaller range of the sizing spectrum. 
                                "Medium" — Represents the middle range, often the most commonly stocked size. 
                                "Large" — Used for products that are larger than the average. 
                                Additional examples might include numeric or descriptive sizes such as "16 oz," "Size 10," or "XXL."`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product size."
                      },
                      {
                        label: "Purpose",
                        value: "The description offers insights into the specific dimensions or fitting information associated with the size, helping customers and staff understand how the size relates to actual product use or fit. This can be particularly important in industries where size affects functionality or user experience."
                      },
                      {
                        label: "Example",
                        value: `For "Small," the description might be: "Best suited for compact spaces or children's products. Dimensions: 10x5 inches." 
                                For "Medium," it could read: "Ideal for average adult users. Dimensions: 15x7 inches." 
                                For "Large," it might state: "Designed for larger spaces or for use in industrial settings. Dimensions: 20x10 inches." 
                                For "Size 10," the description could include: "Fits foot length 10-11 inches. Commonly purchased size for adult footwear."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are vital for effectively managing the size aspect of products within the ERP system. They enable organizations to maintain accurate inventory records, optimize storage and shipping processes, and enhance customer satisfaction through precise product descriptions and availability."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}