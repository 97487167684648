// Components
import { BaseTable, FooterMenu, HeaderMenu, Select } from "components"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import { Link } from "react-router-dom"

export default function RecordReject(): JSX.Element {
  return (
    <div className="container my-5 flex flex-col gap-3">
      <HeaderMenu title="DATA ENTRY | RECORD REJECT" />

      <div className="w-[300px]">
        <Select
          label="STATUS REJECT"
          placeholder="Select Status"
        />
      </div>

      <BaseTable>
        <thead>
          <tr>
            <th>NO</th>
            <th colSpan={2}>PROJECT ID#</th>
            <th>DATE</th>
            <th>LINK BILL ID#</th>
            <th>VENDOR</th>
            <th>QTY REJECT</th>
            <th>TOTAL</th>
            <th>LOCATION</th>
            <th>MEMO</th>
            <th>STATUS APPROVE</th>
            <th>STATUS REJECT</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="text-center">1</td>

            <td>
              <Link to="/purchase/transaction/record-reject/1">
                <IoSendSharp />
              </Link>
            </td>

            <td>RJ20240500004</td>
            <td>16/05/2024</td>
            <td>BL20240500004</td>
            <td>PT MAJU JAYA</td>
            <td>40</td>
            <td>20.000</td>
            <td>Headoffice</td>
            <td>Rusak 1</td>
            <td>PENDING</td>
            <td>OPEN</td>
          </tr>
        </tbody>
      </BaseTable>

      <FooterMenu />
    </div>
  )
}