// React
import { useContext } from "react"

// Components
import { ActionButton, ErrorText, NumberInput } from "components"

// Contexts
import { DataContext, ListContext, ParamsContext } from "../contexts"

// Form
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Types
import { FormType, type DetailType, type FormDetailType, validationDetailSchema } from "../utils"

// Utils
import { useToggle } from "utils"
import { DetailProduct } from "pages/Purchase/components"

export function TableRow(params: {
  remove: () => void
}) {
  // Hooks
  const { detail } = useContext(DataContext)
  const { index, item } = useContext(ListContext)
  const { isDisabled } = useContext(ParamsContext)
  const { isActive, toggle } = useToggle(false)

  // Vars
  const product = detail.find(i => i.product_id === item.product_id)

  // Form
  const { control, clearErrors, setValue } = useFormContext<FormType>()

  if (isActive) {
    return (
      <FormSection
        item={item}
        toggle={toggle}
        onSuccess={value => {
          clearErrors(`prorejectdetail.${index}.quantity_reject`)
          setValue(`prorejectdetail.${index}.quantity_reject`, value)
        }}
      />
    )
  }

  return (
    <tr>
      <td>{item.quantity_bill}</td>

      <Controller
        name={`prorejectdetail.${index}.quantity_reject`}
        control={control}
        render={({ field, fieldState }) => (
          <td>
            <div>{field.value}</div>
            {fieldState.error && <ErrorText text={fieldState.error.message!} />}
          </td>
        )}
      />

      <td>
        <DetailProduct
          barcode={product?.product_barcode ?? ""}
          id={item.product_id.toString()}
        />
      </td>

      <td>{product?.product_name}</td>
      <td>{product?.unit_measure}</td>
      <td className="text-right">{item.price_reject.toLocaleString()}</td>
      <td>{item.discount}</td>
      <Total />
      <td>{product?.tax_name}</td>

      <td>
        {!isDisabled && (
          <section className="flex justify-center gap-1">
            <ActionButton.Update onClick={toggle} />
            <ActionButton.Delete onClick={params.remove} />
          </section>
        )}
      </td>
    </tr>
  )
}

function Total(): JSX.Element {
  // Hooks
  const { index, item } = useContext(ListContext)

  // Form
  const { control } = useFormContext()
  const quantity_reject = useWatch({
    control,
    name: `prorejectdetail.${index}.quantity_reject`
  })

  // Vars
  const subtotal: number = item.price_reject * quantity_reject
  const total: number = subtotal - (item.discount / 100 * subtotal)

  return <td className="text-right">{total.toLocaleString()}</td>
}

function FormSection(params: {
  item: DetailType
  onSuccess: (quantity_reject: number) => void
  toggle: () => void
}): JSX.Element {
  // Hooks
  const { detail } = useContext(DataContext)

  // Vars
  const item = params.item
  const product = detail.find(i => i.product_id === item.product_id)

  // Form
  const { control, handleSubmit } = useForm<FormDetailType>({
    defaultValues: {
      quantity_bill: item.quantity_bill,
      quantity_reject: item.quantity_reject
    },
    resolver: yupResolver(validationDetailSchema)
  })
  const onSubmit = (value: FormDetailType) => {
    params.onSuccess(value.quantity_reject)
    params.toggle()
  }

  return (
    <Controller
      name="quantity_reject"
      control={control}
      render={({ field, fieldState }) => {
        // Vars
        const subtotal: number = item.price_reject * field.value
        const total: number = subtotal - (item.discount / 100 * subtotal)

        return (
          <tr>
            <td>{item.quantity_bill}</td>

            <td>
              <NumberInput
                value={field.value}
                onValueChange={value => field.onChange(value.floatValue)}
              />

              {fieldState.error && <ErrorText text={fieldState.error.message!} />}
            </td>

            <td>{product?.product_barcode}</td>
            <td>{product?.product_name}</td>
            <td>{product?.unit_measure}</td>
            <td className="text-right">{item.price_reject.toLocaleString()}</td>
            <td>{item.discount}</td>
            <td className="text-right">{total.toLocaleString()}</td>
            <td>{product?.tax_name}</td>

            <td>
              <section className="flex justify-center gap-1">
                <ActionButton.Check onClick={handleSubmit(onSubmit)} />

                <ActionButton.Close
                  className="text-error"
                  onClick={params.toggle}
                />
              </section>
            </td>
          </tr>
        )
      }}
    />
  )
}