
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type Response = {
  payload: {
    no: number
  }
}

type UseNumberTaskAction = {
  task_id: string
  task_action_id: string
}

export function useNumberTaskAction({ task_id, task_action_id }: UseNumberTaskAction) {
  const api = useApi()
  const [data, setData] = useState<number>(1)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .post("/task_action/getnotaskaction", null, {
        params: {
          task_id,
          task_action_id
        }
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        setData(data.no ?? 1)
      })
      .catch(() => setData(1))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData(1)
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
  }
}
