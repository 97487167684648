export const formParams = {
  balanceUrl: "/spendmoney/balance",
  codeUrl: "/spendmoney/getnewtrxcode",
  creditTitle: "CREDIT",
  dataSource: "1",
  defaultGroup: "06",
  isSpendMoneyPosition: true,
  removeFileUrl: "/spendmoney/removebukti",
  title: "SPEND MONEY",
  uploadFileUrl: "/spendmoney/uploadfile"
}