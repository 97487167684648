export function getValuesByDifferent(different: number): {
  position: "credit" | "debet"
  stock_in: number
  stock_out: number
} {
  if (different > 0) {
    return {
      position: "credit",
      stock_in: 0,
      stock_out: different
    }
  } else if (different < 0) {
    return {
      position: "debet",
      stock_in: different,
      stock_out: 0
    }
  }

  return {
    position: "credit",
    stock_in: 0,
    stock_out: 0
  }
}