import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


export function TableHeaderSort(props: {
    title: any[],
    onSort: (item: string, sortType: any) => void,
    className?: string
}) {

    const [active, setActive] = useState<number | null>(null)

    const toggleActive = (index: number, item: string) => {
        setActive(index === active ? null : index)
        const newSort = index === active ? 'desc' : 'asc'
        props.onSort(item, newSort);
    }

    return (
        <>
            {props?.title.map((item: any, index) => (
                <th
                    key={index}
                    onClick={() => item?.sort ? toggleActive(index, item?.title) : null}
                    className={`${item.className} cursor-pointer`}
                >
                    <div className={`flex gap-2 items-center ${item.align === 'right' ? 'justify-end' : (item.align === 'left' ? 'justify-start' : 'justify-center')}`}>
                        {item?.title}
                        {index === active ? <IoIosArrowUp className="w-5 h-5" /> : <IoIosArrowDown className="w-5 h-5" />}
                    </div>
                </th>
            ))}
        </>
    )
}
