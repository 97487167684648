// React
import { Fragment } from "react"

// Components
import { Button, Label, Modal, Select } from "components"
import { CreatePage as EmployeeModal } from "pages/Card/Transaction/CreateNewEmployee/components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Icons
import { TbPlus } from "react-icons/tb"

// Types
import type { FormType } from "../types"

// Utils
import { useToggle } from "utils"
import { useEmployeeList } from "../utils/hooks"

export function Employee(): JSX.Element {
  // Hooks
  const { isLoading, dropdown, refetch } = useEmployeeList()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <section>
      <Label text="REQUEST BY" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="card_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Employee"
              isLoading={isLoading}
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                    (item) => item.value === field.value?.toString()
                  )
                  : null
              }
              onChange={(e) => field.onChange(e?.value)}
              error={fieldState.error?.message}
            />
          )}
        />

        <CreateEmployee refetch={refetch} />
      </section>
    </section>
  )
}

function CreateEmployee(params: {
  refetch: () => void
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button color="primary" permission="AT021" className="px-2" onClick={toggle}>
        <TbPlus size={24} />
      </Button>

      {isActive && (
        <Modal
          isOpen
          title="Create New Employee"
          size="full"
          closeModal={() => {
            toggle()
            params.refetch()
          }}
        >
          <Modal.Body>
            <EmployeeModal asModal />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}
