import { Fragment, useState } from "react"
import { ActionButton, Button, Card, FooterMenu, HeaderMenu, Loading } from "../../../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type { AddressList, PersonalList, ProfileList } from "../../../../../types"
import { useLocation, useNavigate } from "react-router-dom"
import { useDetailProfileData, useDetailAddressData, useDetailMapData } from "../utils"
import { RenderProvider } from "../../../../../utils"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApi } from "../../../../../../../../../utils"
import toast from "react-hot-toast"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { FormAddress } from "../../../../FormSection/FormAddress"
import { FormMap } from "../../../../FormSection/FormMap"
import { profileValidationSchema, addressValidationSchema, mapValidationSchema } from "pages/Card/Transaction/CreateNewVendor/utils/function"

export function Update(props: { id: PersonalList; permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <ActionButton.Update permission={"CR023"} className="join-item" onClick={() => navigate("/cards/transaction/create-new-customer/update", { state: { dataId: props.id } })} />
    </Fragment>
  )
}
export function UpdatePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const id = location?.state?.dataId?.customer_id
  const data = location?.state?.dataId
  const tabs = ["PROFILE", "ADDRESS", "MAP"];

  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  
  const { dataProfile, errorProfile, isLoadingProfile, refetchProfile } = useDetailProfileData(id ? id : null)
  const { dataAddress, errorAddress, isLoadingAddress, refetchAddress } = useDetailAddressData(id ? id : null)
  const { dataMap, errorMap, isLoadingMap, refetchMap } = useDetailMapData(id ? id : null)

  return (
    <RenderProvider>
      <section className="container my-5">
        <HeaderMenu title="UPDATE CUSTOMER REGISTRATION" />

        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Button color="primary" onClick={() => navigate("/cards/register/all")}>
                BACK
            </Button>
          </Card.Body>
          <Card.Body>
            <div className="lg:flex gap-1">
              <div className="lg:w-[34%] border border-[#3c3c3c] lg:border-[#3c3c3c]">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[1600px]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    <DataForm dataDefault={data!} setActiveTab={setActiveTab} id={id} />
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%] border border-[#3c3c3c] lg:border-[#3c3c3c]">
                <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[1600px]`}>
                  <div className="w-full p-6">
                    {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm dataProfile={dataProfile!} id={id} refetchProfile={refetchProfile} />
                      : ""
                    }
                    {activeTab === 1
                      ? isLoadingAddress || errorAddress 
                        ? <Loading errorText={errorAddress} loading={isLoadingAddress} /> 
                        : <DataAddressForm dataAddress={dataAddress!} id={id} refetchAddress={refetchAddress} />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingMap || errorMap 
                        ? <Loading errorText={errorMap} loading={isLoadingMap} /> 
                        : <DataMapForm dataMap={dataMap!} id={id} refetchMap={refetchMap} />
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
              <FooterMenu/>
        </section>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  dataDefault: PersonalList
  id: number
  setActiveTab: (newState: any) => void
}) {
  const api = useApi()
  // const location = useLocation()

  const [fileValue, setFileValue] = useState<File | undefined>(undefined);

  type UpdatePersonalData = Pick<PersonalList, "Customer_code" | "Customer_Name" | "Customer_Foto">;

  const defaultValues:UpdatePersonalData = {
    Customer_code: props?.dataDefault?.customer_code ?? "",
    Customer_Name: props?.dataDefault?.customer_name ?? "",
    Customer_Foto: props?.dataDefault?.foto ?? "",
  }
  const validationSchema = yup.object().shape({ Customer_Name: yup.string().label("Customer name").required().max(30, "Must be 30 characters or less") })
  const methods = useForm<UpdatePersonalData>({ 
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: PersonalList) => {
    const formData = new FormData();
    
    // formData.append('Customer_code', "CM000009");
    formData.append('Customer_Name', value?.Customer_Name);
    if(value?.Customer_Foto){formData.append(fileValue ? 'Customer_Foto' : "", value?.Customer_Foto)}

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/edit?customer_id=${props.id}`, formData),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {
        props.setActiveTab(0)
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData action="UPDATE" onSubmit={onSubmit} setFileValue={setFileValue} />
    </FormProvider>
  )
}

interface ProfileProps {  dataProfile?: any; id: number; refetchProfile?: () => void }
const DataProfileForm: React.FC<ProfileProps> = ({ dataProfile, id, refetchProfile }) => {
  const api = useApi()
  type CreateProfile = Omit<ProfileList, "vendor_id" | "price_level_vendor_id" | "payment_term_vendor_id" | "link_map">;

  const defaultValues: CreateProfile = {
    handphone: dataProfile?.[0]?.handphone ?? "",
    email: dataProfile?.[0]?.email ?? "",
    website: dataProfile?.[0]?.website ?? "",
    card_label:  dataProfile?.[0]?.card_label ?? undefined,
    group_card: dataProfile?.[0]?.group_card ?? "2",
    category_customer_id: dataProfile?.[0]?.category_customer_id ?? undefined,
    source_from: dataProfile?.[0]?.source_from ?? "",
    employee_id: dataProfile?.[0]?.employee_id ?? undefined,
    media: dataProfile?.[0]?.media ?? "",
    price_level_id: dataProfile?.[0]?.price_level_id ?? undefined,
    payment_term_id: dataProfile?.[0]?.payment_term_id ?? undefined,
    max_balance: dataProfile?.[0]?.max_balance ?? undefined,
    bank_id: dataProfile?.[0]?.bank_id ?? undefined,
    bank_account: dataProfile?.[0]?.bank_account ?? "",
    bank_beneficiary: dataProfile?.[0]?.bank_beneficiary ?? "",
    bank_branch: dataProfile?.[0]?.bank_branch ?? "",
  }
  const methods = useForm<CreateProfile>({ 
    defaultValues,
    resolver: yupResolver(profileValidationSchema) 
  })
  const onSubmit = (value: CreateProfile) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/updateprofile?customer_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchProfile && refetchProfile())
      .catch(() => refetchProfile && refetchProfile())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface AddressProps {  dataAddress?: any; id: number; refetchAddress?: () => void }
const DataAddressForm: React.FC<AddressProps> = ({ dataAddress, id, refetchAddress }) => {
  const api = useApi()

  const defaultValues = {
    address_type_id: dataAddress?.[0]?.address_type_id ?? undefined,
    address_type_id_2: dataAddress?.[0]?.address_type_id_2 ?? undefined,
    country_id: dataAddress?.[0]?.country_id ?? undefined,
    country_id_2: dataAddress?.[0]?.country_id_2 ?? undefined,
    province_id: dataAddress?.[0]?.province_id ?? undefined,
    province_id_2: dataAddress?.[0]?.province_id_2 ?? undefined,
    regency_id: dataAddress?.[0]?.regency_id ?? undefined,
    regency_id_2: dataAddress?.[0]?.regency_id_2 ?? undefined,
    subdistrict_id: dataAddress?.[0]?.subdistrict_id ?? undefined,
    subdistrict_id_2: dataAddress?.[0]?.subdistrict_id_2 ?? undefined,
    village_id: dataAddress?.[0]?.village_id ?? undefined,
    village_id_2: dataAddress?.[0]?.village_id_2 ?? undefined,
    address: dataAddress?.[0]?.address ?? undefined,
    address_2: dataAddress?.[0]?.address_2 ?? undefined,
  }
  const methods = useForm<AddressList>({ 
    defaultValues,
    resolver: yupResolver(addressValidationSchema) 
  })
  const onSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/updateaddress?customer_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchAddress && refetchAddress())
      .catch(() => refetchAddress && refetchAddress())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormAddress action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface MapProps {  dataMap?: any; id: number; refetchMap?: () => void }
const DataMapForm: React.FC<MapProps> = ({ dataMap, id, refetchMap }) => {
  const api = useApi()
  type CreateMap = Pick<ProfileList, "link_map">;

  const defaultValues: CreateMap = { link_map: dataMap?.link_map ?? "" }
  const methods = useForm<CreateMap>({ 
    defaultValues,
    resolver: yupResolver(mapValidationSchema) 
  })
  const onSubmit = (value: CreateMap) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/updatealinkmap?customer_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchMap && refetchMap())
      .catch(() => refetchMap && refetchMap())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormMap action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}