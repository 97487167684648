import * as yup from "yup"

// Vars
const reminder_validation = {
  memo: yup.string().label("Memo").required(),
  promise_date: yup.string().label("Promised Date").required(),
  transaction_date: yup.string().label("Date").required()
}

export const detail_validation_schema = yup.object().shape({
  discount: yup.number().label("Discount").required(),
  mr_unit_id: yup.string().label("Unit").required(),
  price_measure: yup.number().label("Price").required(),
  product_id: yup.string().label("Item Order").required(),
  project_id: yup.string().label("Jobs").required(),
  qty_order: yup.number().label("Order").min(1).required(),
  tax_id: yup.string().label("Tax").required(),
  total: yup.number().label("Total").required(),
  delivered: yup.number().label("Total").required()
})
export const reminder_validation_schema = yup.object().shape(reminder_validation)
export const validation_schema = yup.object().shape({
  _vendor_name: yup.string().defined(),
  approval_status: yup.number().defined(),
  address: yup.string().label("Address").optional(),
  freight: yup.number().label("Freight").required(),
  location_id: yup.string().label("Location").required(),
  memo: yup.string().label("Memo").required(),
  payment_term: yup.string().label("Trading Term").required(),
  promize_date: yup.string().label("Promised Date").required(),
  referensi: yup.string().label("Referensi").required(),
  tax_inclusive: yup.boolean().label("Tax Inclusive").required(),
  transaction_date: yup.string().label("Date").required(),
  vendor_id: yup.string().label("Supplier").required(),
  reminder: yup.object().nullable().shape(reminder_validation),
  podetail: yup.array().label("Purchase Order Detail").min(1).required().of(detail_validation_schema)
})

export type FormType = yup.InferType<typeof validation_schema>
export type DetailFormType = yup.InferType<typeof detail_validation_schema>
export type ReminderFormType = yup.InferType<typeof reminder_validation_schema>