import { Label, Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { ACTION_STATUS } from "../utils/vars"
type DropdownActionStatusProps = {
  name?: string
  label?: string
}

export const DropdownActionStatus = ({
  name = "status",
  label = "STATUS"
}: DropdownActionStatusProps) => {
  const { control, getValues, setValue } = useFormContext()
  const dropdown = Object.values(ACTION_STATUS)
    .filter((item) => typeof item === "number")
    .map((item) => ({
      value: (item as unknown as number).toString(),
      label: ACTION_STATUS[item as number]
    }))

  return (
    <section>
      <Label text={label} />

      <section className="flex gap-3">
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Status"
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                      (item) => item.value === field.value?.toString()
                    )
                  : null
              }
              onChange={(e) => {
                field.onChange(e?.value)

                if (e?.value !== getValues()?.prev_status?.toString()) {
                  return setValue("attachments", [])
                }

                setValue("attachments", getValues()?.prev_attachments)
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
