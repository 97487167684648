// React
import { Fragment, useEffect, useState } from "react"

// Components
import { BaseTable, Button, Card, Checkbox, FooterMenu, HeaderMenu, Input, Loading, PaginationData, TableHeaderSort, TableNumber } from "../../../../components"
import { Create, Delete as ButtonDelete, Update } from "./components"

// Third-Party Libraries
import { debounce } from "lodash"

// Types
import type { PaginationState } from "types"
import type { TaxCodeType } from "./types"

// Utils
import { default_pagination_value, useApi, useToggle } from "utils"
import { useAllId, useList } from "./utils"
import { AxiosResponse } from "axios"
import { arraysEqual, deleteAlert } from "pages/Setup/utils"
import toast from "react-hot-toast"
import { PiBroomBold } from "react-icons/pi"

type headerState = { title: string, sort: boolean, align?: 'left' | 'right' }

export default function TaxCode() {
  // Hooks
  const [deleteList, setDeleteList] = useState<number[]>([])
  const [isDeleteAll, setDeleteAll] = useState<boolean>(false)
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const onDebounce = debounce((e: any) => setPagination(prev => {
    return {
      ...prev,
      keyword: e.target.value
    }
  }))

  const dataList: TaxCodeType[] = data.items

  const header: headerState[] = [
    { title: "CODE", sort: true },
    { title: "NAME", sort: true },
    { title: "TYPE", sort: false },
    { title: "RATE", sort: false },
    { title: "COA COLLECT", sort: false },
    { title: "COA PAID", sort: false },
    { title: "NOTE", sort: false },
  ]

  const sortMapping: Record<string, string> = {
    'CODE': 'tax_code',
    'NAME': 'tax_name',
    'TYPE': 'type',
    'RATE': 'rate',
    'COA COLLECT': 'coaCollect',
    'COA PAID': 'coaPaid',
    'NOTE': 'note'
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }

  return (
    <section className="container gap-5 flex flex-col">
      <HeaderMenu title="TAX CODE" />

      <Card>
        <Card.Body className="flex-row justify-end items-center">
          <Delete
            deleteList={deleteList}
            isDeleteAll={isDeleteAll}
            onSuccess={() => {
              refetch()
              setDeleteAll(false)
              setDeleteList([])
            }}
          />

          <Create onSuccess={refetch} />
        </Card.Body>

        <Card.Body>
          <div className="flex">
            <div className="mb-3">
              <Input
                label="SEARCH"
                className="md:max-w-[300px] w-full"
                placeholder="Search..."
                onChange={onDebounce}
              />
            </div>
          </div>
          <BaseTable>
            <thead>
              <tr>
                <th>NO</th>
                <SelectAll
                  deleteList={deleteList}
                  isDeleteAll={isDeleteAll}
                  toggle={setDeleteList}
                  toggleDeleteAll={setDeleteAll}
                />
                <TableHeaderSort
                  title={header}
                  onSort={(item, sortType) => handleSort(item, sortType)}
                />
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {isLoading || dataList.length === 0 ? (
                <tr>
                  <td colSpan={9} className="text-center">
                    <Loading loading={isLoading} errorText="No data available" />
                  </td>
                </tr>
              ) : (
                <Fragment>
                  {dataList.map((item, key) => (
                    <tr key={key}>
                      <td className="text-center">{TableNumber({ dataLength: data.pagination_data.size, pageCurrent: pagination.page, index: key, limit: pagination.limit, totalData: pagination.total })}</td>
                      <td className="w-[1px]">
                        <Checkbox
                          value={Boolean(deleteList.find(val => val === item.tax_id))}
                          onClick={() => {
                            // Vars
                            const list = [...deleteList]
                            const findIndex = list.findIndex(val => val === item.tax_id)
      
                            if (findIndex !== -1) {
                              list.splice(findIndex, 1)
                            } else {
                              list.push(item.tax_id)
                            }
      
                            return setDeleteList(list)
                          }}
                        />
                      </td>
                      <td>{item.tax_code}</td>
                      <td>{item.tax_name}</td>
                      <td>{item.type_tax_name}</td>
                      <td>{item.rate}</td>
                      <td>{item.coa_collect_coa_code} {item.coa_collect_coa_name}</td>
                      <td>{item.coa_paid_grp_code}-{item.coa_paid_coa_code} {item.coa_paid_coa_name}</td>
                      <td>{item.note}</td>
                      <td>
                        <section className="flex justify-center">
                          <Update item={item} onSuccess={refetch} />
                          <ButtonDelete item={item} onSuccess={refetch} />
                        </section>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              )}
            </tbody>
          </BaseTable>
          <div className="mt-5">
            <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
          </div>
        </Card.Body>
      </Card>
      <FooterMenu/>
    </section>
  )
}

function Delete(params: {
  deleteList: number[]
  isDeleteAll: boolean
  onSuccess: () => void
}): JSX.Element {
  // Hooks
  const api = useApi()
  const { isActive, setActive } = useToggle(false)

  // Functions
  const getUrl = (): Promise<AxiosResponse<any, any>> => {
    if (params.isDeleteAll) {
      return api.delete("/taxcode/all")
    }

    return api.delete("/taxcode/delete-multiple", { data: params.deleteList })
  }

  if (params.deleteList.length) {
    return (
      <Button
        className="tooltip tooltip-error flex gap-3"
        color="ghost"
        loading={isActive ? "true" : undefined}
        onClick={() => {
          deleteAlert(params.isDeleteAll).then(() => {
            setActive(true)

            toast.promise(
              getUrl(),
              {
                loading: "Loading...",
                success: (res) => res.data.message,
                error: (err) => err.response.data?.message ?? err.response.message
              }
            ).then(() => {
              params.onSuccess()
            }).catch(() => {
            }).finally(() => {
              setActive(false)
            })
          }).catch(() => {})
        }}
      >
        {params.isDeleteAll ? "DELETE ALL DATA" : "DELETE SELECTED DATA"} <PiBroomBold className="w-[20px] h-[20px]" />
      </Button>
    )
  }

  return <Fragment />
} 

function SelectAll(params: {
  deleteList: number[]
  isDeleteAll: boolean
  toggle: (value: number[]) => void
  toggleDeleteAll: (value: boolean) => void
}): JSX.Element {
  // Hooks
  const { data } = useAllId()

  useEffect(() => {
    if (!params.deleteList.length) {
      return params.toggleDeleteAll(false)
    }

    return params.toggleDeleteAll(arraysEqual(params.deleteList, data))

    // eslint-disable-next-line
  }, [params.deleteList])

  return (
    <th>
      {data.length ? (
        <Checkbox
          value={params.isDeleteAll}
          onClick={() => {
            if (!arraysEqual(params.deleteList, data)) {
              return params.toggle(data)
            }

            return params.toggle([])
          }}
        />
      ) : (
        <Fragment />
      )}
    </th>
  )
}