// Components
import { Dropdown, Select } from "components"

// Types
import type { SelectOptionType } from "types"

export function ComparisonDate(params: {
  onChangeAmount?: (value: string | null) => void
  onChangeType?: (value: string) => void
  value: {
    type: string
    amount: string | null
  }
}) {
  // Vars
  const value = params.value
  const amountList: SelectOptionType[] = [...Array(12)].map((_, key) => {
    return {
      value: (key + 1).toString(),
      label: `${(key + 1).toString()} ${value.type}`
    }
  })
  const typeList: string[] = ["None", "Day", "Month", "Quarter", "Year"]

  return (
    <section>
      <label className="label">
        <span className="label-text font-bold">DATE COMPARISON</span>
      </label>

      <section className="join items-end">
        <Dropdown
          buttonClass="rounded-r-none whitespace-nowrap"
          className="join-item"
          buttonLabel={value.type}
          list={typeList.map(item => {
            return {
              label: item
            }
          })}
          onClick={(res) => {
            if (params.onChangeType) {
              params.onChangeType(res)
            }

            if (params.onChangeAmount) {
              if (res === "None") {
                params.onChangeAmount(null)
              } else {
                params.onChangeAmount("1")
              }
            }
          }}
        />

        <Select
          className="w-[250px]"
          controlClass="!rounded-l-none"
          isDisabled={value.type === "None"}
          value={amountList.find(item => item.value === value.amount) ?? null}
          options={amountList}
          onChange={res => {
            if (res && params.onChangeAmount) {
              params.onChangeAmount(res.value)
            }
          }}
        />
      </section>
    </section>
  )
}