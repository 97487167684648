import { Label, Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { nextBiWeek, nextMonth, nextThreeWeek, nextWeek } from "utils"
import { TaskType } from "../types"
import { TASK_PRIORITY } from "../utils/vars"

type DropdownPriorityProps = {
  approvalView?: boolean
  isDisabled?: boolean
}

export const DropdownPriority = ({
  approvalView,
  isDisabled
}: DropdownPriorityProps) => {
  const { control, setValue } = useFormContext<TaskType>()
  const dropdown = Object.values(TASK_PRIORITY)
    .filter((item) => typeof item === "number")
    .map((item) => ({
      value: (item as unknown as number).toString(),
      label: TASK_PRIORITY[item as number]
    }))

  return (
    <section>
      <Label text="PRIORITY" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="priority"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Priority"
              isDisabled={isDisabled || approvalView}
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                      (item) => item.value === field.value.toString()
                    )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)

                  if (
                    e.value === TASK_PRIORITY["URGENT-IMPORTANT"].toString()
                  ) {
                    setValue("date_end", nextWeek())
                  }

                  if (
                    e.value === TASK_PRIORITY["URGENT-NOT IMPORTANT"].toString()
                  ) {
                    setValue("date_end", nextBiWeek())
                  }

                  if (
                    e.value === TASK_PRIORITY["IMPORTANT-NOT URGENT"].toString()
                  ) {
                    setValue("date_end", nextThreeWeek())
                  }

                  if (
                    e.value ===
                    TASK_PRIORITY["NOT IMPORTANT-NOT URGENT"].toString()
                  ) {
                    setValue("date_end", nextMonth())
                  }
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
