// React
import { useEffect, useState } from "react"

// Types
import type { ProductType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useProduct() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<ProductType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  function refetch() {
    setLoading(true)

    api.get("/product/").then((res: SuccessFetch<{
      payload: ProductType[]
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}