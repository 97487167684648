// React
import { Fragment, useState } from "react"

// Components
import { FooterMenu, TabSection } from "components"

// Pages
import Academy from "./Academy"
import Doctoral from "./Doctoral"
import Magister from "./Magister"
import SD from "./SD"
import SMA from "./SMA"
import SMP from "./SMP"
import Univercity from "./Univercity"

export default function Education(): JSX.Element {
  // Vars
  const labelList: string[] = ["S3", "S2", "UNIVERSITY", "ACADEMY", "SMU/SMK", "SMP", "SD"]
  const contentList: (() => JSX.Element)[] = [Doctoral, Magister, Univercity, Academy, SMA, SMP, SD]

  // Hooks
  const [activeTab, setTab] = useState<number>(0)

  return (
    <div className="container pt-3">
      <div className="mb-5">
        <TabSection
          activeIndex={activeTab}
          list={labelList}
          onChangeTab={setTab}
        />

        {contentList.map((Item, key): JSX.Element => {
          if (key === activeTab) {
            return <Item key={key} />
          }

          return <Fragment />
        })}
      </div>

      <FooterMenu/>
    </div>
  )
}