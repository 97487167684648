// Components
import { Button, DateInput, Input, Select, Textarea } from "components"
import moment from "moment"

// Form
import { Controller, useForm } from "react-hook-form"

interface FormType {
  type_payment: string
  name_payment: string
  number_payment: string
  expired_payment: string
  authorization_payment: string
  note_payment: string
}

export function FormSection(props: {
  defaultValues: FormType
  onSubmit: (value: FormType) => void
}) {
  // Vars
  const type_list = [
    {
      label: "Cash",
      value: "1"
    },
    {
      label: "Debit Card",
      value: "2"
    },
    {
      label: "Credit Card",
      value: "3"
    },
    {
      label: "Cheque",
      value: "4"
    }
  ]

  // Form
  const onSubmit = props.onSubmit
  const { control, handleSubmit, reset } = useForm<FormType>({
    defaultValues: props.defaultValues
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
      <Controller
        control={control}
        name="type_payment"
        render={({ field }) => (
          <Select
            label="TYPE"
            placeholder="Select Type"
            options={type_list}
            value={type_list.find(item => item.value === field.value)}
            onChange={item => {
              if (item?.value === "1") {
                reset({
                  type_payment: item.value,
                  authorization_payment: "",
                  expired_payment: "",
                  name_payment: "",
                  note_payment: "",
                  number_payment: ""
                })
              } else {
                field.onChange(item?.value)
              }
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="name_payment"
        render={({ field }) => <Input {...field} ref={null} label="NAME ON CARD" />}
      />

      <Controller
        control={control}
        name="number_payment"
        render={({ field }) => <Input {...field} ref={null} label="ACC/CARD NUMBER" />}
      />

      <Controller
        control={control}
        name="expired_payment"
        render={({ field }) => (
          <DateInput
            label="EXPIRE DATE"
            ref={field.ref}
            selected={field.value ? moment(field.value).toDate() : null}
            onChange={value => {
              if (value) {
                return field.onChange(moment(value).format("YYYY-MM-DD"))
              }

              return field.onChange(null)
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="authorization_payment"
        render={({ field }) => <Input {...field} ref={null} label="AUTORISATION CODE" />}
      />

      <Controller
        control={control}
        name="note_payment"
        render={({ field }) => <Textarea {...field} ref={null} label="NOTE" />}
      />

      <section className="text-right">
        <Button type="submit" color="primary">SAVE</Button>
      </section>
    </form>
  )
}