import { Input, Label, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>()

  return (
    <table className="table">
      <tbody>
      <Controller
        control={control}
        name="mr_product_material_code"
        render={({ field, fieldState }) => 
          <tr>
            <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
            <td><Input {...field} placeholder="Input Code" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
          </tr>
        }
      />

      <Controller
        control={control}
        name="mr_product_material_name"
        render={({ field, fieldState }) => 
          <tr>
            <td><Label text="PRODUCT MATERIAL" error={Boolean(fieldState.error)} /></td>
            <td><Input {...field} placeholder="Input product material" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
          </tr>
        }
      />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" /></td>
              <td>
                <Textarea 
                  {...field} 
                  placeholder="Input Description" 
                  value={field.value ?? ""}
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  error={fieldState.error?.message}
                />
              </td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}