// Pages
import { CreateNewCustomer } from "pages/Iframe/CreateNewCustomer"
import { CreateNewEmployee } from "pages/Iframe/CreateNewEmployee"
import { CreateNewVendor } from "pages/Iframe/CreateNewVendor"
import { DetailInvoice } from "pages/Iframe/DetailInvoice"

// Types
import type { RouteObject } from "react-router-dom"

export const iframeRoutes: RouteObject[] = [
  {
    path: "create-customer",
    element: <CreateNewCustomer />
  },
  {
    path: "create-employee",
    element: <CreateNewEmployee />
  },
  {
    path: "create-vendor",
    element: <CreateNewVendor />
  },
  {
    path: "invoice-detail/:id",
    element: <DetailInvoice />
  }
]