import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  mr_product_pricinglevel_code?: string
  mr_product_pricinglevel_name?: string 
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_pricinglevel_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_pricinglevel_id"> 

  const defaultValues:CreateData= {
    mr_product_pricinglevel_code: "",
    mr_product_pricinglevel_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productpricinglevel/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`Defining data attributes for "Product/Item Pricing Level According to Each Customer Category" in an ERP system is critical for enabling differentiated pricing strategies. This setup helps businesses offer customized pricing based on customer categories, such as wholesale, retail, or VIP customers, enhancing sales strategies and customer relationship management. Here’s a comprehensive explanation of the data attributes for this setup:`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each pricing level."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a quick and consistent reference for identifying different pricing levels within the ERP system. It simplifies data entry, enhances searchability, and ensures uniformity across pricing strategies. This code helps avoid confusion and ensures products are priced accurately across various customer interactions."
                      },
                      {
                        label: "Example",
                        value: `Codes like "PL01" for "Retail Pricing," "PL02" for "Wholesale Pricing," or "PL03" for "VIP Pricing" allow for efficient management and application of pricing strategies.`
                      }
                    ]
                  },
                  {
                    label: "Price Level",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the pricing level that indicates the specific pricing strategy applied to a particular customer category."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the pricing strategy based on customer categorization, which is essential for marketing, sales segmentation, and revenue management. Differentiating pricing by customer category helps tailor pricing to customer needs and purchasing power, which can enhance customer loyalty and sales volume."
                      },
                      {
                        label: "Example",
                        value: `"Retail Pricing" — Standard pricing applied to general consumers. 
                                "Wholesale Pricing" — Reduced pricing offered to customers buying in bulk. 
                                "VIP Pricing" — Special pricing for high-value customers, often including additional perks or discounts.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the pricing level."
                      },
                      {
                        label: "Purpose",
                        value: "The description elaborates on the criteria, benefits, and conditions associated with each pricing level. It provides insights into why certain pricing is offered to a specific customer category and how it benefits both the customer and the business. This understanding aids in transparent communication and effective pricing management."
                      },
                      {
                        label: "Example",
                        value: `For "Retail Pricing," the description might be: "Default pricing for end consumers purchasing items for personal use. No minimum order quantity required." 
                                For "Wholesale Pricing," it could read: "Available for businesses and resellers purchasing in large quantities. Minimum order quantities apply, offering significant discounts compared to retail pricing." 
                                For "VIP Pricing," it might state: "Exclusive pricing available to our most loyal customers or those meeting specific spending thresholds. Includes early access to new products and additional discounts on all purchases."`
                      },
                    ]
                  }
                ]}
                detail="This pricing level setup is crucial for companies aiming to strategically segment their market and offer pricing that aligns with the specific characteristics and expectations of each customer group. It allows for sophisticated pricing strategies that can adapt to competitive market conditions and customer expectations, thereby driving profitability and customer satisfaction."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}