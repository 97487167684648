import { Card } from "components"
import { Fragment, useEffect, useState } from "react"
import { useList } from "./hooks"
import { FilterSection, TableSection } from "./components"
import { SelectedState } from "pages/FindSection/types"

export function FindByInventory() {
  const [selected, setSelected] = useState<SelectedState>({ date_from: "", date_end: "", search_by: "", search_id: "" })
  const { data, isLoading, refetch } = useList()

  useEffect(() => {
    const filteredSelected: Partial<SelectedState> = {};

    (Object.keys(selected) as Array<keyof SelectedState>).forEach(key => {
      if (selected[key]) { filteredSelected[key] = selected[key] }
    });

    refetch(filteredSelected);

    // eslint-disable-next-line
  }, [selected])

  return (
    <Fragment>
      <FilterSection selected={selected} setSelected={setSelected} />

      <section className="mt-2">
        <Card>
          <Card.Body>
            <TableSection data={data} isLoading={isLoading} refetch={refetch} selected={selected} />
          </Card.Body>
        </Card>
      </section>
    </Fragment>
  )
}
