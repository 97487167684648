import { Fragment, useContext } from "react"
import { ChildItem } from "../.."
import { Delete, Update } from "./components"
import type { DataItem } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Account/CoaType"
import { Checkbox, TableNumber } from "components"
import { DeleteContext } from "pages/Setup/contexts"


export function Item(props: { item: any, index: number, dataList: DataItem, refetch: () => void}) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total } = useContext(dataContext)
  const { toggleDeleteData, value } = useContext(DeleteContext)
  
  const withOutButton = ["A1", "A2", "A3", "B1", "B2", "C1", "C2", "D1", "D2", "D3", "E1", "E2", "E3", "F1", "F2", "F3", "G1", "G2", "G3", "G4", "G5", "H1", "H2"];

  return (
    <Fragment>
      <tr>
        <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
        <td className="w-[1px]">
          {!withOutButton.includes(props.item.AccountType.account_type_code) &&
            <Checkbox
              value={Boolean(value.find(item => item === props.item.AccountType.account_type_id))}
              onClick={() => toggleDeleteData(props.item.AccountType.account_type_id)}
            />
          }
        </td>
        <td>{props.item.AccountType.account_type_code}</td>
        <td>{props.item.group_coa_name}</td>
        <td>{props.item.AccountType.account_type_name}</td>
        <td>{props.item.AccountType.description}</td>
        <td>
          {!withOutButton.includes(props.item.AccountType.account_type_code) &&
            <div className="flex items-center justify-center">
              <Update id={props.item.AccountType} refetch={props.refetch} />
              <Delete id={props.item.AccountType} refetch={props.refetch} />
            </div>
          }
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}