import * as yup from "yup"

export type TaskActionType = yup.InferType<typeof taskActionSchema>

export const taskActionSchema = yup.object().shape({
  task_action_id: yup.number().label("Task Action").optional(),
  action_task_id: yup.number().label("Action Task").required(),
  task_id: yup.number().label("Task").required(),
  trx_code: yup.string().label("Transaction Code").required(),
  date_start: yup.string().label("Date Start").required(),
  date_end: yup.string().label("Date End").required(),
  assign_to: yup.number().label("Assign To").typeError("Required").required(),
  description: yup.string().label("Description").required(),
  status: yup.number().label("Status").required(),
  attachments: yup.array().label("Attachments").optional(),
  prev_status: yup.number().label("Prev Status").optional(),
  prev_attachments: yup.array().label("Attachments").optional()
})
