export const formParams = {
  balanceUrl: "/receivemoney/balance",
  codeUrl: "/receivemoney/getnewtrxcode",
  creditTitle: "AMOUNT RECEIVE",
  dataSource: "2",
  defaultGroup: "04",
  isSpendMoneyPosition: false,
  removeFileUrl: "/receivemoney/removebukti",
  title: "RECEIVE MONEY",
  uploadFileUrl: "/receivemoney/uploadfile",
}