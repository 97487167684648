import { Fragment, useContext } from "react"
import { ChildItem } from "../.."
import { Delete, Update } from "./components"
import type { DataItem, DataList } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Preference/PaymentTermVendor"
import { Checkbox, TableNumber } from "components"
import { restrictedData } from "./utils"
import { DeleteContext } from "pages/Setup/contexts"

export function Item(props: { item: DataList, index: number, dataList: DataItem, refetch: () => void }) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total } = useContext(dataContext)
  const { toggleDeleteData, value } = useContext(DeleteContext) 

  return (
    <Fragment>
      <tr>
        <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
        <td className="w-[1px]">
          <Checkbox
            value={Boolean(value.find(val => val === props.item.payment_term_vendor_id))}
            onClick={() => toggleDeleteData(props.item.payment_term_vendor_id)}
          />
        </td>
        <td>{props.item.payment_term_vendor_code}</td>
        <td>{`${props.item.payment_term_vendor_name} day`}</td>
        <td>{props.item.description}</td>
        <td>
          {!restrictedData.find(item => item === props.item.payment_term_vendor_code) && (
            <div className="flex items-center justify-center">
              <Update id={props.item} refetch={props.refetch} />
              <Delete id={props.item} refetch={props.refetch} />
            </div>
          )}
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}

// function Add(props: { item: DataList }) {
//   const { isActive, toggle } = useToggle(false)

//   return (
//     <Fragment>
//       <ActionButton.Add className="join-item" onClick={toggle} />

//       {isActive && (
//         <CreateModal
//           payment_term_vendor_code={props.item.payment_term_vendor_code}
//           payment_term_vendor_name={props.item.payment_term_vendor_name}
//           description={props.item.description}
//           toggle={toggle}
//         />
//       )}
//     </Fragment>
//   )
// }