/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { nextWeek, today, useApi } from "utils"
import { FormSection } from "./components"
import { taskSchema, TaskType } from "./types"
import { submitMap } from "./utils/functions"
import { useDetailTask } from "./utils/hooks"

export default function TaskEdit() {
  const navigate = useNavigate()
  const params = useParams()
  const api = useApi()
  const task = useDetailTask({ task_id: params?.task_id?.toString() ?? "" })

  const defaultValues: TaskType = useMemo(() => {
    return {
      task_id: task.data?.task_id,
      location_id: task.data?.location_id ?? NaN,
      department_id: task.data?.departement_id ?? NaN,
      department_name: task.data?.department_name ?? "",
      description: task.data?.description ?? "",
      project_id: task.data?.project_id ?? NaN,
      task_status: task.data?.task_status ?? 1,
      mr_group_task_id: task.data?.mr_group_task_id ?? NaN,
      mr_group_sub_id: task.data?.mr_group_sub_id ?? NaN,
      subject: task.data?.subject ?? "",
      owner_task_id: task.data?.owner_task_id ?? NaN,
      owner_name: task.data?.owner_name ?? "",
      trx_code: task.data?.trx_code ?? "",
      priority: task.data?.priority ?? 1,
      date_start: task.data?.date_start ?? today(),
      date_end: task.data?.date_end ?? nextWeek()
    }
  }, [task.data])

  const onSubmit = async (value: TaskType) => {
    await toast
      .promise(api.put(`/task/${value.task_id}`, submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then((res) => {
        navigate("/")
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<TaskType>({
    defaultValues,
    resolver: yupResolver(taskSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection
        isEdit
        approvalView={false}
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  )
}
