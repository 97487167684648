import { DateInput, Select } from "components";
import { Fragment } from "react";
import { FilterType } from "../types";
import moment from "moment";
import { APPROVAL_OPTIONS } from "utils";
import { TRANSFER_OPTIONS } from "../../utils";
import { useLocationList } from "../utils";

export function FilterSection(props: {
    selected: FilterType
    setSelected: (data: FilterType) => void
}) {

    const {dropdown} = useLocationList()

    return (
        <Fragment>
            <section className="flex lg:flex-row flex-col justify-between lg:items-end gap-3 mt-2">
                <section className="grid lg:grid-cols-6 grid-cols-1 gap-3 w-full">
                    <DateInput
                        label="DATE FROM"
                        onChange={(date) => {
                            props.setSelected({ ...props.selected, start_date: moment(date).format("YYYY-MM-DD") })
                        }}
                        selected={props.selected?.start_date ? moment(props.selected?.start_date).toDate() : null}
                    />

                    <DateInput
                        label="DATE END"
                        onChange={(date) => {
                            props.setSelected({ ...props.selected, end_date: moment(date).format("YYYY-MM-DD") })
                        }}
                        selected={props.selected?.end_date ? moment(props.selected.end_date).toDate() : null}
                    />

                    <Select
                        label="FROM LOCATION"
                        options={dropdown}
                        value={dropdown.find((item) => item.value === props.selected.from_location_id)}
                        onChange={(e) => { props.setSelected({ ...props.selected, from_location_id: e?.value }) }}
                    />

                    <Select
                        label="TO LOCATION"
                        options={dropdown}
                        value={dropdown.find((item) => item.value === props.selected.to_location_id)}
                        onChange={(e) => { props.setSelected({ ...props.selected, to_location_id: e?.value }) }}
                    />

                    <Select
                        label="STATUS"
                        options={[{value: "", label: "ALL"},...TRANSFER_OPTIONS]}
                        value={TRANSFER_OPTIONS.find((item) => item.value === props.selected.transfer_status) || { label: 'All', value: "" }}
                        onChange={(e) => { props.setSelected({ ...props.selected, transfer_status: e?.value }) }}
                    />

                    <Select
                        label="APPROVED"
                        options={[{value: "", label: "ALL"},...APPROVAL_OPTIONS]}
                        value={APPROVAL_OPTIONS.find((item) => item.value === props.selected.approval_status) || { label: 'All', value: "" }}
                        onChange={(e) => { props.setSelected({ ...props.selected, approval_status: e?.value }) }}
                    />
                </section>
            </section>
        </Fragment>
    )
}