import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { menuTitle } from "./MenuTitle"

export function Delete(props: { id: DataList, refetch?: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
  refetch?: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title={`DELETE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle}  refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  id: DataList
  toggle: () => void
  refetch?: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const onSubmit = ( value: DataList ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete("/productstatus/delete", { params: { mr_product_status_id: props.id.mr_product_status_id } }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({ defaultValues: props.id })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <h5 className="text-error">Are you sure want to delete this data?</h5>
          <FormSection isDetail action="DELETE" />
        </Modal.Body>

        <Modal.Footer className="text-end">
          <Button type="submit" color="error" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}