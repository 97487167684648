// Icons
import { TbAlertCircle } from "react-icons/tb"

// Third-Party Libraries
import { useRouteError } from "react-router-dom"

export default function Error(): JSX.Element {
  const error = useRouteError() as { message: string }

  return (
    <div className="min-h-dvh container flex flex-col justify-center items-center">
      <TbAlertCircle className="size-40 text-error" />
      <div className="text-5xl font-bold text-center mb-3">Error</div>
      <div className="text-3xl font-bold text-center">{process.env.REACT_APP_SHOW_ERROR_MESSAGE === "true" && error.message ? error.message : "Something went wrong, please contact admin"}</div>
    </div>
  )
}