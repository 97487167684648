// React
import { PropsWithChildren } from "react"

// Icons
import { FaRegQuestionCircle } from "react-icons/fa"

export function HeaderMenu(params: PropsWithChildren<{
  onClickHelp?: () => void
  title: string
}>) {
  return (
    <section className="border flex items-center">
      <section className={`p-3 bg-black grow flex flex-wrap text-white text-xl font-bold items-center gap-3 ${params.children ? "justify-between" : "justify-center"}`}>
        <div>{params.title}</div>

        {params.children && (
          <div>
            {params.children}
          </div>
        )}
      </section>

      <button
        className="p-3"
        onClick={params.onClickHelp}
      >
        <FaRegQuestionCircle className="size-6" />
      </button>
    </section>
  )
}