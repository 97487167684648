import { Fragment } from "react"
import { ActionButton, Button, Modal } from "components"
import { FormSection } from "../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { ActionType } from "../../types"
import { useApi, useToggle } from "utils"
import toast from "react-hot-toast"

export function Delete({ data, refetch }: ActionType) {
  const { isActive, toggle } = useToggle(false)
  const api = useApi()

  const onSubmit = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete("/action-task/delete", { params: { mr_action_task_id: data?.mr_action_task_id } }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {
        toggle()
        refetch && refetch()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const methods = useForm({ defaultValues: data })

  return (
    <Fragment>
      <ActionButton.Delete className="join-item" onClick={toggle} />
      {isActive && 
        <Modal isOpen title="DELETE ACTION TASK" closeModal={toggle}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Modal.Body>
                <h5 className="text-error">Are you sure want to delete this data?</h5>
                <FormSection action="DELETE" />
              </Modal.Body>

              <Modal.Footer className="text-end">
                <Button type="submit" color="error" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      }
    </Fragment>
  )
}