import { DataList } from "../types"
import { InfoSection } from "./InfoSection"

interface StatementInfoProps { data?: DataList[] }

export const StatementInfo:React.FC<StatementInfoProps> = ({ data }) => (
    <>
        <div className="label-text lg:w-[30%] flex flex-col gap-5">
            <div>
                <div><b>CASH-FLOW STATEMENT</b></div>
                <InfoSection label="Operating Activities" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="Investing Activities" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="Financing Activities" value={data?.[0]?.trade_name ?? "-"} />
            </div>
            <div>
                <div><b>PROFIT & LOSS STATEMENT</b></div>
                <InfoSection label="Total Revenue" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="Total Cogs" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="Total Expenses" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="P&L" value={data?.[0]?.trade_name ?? "-"} />
            </div>
            <div>
                <div><b>BALANCE SHEET STATEMENT</b></div>
                <InfoSection label="Total Assets" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="Total Liability" value={data?.[0]?.trade_name ?? "-"} />
                <InfoSection label="Total Equity" value={data?.[0]?.trade_name ?? "-"} />
            </div>
            <div>
                <div><b>CASH FLOW SHORT TERM PROJECTION</b></div>
                <InfoSection label="Total Available Funds (Balance all Bank)" value="-" />
                <InfoSection label="Total Money in" value="-" />
                <InfoSection label="Total Money out" value="-" />
            </div>
        </div>
            
        <div className="lg:w-[4%]"></div>
    </>
  )