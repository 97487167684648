import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  mr_product_colour_code?: string
  mr_product_colour_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_colour_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_colour_id"> 

  const defaultValues:CreateData= {
    mr_product_colour_code: "",
    mr_product_colour_name: "",
    description: ""
  }
 
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productcolour/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for "Product/Item Color" in an ERP system, it's essential to clearly categorize products based on their colors. This categorization facilitates inventory management, enhances customer offerings, and supports marketing and sales activities. Here’s a comprehensive explanation of the data attributes for "Product/Item Color":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each color."
                      },
                      {
                        label: "Purpose",
                        value: "The code serves as a concise reference that allows quick identification and management of different colors within the ERP system. It simplifies data entry, enhances searchability, and ensures consistency in recording and tracking products by color."
                      },
                      {
                        label: "Example",
                        value: `Codes like "COL01" for "Black," "COL02" for "Red," or "COL03" for "Blue" enable users to efficiently differentiate and manage inventory based on color specifications.`
                      }
                    ]
                  },
                  {
                    label: "Product Color",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the color of the product."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the color of the product, which is crucial for sales, marketing, and customer satisfaction. Accurate color identification helps in matching customer preferences, managing stock levels, and creating marketing materials."
                      },
                      {
                        label: "Example",
                        value: `"Black" — Often used for products where style, sophistication, or versatility is desired. 
                                "Red" — Commonly associated with products that are intended to attract attention or denote energy. 
                                "Blue" — Used for products that aim to convey trust, calm, and reliability.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product color."
                      },
                      {
                        label: "Purpose",
                        value: "The description offers insights into the shade or specific characteristics of the color, helping distinguish between similar shades and providing guidance on the usage and popularity of the color in certain contexts or for specific products."
                      },
                      {
                        label: "Example",
                        value: `For "Black," the description might be: "Jet black, suitable for formal attire and electronic devices, popular for its slimming effect and ability to blend with other colors." 
                                For "Red," it could read: "Bright red, ideal for high-visibility items such as safety gear and sports equipment. Often used to make a bold statement." 
                                For "Blue," it might state: "Navy blue, perfect for professional and casual products alike, known for its soothing properties and wide appeal."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are vital for effectively managing the color aspect of products within the ERP system. They help organizations track and control color-specific inventory accurately, tailor product offerings to meet market demands, and enhance the aesthetic appeal of their product presentations."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}