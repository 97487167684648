/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";
import { DetailTaskAction } from "../../types";
import { mapAsetPath } from "../functions/mapAsetPath";

type UseDetailTaskAction = {
  task_action_id: string;
  onSuccess?: (data: DetailTaskAction) => void;
};

type Response = {
  payload: DetailTaskAction
};

export function useDetailTaskAction({
  task_action_id,
  onSuccess
}: UseDetailTaskAction) {
  const api = useApi();
  const [data, setData] = useState<DetailTaskAction | null>(null);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    api
      .get(`/task_action/${task_action_id}`)
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data);
        setData({
          ...data,
          task_action_files: mapAsetPath(data.task_action_files ?? []),
        });
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    if (task_action_id) {
      fetchData();
    }

    return () => {
      setData(null);
      setLoading(false);
    };
  }, [task_action_id]);

  return { data, isLoading, refetch };
}
