import { Input, NumberInput, Select } from "components"
import { SellingList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { DisableField } from "./DisableField"
import { Diskon } from "./Diskon"
import { convertNumber } from "utils"
import { usePriceUnit } from "../../utils"
import { useProductUnit } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"

interface CostOfGodManufacturProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    totalValueProduction: number 
    checkLoading?: boolean
    discountAmound?: string
    productId: number
    setCheckLoading: ( newState: boolean ) => void
}
export const CostOfGodManufactur = ({ action, totalValueProduction, checkLoading, discountAmound, productId, setCheckLoading }: CostOfGodManufacturProps) => {
    const { control} = useFormContext<SellingList>()

    const { dataPriceUnit, isLoadingPriceUnit } = usePriceUnit(productId!)
    const { dataUnit, isLoadingUnit } = useProductUnit("name")
    
    const overhead = useWatch({control,name: "overhead"})
    const salesTax = useWatch({control, name: "sales_tax"})
    const otherCahnges = useWatch({control, name: "other_changes"})
    const priceRetail = useWatch({control, name: "price_retail"})
    const grosMarkup = useWatch({control, name: "gros_markup"})
    
    const valueOverhead =  (overhead) ? totalValueProduction * parseFloat(String(overhead)) / 100 : 0
    const valueSalesTax = (salesTax) ? totalValueProduction * parseFloat(String(salesTax)) / 100 : 0
    const valueOtherChanges = (otherCahnges) ? totalValueProduction * parseFloat(String(otherCahnges)) / 100 : 0
    
    const cogm = (valueOverhead ?? 0) + (valueSalesTax ?? 0) + (valueOtherChanges ?? 0) + totalValueProduction
    const persentaseCogm = priceRetail ? Math.floor(cogm / parseFloat(String(priceRetail)) * 100) : 0
    const persentasePriceRetail = Math.round(priceRetail ? ((parseFloat(String(priceRetail)) - cogm ) / cogm) * 100 : 0)
    
    const valueGrosMarkup = grosMarkup ? (((100 + parseFloat(String(grosMarkup))) / 100 ) * cogm) : 0
    const sellingProfitPerPiece = parseFloat(priceRetail) - cogm

    return (
        <div>
            <section>
                <div className="grid grid-cols-2">
                    <div>
                        <div className="my-6 w-[200%] border-t-[2px] border-t-black" />
                        {/* COST OF GOOD MANUFACTURE (COGM) */}
                        <>
                            <label className="label">
                                <span className="label-text font-bold">COST OF GOOD MANUFACTURE (COGM)</span>
                            </label>
                            <div className="flex justify-between items-center mb-2">
                                <div className="join">
                                    <Controller
                                        control={control}
                                        name="cogm"
                                        render={({ field }) => <> { action === "DETAIL" ?
                                            <Input
                                                {...field}
                                                disabled
                                                inputClass="w-[150px] !rounded-r-none"
                                                value={cogm ? convertNumber(cogm)?.intoCurrency : "0"} 
                                            /> :
                                            <DisableField width="150px" className="!rounded-r-none">{cogm ? convertNumber(cogm)?.intoCurrency : "0"}</DisableField>
                                        } </> }
                                    />
                                    {action === "DETAIL" 
                                        ? <Input disabled inputClass="w-[150px] !rounded-l-none" value={`${persentaseCogm ?? ''} %`} />
                                        : <DisableField width="150px" className="!rounded-l-none">{`${persentaseCogm ? persentaseCogm : '0'} %`}</DisableField>
                                    }
                                </div>
                            </div>
                        </>
                        {/* GROSS MARKUP */}
                        <>
                            <label className="label">
                                <span className="label-text font-bold">GROSS MARKUP (%)</span>
                            </label>
                            <div className="flex justify-between items-center mb-2">
                                <div className="join">
                                    <Controller
                                        control={control}
                                        name="gros_markup"
                                        render={({ field }) =>
                                            <Input
                                                value={field.value ?? ''}
                                                inputClass="w-[150px] !rounded-r-none"
                                                onChange={(e) => field.onChange(e.target.value)}
                                                disabled={action === "DETAIL"}
                                            />
                                        }
                                    />
                                    {action === "DETAIL" 
                                        ? <Input disabled inputClass="w-[150px] !rounded-l-none" value={convertNumber(valueGrosMarkup)?.intoCurrency ?? 0} />
                                        : <DisableField width="150px" className="!rounded-l-none">{convertNumber(valueGrosMarkup ? valueGrosMarkup : 0)?.intoCurrency ?? 0}</DisableField>
                                    }
                                </div>
                            </div>
                        </>
                        {/* PRICE RETAIL */}
                        <section className="flex gap-2">
                            {/* RECOMMENDED RETAIL PRICE */}
                            <section>
                                <label className="label">
                                    <span className="label-text font-bold">RECOMMENDED RETAIL PRICE</span>
                                </label>
                                <div className="flex justify-between items-center mb-2">
                                    <div className="join">
                                        <Controller
                                            control={control}
                                            name="price_retail"
                                            render={({ field, fieldState }) => 
                                                <NumberInput
                                                    decimalSeparator='.' 
                                                    thousandSeparator=","
                                                    inputClass="w-[150px] !rounded-r-none"
                                                    error={fieldState.error?.message}
                                                    value={field.value}
                                                    onValueChange={value => field.onChange(value.floatValue)}
                                                    disabled={action === "DETAIL"}
                                                />
                                            }
                                        />
                                        <div className="join">
                                            <DisableField width="110px" className="!rounded-l-none !rounded-r-none">{`${persentasePriceRetail ? persentasePriceRetail : '0'} %`}</DisableField>
                                            <DisableField width="150px" className="!rounded-l-none">{sellingProfitPerPiece ? convertNumber(sellingProfitPerPiece)?.intoCurrency : "0"}</DisableField>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* UNIT */}
                            <section>
                                <label className="label">
                                    <span className="label-text font-bold">UNIT</span>
                                </label>
                                <Select
                                    className="w-[130px]"
                                    placeholder="Select Unit"
                                    isLoading={isLoadingUnit || isLoadingPriceUnit}
                                    options={dataUnit}
                                    value={dataPriceUnit?.unit_convention_id ? dataUnit.find(item => item.value === dataPriceUnit?.unit_convention_id?.toString()) : undefined} 
                                    isDisabled
                                />
                            </section>

                            {/* NUMBER OF UNIT */}
                            <section>
                                <label className="label">
                                    <span className="label-text font-bold">NUMBER OF UNIT</span>
                                </label>
                                <Controller
                                    control={control}
                                    name="quantity"
                                    render={({ field }) =>
                                        <NumberInput
                                            decimalScale={0}
                                            value={field.value}
                                            inputClass="w-[130px]"
                                            onChange={(e) => field.onChange(e.target.value)}
                                            disabled
                                        />
                                    }
                                />
                            </section>
                        </section>
                        <div className="my-6 border-t-[2px] border-t-black w-[200%]" />
                        <Diskon 
                            action={action} 
                            discountAmound={discountAmound} 
                            totalValueProduction={totalValueProduction} 
                            checkLoading={checkLoading} 
                            setCheckLoading={setCheckLoading} 
                            productId={productId} 
                        />
                    </div>
                    <div></div>
                </div>
            </section>
        </div>
    )
}