import * as yup from "yup"

export type TaskType = yup.InferType<typeof taskSchema>

export const taskSchema = yup.object().shape({
  task_id: yup.number().label("Task").optional(),
  department_id: yup
    .number()
    .label("Department")
    .typeError("Required")
    .required(),
  department_name: yup.string().label("Department").optional(),
  project_id: yup.number().label("Project").typeError("Required").required(),
  mr_group_task_id: yup
    .number()
    .label("Group")
    .typeError("Required")
    .required(),
  mr_group_sub_id: yup
    .number()
    .label("Sub Group")
    .typeError("Required")
    .required(),
  location_id: yup.number().label("Location").typeError("Required").required(),
  owner_task_id: yup
    .number()
    .label("Task Owner")
    .typeError("Required")
    .required(),
  owner_name: yup.string().label("Task Owner").optional(),
  trx_code: yup.string().label("Transaction Code").required(),
  subject: yup.string().label("Subject").required(),
  priority: yup.number().label("Priority").typeError("Required").required(),
  date_start: yup.string().label("Date Start").required(),
  date_end: yup.string().label("Date End").required(),
  task_status: yup.number().label("Status").required(),
  description: yup.string().label("Description").required()
})
