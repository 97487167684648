// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useDataCode(): {
  fetchCode: (transaction_date: string) => Promise<string>
} {
  // Hooks
  const api = useApi()

  const fetchCode = (transaction_date: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      api.post("/invadjustment/generate-code", {
        trx_date: transaction_date
      }).then((res: SuccessFetch<{
        payload: { next_code: string }
      }>) => {
        resolve(res.data.payload.next_code)
      }).catch(() => {
        reject("")
      })
    })
  }

  return { fetchCode }
}