// React
import { useContext, useEffect } from "react"

// Components
import { Loading } from "components/feedbacks"

// Contexts
import { AuthContext, IframeContext } from "contexts"

// Third-Party Libraries
import { Outlet, useSearchParams } from "react-router-dom"

// Utils
import { useToggle } from "utils"

export function IframeLayout(): JSX.Element {
  // Hooks
  const loading = useToggle(true)
  const error = useToggle(false)
  const { setAuthData, resetData } = useContext(AuthContext)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    // Vars
    const token: string | null = searchParams.get("token")
    const permission: string | null = searchParams.get("permission")

    // Functions
    const promise: Promise<void> = new Promise<void>((resolve, reject) => {
      if (!token) {
        return reject()
      }

      setTimeout(() => {
        resolve()
      }, 300)
    })

    promise.then(() => {
      // Vars
      let permissionList: string[] = []

      window.localStorage.setItem("token", token!)

      if (permission) {
        permissionList = permission.split(",")
      }

      setAuthData(token!, permissionList)
    }).catch(() => {
      error.setActive(true)
    }).finally(() => {
      loading.setActive(false)
    })

    window.addEventListener('message', (e) => {
      // Vars
      const iframeData = e.data

      if (iframeData === "close") {
        window.localStorage.removeItem("token")
        resetData()
      }
    })

    return () => {
      error.setActive(false)
      loading.setActive(true)
      window.removeEventListener('message', () => {})
    }

    // eslint-disable-next-line
  }, [])

  return (
    <IframeContext.Provider
      value={{
        onClose: () => window.parent.postMessage("close", "*")
      }}
    >
      <section className="min-h-svh flex flex-col justify-center items-center">
        {loading.isActive || error.isActive ? (
          <Loading loading={loading.isActive} />
        ) : (
          <Outlet />
        )}
      </section>
    </IframeContext.Provider>
  )
}