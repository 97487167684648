import { Fragment, useContext } from "react"
import { Delete, Update } from "../TableSection"
import { ListShopTransactionAccess } from "../../types"
import { dataContext } from "pages/Setup/Preference/Bank"
import { Checkbox, Loading, TableNumber } from "components"
import { TableType } from "../../types"
import { DeleteContext } from "pages/Setup/contexts"

export const TableSection = ({ data, isLoading, refetch }: TableType) => {
    const { currentPage, limit, size, total } = useContext(dataContext)
    const dataGroupTask: ListShopTransactionAccess[] = data.payload
    const { toggleDeleteData, value } = useContext(DeleteContext)

    return (
        <Fragment>
            {isLoading || dataGroupTask.length === 0
                ? (<tr><td colSpan={9} className="text-center"><Loading loading={isLoading} errorText="No data available" /></td></tr>)
                : dataGroupTask?.map((item, key) => (
                    <tr>
                        <td className="text-center">{TableNumber({ dataLength: size, pageCurrent: currentPage, index: key + 1, limit: limit, totalData: total })}</td>
                        <td className="w-[1px]">
                            <Checkbox
                                value={Boolean(value.find(id => id === item?.shop_transaction_access_id))}
                                onClick={() => toggleDeleteData(item?.shop_transaction_access_id)}
                            />
                        </td>
                        <td>{item.shop_transaction?.shop_transaction_name ?? "-"}</td>
                        <td>{item.job_position?.job_position_name ?? "-"}</td>
                        <td>
                            <div className="flex items-center justify-center">
                                <Update data={item} refetch={refetch} />
                                <Delete data={item} refetch={refetch} />
                            </div>
                        </td>
                    </tr>
                ))
            }
        </Fragment>
    )
}