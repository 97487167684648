import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"

export function CreateModal(props: {
  payment_term_vendor_code?: string
  payment_term_vendor_name?: number
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("payment_term_vendor_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "payment_term_vendor_id"> 

  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/paymenttermvendor/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE PAYMENT TERM VENDOR" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When setting up data attributes for "Vendor Payment Term" in an ERP system, it is important to define the conditions under which payments to vendors (suppliers) are to be made. These attributes help in managing cash flow, maintaining good vendor relationships, and ensuring compliance with agreed-upon payment terms. Here is a comprehensive explanation of the data attributes for "Vendor Payment Term":`}
                detail={`These data attributes are crucial for defining vendor payment terms in the ERP system, allowing the organization to effectively manage its payables, optimize cash flow, and maintain positive vendor relationships.`}
                title=""
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each payment term for vendors."
                      },
                      {
                        label: "Purpose",
                        value: "The code simplifies the identification and management of payment terms by providing a concise reference. It ensures consistency across all transactions, helps avoid confusion, and speeds up data entry and lookup processes."
                      },
                      {
                        label: "Example",
                        value: `Codes like "PT01" for "Net 30," "PT02" for "Net 60," or "PT03" for "Advance Payment" make it easy to distinguish between different payment terms.`
                      }
                    ]
                  },
                  {
                    label: "Payment Term",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the payment term, indicating the conditions and timing under which payments to vendors are expected to be made."
                      },
                      {
                        label: "Purpose",
                        value: "The payment term defines the payment expectations and obligations for the purchasing organization. It outlines when payments should be made (e.g., number of days after receiving an invoice) and any potential discounts or penalties. This helps manage cash flow, negotiate favorable terms, and maintain a good relationship with vendors."
                      },
                      {
                        label: "Example",
                        value: `-"Net 30" — Payment is due 30 days from the invoice date. 
                                -"2/10, Net 30" — Payment is due in 30 days, but a 2% discount is offered if paid within 10 days. 
                                -"Cash on Delivery (COD)" — Payment is due upon the delivery of goods or services. 
                                -"Advance Payment" — Full payment is required before goods or services are delivered.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the payment term."
                      },
                      {
                        label: "Purpose",
                        value: "The description helps clarify the specific conditions, exceptions, or additional details associated with each payment term. It provides essential information to users regarding the expectations and implications of different payment terms, such as any applicable discounts, penalties, or payment methods, ensuring that all stakeholders are aware of the payment conditions."
                      },
                      {
                        label: "Example",
                        value: `-For "Net 30," the description might be: "Payment must be made within 30 days of the invoice date. Late payments will incur a 1.5% monthly interest charge." 
                                -For "2/10, Net 30," it could read: "A 2% discount is applied if payment is made within 10 days; otherwise, the full amount is due within 30 days." 
                                -For "Cash on Delivery (COD)," it might state: "Full payment is required upon delivery of goods; no credit terms are extended." 
                                -For "Advance Payment," it might note: "Full payment must be received before goods are shipped or services are rendered."`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}