import { Label, Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { useActionTaskList } from "../utils/hooks/useActionTaskList"
type DropdownActionTaskProps = {
  name?: string
  label?: string
}

export const DropdownActionTask = ({
  name = "action_task_id",
  label = "ACTION"
}: DropdownActionTaskProps) => {
  const { control } = useFormContext()
  const actionTask = useActionTaskList()

  return (
    <section>
      <Label text={label} />

      <section className="flex gap-3">
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Action Task"
              isLoading={actionTask?.isLoading}
              options={actionTask?.dropdown ?? []}
              value={
                field.value
                  ? actionTask?.dropdown.find(
                    (item) => item.value === field.value?.toString()
                  )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
