// React
import { Fragment, useContext, useEffect, useRef, useState } from "react"

// Components
import { Button, Modal, NumberInput } from "components"

// Contexts
import { PrintItemContext } from "../contexts"

// Icons
import { TbPrinter } from "react-icons/tb"

// Third-Party Libraries
import ReactToPrint from "react-to-print"
import { ReactBarcode } from "react-jsbarcode"

// Utils
import { useToggle } from "utils"
import { useProductDetail } from "../utils"

// Type
interface PrintType {
  price: number
  product_id: string
  product_barcode: string
  product_foto: string
  product_name: string
  qty: number
}

export function Print(): JSX.Element {
  // Hooks
  const { selectedItem } = useContext(PrintItemContext)
  const { isActive, toggle } = useToggle(false)

  if (!selectedItem.length) {
    return <Fragment />
  }

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={toggle}
      >
        <TbPrinter size={20} />
      </Button>

      {isActive && <ModalSection toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(params: {
  toggle: () => void
}): JSX.Element {
  // Hooks
  const ref = useRef<HTMLDivElement>(null)
  const { items, selectedItem } = useContext(PrintItemContext)
  const [isError, setError] = useState<boolean>(false)
  const [data, setData] = useState<PrintType[]>(selectedItem.map(item => {
    // Vars
    const product = items.find(i => i.product_id.toString() === item)

    return {
      product_id: item,
      product_code: product.product_code ?? "",
      product_name: product.product_name ?? "",
      product_barcode: product.product_barcode ?? "",
      price: 50000,
      product_foto: product.product_foto ?? "",
      qty: 1
    }
  }))

  return (
    <Fragment>
      <Modal
        isOpen
        title="PRINT PRODUCT"
        closeModal={params.toggle}
      >
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, key) => (
                <Item
                  key={key}
                  item={item}
                  setProduct={value => {
                    // Vars
                    const list = [...data]

                    list.splice(key, 1, value)
                    setData(list)
                  }}
                  setError={() => setError(true)}
                />
              ))}
            </tbody>
          </table>
        </Modal.Body>

        <Modal.Footer>
          {!Boolean(isError || data.find(item => item.product_barcode === "")) && (
            <ReactToPrint
              content={() => ref.current}
              trigger={() => <Button color="primary">PRINT</Button>}
            />
          )}
        </Modal.Footer>
      </Modal>

      <div
        ref={ref}
        className="print:flex hidden flex-wrap"
      >
        {data.map((item, key) => (
          <Fragment key={key}>
            {[...Array(item.qty)].map((_, k) => (
              <Fragment key={k}>
                <div className="border w-[3.5cm] h-[5cm] break-inside-avoid">
                  <div className="h-full barcode flex flex-col justify-between p-1">
                    <div className="grow text-center text-[9px] flex flex-col">
                      <div className="font-bold">{item.product_barcode}</div>

                      <div className="grow">
                        <div className="line-clamp-3">{item.product_name}</div>
                      </div>

                      {/* <div className="font-bold">Rp {convertNumber(item.price).intoNormalAmount}</div> */}
                      <div className="font-bold">Rp [00.000]</div>
                    </div>

                    <section className="flex justify-center">
                      <div className="w-[3cm]">
                        <ReactBarcode
                          value={item.product_barcode}
                          options={{ displayValue: false }}
                        />
                      </div>
                    </section>
                    
                    <section className="flex justify-center">
                      <div
                        className="size-[60px] bg-cover bg-no-repeat bg-center bg-slate-500"
                        style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL_API}/getimage/?imgpath=${item.product_foto})` }}
                      />
                    </section>
                  </div>
                </div>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </Fragment>
  )
}

function Item(params: {
  item: PrintType
  setProduct: (item: PrintType) => void
  setError: () => void
}): JSX.Element {
  // Hooks
  const { isLoading, fetch } = useProductDetail(params.item.product_id)
  const [data, setData] = useState<PrintType>(params.item)

  useEffect(() => {
    if (!params.item.product_barcode) {
      fetch().then(res => {
        if (res) {
          // Vars
          const value: PrintType = {
            price: 50000,
            product_barcode: res.product_barcode,
            product_foto: res.product_foto,
            product_id: params.item.product_id,
            product_name: res.product_name,
            qty: 1
          }

          setData(value)
          params.setProduct(value)
        }
      }).catch(() => {
        params.setError()
      })
    }

    // eslint-disable-next-line
  }, [data])

  return (
    <tr>
      {isLoading ? (
        <td colSpan={2}>
          <div className="w-full h-8 bg-slate-500 animate-pulse" />
        </td>
      ) : (
        <Fragment>
          <td>{data.product_name}</td>

          <td>
            <NumberInput
              value={data.qty}
              onValueChange={res => {
                // Vars
                const value: PrintType = {
                  price: 50000,
                  product_barcode: data.product_barcode,
                  product_foto: data.product_foto,
                  product_id: data.product_id,
                  product_name: data.product_name,
                  qty: res.floatValue ?? 0
                }

                setData(value)
                params.setProduct(value)
              }}
            />
          </td>
        </Fragment>
      )}
    </tr>
  )
}