// React
import { useContext } from "react"

// Contexts
import { AuthContext } from "contexts"

// Third-Party Libraries
import { jwtDecode } from "jwt-decode"
import { useLocation, useNavigate } from "react-router-dom"

// Types
import type { SuccessFetch, TokenData } from "../../types"

export function useHandleAuth() {
  // Vars
  const { setAuthData, resetData } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const login = (
    res: SuccessFetch<{
      access_token: string
      refresh_token: string
      scopes: string[]
    }>
  ) => {
    // Vars
    const token: string = res.data.access_token
    const decode: { is_subscribed: boolean } = jwtDecode(token)
    const finalScopes: string[] = res.data.scopes

    if (decode.is_subscribed) {
      finalScopes.push("SUBSCRIBED")
    }

    localStorage.setItem("token", res.data.access_token)
    localStorage.setItem("ref", res.data.refresh_token)
    localStorage.setItem("access", JSON.stringify(finalScopes))
    setAuthData(res.data.access_token, finalScopes)

    if (location?.state?.action === "subscribe") {
      navigate("/cards/transaction/create-new-employee")
    } else {
      navigate("/")
    }
  }

  const subscribe = (res: SuccessFetch<{ id: number }>) =>
    navigate("/login", {
      state: { action: "subscribe", id: res?.data?.id ?? "dataId" }
    })

  const logout = () => {
    localStorage?.removeItem("token")
    localStorage?.removeItem("ref")
    localStorage?.removeItem("access")
    resetData()
    navigate("/login", { replace: true })
  }

  const userTokenData = (): null | TokenData => {
    const token = localStorage?.getItem("token")

    if (token) {
      try {
        const decoded = jwtDecode<TokenData>(token)
        return decoded
      } catch (error) {
        console.error("Invalid token", error)
        logout()
      }
    } else {
      console.log("No token found")
    }

    return null
  }

  return { login, logout, subscribe, userTokenData }
}
