import { Input, Select, Button, DateInput } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ProfileList } from "../../types"
import { useReligion } from "./utils"
import { useState } from "react"
import { FaPlus } from "react-icons/fa";
import { CreateModal as CreateModalReligion } from "../../../../../Setup/Preference/Religion/components";
import moment from "moment"

interface ProfileProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: boolean
}

export const FormProfile:React.FC<ProfileProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<ProfileList>()

  return (
    <div>
      <section>
        <TypeNationality isDisabled={action === "DETAIL"} />
        <Controller
          control={control}
          name="nomor"
          render={({ field, fieldState }) => 
            <Input 
              {...field} 
              label="NUMBER OF NATIONALITY ID" 
              className="w-full" 
              error={fieldState.error?.message}
              placeholder="Input number of nationality id" 
              value={field.value ?? ""} 
              onChange={(e) => {
                const result = e.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '')
                field.onChange(result)
              }}
              disabled={action === "DETAIL"} 
            />
          }
        />
        <Controller
          control={control}
          name="birthdate"
          render={({ field, fieldState }) => 
            <DateInput
              label="DATE OF BIRTH" 
              className="w-full" 
              error={fieldState.error?.message}
              disabled={action === "DETAIL"}
              placeholderText="Input date of birth"
              ref={field.ref}
              selected={moment(field.value).toDate()}
              onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
            />
          }
        />
        <MartialStatus isDisabled={action === "DETAIL"} />
        <Religion isDisabled={action === "DETAIL"} />
        <Controller
          control={control}
          name="handphone"
          render={({ field, fieldState }) => 
            <Input 
              {...field} 
              label="HANDPHONE" 
              type="text"
              className="w-full" 
              error={fieldState.error?.message}
              placeholder="Input handphone" 
              value={field.value ?? ""} 
              onChange={(e) => {
                const result = e.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '')
                field.onChange(result)
              }}
              disabled={action === "DETAIL"}
            />
          }
        />
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => 
            <Input 
              {...field} 
              type="email"
              label="EMAIL" 
              className="w-full" 
              error={fieldState.error?.message}
              placeholder="Input email" 
              value={field.value ?? ""} 
              disabled={action === "DETAIL"} 
            />
          }
        />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            permission={"CT021"}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}


function TypeNationality(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const data = [
    { value: "1", label: "KTP" },
    { value: "2", label: "Passport" },
  ]

  return (
    <Controller
      control={control}
      name="type_nationality"
      render={({ field, fieldState }) => (
        <Select
          label="TYPE OF NATIONALITY ID"
          placeholder="Select type of nationality..."
          isDisabled={props.isDisabled}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
    
  )
}
function MartialStatus(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const data = [
    { value: "1", label: "Single" },
    { value: "2", label: "Married" },
  ]

  return (
    <Controller
      control={control}
      name="marital_status"
      render={({ field, fieldState }) => (
        <Select
          label="MARITAL STATUS"
          placeholder="Select martial status..."
          isDisabled={props.isDisabled}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Religion(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading, refetch } = useReligion()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="religion_id"
        render={({ field, fieldState }) => (
          <Select
            label="RELIGION"
            placeholder="Select religion..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalReligion toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )

}