/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { DataTypePaging, SuccessFetch } from "types";
import { useApi } from "utils";
import { FilterType, ReceivedType } from "../../types";

export function useList({
  page,
  approval_status,
  limit,
  start_date,
  end_date,
  from_location_id,
  to_location_id,
  received_status
}: FilterType) {

  const defaultValue = {
    payload: [],
    pagination_data: {
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }

  const api = useApi();
  const [data, setData] = useState<DataTypePaging<ReceivedType>>(defaultValue);
  const [isLoading, setLoading] = useState(true)

  const params: { [key: string]: number | string | undefined } = {}
  if (page) params.page = page;
  if (limit) params.limit = limit;
  if (start_date) params.start_date = start_date;
  if (end_date) params.end_date = end_date;
  if (from_location_id) params.from_location_id = from_location_id;
  if (to_location_id) params.to_location_id = to_location_id;
  if (received_status) params.received_status = received_status;
  if (approval_status) params.approval_status = approval_status;

  const fetchUrl = () => {
    return api.get("/received/register?", { params })
  }

  const fetchData = () => {
    setLoading(true);

    fetchUrl().then((res: SuccessFetch<DataTypePaging<ReceivedType>>) => {
      setData(res.data);
    }).catch(() => {
      setData(defaultValue);
    }).finally(() => {
      setLoading(false);
    });
  }

  const refetch = () => fetchData();

  useEffect(() => {
    fetchData();

    return () => {
      setData(defaultValue);
      setLoading(true);
    }
  }, [page, approval_status, limit, start_date, end_date, from_location_id, to_location_id, received_status, approval_status]);

  return { data, isLoading, refetch }
}
