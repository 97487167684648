import toast from "react-hot-toast";
import { getExtensionFile } from "./getExtensionFile";

export const saveFile = (url: string) => {
  const fileName = url.split('/').pop();
  const extension = getExtensionFile(url);

  fetch(url)
    .then(response => {
      if (!response.ok) {
        toast.error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName ?? 'attachment.' + extension;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch(error => toast.error('Download failed:', error));
}