// React
import { useContext } from "react"

// Components
import { ItemView } from "./ItemView"

// Contexts
import { FilterContext } from "../contexts"
import { EditContext } from "./EditSection"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Utils
import { FormType, getOpeningBalance } from "../utils"

export function NetAssets(params: {
  isInputOnly?: boolean
}) {
  // Hooks
  const { edit } = useContext(EditContext)
  const { zero_value } = useContext(FilterContext)

  // Form
  const { control } = useFormContext<FormType>()
  const list = useWatch({
    control,
    name: "list"
  })

  // Vars
  const assets = getOpeningBalance(list, "01")
  const liability = getOpeningBalance(list, "02")
  const amount = assets - liability

  if (Boolean(amount === 0 ? zero_value.isActive : true)) {
    return (
      <ItemView
        inputClass="font-extrabold"
        label="NET ASSETS"
        level={1}
        amount={amount}
        isAmountOnly={edit === null}
        isInputOnly={params.isInputOnly}
      />
    )
  }

  return <tr />
}