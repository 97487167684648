// React
import { Fragment, useContext } from "react"

// Components
import { ChildItem } from "./ChildItem"

// Contexts
import { CoaContext } from ".."

// Icons
import { TbChevronRight, TbCircleCheck } from "react-icons/tb"

// Types
import type { CoaGroup } from "../../../../types"

// Utils
import { convertNumber, useToggle } from "../../../../utils"

export function Item(props: {
  index: number
  item: CoaGroup
  level: number
  parent_id?: number
  cheque_coa_condition?: boolean
}) {
  // Hooks
  const { selected, setSelected } = useContext(CoaContext)
  const { isActive, toggle } = useToggle(props.item.header_level !== 3)
  const isChequeCoa = props.item.header_level === 3 && props.item.cheque_coa
  const isNotDefineChequeCondition = props.cheque_coa_condition === undefined

  return (
    <Fragment>
      <tr
        style={{
          display:
            isChequeCoa ||
            isNotDefineChequeCondition ||
            props.item.header_level !== 3
              ? "table-row"
              : "none"
        }}
        className={`${selected === props.item && "!bg-blue-400/50"} ${
          props.item.header_level === 3
            ? "font-normal cursor-pointer"
            : "font-bold"
        }`}
        onClick={() => {
          if (props.item.header_level === 3) {
            setSelected(props.item)
          }
        }}>
        <td>
          {props.item.header_level !== 3 && (
            <button
              color="primary"
              className="rounded-full"
              onClick={toggle}
              title="Collapse">
              <TbChevronRight
                className={`transition ${isActive && "rotate-90"}`}
              />
            </button>
          )}
        </td>
        <td style={{ paddingLeft: `${16 * (props.item.coa_level + 1)}px` }}>
          <span className="whitespace-nowrap">{props.item.coa_code}</span>{" "}
          {props.item.coa_name}
        </td>
        <td>
          {props.item.budget_coa && <TbCircleCheck className="mx-auto" />}
        </td>
        <td>{props.item.account_type_name}</td>
        <td>{props.item.note}</td>
        <td>{convertNumber(props.item?.opening_balance ?? 0).intoCurrency}</td>
      </tr>

      {isActive && (
        <ChildItem
          cheque_coa_condition={props.cheque_coa_condition}
          parent_id={props.item.coa_id}
          level={props.level}
        />
      )}
    </Fragment>
  )
}
