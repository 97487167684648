/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DateInput, Modal } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Fragment, useContext, useState } from "react"
import toast from "react-hot-toast"
import { useApi, useToggle } from "utils"
import { ContextApproveInvoice } from ".."
import { useDropdownEmployee } from "../../CustomerOrder/utils/hooks/useDropdownEmployee"
import { ReturnItemsList } from "../../ReturnItems/utils/hooks"
import ReturnItemsDetail from "../../ReturnItems/components/Detail"
import { IoSendSharp } from "react-icons/io5"

type ApproveActionProps = {
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  data: ReturnItemsList
}

export function ApproveAction({ employees, data }: ApproveActionProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        type="button"
        color="ghost"
        permission={"ST10"}
        onClick={toggle}
        disabled={[APPROVAL.REJECT, APPROVAL.APPROVE].includes(
          data?.approve_status
        )}>
        <IoSendSharp />
      </Button>

      {isActive && (
        <ModalSection employees={employees} toggle={toggle} data={data} />
      )}
    </Fragment>
  )
}

type ModalSectionProps = {
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  toggle: ReturnType<typeof useToggle>["toggle"]
  data: ApproveActionProps["data"]
}

function ModalSection({ employees, toggle, data }: ModalSectionProps) {
  const api = useApi()
  const context = useContext(ContextApproveInvoice)
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"))

  const onSubmit = async (date: string, status: number) => {
    const submitValue = {
      return_id: data.return_id!,
      approval_status: status,
      approve_date: date
    }

    await toast
      .promise(api.patch(`/return/approve/${data.return_id}`, submitValue), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        context.refetch()
        toggle()
      })
  }

  return (
    <Modal isOpen title="APPROVE RETURN ITEMS" closeModal={toggle} size="lg">
      <Modal.Body>
        <ReturnItemsDetail return_id={data.return_id.toString()} />
      </Modal.Body>

      <Modal.Footer className="flex md:flex-row flex-col justify-between md:items-end gap-3">
        <DateInput
          placeholderText="Select Date"
          selected={moment(date).toDate()}
          className="w-full"
          label="APPROVAL DATE"
          onChange={(date) => {
            const selectDate = moment(date).format("YYYY-MM-DD")
            setDate(selectDate)
          }}
        />

        <section className="flex gap-3">
          <Button onClick={toggle}>CANCEL</Button>
          <Button color="error" onClick={() => onSubmit(date, 3)}>
            VOID
          </Button>
          <Button color="primary" onClick={() => onSubmit(date, 4)}>
            APPROVED
          </Button>
        </section>
      </Modal.Footer>
    </Modal>
  )
}
