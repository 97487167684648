/* eslint-disable react-hooks/exhaustive-deps */
import { BaseTable, Card, FooterMenu, Loading } from "components"
import moment from "moment"
import { Fragment, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useApi, usePermission, useToggle } from "utils"
import { thisMonth } from "utils/functions"
import {
  BalanceSheetNoneType,
  BalanceSheetReportType,
  FilterReportPNL,
  ProfitLossComparisonType
} from "../../types"
import { periode } from "../../utils/vars"
import { Item } from "../TabBalanceSheet/components"
import { ItemTotal } from "../TabBalanceSheet/components/ItemTotal"
import { calculateParent } from "../TabBalanceSheet/utils/functions"
import { FilterPL } from "./components"
import { generateNetProfit } from "./utils/functions"

export type ResponsePNL =
  | Array<BalanceSheetNoneType>
  | Array<ProfitLossComparisonType[]>

export const TabProfitLoss = () => {
  const permission = usePermission(["AP02"])
  const api = useApi()
  const toggleAccountNumber = useToggle(true)
  const toggleZeroValue = useToggle(true)
  const toggleSubHeader = useToggle(true)
  const [comparisonDate, setComparisonDate] = useState<string[]>([])
  const [reportData, setReportData] = useState<Array<BalanceSheetReportType>>(
    []
  )
  const nonZeroReportData: BalanceSheetReportType[] = reportData.filter(
    (item) => item.balance.some((balance) => balance !== 0)
  )
  const data = toggleZeroValue.isActive ? reportData : nonZeroReportData

  const methods = useForm<FilterReportPNL>({
    defaultValues: {
      dateType: "1",
      date: thisMonth().fromDate,
      dateTo: thisMonth().toDate,
      periode: periode[0],
      comparison: undefined,
      filter: "0"
    }
  })

  const [date, dateTo] = methods.watch(["date", "dateTo"])

  const onSubmitFilter = async (data: FilterReportPNL) => {
    setComparisonDate([])

    const isFilterAll = Boolean(data.filter === "0")

    await api
      .postForm<{ payload: ResponsePNL }>("/profitandlost/", {
        DateType: data.dateType,
        DateStart: data.date,
        DateEnd: data.dateTo,
        PeriodType: data.periode.value,
        Comparison: data.comparison
      })
      .then((res) => {
        /**
         * Should pay attention to the response type, the response has 2 different type.
         * if the comparation is exist, the response will be an array of array and need to be flatten for simplicity
         * else, if the comparation is undefined, the response will be an array of object
         **/

        if (data.comparison) {
          const payload = res.data.payload as ProfitLossComparisonType[][]

          const flattenPayload = payload.flat()

          const dateCompare = flattenPayload.map(
            ({ DateStart, DateEnd }) =>
              `${moment(DateStart).format("DD/MM/YYYY")} - ${moment(
                DateEnd
              ).format("DD/MM/YYYY")}`
          )

          const calculate = flattenPayload.map((data) => {
            return {
              ...data,
              BSData: calculateParent(data.BSData)
            }
          })

          const filterByCoaGrup = isFilterAll
            ? calculate.map((row) => ({
                ...row,
                BSData: generateNetProfit(row.BSData)
              }))
            : calculate.map((row) => ({
                ...row,
                BSData: row.BSData.filter(
                  (coa) => coa.group_coa_code === data.filter
                )
              }))

          const mapBalanceToArray = filterByCoaGrup[0].BSData?.map((data) => ({
            ...data,
            balance: filterByCoaGrup.map(({ BSData }) => {
              return (
                BSData?.find(({ coa_id }) => coa_id === data.coa_id)?.balance ||
                0
              )
            })
          }))

          setComparisonDate(dateCompare)
          setReportData(mapBalanceToArray)
        } else {
          const date = moment(data.date)
          const dateTo = moment(data.dateTo)

          const payload = res.data.payload as BalanceSheetNoneType[]

          const dateCompare = [
            `${date.format("DD/MM/YYYY")} - ${dateTo.format("DD/MM/YYYY")}`
          ]

          const calculate = calculateParent(payload)

          const filterByCoaGrup = isFilterAll
            ? generateNetProfit(calculate)
            : calculate.filter((coa: any) => coa.group_coa_code === data.filter)

          const mapBalanceToArray = filterByCoaGrup.map((data) => ({
            ...data,
            balance: [data.balance]
          }))

          setComparisonDate(dateCompare)
          setReportData(mapBalanceToArray)
        }
      })
  }

  useEffect(() => {
    if (methods.formState.submitCount === 0) {
      methods.handleSubmit((data) => onSubmitFilter(data))()
    }
  }, [])

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <FormProvider {...methods}>
              <FilterPL
                permission={permission?.canUpdate}
                toggleAccountNumber={toggleAccountNumber}
                toggleZeroValue={toggleZeroValue}
                toggleSubHeader={toggleSubHeader}
                onSubmit={onSubmitFilter}
              />
            </FormProvider>
            </Card.Body>
          </Card>
        </section>

      <section className="mt-2">
        <Card>
          <Card.Body>
            <section className="container my-5">
              <hr className="mb-3" />
              <div className="container flex flex-col justify-start items-start my-3 uppercase text-md font-bold gap-y-2">
                <div>PT Hawk</div>
                <div>Profit And Loss</div>
                <div className="capitalize">
                  {`${moment(date).format("DD MMMM YYYY")} - ${moment(dateTo).format(
                    "DD MMMM YYYY"
                  )}`}
                </div>
              </div>

              <Card>
                <Card.Body>
                  <BaseTable className="border-collapse border-spacing-0">
                    <thead>
                      <tr className="uppercase">
                        <th></th>
                        {comparisonDate?.map((date) => (
                          <th className="text-right" key={date}>
                            {date}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {!reportData?.length || methods.formState.isSubmitting ? (
                        <tr>
                          <td colSpan={10} className="text-center">
                            <Loading
                              loading={methods.formState.isSubmitting}
                              errorText={
                                methods.formState.isSubmitting
                                  ? ""
                                  : "No data available"
                              }
                            />
                          </td>
                        </tr>
                      ) : (
                        data.map(
                          (item, _key: number) =>
                            item.parent_id === -1 && (
                              <>
                                <Item
                                  key={item.coa_id}
                                  list={data}
                                  item={item}
                                  displayAccountNumber={
                                    toggleAccountNumber.isActive
                                  }
                                  displaySubHeader={toggleSubHeader.isActive}
                                />

                                {item.coa_name === "NET PROFIT" ? null : (
                                  <ItemTotal item={item} />
                                )}
                              </>
                            )
                        )
                      )}
                    </tbody>
                  </BaseTable>
                </Card.Body>
              </Card>
            </section>
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <FooterMenu />
      </section>
    </Fragment>
  )
}
