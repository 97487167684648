/* eslint-disable react-hooks/exhaustive-deps */

// React
import { useEffect, useState } from "react"

// Components
import { Card, FooterMenu } from "components"
import { DropdownAccount } from "../DropdownAccount"
import { FilterByDate } from "../FilterByDate"
import { FooterSummary } from "../FooterSummary"
import { TableRegister } from "../TableRegister"

// Third-Party Libraries
import moment from "moment"

// Types
import type { SuccessFetch } from "types"
import type { RegisterDateType } from "../../types"

// Utils
import { useApi } from "utils"
import { useFilter } from "pages/Finance/Journal/utils/hooks"
import { prevYear, thisMonth, thisWeek, thisYear, today } from "utils/functions"

type Response = {
  payload: {
    beginingbalance: number
    currentbalance: number
    data_register: RegisterDateType[]
  }
}

export function RegisterDateRange() {
  const api = useApi()
  const todayDate = today()
  const prevYearDate = prevYear()
  const thisYearDate = thisYear()
  const thisMonthDate = thisMonth()
  const thisWeekDate = thisWeek()
  const [balance, setBalance] = useState({ beginning: 0, current: 0 })
  const [data, setData] = useState<RegisterDateType[]>([])
  const isLoading = false

  const { filter, handleChangeFilter } = useFilter({
    CoaID: "0",
    dateFrom: thisMonthDate.fromDate,
    dateTo: todayDate
  })

  const fetch = () =>
    api
      .postForm("/regbydatarange/getdata", {
        CoaID: filter.CoaID,
        StartDate: filter.dateFrom,
        EndDate: filter.dateTo
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        setBalance({
          beginning: data.beginingbalance,
          current: data.currentbalance
        })

        setData(
          data.data_register?.map((item) => ({
            ...item,
            checked: false
          })) ?? []
        )
      })

  useEffect(() => {
    if (filter.CoaID) {
      fetch()
    }
  }, [filter])

  const handleCheckData = (index: number) => {
    const newData = [...data]
    data[index].checked = !data[index].checked

    setData(newData)
  }

  return (
    <section className="flex flex-col gap-2 mt-2">
      <Card>
        <Card.Body>
          <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-5">
            <div className="w-full lg:w-1/2 flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
              <div className="label-text pb-1 font-bold pe-6">Account</div>
              <DropdownAccount
                value={null}
                currentBalance={balance.current ?? 0}
                onChange={(item) =>
                  handleChangeFilter({ CoaID: item?.value })
                }
              />
            </div>
          </section>
          <FilterByDate
            permission="FR013"
            dateFrom={filter.dateFrom}
            dateTo={filter.dateTo}
            onChangeDateFrom={(dateFrom) => {
              const isValid = moment(dateFrom).isBefore(filter.dateTo)
              handleChangeFilter({
                dateFrom,
                dateTo: isValid ? filter.dateTo : dateFrom
              })
            }}
            onChangeDateTo={(dateTo) => handleChangeFilter({ dateTo })}
            onClickPrevYear={() =>
              handleChangeFilter({
                dateFrom: prevYearDate.fromDate,
                dateTo: prevYearDate.toDate
              })
            }
            onClickThisYear={() =>
              handleChangeFilter({
                dateFrom: thisYearDate.fromDate,
                dateTo: thisYearDate.toDate
              })
            }
            onClickThisWeek={() =>
              handleChangeFilter({
                dateFrom: thisWeekDate.fromDate,
                dateTo: thisWeekDate.toDate
              })
            }
            onClickThisMonth={() =>
              handleChangeFilter({
                dateFrom: thisMonthDate.fromDate,
                dateTo: thisMonthDate.toDate
              })
            }
            onClickToday={() =>
              handleChangeFilter({
                dateFrom: todayDate,
                dateTo: todayDate
              })
            }
          />
        </Card.Body>
      </Card>

      <TableRegister
        data={data}
        filter={filter}
        isLoading={isLoading}
        handleCheckData={handleCheckData}
        fetchList={fetch}
        permission="FR01"
      />

      <FooterMenu
        customRightElement={(
          <FooterSummary
            data={data}
            dateFrom={filter.dateFrom}
          />
        )}
      />
    </section>
  )
}
