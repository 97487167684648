// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { CoaGroup } from "../../types"

// Utils
import { useApi } from "utils"

interface FetchType {
  coa_id: number
  group_coa_code: string
  coa_code: string
  coa_name: string
  parent_id: number
  coa_level: number
  cheque_coa: boolean
  budget_coa: boolean
  opening_balance: number | null
  account_type: string
  account_type_id: number | null
  group_coa_id: number
  header_level: 1 | 2 | 3
  report_type_name: string
  account_type_name: string
  location_id: number | null
  note: string
}

export function useData() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<CoaGroup[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    refetch()
    setLoading(true)

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  function refetch() {
    api.get("/coa/").then((res: SuccessFetch<{
      payload: FetchType[]
    }>) => {
      // Functions
      const countChildren = (items: FetchType[], id: number) => {
        // Vars
        const list = items.filter(item => item.parent_id === id && item.header_level === 3)

        return list.length
      }
      const summerizeOpeningBalance = (items: FetchType[], id: number) => {
        // Vars
        let parent_id_list = [id]
      
        return items.reduce((accumulator, { coa_id, header_level, opening_balance, parent_id }) => {
          // Vars
          let opening_balance_value = 0
      
          if (parent_id_list.includes(parent_id)) {
            opening_balance_value = opening_balance ?? 0
            if (header_level !== 3) parent_id_list.push(coa_id)
          }
      
          return accumulator + opening_balance_value
        }, 0)
      }

      // Vars
      const payload = res.data.payload
      const result = payload.map(item => {
        // Vars
        const opening_balance = item.opening_balance ?? 0

      
        return {
          ...item,
          childcount: countChildren(payload, item.coa_id),
          opening_balance: item.header_level === 3 ? opening_balance : summerizeOpeningBalance(payload, item.coa_id)
        }
      })

      setData(result.map(item => {
        return {
          ...item,
          account_type_id: item.account_type_id?.toString() ?? "",
          account_type: item.header_level === 1 ? "Header" : item.header_level === 2 ? "Sub Header" : "Detail",
          group_coa_id: item.group_coa_id.toString(),
          report_type: item.report_type_name,
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}