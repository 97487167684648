import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useDepartement() {
  const api = useApi()
  const [dataDepartement, setData] = useState<SelectOptionType[]>([])
  const [isLoadingDepartement, setLoading] = useState(false)

  const refetch = (divisi_id?: string) => {
    setLoading(true)

    api.get(
      "/department/",
      { params: { divisi_id } }
    )
    .then((res: SuccessFetch<{
      payload: {
        department_id: number
        department_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.department_name, value: item.department_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)

    api.get("/department/")
    .then((res: SuccessFetch<{
      payload: {
        department_id: number
        department_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.department_name, value: item.department_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataDepartement, isLoadingDepartement, refetch }
}