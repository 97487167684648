import { Fragment, useContext } from "react"
import type { DataList } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"
import { Render } from "../../../../../utils"


export function IsActive(props: { data: DataList, refetch?: () => void }) {
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: DataList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put("/jobposition/changestatus",
                    {
                        job_position_id: value?.job_position_id,
                        job_positiondel: !value?.is_deleted
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            )
            .then(() => {
                toggle()
                props?.refetch && props?.refetch()
            })
            .catch(() => {})
            .finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle 
                label="Soft Delete"
                isChecked={props?.data?.is_deleted} 
                onClick={() => onSubmit(props?.data)} 
            />
        </Fragment>
    )
}