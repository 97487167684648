import { Card, FooterMenu, Loading } from "components"
import { Fragment, useEffect, useState } from "react"
import { SearchingSections } from "./SearchingSection"
import { SelectedState } from "../../types/AgedPayable"
import { useList } from "./hooks"
import { TableSection } from "./components"
import { convertNumber } from "utils"

export function RegisterAgedPayable() {
  // Hooks
  const [selected, setSelected] = useState<SelectedState>({ date_from: "", date_end: "", vendor_id: "", bill_status: "" })
  const { data, isLoading, refetch } = useList()

  // Vars
  const summary: {
    balance: number
    "7_day": number
    "15_day": number
    "21_day": number
    "30_day": number
  } = data.reduce((acc, val) => {
    if (val?.aged_payable <= 7) {
      return {
        ...acc,
        balance: acc.balance + val?.balance,
        "7_day": acc["7_day"] + val?.balance
      }
    } else if (val?.aged_payable >= 8 && val?.aged_payable <= 15) {
      return {
        ...acc,
        balance: acc.balance + val?.balance,
        "15_day": acc["15_day"] + val?.balance
      }
    } else if (val?.aged_payable >= 16 && val?.aged_payable <= 21) {
      return {
        ...acc,
        balance: acc.balance + val?.balance,
        "21_day": acc["21_day"] + val?.balance
      }
    } else if (val?.aged_payable >= 22) {
      return {
        ...acc,
        balance: acc.balance + val?.balance,
        "30_day": acc["30_day"] + val?.balance
      }      
    }

    return acc
  }, {
    balance: 0,
    "7_day": 0,
    "15_day": 0,
    "21_day": 0,
    "30_day": 0
  })

  useEffect(() => {
    const filteredSelected: Partial<SelectedState> = {};

    (Object.keys(selected) as Array<keyof SelectedState>).forEach(key => {
      if (selected[key]) { filteredSelected[key] = selected[key] }
    });

    refetch(filteredSelected);
    
    // eslint-disable-next-line
  }, [selected])

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <SearchingSections 
              selected={selected} 
              setSelected={setSelected}
            />
          </Card.Body>
        </Card>
      </section>

      {isLoading
        ? <Card className="mt-2"><Card.Body><div className="flex justify-center py-12"><Loading loading /></div></Card.Body></Card> 
        : <>
            <section className="mt-2">
              <Card>
                <Card.Body>
                    <TableSection data={data} isLoading={isLoading} />
                </Card.Body>
              </Card>
            </section>

            <section className="mt-2">
              <FooterMenu
                customRightElement={(
                  <section className="flex gap-3">
                    <Summary
                      label="Balance"
                      amount={summary.balance}
                    />

                    <Summary
                      label="7 Days"
                      amount={summary["7_day"]}
                    />

                    <Summary
                      label="15 Days"
                      amount={summary["15_day"]}
                    />

                    <Summary
                      label="21 Days"
                      amount={summary["21_day"]}
                    />

                    <Summary
                      label="30+ Days"
                      amount={summary["30_day"]}
                    />
                  </section>
                )}
              />
            </section>
          </>
        }
    </Fragment>
  )
}

function Summary(params: {
  amount: number
  label: string
}) {
  return (
    <div className="min-w-[100px] h-20 border flex flex-col justify-center text-center">
      <div>Summary</div>
      <div>{params.label}</div>
      <div className="mt-2 font-bold">{convertNumber(params.amount).intoCurrency}</div>
    </div>
  )
}