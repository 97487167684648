// Components
import { FooterMenu, Loading } from "components"
import { FormSection } from "./components"

// Contexts
import { DetailDataContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Types
import { DataCountType } from "../types"

// Utils
import { useApi } from "utils"
import { getValuesByDifferent, useDataCountDetail } from "../utils"
import { type FormType, validationSchema, getAccValues } from "./utils"

export default function CreateAdjustmentInventory(): JSX.Element {
  // Hooks
  const { count_id } = useParams()
  const { data, isLoading } = useDataCountDetail(count_id!)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return <ViewSection data={data} />
}

function ViewSection(params: {
  data: DataCountType
}): JSX.Element {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()

  // Vars
  const dataCount = params.data
  const invCount = params.data.invcount

  // Form
  const defaultValues: FormType = {
    memo: "",
    location_id: "",
    transaction_date: moment().format("YYYY-MM-DD"),
    trx_code: "",
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType): Promise<void> => {
    const finalValue = {
      ...value,
      count_id: invCount.count_id,
      employee_id: invCount.employee_id,
      adjustmentdetail: dataCount.countdetail.map(item => {
        // Vars
        const acc = getAccValues(item)
        const differentValue = getValuesByDifferent(item.different)

        return {
          product_id: item.product_id,
          mr_unit_id: item.mr_unit_id,
          different_adjustment: item.different,
          different_unit: item.different * item.quantity_unit_sell_convention,
          avg_price_use: item.avg_price_use,
          amount_adjustment: acc.amount_adjustment,
          position: differentValue.position === "credit" ? 2 : 1,
          coa_inventory_id: item.coa_inventory_id,
          coa_coex_id: item.coa_coex_id
        }
      })
    }

    return new Promise<void>((resolve) => {
      toast.promise(api.post("/invadjustment/add", finalValue),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        navigate("/inventory/transaction/adjustment-inventory")
      }).catch(() => {}).finally(resolve)
    })
  }

  return (
    <DetailDataContext.Provider value={params.data}>
      <FormProvider {...methods}>
        <FormSection
          footerElement={(
            <FooterMenu
              attachment={{ actiontype: "attachment" }}
              email={{ actiontype: "email" }}
              export={{ actiontype: "export" }}
              journal={{ actiontype: "journal" }}
              print={{ actiontype: "print" }}
              save={{
                actiontype: "save",
                loading: methods.formState.isSubmitting ? "true" : undefined,
                onClick: methods.handleSubmit(onSubmit)
              }}
            />
          )}
        />
      </FormProvider>
    </DetailDataContext.Provider>
  )
}