import { Fragment, useContext } from "react"
import { ChildItem } from "../.."
import { Delete, Update } from "./components"
import type { DataList, DataItem } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Preference/SalaryType"
import { Checkbox, TableNumber } from "components"
import { DeleteContext } from "pages/Setup/contexts"

export function Item(props: { item: DataList, index: number, dataList: DataItem, refetch: () => void }) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total } = useContext(dataContext)
  const { toggleDeleteData, value } = useContext(DeleteContext)

  return (
    <Fragment>
      <tr>
        <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
        <td className="w-[1px]">
          <Checkbox
            value={Boolean(value.find(val => val === props.item.salary_type_id))}
            onClick={() => toggleDeleteData(props.item.salary_type_id)}
          />
        </td>
        <td>{props.item.salary_type_code}</td>
        <td>{props.item.salary_type_name}</td>
        <td>{props.item.description}</td>
        <td>
          <div className="flex items-center justify-center">
            <Update id={props.item} refetch={props.refetch} />
            <Delete id={props.item} refetch={props.refetch} />
          </div>
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}