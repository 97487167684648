type CardSectionProps = { label: string; value: string | number; text: string; className?: string };

export const CardSection = ({ label, value, text, className }: CardSectionProps) => {

    return (
        <div className="mt-2">
            <div className={`${className} bg-black dark:bg-black border-[1px] border-[#3c3c3c] h-[50px] min-w-[130px] flex justify-center items-center`}>
                <div className="label-text my-1 text-center text-white uppercase w-[128px] px-2">{label}</div>
            </div>
            <div className="flex">
                <div className="border border-[#3c3c3c] h-[130px] text-center min-w-[130px] flex flex-col gap-1">
                    <div className="grow text-[30px] my-auto font-bold flex flex-col items-center justify-center">{value ? value : "-"}</div>
                    <div className="text-[13px]">{text}</div>
                </div>
            </div>
        </div>
    )
}