// React
import { Fragment, useContext, useEffect } from "react"

// Components
import { ActionButton, Button, NumberInput } from "components"
import { DetailProduct } from "pages/Purchase/components"
import { DeleteAlert } from "pages/Purchase/Transaction/components"
import { Description, ItemOrder, Job, Price, Tax, Total, Unit } from "./components"

// Contexts
import { DataJournalContext } from "contexts"
import { ProductContext } from "./contexts"
import { PurchaseOrderContext } from "../../contexts"

// Form
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Icons
import { TbEye } from "react-icons/tb"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ProductType } from "./types"

// Utils
import { convertNumber, useToggle } from "utils"
import { type DetailFormType, type FormType, detail_validation_schema } from "../../utils"
import { useProduct } from "./utils"

export function DetailBody() {
  // Hooks
  const { data, isLoading, refetch } = useProduct()
  const { disabled } = useContext(PurchaseOrderContext)

  // Form
  const { control } = useFormContext<FormType>()
  const vendor_id = useWatch({
    control,
    name: "vendor_id"
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "podetail"
  })

  useEffect(() => {
    if (vendor_id) {
      refetch(vendor_id)
    }

    // eslint-disable-next-line
  }, [vendor_id])

  return (
    <ProductContext.Provider value={{ data, isLoading }}>
      <Fragment>
        {fields.map((_, key) => (
          <ListSection
            key={key}
            index={key}
            onDelete={() => remove(key)}
          />
        ))}

        {!disabled && (
          <FormSection
            onCreate={(value) => {
              if (fields.find(item => item.product_id === value.product_id)) {
                return toast.error("The product has already on the detail list")
              }

              append(value)
            }}
          />
        )}
      </Fragment>
    </ProductContext.Provider>
  )
}

function FormSection(params: {
  default_values?: DetailFormType
  onCancel?: () => void
  onCreate?: (value: DetailFormType) => void
  onUpdate?: (value: DetailFormType) => void
}) {
  // Hooks
  const { isActive, toggle } = useToggle(Boolean(params.default_values))

  // Form
  const { control } = useFormContext<FormType>()
  const vendor_id = useWatch({
    control,
    name: "vendor_id"
  })
  const methods = useForm<DetailFormType>({
    defaultValues: params.default_values ?? {
      delivered: 0,
      discount: 0,
      mr_unit_id: "",
      price_measure: 0,
      product_id: "",
      project_id: "",
      qty_order: 0,
      tax_id: "",
      total: 0
    },
    resolver: yupResolver(detail_validation_schema)
  })
  const onCreate = (value: DetailFormType) => {
    if (params.onCreate) {
      methods.reset()
      toggle()

      params.onCreate(value)
    }
  }
  const onUpdate = (value: DetailFormType) => {
    if (params.onUpdate) {
      params.onUpdate(value)
    }
  }

  useEffect(() => {
    if (vendor_id) {
      methods.reset()
    }

    // eslint-disable-next-line
  }, [vendor_id])

  if (isActive) {
    return (
      <FormProvider {...methods}>
        <tr className="no-padding-body">
          <td />

          <Controller
            name="qty_order"
            control={methods.control}
            render={({ field, fieldState }) => (
              <td className="min-w-[200px]">
                <NumberInput
                  decimalScale={0}
                  error={fieldState.error?.message}
                  ref={field.ref}
                  value={field.value}
                  onValueChange={e => field.onChange(e.floatValue)}
                />
              </td>
            )}
          />

          <td className="min-w-[200px]">
            <div className="px-3">{methods.getValues("delivered")}</div>
          </td>

          <Backorder />

          <td className="min-w-[200px]">
            <ItemOrder />
          </td>

          <td className="min-w-[200px] !px-3">
            <Description />
          </td>

          <td className="min-w-[200px] !px-3">
            <Unit />
          </td>

          <td className="min-w-[200px]">
            <Price />
          </td>

          <td className="min-w-[200px]">
            <Controller
              name="discount"
              control={methods.control}
              render={({ field, fieldState }) => (
                <NumberInput
                  textRight
                  suffix="%"
                  decimalScale={0}
                  ref={field.ref}
                  value={field.value}
                  onValueChange={(value) => field.onChange(value.floatValue)}
                  error={fieldState.error?.message}
                />
              )}
            />
          </td>

          <td className="min-w-[200px]">
            <Total />
          </td>

          <td className="min-w-[200px]">
            <Job />
          </td>

          <td className="min-w-[200px]">
            <Tax />
          </td>

          <td className="text-center">
            {params.default_values ? (
              <section className="flex justify-center">
                <ActionButton.Check
                  type="button"
                  onClick={methods.handleSubmit(onUpdate)}
                />

                <ActionButton.Close
                  type="button"
                  onClick={params.onCancel}
                />
              </section>
            ) : (
              <ActionButton.Add
                type="button"
                onClick={methods.handleSubmit(onCreate)}
              />
            )}
          </td>
        </tr>
      </FormProvider>
    )
  }

  return (
    <tr className="no-padding-body">
      <td className="text-center">
        <Button color="transparent" onClick={toggle}>
          <TbEye className="size-5" />
        </Button>
      </td>

      <td colSpan={12} />
    </tr>
  )
}

function Backorder() {
  // Form
  const { control, getValues } = useFormContext<DetailFormType>()
  const qty_order = useWatch({
    control,
    name: "qty_order"
  })

  return (
    <td className="min-w-[200px]">
      <div className="px-3">
        {convertNumber(qty_order - getValues("delivered")).intoNormalAmount}
      </div>
    </td>
  )
}

function ListSection(params: {
  index: number
  onDelete: () => void
}) {
  // Hooks
  const { data } = useContext(ProductContext)
  const { disabled } = useContext(PurchaseOrderContext)
  const { isActive, toggle } = useToggle(false)
  const { project, tax_code } = useContext(DataJournalContext)

  // Form
  const { control, getValues } = useFormContext<FormType>()

  return (
    <Controller
      name={`podetail.${params.index}` as "podetail.0"}
      control={control}
      render={({ field }) => {
        // Vars
        const item_order: ProductType | undefined = data.find(item => item.value === field.value.product_id)
        const project_name: string | undefined = project.data.find(item => item.value === field.value.project_id)?.label
        const tax_name: string | undefined = tax_code.data.find(item => item.value === field.value.tax_id)?.label

        if (isActive) {
          return (
            <FormSection
              default_values={field.value}
              onCancel={toggle}
              onUpdate={res => {
                if (res.product_id !== field.value.product_id && getValues("podetail").find(item => item.product_id === res.product_id)) {
                  return toast.error("The product has already on the detail list")
                }

                field.onChange(res)
                toggle()
              }}
            />
          )
        }

        return (
          <tr>
            <td>{params.index + 1}</td>
            <td>{convertNumber(field.value.qty_order).intoNormalAmount}</td>
            <td>{convertNumber(field.value.delivered).intoNormalAmount}</td>
            <td>{convertNumber(field.value.qty_order - field.value.delivered).intoNormalAmount}</td>

            <td>
              <DetailProduct
                barcode={item_order?.product_barcode ?? ""}
                id={field.value.product_id}
              />
            </td>

            <td>{item_order?.product_name}</td>
            <td>{item_order?.mr_unit_name}</td>
            <td className="text-right">{convertNumber(field.value.price_measure).intoCurrency}</td>
            <td className="text-right">{convertNumber(field.value.discount).intoNormalAmount}%</td>
            <td className="text-right">{convertNumber(field.value.total).intoCurrency}</td>
            <td>{project_name}</td>
            <td>{tax_name}</td>
            <td>
              {!disabled && (
                <section className="flex justify-center">
                  <ActionButton.Update
                    type="button"
                    onClick={toggle}
                  />

                  <DeleteAlert onDelete={params.onDelete} />
                </section>
              )}
            </td>
          </tr>
        )
      }}
    />
  )
}