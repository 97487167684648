import { Button } from "components"
import { TabReportPath } from "../../types"
import { Fragment } from "react"

type TabReportProps = {
  activeTab: string
  setActiveTab: (path: string) => void
}

type Tabs = {
  label: string
  path: TabReportPath
  permission: string
}

export function TabNavReport({ activeTab, setActiveTab }: TabReportProps) {

  const tabs: Tabs[] = [
    { label: "Balance Sheet", path: "balance-sheet", permission: "AP01" }, 
    { label: "Profit and Loss", path: "profit-loss", permission: "AP02" }
  ]

  return (
    <Fragment>
      <section className="container">
        <section className="grow flex items-stretch overflow-x-auto">
          {tabs.map((tab) => (
            <Button
              key={tab?.path}
              permission={tab?.permission}
              type="button"
              color="ghost"
              size="sm"
              className={`grow bg-[#3c3c3c] border-y-0 border-x no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${tab.path === activeTab ? "bg-primary text-black" : "text-white"}`}
              onClick={() => setActiveTab(tab.path)}
            >
              {tab.label}
            </Button>
          ))}
        </section>
      </section>
    </Fragment>
  )
}
