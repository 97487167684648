// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import moment from "moment"

// Types
import { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

// Types
interface Type {
  count_id: number
  employee_name: string
  location_name: string
  memo: string
  transaction_date: string
  trx_code: string
}
interface DataType extends Type {
  count_status: string
  real_count: number
}
interface FetchType extends Type {
  count_status: number
  real_count: string
}

export function useList(): {
  data: DataType[],
  isLoading: boolean
} {
  // Vars
  const statusList: {
    [key: string]: string
  } = {
    1: "COUNT",
    2: "ADJUSTMENT"
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get(
      "/invcount/invcount_adjustment_list"
    ).then((res: SuccessFetch<{
      payload: FetchType[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          count_id: item.count_id,
          count_status: statusList[item.count_status],
          employee_name: item.employee_name,
          location_name: item.location_name,
          memo: item.memo,
          real_count: parseInt(item.real_count),
          transaction_date: moment(item.transaction_date).format("DD/MM/YYYY"),
          trx_code: item.trx_code
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}