import { BaseTable, Button, FooterMenu } from "components"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { APPROVAL, useHandleAuth } from "utils"
import { useTransferItemsList } from "../../../utils/hooks"

export const TableTransferList = () => {
  const navigate = useNavigate()
  const transfers = useTransferItemsList({ Approval: APPROVAL.APPROVE })
  const handleAuth = useHandleAuth()
  const user = handleAuth.userTokenData()

  return (
    <>
      <div className="flex flex-col justify-start items-start uppercase">
        <span className="font-bold text-sm py-[0.5rem]">
          EMPLOYEE : {user?.employee_name ?? ""}
        </span>

        <span className="font-bold text-sm py-[0.5rem]">
          LOCATION : {user?.location_name ?? ""}
        </span>
      </div>

      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>No</th>
            <th colSpan={2}>TRANSFER ID#</th>
            <th>Date</th>
            <th>From Location</th>
            <th>To Location</th>
            <th>Trader</th>
            <th>Memo</th>
            <th>Qty Transfered</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {transfers?.data?.length ? (
            transfers.data?.map((transfer, key) => {
              return (
                <tr key={transfer.transfer_id}>
                  <td>{key + 1}</td>
                  <td className="text-center px-0">
                    <Button
                      size="xs"
                      color="ghost"
                      onClick={() => {
                        navigate(
                          `/inventory/transaction/received/${transfer.transfer_id}`
                        )
                      }}>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td>{transfer.trx_code}</td>
                  <td>{convertDate(transfer.transaction_date)}</td>
                  <td>{transfer.location_from_name}</td>
                  <td>{transfer.location_to_name}</td>
                  <td>{transfer.employee_name}</td>
                  <td>{transfer.memo}</td>
                  <td>{transfer.total_transferred ?? 0}</td>
                  <td>{transfer.transfer_status === 2 ? "CLOSED" : "OPEN"}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={10} className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </BaseTable>
      <section className="mt-2">
        <FooterMenu />
      </section>
    </>
  )
}
