import * as yup from "yup"

export const profileValidationSchema = yup.object().shape({ 
    nomor: yup.string().label("Number").optional().max(20, "Must be 20 characters or less"),
    handphone: yup.string().label("Handphone").optional().max(20, "Must be 20 characters or less"),
    email: yup.string().label("Email").optional().max(200, "Must be 200 characters or less").email("Email must be a valid email address"),

    type_nationality: yup.string().optional(),
    marital_status: yup.string().optional(),
    religion_id: yup.string().optional(),
    birthdate: yup.string().optional()
})