/* eslint-disable react-hooks/exhaustive-deps */
import { Card, FooterMenu } from "components"
import moment from "moment"
import { useFilter } from "pages/Finance/Journal/utils/hooks"
import { Fragment, useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { prevYear, thisMonth, thisWeek, thisYear, today } from "utils/functions"
import { RegisterDateType } from "../../types"
import { DropdownReference } from "../DropdownReference"
import { FilterByDate } from "../FilterByDate"
import { TableRegister } from "../TableRegister"
import { FooterSummary } from "../FooterSummary"

type Response = {
  payload: RegisterDateType[]
}

export function RegisterReference() {
  const api = useApi()
  const todayDate = today()
  const prevYearDate = prevYear()
  const thisYearDate = thisYear()
  const thisMonthDate = thisMonth()
  const thisWeekDate = thisWeek()
  const [data, setData] = useState<RegisterDateType[]>([])
  const isLoading = false

  const { filter, handleChangeFilter } = useFilter({
    ReferenceCode: "",
    dateFrom: thisMonthDate.fromDate,
    dateTo: todayDate
  })

  const fetch = () =>
    api
      .postForm("/regbydatarange/getdatabyref", {
        ReferenceCode: filter.ReferenceCode,
        StartDate: filter.dateFrom,
        EndDate: filter.dateTo
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        setData(
          data?.map((item) => ({
            ...item,
            checked: false
          })) ?? []
        )
      })

  useEffect(() => {
    if (filter.ReferenceCode) {
      fetch()
    }
  }, [filter])

  const handleCheckData = (index: number) => {
    const newData = [...data]
    data[index].checked = !data[index].checked

    setData(newData)
  }

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-5">
              <div className="w-full lg:w-1/2 flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
                <div className="label-text pb-1 font-bold pe-6">Reference</div>
                <DropdownReference
                  value={null}
                  onChange={(item) =>
                    handleChangeFilter({ ReferenceCode: item?.value })
                  }
                />
              </div>
            </section>
            <FilterByDate
              permission="FR043"
              dateFrom={filter.dateFrom}
              dateTo={filter.dateTo}
              onChangeDateFrom={(dateFrom) => {
                const isValid = moment(dateFrom).isBefore(filter.dateTo)
                handleChangeFilter({
                  dateFrom,
                  dateTo: isValid ? filter.dateTo : dateFrom
                })
              }}
              onChangeDateTo={(dateTo) => handleChangeFilter({ dateTo })}
              onClickPrevYear={() =>
                handleChangeFilter({
                  dateFrom: prevYearDate.fromDate,
                  dateTo: prevYearDate.toDate
                })
              }
              onClickThisYear={() =>
                handleChangeFilter({
                  dateFrom: thisYearDate.fromDate,
                  dateTo: thisYearDate.toDate
                })
              }
              onClickThisWeek={() =>
                handleChangeFilter({
                  dateFrom: thisWeekDate.fromDate,
                  dateTo: thisWeekDate.toDate
                })
              }
              onClickThisMonth={() =>
                handleChangeFilter({
                  dateFrom: thisMonthDate.fromDate,
                  dateTo: thisMonthDate.toDate
                })
              }
              onClickToday={() =>
                handleChangeFilter({
                  dateFrom: todayDate,
                  dateTo: todayDate
                })
              }
            />
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <TableRegister
          data={data}
          filter={filter}
          isLoading={isLoading}
          handleCheckData={handleCheckData}
          fetchList={fetch}
          permission="FR04"
        />


      </section>

      <section className="mt-2">
        <FooterMenu
          customRightElement={(
            <FooterSummary
              data={data}
              dateFrom={filter.dateFrom}
            />
          )}
        />
      </section>
    </Fragment>
  )
}
