import { ErrorMessage } from "@hookform/error-message"
import { ActionButton, BaseTable } from "components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useState } from "react"
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form"
import toast from "react-hot-toast"
import { FormTransferItemsType } from "../../../types"
import { useProductAvailableList } from "../../../utils/hooks"
import { RowTransferItem } from "./RowTransferItem"

type TableCustomerOrderProps = {
  approvalView?: boolean
  display?: boolean
}

export const TableTransferItem = ({
  approvalView = false,
  display = true
}: TableCustomerOrderProps) => {
  const methods = useFormContext<FormTransferItemsType>()
  const [editField, setEditField] = useState<number | null>(null)
  const [location_from_id, approval_status] = useWatch({
    control: methods.control,
    name: ["location_from_id", "approval_status"]
  })
  const products = useProductAvailableList({ location_id: location_from_id })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "transfer_detail"
  })

  return (
    <div className={`${display ? "" : "hidden"} overflow-x-auto`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>-</th>
            <th>Item Code</th>
            <th>Description</th>
            <th>SOH</th>
            <th>Unit</th>
            <th className="w-1/6">Ship</th>
            <th className={approvalView ? "hidden" : ""}>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {fields.map((arrField, key) => {
            return (
              <Controller
                key={arrField.id}
                control={methods.control}
                name={`transfer_detail.${key}`}
                render={({ field }) => {
                  if (editField === key) {
                    return (
                      <RowTransferItem
                        key={arrField.id}
                        products={products}
                        index={editField}
                        action="edit"
                        isHideForm={false}
                        defaultValues={field.value}
                        onCancelEditing={() => setEditField(null)}
                        onSubmit={(value) => {
                          field.onChange(value)
                          setEditField(null)
                        }}
                      />
                    )
                  } else {
                    return (
                      <tr>
                        <td className="text-center px-0">{key + 1}</td>
                        <td>{field.value.product_barcode}</td>
                        <td>{field.value.product_name}</td>
                        <td className="text-right">{field.value.soh}</td>
                        <td>{field.value.mr_unit_name}</td>
                        <td className="text-right">
                          {field.value.quantity_transfer}
                        </td>
                        <td className={approvalView ? "hidden" : ""}>
                          {methods.getValues("approval_status") !==
                            APPROVAL.APPROVE && (
                              <div className="flex justify-center items-center">
                                <ActionButton.Update
                                  onClick={() => setEditField(key)}
                                />

                                <ActionButton.Delete
                                  onClick={() => remove(key)}
                                />
                              </div>
                            )}
                        </td>
                      </tr>
                    )
                  }
                }}
              />
            )
          })}

          {approval_status === APPROVAL.PENDING && !approvalView && (
            <RowTransferItem
              key={fields.length}
              products={products}
              action="add"
              onSubmit={(value, methods) => {
                if (
                  !fields.find((item) => item.product_id === value.product_id)
                ) {
                  return append(value)
                }

                toast.error("Product is already in the list")

                methods?.setError(
                  "product_id",
                  { message: "Product is already in the list" },
                  { shouldFocus: true }
                )
              }}
            />
          )}
        </tbody>
      </BaseTable>

      <ErrorMessage
        errors={methods.formState.errors}
        name="sodetail"
        render={({ message }) => (
          <div className="mt-2">
            <p className="text-red-500">{message}</p>
          </div>
        )}
      />
    </div>
  )
}
