/* eslint-disable react-hooks/exhaustive-deps */
import { BaseTable, Card, FooterMenu, Loading } from "components"
import moment from "moment"
import "moment/locale/id"
import { Fragment, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useApi, usePermission, useToggle } from "utils"
import { today } from "utils/functions"
import {
  BalanceSheetComparisonType,
  BalanceSheetNoneType,
  BalanceSheetReportType,
  FilterReport
} from "../../types"
import { periode } from "../../utils/vars"
import { FilterBS, Item } from "./components"
import {
  calculateParent,
  generateDateHeaderColumn,
  generateNetAsset
} from "./utils/functions"
import { ItemTotal } from "./components/ItemTotal"

export type ResponseBS =
  | Array<BalanceSheetNoneType>
  | Array<BalanceSheetComparisonType[]>

export const TabBalanceSheet = () => {
  const permission = usePermission(["AP01"])
  const api = useApi()
  const toggleAccountNumber = useToggle(true)
  const toggleZeroValue = useToggle(true)
  const toggleSubHeader = useToggle(true)
  const [comparisonDate, setComparisonDate] = useState<string[]>([])
  const [reportData, setReportData] = useState<BalanceSheetReportType[]>([])
  const nonZeroReportData: BalanceSheetReportType[] = reportData.filter(
    (item) => item.balance.some((balance) => balance !== 0)
  )
  const data = toggleZeroValue.isActive ? reportData : nonZeroReportData

  const methods = useForm<FilterReport>({
    defaultValues: {
      dateType: "1",
      date: today(),
      periode: periode[0],
      comparison: undefined,
      filter: "0"
    }
  })

  const [date, periodValue] = methods.watch(["date", "periode.value"])

  const onSubmitFilter = async (data: FilterReport) => {
    setComparisonDate([])

    const isFilterAll = Boolean(data.filter === "0")

    await api
      .postForm<{ payload: ResponseBS }>("/balancesheet/", {
        DateType: data.dateType,
        DateBalance: data.date,
        PeriodType: data.periode.value,
        Comparison: data.comparison
      })
      .then((res) => {
        /**
         * Should pay attention to the response type, the response has 2 different type.
         * if the comparation is exist, the response will be an array of array and need to be flatten for simplicity
         * else, if the comparation is undefined, the response will be an array of object
         **/

        if (data.comparison) {
          const payload = res.data.payload as BalanceSheetComparisonType[][]

          const flattenPayload = payload.flat()

          const dateCompare = flattenPayload.map(
            ({ DateCompare }) => DateCompare
          )

          const calculate = flattenPayload.map((data) => {
            return {
              ...data,
              BSData: calculateParent(data.BSData)
            }
          })

          const filterByCoaGrup = isFilterAll
            ? calculate.map((row) => ({
              DateCompare: row.DateCompare,
              BSData: generateNetAsset(row.BSData)
            }))
            : calculate.map((row) => ({
              DateCompare: row.DateCompare,
              BSData: row.BSData.filter(
                (coa) => coa.group_coa_code === data.filter
              )
            }))

          const mapBalanceToArray = filterByCoaGrup[0].BSData?.map((data) => ({
            ...data,
            balance: calculate.map(
              ({ BSData }) =>
                BSData?.find(({ coa_id }) => coa_id === data.coa_id)?.balance ||
                0
            )
          }))

          setComparisonDate(dateCompare)
          setReportData(mapBalanceToArray)
        } else {
          const date = moment(data.date)

          const payload = res.data.payload as BalanceSheetNoneType[]

          const dateCompare = [date.format("YYYY-MM-DD")]

          const calculate = calculateParent(payload)

          const filterByCoaGrup = isFilterAll
            ? generateNetAsset(calculate)
            : calculate.filter((coa: any) => coa.group_coa_code === data.filter)

          const mapBalanceToArray = filterByCoaGrup.map((data) => ({
            ...data,
            balance: [data.balance]
          }))

          setComparisonDate(dateCompare)
          setReportData(mapBalanceToArray)
        }
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    if (methods.formState.submitCount === 0) {
      methods.handleSubmit((data) => onSubmitFilter(data))()
    }
  }, [])

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <FormProvider {...methods}>
              <FilterBS
                permission={permission?.canUpdate}
                toggleAccountNumber={toggleAccountNumber}
                toggleZeroValue={toggleZeroValue}
                toggleSubHeader={toggleSubHeader}
                onSubmit={onSubmitFilter}
              />
            </FormProvider>
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <Card>
          <Card.Body>
            <section className="container my-5">
              <div className="container flex flex-col justify-start items-start my-3 uppercase text-md font-bold gap-y-2">
                <div>PT Hawk</div>
                <div>Balance Sheet</div>
                <div className="capitalize">
                  {moment(date).format("DD MMMM YYYY")}
                </div>
              </div>

              <Card>
                <Card.Body>
                  <BaseTable className="border-collapse border-spacing-0">
                    <thead>
                      <tr className="uppercase">
                        <th></th>
                        {comparisonDate?.map((date) => (
                          <th key={date} className="text-right">
                            {generateDateHeaderColumn(periodValue, date)}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {!reportData?.length || methods.formState.isSubmitting ? (
                        <tr>
                          <td colSpan={10} className="text-center">
                            <Loading
                              loading={methods.formState.isSubmitting}
                              errorText={
                                methods.formState.isSubmitting
                                  ? ""
                                  : "No data available"
                              }
                            />
                          </td>
                        </tr>
                      ) : (
                        data.map((item, key) =>
                          item.parent_id === -1 ? (
                            <>
                              <Item
                                key={item.coa_id + key}
                                list={data}
                                item={item}
                                displayAccountNumber={toggleAccountNumber.isActive}
                                displaySubHeader={toggleSubHeader.isActive}
                              />

                              {item.coa_name === "NET ASSETS" ? null : (
                                <ItemTotal item={item} />
                              )}
                            </>
                          ) : (
                            <Fragment />
                          )
                        )
                      )}
                    </tbody>
                  </BaseTable>
                </Card.Body>
              </Card>
            </section>
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <FooterMenu />
      </section>
    </Fragment>
  )
}
