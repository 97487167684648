/* eslint-disable react-hooks/exhaustive-deps */
import { Card, DateInput, Input, NumberInput, Textarea } from "components"
import moment from "moment"
import { useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { DropdownPay } from "../../PaymentDeposit/components"
import { PaymentInvoiceType } from "../types"

type FormPaymentInvoiceProps = {
  approvalView?: boolean
  isEdit?: boolean
}

export const FormPaymentInvoice = ({
  approvalView,
  isEdit
}: FormPaymentInvoiceProps) => {
  const { control, setValue } = useFormContext<PaymentInvoiceType>()
  const [balance_invoice, payment_amount, deposit_amount] = useWatch({
    control,
    name: ["balance_invoice", "payment_amount", "deposit_amount"]
  })

  useEffect(() => {
    const balance = balance_invoice - (payment_amount + deposit_amount)

    setValue("balance", balance)
  }, [balance_invoice, payment_amount, deposit_amount])

  return (
    <Card>
      <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <section>
            <section className="flex gap-3">
              <div className="grow">
                <DropdownPay isDisabled />
              </div>
            </section>
          </section>

          <Controller
            control={control}
            name="memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                disabled={approvalView}
                label="MEMO"
                defaultValue={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="shipped"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                disabled
                label="BRANCH LOCATION"
                defaultValue={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="link"
            render={({ field }) => (
              <Input
                disabled
                label="LINK TO INVOICE ID"
                defaultValue={field.value}
              />
            )}
          />
        </section>

        <section className="flex flex-col gap-3">
          <Controller
            control={control}
            name="referensi"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                disabled={approvalView}
                label="CC | DEBIT NO. | CHAQUE NO."
                value={field.value}
                onChange={(data) => {
                  const value = data.target.value

                  if (!isNaN(Number(value))) {
                    field.onChange(value)
                  }
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="transaction_date"
            render={({ field, fieldState }) => (
              <DateInput
                disabled={approvalView}
                label="RECEIVE DATE"
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          {/* total invoice dikurangi payment yang sudah bayar */}
          <Controller
            control={control}
            name="balance_invoice"
            render={({ field }) => (
              <NumberInput
                disabled
                label="BALANCE INVOICE"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                textRight
                value={field.value}
              />
            )}
          />

          {/* Default : dari sisa deposit customer yg ngelink dengan SO. Nilai default bisa di edit oleh user , untuk menyesuikan jumlah yg diinginkan  */}
          <Controller
            control={control}
            name="deposit_amount"
            render={({ field, fieldState }) => (
              <NumberInput
                disabled={approvalView}
                label="PAYMENT FROM DEPOSIT"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                textRight
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => field.onChange(value.floatValue)}
                error={fieldState.error?.message}
              />
            )}
          />

          {/* 
          -Awal nya 0 
          -User bisa memasukkan angka sesuai keinginan 
          -Dengan ketentuan setelah input PAYMENT AMOUNT nilai BALANCE tidak boleh minus 
          -BALANCE = (BALANCE INVOICE ) – (input PAYMENT FROM DEPOSIT ) – (input PAYMENT AMOUNT)  
          */}
          <Controller
            control={control}
            name="payment_amount"
            render={({ field, fieldState }) => (
              <NumberInput
                disabled={approvalView}
                label="PAYMENT AMOUNT"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                textRight
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => field.onChange(value.floatValue)}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="balance"
            render={({ field, fieldState }) => (
              <NumberInput
                disabled
                textRight
                label="BALANCE"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => field.onChange(value.floatValue)}
                error={fieldState.error?.message}
              />
            )}
          />
        </section>
      </Card.Body>
    </Card>
  )
}
