// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { BillType } from "../../types"

// Utils
import { useApi } from "utils"

export function useDetail(payment_bill_id: string) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<BillType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/paymentbill/datapayment_by_payment_id", {
      params: { payment_bill_id }
    }).then((res: SuccessFetch<{
      payload: {
        approve_status: number
        bill_id: number
        coa_id: number
        memo: string
        deposit_amount: number
        payment_amount: number
        referensi: string
        trx_code: string
        transaction_date: string
      }
    }>) => {
      // Vars
      const payload = res.data.payload

      api.get("/probill/detailbill", {
        params: { bill_id: payload.bill_id }
      }).then((res: SuccessFetch<{
        payload: {
          databill: {
            address: string
            balance: number
            location_id: number
            saldo_deposit: number
            trx_code: string
            vendor_id: number
            vendor_name: string
          }
        }
      }>) => {
        // Vars
        const result = res.data.payload
  
        setData({
          approve_status: payload.approve_status,
          bill_id: payload.bill_id,
          coa_id: payload.coa_id.toString(),
          deposit_amount: payload.deposit_amount,
          memo: payload.memo,
          payment_amount: payload.payment_amount,
          referensi: payload.referensi,
          transaction_date: payload.transaction_date,
          trx_code: payload.trx_code,
          databill: {
            ...result.databill,
            location_id: result.databill.location_id.toString(),
            vendor_id: result.databill.vendor_id.toString()
          }
        })
      }).catch(() => {
        setData(null)
      }).finally(() => {
        setLoading(false)
      })
    }).catch(() => {
      setData(null)
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}