import { Button, HeaderMenu, PaginationData, Select } from "components"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { default_pagination_value } from "utils"
import { TableTaskList } from "./components/Tables/TaskList"
import { FilterTask } from "./types"
import { useTaskList } from "./utils/hooks"
import { TASK_PRIORITY, TASK_STATUS } from "./utils/vars"

export default function TaskList() {
  const navigate = useNavigate()
  const [pagination, setPagination] = useState<FilterTask>({
    ...default_pagination_value,
    size: 0,
    pages: 1,
    total: 0,
    task_status: "1",
    priority: "0"
  })

  const tasks = useTaskList({
    params: pagination,
    onSuccess: (paging) => {
      setPagination((prev) => ({
        ...prev,
        ...paging
      }))
    }
  })

  const status = Object.values(TASK_STATUS)
    .filter((item) => typeof item === "number")
    .map((item) => ({
      value: (item as unknown as number).toString(),
      label: TASK_STATUS[item as number]
    }))
    .concat([
      {
        value: "0",
        label: "All"
      }
    ])

  const priorities = Object.values(TASK_PRIORITY)
    .filter((item) => typeof item === "number")
    .map((item) => ({
      value: (item as unknown as number).toString(),
      label: TASK_PRIORITY[item as number]
    }))
    .concat([
      {
        value: "0",
        label: "All"
      }
    ])

  const onClickCreateTask = () => {
    navigate("/tasks/create")
  }

  return (
    <section className="m-5">
      <HeaderMenu title="HOME | TASK LIST" />
      <section className="flex justify-between items-center my-3">
        <section className="w-1/4 flex justify-start items-start gap-x-3">
          <Select
            className="max-w-[200px]"
            placeholder="Select Status"
            options={status ?? []}
            value={
              pagination.task_status
                ? status.find((item) => item.value === pagination.task_status)
                : null
            }
            onChange={(e) => {
              if (e?.value) {
                setPagination({ ...pagination, task_status: e?.value, page: 1 })
              }
            }}
          />

          <Select
            className="max-w-[250px]"
            placeholder="Select Status"
            options={priorities ?? []}
            value={
              pagination.priority
                ? priorities.find((item) => item.value === pagination.priority)
                : null
            }
            onChange={(e) => {
              if (e?.value) {
                setPagination({ ...pagination, priority: e?.value, page: 1 })
              }
            }}
          />
        </section>

        <Button className="bg-primary" onClick={onClickCreateTask}>
          CREATE NEW TASK
        </Button>
      </section>

      <TableTaskList tasks={tasks} />

      <div className="mt-5">
        <PaginationData
          data={{
            pagination_data: {
              size: tasks.paging?.size
            }
          }}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </section>
  )
}
