// React
import { createContext } from "react"

// Types
import type { BillType } from "../types"

export const BillContext = createContext<BillType>({
  approve_status: 0,
  bill_id: 0,
  coa_id: "",
  deposit_amount: 0,
  memo: "",
  payment_amount: 0,
  referensi: "",
  transaction_date: "",
  trx_code: "",
  databill: {
    address: "",
    balance: 0,
    location_id: "",
    saldo_deposit: 0,
    trx_code: "",
    vendor_id: "",
    vendor_name: ""
  }
})