import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, DateInput, Input, NumberInput } from "components"
import { useContext, useEffect } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { TbEye } from "react-icons/tb"
import { convertNumber, useApi, useToggle } from "utils"
import * as yup from "yup"
import moment from "moment"
import { DataFormValue } from "../types"
import toast from "react-hot-toast"
import { useDetailBuyingData, useDetailPersonalData } from "../../../../utils"
import { SellingContext } from "pages/Inventori/Transaction/AddNewItem/contexts"
import { ReactBarcode } from "react-jsbarcode"

type DataFormProps = {
  action?: "CREATE" | "UPDATE" | "DETAIL" 
  defaultValue?: any
  method: "add" | "edit"
  refetch?: () => void 
  onCancelEditing?: () => void
  setCheckEdit?: ( newState: string ) => void
}
export const DataForm = ({ action, defaultValue, method, refetch, onCancelEditing, setCheckEdit }: DataFormProps) => {
    const { isActive, toggle } = useToggle(false)
    const api = useApi()
    
    const dataSelling = useContext(SellingContext)
    const productId = localStorage.getItem("prdId")
    const productSKUId = localStorage.getItem("pskuId")
    const { dataDetail } = useDetailPersonalData(Number(productId))
    const { dataBuying } = useDetailBuyingData(Number(productId))

    const defaultValueRemapper = {
      ...defaultValue,
      retail_price: defaultValue?.retail_price ? convertNumber(Number(defaultValue?.retail_price))?.intoCurrency : 0,
    };
  
    const defaultValues: DataFormValue = { 
      product_id: Number(productId), 
      sku: "", 
      qty: NaN, 
      retail_price: dataBuying?.price_unit_convention ? Number(dataBuying?.price_unit_convention) : NaN, 
      created_date: "", 
      expired_date: "" 
    }
    const validationSchema = yup.object().shape({
      sku: yup.string().required("Required Field").max(20, "Max 20 chars"),
      qty: yup
        .number()
        .typeError("Must be a number")
        .min(-2147483648, "Qty is too low")
        .max(2147483647, "Qty is too high")
        .transform((value, originalValue) => originalValue === "" ? undefined : value )
        .required("Required Field"),
      retail_price: yup
        .number()
        .typeError("Must be a number")
        .min(-2147483648, "Price is too low")
        .max(2147483647, "Price is too high")
        .transform((value, originalValue) => originalValue === "" ? undefined : value )
        .required("Required Field"),
      created_date: yup.string().required("Required Field"),
      expired_date: yup.string().required("Required Field"),
    })

    const methods = useForm<DataFormValue>({
      defaultValues: method === "edit" ? defaultValueRemapper : defaultValues,
      resolver: yupResolver(validationSchema)
    })
    const onSubmit = (value: DataFormValue) => {
      const updateValue = { 
        ...value, 
        product_id: Number(productId), 
        sku: value.sku,
        qty: value.qty,
        retail_price: value.retail_price ? Number(value.retail_price) : 0,
        created_date: value.created_date, 
        expired_date: value.expired_date
      }

      return new Promise<void>((resolve) => {
        toast.promise(
          api.post("/product_sku", updateValue),
          {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) => err.response.data.message ?? err.response.message
          }
        ).then(() => {
            setCheckEdit && setCheckEdit("onAdd")
            methods.reset()
            refetch && refetch()
        }).catch(() => {}).finally(resolve)
      })
    }
    const onUpdate = (value: DataFormValue) => {
      const updateValue = { 
        ...value, 
        product_id: Number(productId), 
        sku: value.sku,
        qty: value.qty,
        retail_price: value.retail_price ? Number(value.retail_price) : 0,
        created_date: value.created_date, 
        expired_date: value.expired_date
      }

      return new Promise<void>((resolve) => {
        toast.promise(
          api.put(`/product_sku/${productSKUId}`, updateValue),
          {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) => err.response.data.message ?? err.response.message
          }
        ).then(() => {
            setCheckEdit &&setCheckEdit("")
            methods.reset()
            refetch && refetch()
        }).catch(() => {}).finally(resolve)
      })
    }
  
    useEffect(() => {
      if (method === "add") {
        methods.reset({ sku: "", qty: NaN, retail_price: NaN, created_date: "", expired_date: "" })
      }
      // eslint-disable-next-line
    }, [])
    useEffect(() => {
      if (dataSelling.length && method === "add") {
        methods.setValue("retail_price", Number(dataSelling[0].price_retail))
      }
      // eslint-disable-next-line
    }, [dataSelling])
  
    return (
      <FormProvider {...methods}>
        <tr className="no-padding-body">
          <td>{!isActive && method === "add" && <TbEye className="size-5 mx-auto my-3 text-center cursor-pointer" onClick={toggle} />}</td>
          {method === "add" 
            ? isActive && <>
                <td>
                  <Controller
                    control={methods.control}
                    name="sku"
                    render={({ field, fieldState }) => 
                      <Input 
                        {...field} 
                        ref={field.ref}
                        placeholder="Input SKU"
                        inputClass={`w-[120px] ${fieldState.error ? 'input-error' : ''}`}
                        value={field.value ?? ""} 
                        disabled={action === "DETAIL"} 
                        error={fieldState.error?.message}
                      />
                    }
                  />
                </td>
                <td className="text-center"><ReactBarcode options={{ width: 0.5, fontSize: 11, height: 23 }} value={dataDetail?.product_barcode!} /></td>
                <td className="text-center">{dataDetail?.product_code}</td>
                <td>
                  <Controller
                    control={methods.control}
                    name="qty"
                    render={({ field, fieldState }) => 
                      <NumberInput
                        ref={field.ref}
                        decimalSeparator='.'
                        thousandSeparator=","
                        className="text-gray-400"
                        placeholder="Input qty"
                        inputClass={`w-[135px] ${fieldState.error ? 'input-error' : ''}`}
                        error={fieldState.error?.message}
                        value={field.value}
                        onValueChange={value => field.onChange(value.floatValue)}
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="retail_price"
                    render={({ field, fieldState }) => 
                      <NumberInput
                        ref={field.ref}
                        decimalSeparator='.'
                        thousandSeparator=","
                        className="text-gray-400"
                        placeholder="Input retail price"
                        inputClass={`w-[135px] ${fieldState.error ? 'input-error' : ''}`}
                        error={fieldState.error?.message}
                        value={field.value}
                        onValueChange={value => field.onChange(value.floatValue)}
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="created_date"
                    render={({ field, fieldState }) => (
                      <DateInput
                        {...field} 
                        placeholderText="Input create"
                        ref={field.ref}
                        selected={field?.value ? moment(field?.value).toDate() : undefined}
                        className={`w-full mr-2 ${fieldState.error ? 'input-error' : ''}`}
                        onChange={(value) => {
                          if (value) {
                            field.onChange(moment(value).format("YYYY-MM-DD"));
                          } else {
                            field.onChange(undefined);
                          }
                        }}
                        error={fieldState.error?.message}
                        disabled={Boolean(action === "DETAIL")} 
                        portalId="root"
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="expired_date"
                    render={({ field, fieldState }) => (
                      <DateInput
                        {...field} 
                        placeholderText="Input expired"
                        ref={field.ref}
                        selected={field?.value ? moment(field?.value).toDate() : undefined}
                        className={`w-full mr-2 ${fieldState.error ? 'input-error' : ''}`}
                        onChange={(value) => {
                          if (value) {
                            field.onChange(moment(value).format("YYYY-MM-DD"));
                          } else {
                            field.onChange(undefined);
                          }
                        }}
                        error={fieldState.error?.message}
                        disabled={Boolean(action === "DETAIL")} 
                        portalId="root"
                      />
                    )}
                  />
                </td>
                <td className="text-center flex items-center justify-center">
                  {onCancelEditing && <ActionButton.Close onClick={onCancelEditing} />}
                  <ActionButton.Add loading={methods.formState.isSubmitting ? "true" : undefined} onClick={methods.handleSubmit(onSubmit)} />
                </td>
              </>
            : <>
                <td>
                  <Controller
                    control={methods.control}
                    name="sku"
                    render={({ field, fieldState }) => 
                      <Input 
                        {...field} 
                        ref={field.ref}
                        placeholder="Input SKU"
                        inputClass={`w-[120px] ${fieldState.error ? 'input-error' : ''}`}
                        value={field.value ?? ""} 
                        disabled={action === "DETAIL"} 
                        error={fieldState.error?.message} 
                      />
                    }
                  />
                </td>
                <td className="text-center"><ReactBarcode options={{ width: 0.5, fontSize: 11, height: 23 }} value={defaultValue?.product.product_barcode} /></td>
                <td>{defaultValue?.product.product_code ?? "-"}</td>
                <td>
                  <Controller
                    control={methods.control}
                    name="qty"
                    render={({ field, fieldState }) => 
                      <NumberInput
                        ref={field.ref}
                        decimalSeparator='.'
                        thousandSeparator=","
                        className="text-gray-400"
                        placeholder="Input qty"
                        inputClass={`w-[135px] ${fieldState.error ? 'input-error' : ''}`}
                        error={fieldState.error?.message}
                        value={field.value}
                        onValueChange={value => field.onChange(value.floatValue)}
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="retail_price"
                    render={({ field, fieldState }) => 
                      <NumberInput
                        ref={field.ref}
                        decimalSeparator='.'
                        thousandSeparator=","
                        className="text-gray-400"
                        placeholder="Input retail price"
                        inputClass={`w-[135px] ${fieldState.error ? 'input-error' : ''}`}
                        error={fieldState.error?.message}
                        value={field.value}
                        onValueChange={value => field.onChange(value.floatValue)}
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="created_date"
                    render={({ field, fieldState }) => (
                      <DateInput
                        {...field} 
                        placeholderText="Input create"
                        ref={field.ref}
                        selected={field?.value ? moment(field?.value).toDate() : undefined}
                        className={`w-full mr-2 ${fieldState.error ? 'input-error' : ''}`}
                        onChange={(value) => {
                          if (value) {
                            field.onChange(moment(value).format("YYYY-MM-DD"));
                          } else {
                            field.onChange(undefined);
                          }
                        }}
                        error={fieldState.error?.message}
                        disabled={Boolean(action === "DETAIL")} 
                        portalId="root"
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="expired_date"
                    render={({ field, fieldState }) => (
                      <DateInput
                        {...field} 
                        placeholderText="Input expired"
                        ref={field.ref}
                        selected={field?.value ? moment(field?.value).toDate() : undefined}
                        className={`w-full mr-2 ${fieldState.error ? 'input-error' : ''}`}
                        onChange={(value) => {
                          if (value) {
                            field.onChange(moment(value).format("YYYY-MM-DD"));
                          } else {
                            field.onChange(undefined);
                          }
                        }}
                        error={fieldState.error?.message}
                        disabled={Boolean(action === "DETAIL")} 
                        portalId="root"
                      />
                    )}
                  />
                </td>
                <td className="text-center flex items-center justify-center">
                  {onCancelEditing && <ActionButton.Close onClick={onCancelEditing} />}
                  <ActionButton.Add loading={methods.formState.isSubmitting ? "true" : undefined} onClick={methods.handleSubmit(onUpdate)} />
                </td>
              </>
          }
        </tr>
      </FormProvider>
    )
}