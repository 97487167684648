import moment from "moment"
import { FilterType } from "../../types"

export const defaultFilter: FilterType = {
  search: "",
  start_date: moment().startOf("month").format("YYYY-MM-DD"),
  end_date: moment().endOf("month").format("YYYY-MM-DD"),
  approve_status: "",
  customer_id: "",
  total: 0,
  page: 1,
  limit: 10,
  pages: 1,
  keyword: ""
}
