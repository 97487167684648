// React
import { Fragment } from "react"

// Components
import { ModalSection as TaxCodeModal } from "pages/Setup/Preference/TaxCode/components"

// Utils
import { useToggle } from "utils"

export function TaxCode(params: {
  disabled?: boolean
  onSuccess: () => void
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <th className="whitespace-nowrap">
        TAX {!params.disabled && <span className="whitespace-nowrap text-primary cursor-pointer" onClick={toggle}>[+]</span>}
      </th>

      {isActive && (
        <TaxCodeModal
          toggle={toggle}
          onSuccess={params.onSuccess}
        />
      )}
    </Fragment>
  )
}