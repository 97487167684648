import { SubmitType, TaskType } from "../../types"

export const submitMap = (data: TaskType): SubmitType => {
  return {
    task_id: data.task_id,
    trx_code: data.trx_code,
    location_id: data.location_id,
    departement_id: data.department_id,
    project_id: data.project_id,
    mr_group_task_id: data.mr_group_task_id,
    mr_group_sub_id: data.mr_group_sub_id,
    task_status: data.task_status,
    subject: data.subject,
    priority: data.priority,
    date_start: data.date_start,
    date_end: data.date_end,
    owner_task_id: data.owner_task_id,
    description: data.description
  }
}
