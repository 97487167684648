import { BaseTable, Button, Card, FooterMenu, HeaderMenu } from "components";
import { useLinkAccountList, useList } from "./utils";
import { Create } from "./components";

export default function LinkAccountPos() {

    const data = useLinkAccountList()
    const { dataAccount, refetch } = useList()

    return (
        <section className="container pt-5  gap-5 flex flex-col">
            <HeaderMenu title="LINK ACCOUNT POS">
                <Button color="primary">TUTORIAL</Button>
            </HeaderMenu>

            <Card>
                <Card.Body>
                    <div className="overflow-x-auto">
                        <div className="border">
                            {data && data.map((i, key) => (
                                <div key={key}>
                                    <div className="border p-4">
                                        <span className="font-bold">{i.header}</span>
                                    </div>
                                    <div>
                                        <div className="p-4">
                                            <BaseTable className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="border" colSpan={2}>LOCATION</th>
                                                        <th className="border">NUMBER</th>
                                                        <th className="border">COA NAME</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {i.linkAccount && i.linkAccount.map((item, index) => {
                                                        // Vars
                                                        const coa = dataAccount.find(list => list.location_id.toString() === item.location_id && list.trx === item.trx)

                                                        return (
                                                            <tr key={index}>
                                                                <td className="border w-[100px]">{item?.location_name}</td>
                                                                <td className="border text-center w-[100px]">
                                                                    <Create
                                                                        dataItem={item}
                                                                        refetch={refetch}
                                                                    />
                                                                </td>
                                                                <td className="border w-[200px]">{coa ? `${coa.coa_code}` : ''}</td>
                                                                <td className="border w-[200px]">{coa?.coa_name}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </BaseTable>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <FooterMenu />
        </section>
    )
}