import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { IsActive } from "./IsActive"

export function Update(props: { id: DataList, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}

function ModalSection(props: { id: DataList; toggle: () => void; refetch: () => void }) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </>
  )
}

function DataForm(props: { data: DataList; id: DataList; toggle: () => void; refetch: () => void }) {
  const api = useApi()
  const { toggle } = useContext(Render)

  type CreateData = Omit<DataList, "project_id" | "country_name" | "province_name" | "regency_name" | "subdistrict_name" | "village_name" | "countries_id"> 

  const defaultValues: CreateData = {
    project_name: props?.id?.project_name ?? "",
    address: props?.id?.address ?? "",
    note: props?.id?.note ?? "",
    country_id: Number(props?.id?.country_id) ?? null,
    province_id: Number(props?.id?.province_id) ?? null,
    regency_id: Number(props?.id?.regency_id) ?? null,
    subdistrict_id: Number(props?.id?.subdistrict_id) ?? null,
    village_id: Number(props?.id?.village_id) ?? null,
  }
  const validationSchema = yup.object().shape({ 
    project_name: yup.string().label("Project name").required().max(100, "Must be 100 characters or less"),
    address: yup.string().label("Address").optional().max(100, "Must be 100 characters or less"),
    note: yup.string().label("Note").optional().max(250, "Must be 250 characters or less"),
  })
  const onSubmit = ( value: CreateData ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/project/edit?project_id=${props.id.project_id}`, 
        { 
          project_name: value.project_name, 
          address: value.address,
          country_id: value.country_id,
          province_id: value.province_id,
          regency_id: value.regency_id,
          subdistrict_id: value.subdistrict_id,
          village_id: value.village_id,
          note: value.note, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="UPDATE PROJECT" closeModal={props.toggle}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormProvider {...methods}>
            <FormSection isUpdate />
          </FormProvider>
        </Modal.Body>

        <Modal.Footer className="flex justify-between gap-1">
          <IsActive id={props.id} refetch={props.refetch} />
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}