import { Button, DateInput, Modal } from "components";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { IoSendSharp } from "react-icons/io5";
import { useApi, useToggle } from "utils";
import { DetailBill } from "../../EnterBill/components";
import { ApproveType } from "../types";


export function ApproveAction(props:{
    data: any,
    refetch: () => void
}) {
    const { isActive, toggle } = useToggle(false)
    
    return (
        <Fragment>
            <Button 
                type="button" 
                color="ghost"
                permission={"PT082"} 
                onClick={toggle} 
                disabled={Boolean(props?.data?.approve_status === 2 || props?.data?.approve_status === 4)}
                >
                <IoSendSharp />
            </Button>
            {isActive && <ModalSection toggle={toggle} data={props.data} refetch={props.refetch} />}
        </Fragment>
    )
}

function ModalSection(params: {
    toggle: () => void,
    data: ApproveType
    refetch: () => void
}): JSX.Element {
    const api = useApi()
	const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"))


    const onSubmit = (date: string, status: number) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post(`/probill/approve/${params.data.bill_id}`, {
                    approval_status: status,
                    approve_date: date
                }),
                {
                    loading: "Loading...",
                    success: (res: any) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
                params.toggle()
                params?.refetch && params.refetch()
            }).catch(() => { }).finally(resolve)
        })
    }

    return (
        <Modal
			isdarktitle
			isOpen
			title="APPROVE BILL"
			closeModal={params.toggle}
		>
			<Modal.Body>
                <DetailBill bill_id={params.data.bill_id} purchase_order_id={params.data.purchase_order_id.toString()} />
			</Modal.Body>

			<Modal.Footer className="flex md:flex-row flex-col justify-between md:items-end gap-3">
				<DateInput
					placeholderText="Select Date"
					selected={moment(date).toDate()}
					className="w-full"
					label="APPROVAL DATE"
					onChange={(date) => {
						const selectDate = moment(date).format("YYYY-MM-DD")
						setDate(selectDate)
					}}
				/>

				<section className="flex gap-3">
					<Button onClick={params.toggle}>CANCEL</Button>
					<Button color="error" onClick={() => onSubmit(date, 3)}>VOID</Button>
					<Button color="primary" onClick={() => onSubmit(date, 4)} >APPROVED</Button>
				</section>
			</Modal.Footer>
		</Modal>
    )
}

