import { Card } from "components";
import { CardSection } from "../CardSection";
import { BalanceSheet } from "../GrafikSection";
import { PiWarningCircleFill } from "react-icons/pi";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Expenses } from "./Expenses";

export function FinancialPosition() {
    return (
        <section>
            <div>
                <span className="font-bold text-[15px] mr-2">Financial position and cash</span>
                <span>On 3 Oct 2024</span>
            </div>
            <div className="grid lg:grid-cols-3 mt-2 gap-2 h-full">
                <div className="h-full">
                    <Card className="h-full">
                        <Card.Body className="h-full flex flex-col justify-between">
                            <CardSection label="Balance sheet">
                                <div className="mt-[20px]">
                                    <div className="border-b-2 pb-2 mb-2">
                                        Assets: <b>118,020,970,832</b>
                                    </div>
                                    <div className="mb-2">
                                        Liabilities: <b>783,090,902</b>
                                    </div>
                                    <div>
                                        Equity: <b>117,237,879,930</b>
                                    </div>
                                </div>
                                <div className="mt-[50px]">
                                    <BalanceSheet />
                                </div>
                                <div className="mt-5">
                                    Assets are equal to liabilities plus equity
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div className="h-full">
                    <Card className="h-full">
                        <Card.Body className="h-full flex flex-col justify-between">
                            <CardSection
                                label="Overall cash balance"
                                value="-3,196,047,794"
                                persentase={0}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[10px]">
                                    <div className="mb-2">
                                        <div>Mandiri Bank #1111</div>
                                        <div><b>3,693,636,534</b></div>
                                    </div>
                                    <div className="mb-2">
                                        <div>Escrow IDR</div>
                                        <div><b>722,401,340</b></div>
                                    </div>
                                    <div className="mb-2">
                                        <div>Mandiri Bank #4328 USD</div>
                                        <div><b>-244,812,601</b></div>
                                    </div>
                                    <div>
                                        Based on 11 of 11 accounts
                                    </div>
                                    <Link className="text-blue-600 flex items-center gap-2 w-full mt-8" to="">
                                        <b>See your upcoming cash flow </b>
                                        <FiExternalLink />
                                    </Link>
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div className="h-full">
                    <div className="grid grid-rows-2 gap-2 h-full">
                        <Card className="h-full">
                            <Card.Body className="h-full flex flex-col justify-between">
                                <CardSection
                                    label="Average time to get paid"
                                    value="-"
                                    persentase={0}
                                    icon={<PiWarningCircleFill />}
                                >
                                    <div>
                                        Value of unpaid invoices: <b>-10,734,614</b>
                                    </div>
                                </CardSection>
                            </Card.Body>
                        </Card>
                        <Card className="h-full">
                            <Card.Body className="h-full flex flex-col justify-between">
                                <CardSection
                                    label="Average time to pay suppliers"
                                    value="40 days"
                                    persentase={10}
                                    subLabel="92 days from 1 Jan - 3 Oct 2023"
                                    icon={<PiWarningCircleFill />}
                                >
                                    <div className="mt-6 mb-4">
                                        Value of unpaid bills: <b>783,090,902</b>
                                    </div>
                                </CardSection>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="mt-4 overflow-x-auto">
                <Expenses/>
            </div>
        </section>
    )
}