import { TaskActionList } from "pages/Task/types"
import { ACTION_STATUS } from "../vars"

export const calculatePercentageProgress = (data: TaskActionList[]) => {
  const taskTotal = data?.reduce((acc, curr) => {
    if (
      [ACTION_STATUS.CANCELED, ACTION_STATUS.COMPLETED].includes(curr.status)
    ) {
      acc = acc + 100
    }

    return acc
  }, 0)

  const taskPercentage = taskTotal > 0 ? taskTotal / data?.length : 0

  return parseFloat(taskPercentage.toString()).toFixed(2)
}
