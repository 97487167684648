import { Input, Label, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ListGroupTask } from "../../types"

type FormType = { action?: string }
export function FormSection({ action }: FormType) {
  const { control } = useFormContext<ListGroupTask>()
  const isDisable = action === "DETAIL" || action === "DELETE"

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="mr_group_task_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td>
                <Input {...field} 
                  placeholder="Input Group Task Code" 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message} 
                  disabled={isDisable} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="mr_group_task_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="GROUP TASK" error={Boolean(fieldState.error)} /></td>
              <td>
                <Input 
                  {...field} 
                  placeholder="Input Group Task Name" 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message} 
                  disabled={isDisable} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" /></td>
              <td>
                <Textarea 
                  {...field} 
                  placeholder="Input Description" 
                  value={field.value ?? ""}
                  disabled={isDisable} 
                  error={fieldState.error?.message}
                />
              </td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}