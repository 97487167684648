import { Card } from "components";
import { CardSection } from "../CardSection";
import { AreaChart, ProfitOrLose } from "../GrafikSection";
// import { PiArrowDownBold, PiArrowUpBold } from "react-icons/pi";
import { GraphValueType } from "../../types";
import { convertNumber } from "utils";
import moment from "moment"

export function ProfitAndLost(params: { 
        listIncome?: GraphValueType[], 
        list2?: GraphValueType,
        listExpense?: GraphValueType[],
        start_date?: string | undefined,
        end_date?: string | undefined
    }) { 

    const sumValueIncome = params.listIncome ? params.listIncome.reduce((a, b) => a + b.value, 0) : 0;
    const sumValueExpense = params.listExpense ? params.listExpense.reduce((a, b) => a + b.value, 0) : 0;

    return (
        <section>
            <span className="font-bold text-[15px]">Profitablity</span>
            <div className="grid lg:grid-cols-3 mt-2 gap-4">
                <div className="flex-grow">
                    <Card className="flex-grow h-full">
                        <Card.Body>
                            <CardSection
                                label="Profit Or Lose"
                                value={params.list2?.value ? convertNumber(params.list2.value)?.intoNormalAmount! : 0}
                            >
                                <div className="mt-[50px]">
                                    <ProfitOrLose list={params.list2}  />
                                </div>
                                <div className="mt-14">
                                    No profit was made because the expenses were higher than the income.
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div className="flex flex-col gap-2 lg:col-span-2">
                    <Card className="lg:h-[260px]">
                        <Card.Body>
                            <CardSection
                                label="Income"
                                value={`${convertNumber(sumValueIncome).intoNormalAmount!}`}
                                subLabel={params.start_date && params.end_date 
                                    ? `${moment(params.start_date).format("D MMM")} - ${moment(params.end_date).format("D MMM YYYY")}` 
                                    : ""}
                                // persentase={6278.5}
                                className="lg:flex justify-between"
                            >

                                <div className="lg:w-[500px] mt-[50px] lg:mt-0">
                                    <AreaChart
                                        className="lg:h-[235px]"
                                        label={params.listIncome?.map(item => moment(item.label).format("DD-MM-YYYY")) ?? []}
                                        value={params.listIncome?.map(item => item.value) ?? []}
                                        labelContent="Income"
                                        backgroundColor="yellow"
                                        borderColor="yellow"
                                        fill="start"
                                    />
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                    <Card className="lg:h-[260px]">
                        <Card.Body>
                            <CardSection
                                label="Expense"
                                value={`${convertNumber(sumValueExpense).intoNormalAmount!}`}
                                subLabel={params.start_date && params.end_date 
                                    ? `${moment(params.start_date).format("D MMM")} - ${moment(params.end_date).format("D MMM YYYY")}` 
                                    : ""}
                                // persentase={6278.5}
                                className="lg:flex justify-between"
                            >

                                <div className="lg:w-[500px] mt-[50px] lg:mt-0">
                                    <AreaChart
                                        className="lg:h-[235px]"
                                        label={params.listExpense?.map(item => moment(item.label).format("DD-MM-YYYY")) ?? []}
                                        value={params.listExpense?.map(item => item.value) ?? []}
                                        labelContent="Income"
                                        backgroundColor="yellow"
                                        borderColor="yellow"
                                        fill="start"
                                    />
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </section>
    )
}