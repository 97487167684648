/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useLoaderData, useNavigate } from "react-router-dom"

// Assets
import { Logo } from "../../assets"

// Components
import { Button } from "../../components"
import { Password } from "./components"

// Form
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

// Icons
import { TbUser } from "react-icons/tb"

// Third-Party Libraries
import { Controller, FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { jwtDecode } from "jwt-decode"

// Types
import type { FormType } from "./types"

// Utils
import { getBasenamePath, useApi, useHandleAuth } from "../../utils"

export default function Login() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const data = useLoaderData() as { responseStatus: number }
  const handleAuth = useHandleAuth()
  const tokenData = handleAuth?.userTokenData()
  const { companyName } = getBasenamePath()

  useEffect(() => {
    if (data?.responseStatus !== 200) {
      return window.location.replace('/guest/subscribe')
    }

    if (token && companyName !== tokenData?.subdomain) {
      return handleAuth.logout()
    }

    if (token && companyName === tokenData?.subdomain) {
      return navigate('/');
    }
  }, [data])

  return (
    <section className="min-h-screen bg-black flex flex-col justify-center items-center">
      <section className="flex md:flex-row flex-col md:divide-x-2">
        <section className="md:order-1 order-2 md:mr-5">
          <FormSection data={data} />
        </section>

        <img src={Logo} alt="Logo" className="max-h-[250px] md:order-2 order-1" />
      </section>
    </section>
  )
}

function FormSection(props: any) {
  // Hooks
  const api = useApi()
  const handleAuth = useHandleAuth()
  const { companyName } = getBasenamePath()

  // Form
  const defaultValues = {
    username: "",
    password: ""
  }
  const validationSchema = yup.object().shape({
    username: yup.string().label("Username").required(),
    password: yup.string().label("Password").required()
  })
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.postForm("/auth/login", value),
        {
          loading: "Loading...",
          success: (res) => {
            const token: string = res.data.access_token
            const decode: { subdomain: string } = jwtDecode(token)

            if (decode.subdomain !== companyName) {
              throw toast.error("Subdomain does not match")
            }

            return res?.data?.message
          },
          error: (err) => err?.response?.data?.message
        }
      ).then(handleAuth.login).catch(() => { }).finally(resolve)
    })
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3">
      <div className="text-white text-center text-3xl font-bold">{props?.data?.data?.company ?? "Welcome"}</div>

      <Controller
        control={methods.control}
        name="username"
        render={({ field, fieldState }) => (
          <section>
            <div className={`join w-full ${fieldState.error && "border-error border-2"}`}>
              <button disabled type="button" title="Input" className="btn btn-ghost join-item !bg-white p-2 border-0">
                <TbUser className="text-black" size={24} />
              </button>

              <input {...field} className="grow input input-bordered join-item bg-white text-black" placeholder="Username" />
            </div>

            {fieldState.error && (
              <div className="label">
                <span className="label-text text-error">{fieldState.error.message}</span>
              </div>
            )}
          </section>
        )}
      />

      <FormProvider {...methods}>
        <Password />
      </FormProvider>

      <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>LOGIN</Button>

      <div className="text-xs text-center">By continuing you agree to the Flying HAWK Indonesia<br /><span className="text-white">terms of service</span> and <span className="text-white">privacy policy.</span></div>
    </form>
  )
}