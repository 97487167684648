import React, { Fragment, useContext, useEffect, useState } from "react"
import { BaseTable, Button, Card, Checkbox, DeleteConfirm, FooterMenu, HeaderMenu, Input, PaginationData, TableHeaderSort } from "../../../../components"
import { ChildItem, CreateModal } from "./components"
import { TbChevronRight } from "react-icons/tb"
import { default_pagination_value, useApi, useToggle } from "../../../../utils"
import { Render, RenderProvider, useAllId, useList } from "./utils"
import { PaginationState } from "types"
import { debounce } from "lodash"
import { DataItem } from "./types"
import { PiBroomBold } from "react-icons/pi"
import toast from "react-hot-toast"
import { DeleteContext } from "pages/Setup/contexts"
import { AxiosResponse } from "axios"
import { arraysEqual, deleteAlert } from "pages/Setup/utils"

export const dataContext = React.createContext({
  currentPage: 1,
  limit: 10,
  size: 1,
  total: 0
})
type headerState = { title: string, sort: boolean, align?: 'left' | 'right' }

export default function ProductPricingLevel() {
  const [deleteList, setDeleteList] = useState<number[]>([])
  const [isDeleteAll, setDeleteAll] = useState<boolean>(false)
  const api = useApi()
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const { isActive, toggle } = useToggle(false)
  const onDebounce = debounce((e: any) => setPagination(prev => {
    return {
      ...prev,
      keyword: e.target.value
    }
  }))

  const header: headerState[] = [
    { title: "CODE", sort: true },
    { title: "PRODUCT PRICING LEVEL", sort: true },
    { title: "DESCRIPTION", sort: true }
  ]

  const sortMapping: Record<string, string> = {
    'CODE': 'code',
    'PRODUCT PRICING LEVEL': 'name',
    'DESCRIPTION': 'description'
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }

  const deleteAll = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete("/productpricinglevel/all"),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        toggle()
        refetch && refetch()
        
      }).catch(() => {}).finally(resolve)
    })
  }


  return (
    <RenderProvider>
      <section className="container pt-5  gap-5 flex flex-col">
      <HeaderMenu title="PRODUCT PRICING LEVEL" />

        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Delete
              deleteList={deleteList}
              isDeleteAll={isDeleteAll}
              onSuccess={refetch}
            />
            <Create refetch={refetch} />
          </Card.Body>

          <Card.Body>
          <div className="flex">
              <div className="mb-3">
                <Input
                  label="SEARCH"
                  className="md:max-w-[300px] w-full"
                  placeholder="Search..."
                  onChange={onDebounce}
                />
              </div>
            </div>
            <BaseTable>
                <thead>
                  <tr>
                    <th>NO</th>
                    <SelectAll
                      deleteList={deleteList}
                      isDeleteAll={isDeleteAll}
                      toggle={setDeleteList}
                      toggleDeleteAll={setDeleteAll}
                    />
                    <TableHeaderSort
                      title={header}
                      onSort={(item, sortType) => handleSort(item, sortType)}
                    />
                    <th className="w-[150px]">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <DeleteContext.Provider
                    value={{
                      value: deleteList,
                      toggleDeleteData(value) {
                        // Vars
                        const list = [...deleteList]
                        const findIndex = list.findIndex(item => item === value)

                        if (findIndex !== -1) {
                          list.splice(findIndex, 1)
                        } else {
                          list.push(value)
                        }

                        return setDeleteList(list)
                      }
                    }}
                  >
                    <dataContext.Provider
                      value={{
                        currentPage: pagination.page,
                        limit: pagination.limit,
                        size: data.pagination_data.size,
                        total: pagination.total
                      }}
                    >
                      <TableContent dataList={data} isLoading={isLoading} refetch={refetch} />
                    </dataContext.Provider>
                  </DeleteContext.Provider>
                </tbody>
              </BaseTable>
            <div className="mt-5">
              <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
            </div>
          </Card.Body>
        </Card>
        <FooterMenu/>

        {isActive && <DeleteConfirm onClick={() => deleteAll()} closeModal={toggle}/>}
      </section>
    </RenderProvider>
  )
}

function Create({ refetch }: { refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button color="primary" onClick={toggle}>
        CREATE NEW <TbChevronRight />
      </Button>

      {isActive && <CreateModal toggle={toggle} refetch={refetch} />}
    </Fragment>
  )
}

function TableContent(props: {
  dataList: DataItem
  isLoading: boolean
  refetch: () => void
}) {
  const { render } = useContext(Render)

  if (render) {
    return <ChildItem dataList={props.dataList} isLoading={props.isLoading} refetch={props.refetch} />
  } else {
    return <div />
  }
}

function Delete(params: {
  deleteList: number[]
  isDeleteAll: boolean
  onSuccess: () => void
}): JSX.Element {
  // Hooks
  const api = useApi()
  const { isActive, setActive } = useToggle(false)

  // Functions
  const getUrl = (): Promise<AxiosResponse<any, any>> => {
    if (params.isDeleteAll) {
      return api.delete("/productpricinglevel/all")
    }

    return api.delete("/productpricinglevel/delete-multiple", { data: params.deleteList })
  }

  if (params.deleteList.length) {
    return (
      <Button
        className="tooltip tooltip-error flex gap-3"
        color="ghost"
        loading={isActive ? "true" : undefined}
        onClick={() => {
          deleteAlert(params.isDeleteAll).then(() => {
            setActive(true)

            toast.promise(
              getUrl(),
              {
                loading: "Loading...",
                success: (res) => res.data.message,
                error: (err) => err.response.data?.message ?? err.response.message
              }
            ).then(() => {
              params.onSuccess()
            }).catch(() => {
            }).finally(() => {
              setActive(false)
            })
          }).catch(() => {})
        }}
      >
        {params.isDeleteAll ? "DELETE ALL DATA" : "DELETE SELECTED DATA"} <PiBroomBold className="w-[20px] h-[20px]" />
      </Button>
    )
  }

  return <Fragment />
} 

function SelectAll(params: {
  deleteList: number[]
  isDeleteAll: boolean
  toggle: (value: number[]) => void
  toggleDeleteAll: (value: boolean) => void
}): JSX.Element {
  // Hooks
  const { data } = useAllId()

  useEffect(() => {
    if (!params.deleteList.length) {
      return params.toggleDeleteAll(false)
    }

    return params.toggleDeleteAll(arraysEqual(params.deleteList, data))

    // eslint-disable-next-line
  }, [params.deleteList])

  return (
    <th>
      {data.length ? (
        <Checkbox
          value={params.isDeleteAll}
          onClick={() => {
            if (!arraysEqual(params.deleteList, data)) {
              return params.toggle(data)
            }

            return params.toggle([])
          }}
        />
      ) : (
        <Fragment />
      )}
    </th>
  )
}