import { Button, Label, Select } from "components"
import { useLocationList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { CreateModal as LocationModal } from "pages/Setup/Preference/Location/components"
import { Fragment } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useToggle } from "utils"
import { FormSalesOrderType } from "../types"

type DropdownLocationProps = {
  approvalView?: boolean
  isDisabled?: boolean
  showCreate?: boolean
}

export const DropdownLocation = ({
  approvalView,
  isDisabled,
  showCreate = true
}: DropdownLocationProps) => {
  const { control, clearErrors } = useFormContext<FormSalesOrderType>()
  const { dropdown, refetch } = useLocationList()

  return (
    <section>
      <Label text="LOCATION" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="location_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Location"
              isDisabled={
                isDisabled ||
                approvalView
              }
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                    (item) => item.value === field.value?.toString()
                  )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)
                  clearErrors("location_id")
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        <CreateLocation
          showCreate={showCreate}
          approvalView={approvalView}
          onSuccess={refetch}
        />
      </section>
    </section>
  )
}

type CreateLocationProps = {
  approvalView?: boolean
  showCreate?: boolean
  onSuccess: () => void
}

function CreateLocation({
  approvalView,
  showCreate,
  onSuccess
}: CreateLocationProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        color="primary"
        className={`px-2 ${approvalView || !showCreate ? "hidden" : ""} `}
        onClick={toggle}>
        <TbPlus size={24} />
      </Button>

      {isActive && <LocationModal toggle={toggle} onSuccess={onSuccess} />}
    </Fragment>
  )
}
