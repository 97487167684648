/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, Button, NumberInput } from "components"
import { useProjectList, useTaxCodeList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { Fragment, useEffect, useMemo } from "react"
import { Controller, FormProvider, useForm, useFormContext, UseFormReturn, useWatch } from "react-hook-form"
import { TbEye } from "react-icons/tb"
import { useToggle } from "utils"
import {
  FormSalesOrderDetailType,
  FormSalesOrderType,
  formSalesOrderDetailSchema
} from "../../types"
import { calculateTotalSalesOrderDetail } from "../../utils/functions"
import { useDropdownProduct } from "../../utils/hooks/useDropdownProduct"
import { DropdownProduct } from "../DropdownProduct"
import { DropdownTax } from "../DropdownTax"
import { DropdownJobs } from "../DropdwonJobs"

type Action = "add" | "edit"

type RowProps = {
  index?: number
  action: Action
  defaultValues?: any
  isHideForm?: boolean
  onCancelEditing?: () => void
  onDeleteJournal?: () => void
  onSubmit: (value: FormSalesOrderDetailType, methods?: UseFormReturn<FormSalesOrderDetailType>) => void
  tax: ReturnType<typeof useTaxCodeList>
  jobs: ReturnType<typeof useProjectList>
  products: ReturnType<typeof useDropdownProduct>
  priceLevelName?: string
}

export function RowCustomerOrder(props: RowProps) {
  const isHideForm = useToggle(props.isHideForm ?? true)

  const defaultValues: FormSalesOrderDetailType = useMemo(
    () => ({
      sales_order_detail_id: props?.defaultValues?.sales_order_detail_id,
      product_id: props?.defaultValues?.product_id ?? NaN,
      product_name: props?.defaultValues?.product_name ?? "",
      price_level_name: props?.priceLevelName ?? "",
      product_barcode: props?.defaultValues?.product_barcode ?? "",
      qty_order: props?.defaultValues?.qty_order ?? 0,
      received: props?.defaultValues?.received ?? 0,
      mr_unit_id: props?.defaultValues?.mr_unit_id ?? NaN,
      mr_unit_name: props?.defaultValues?.mr_unit_name ?? "",
      price_measure: props?.defaultValues?.price_measure ?? 0,
      discount: props?.defaultValues?.discount ?? 0,
      total: props?.defaultValues?.total ?? 0,
      project_id: props?.defaultValues?.project_id ?? NaN,
      project_name: props?.defaultValues?.project_name ?? "",
      tax_id: props?.defaultValues?.tax_id ?? 2,
      tax_name: props?.defaultValues?.tax_name ?? props.tax?.dropdown.find(({ value }) => value === '2')?.label,
      tax_rate: props?.defaultValues?.tax_rate ?? 0,
      location_id: 0,
      soh: props?.defaultValues?.soh ?? 0
    }),
    [props.defaultValues, props.priceLevelName]
  )
  const methodsParent = useFormContext<FormSalesOrderType>()

  const location_id = useWatch({ control: methodsParent.control, name: "location_id" })

  const methods = useForm<FormSalesOrderDetailType>({
    defaultValues,
    resolver: yupResolver(formSalesOrderDetailSchema)
  })

  const [qty_order, price_measure, discount, received, price_level_name] = methods.watch([
    "qty_order",
    "price_measure",
    "discount",
    "received",
    "price_level_name"
  ])

  const handleToggleHideForm = () => {
    if (!location_id) {
      return methodsParent.trigger("location_id")
    }
    isHideForm.toggle()
  }

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  useEffect(() => {
    methods.setValue(
      "total",
      calculateTotalSalesOrderDetail(methods.getValues()).finalPrice
    )
  }, [qty_order, price_measure, discount])

  useEffect(() => {
    if (props.action === "add") {
      const defaultTax = props.tax.dropdown.find((item) => item.rate === 0)

      methods.setValue("tax_id", parseInt(defaultTax?.value ?? "0"))
      methods.setValue("tax_name", defaultTax?.label ?? "")
      methods.setValue("tax_rate", defaultTax?.rate ?? 0)
    }
  }, [props.tax])

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td className="text-center">
          {isHideForm.isActive && (
            <Button color="transparent" onClick={handleToggleHideForm}>
              <TbEye className="size-5" />
            </Button>
          )}
        </td>
        {isHideForm.isActive ? (
          <td colSpan={13} />
        ) : (
          <Fragment>
            <td>
              <Controller
                control={methods.control}
                name="qty_order"
                render={({ field, fieldState }) => (
                  <NumberInput
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={0}
                    inputClass="w-[120px]"
                    textRight
                    value={field.value}
                    ref={field.ref}
                    onValueChange={(value) => field.onChange(value.floatValue)}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td className="text-right">
              <Controller
                control={methods.control}
                name="received"
                render={({ field }) => (
                  <div className="w-[120px] px-3">{field.value.toLocaleString("id-ID")}</div>
                )}
              />
            </td>
            <td className="text-right">
              <div className="w-[120px] px-3">{(qty_order - received).toLocaleString("id-ID")}</div>
            </td>
            <td className="w-1/4">
              <DropdownProduct priceLevelName={price_level_name} products={props.products} />
            </td>
            <td>
              {
                <Controller
                  control={methods.control}
                  name="product_name"
                  render={({ field }) => (
                    <div className="w-[250px] px-3">{field.value}</div>
                  )}
                />
              }
            </td>
            <td className="text-right">
              {
                <Controller
                  control={methods.control}
                  name="soh"
                  render={({ field }) => (
                    <div className="px-3">{field.value}</div>
                  )}
                />
              }
            </td>
            <td>
              {
                <Controller
                  control={methods.control}
                  name="mr_unit_name"
                  render={({ field }) => (
                    <div className="px-3">{field.value}</div>
                  )}
                />
              }
            </td>
            <td>
              <Controller
                control={methods.control}
                name="price_measure"
                render={({ field, fieldState }) => (
                  <NumberInput
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={0}
                    inputClass="w-[120px]"
                    textRight
                    value={field.value}
                    ref={field.ref}
                    onValueChange={(value) => field.onChange(value.floatValue)}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="discount"
                render={({ field, fieldState }) => (
                  <NumberInput
                    disabled={Boolean(methods.getValues("sales_order_detail_id"))}
                    textRight
                    inputClass="w-[120px]"
                    value={field.value}
                    ref={field.ref}
                    onValueChange={(value) => field.onChange(value.floatValue)}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td className="text-right">
              <Controller
                control={methods.control}
                name="total"
                render={({ field }) => (
                  <div className="w-[120px] px-3">{field.value.toLocaleString()}</div>
                )}
              />
            </td>
            <td>
              <DropdownJobs jobs={props.jobs} />
            </td>
            <td>
              <DropdownTax tax={props.tax} />
            </td>
            <td>
              <div className="text-center flex justify-center items-center">
                {props.action === "edit" && (
                  <>
                    <ActionButton.Check
                      onClick={() => {
                        methods.handleSubmit((data) => props.onSubmit(data))()
                      }}
                    />
                    <ActionButton.Close onClick={props?.onCancelEditing} />
                  </>
                )}

                {props.action === "add" && (
                  <>
                    <ActionButton.Add
                      onClick={() => {
                        methods.handleSubmit((data) => props.onSubmit(data, methods))()
                      }}
                    />
                    <ActionButton.Close onClick={isHideForm.toggle} />
                  </>
                )}
              </div>
            </td>
          </Fragment>
        )}
      </tr>
    </FormProvider>
  )
}
