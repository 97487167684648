// Components
import { Button } from "components/actions"
import { Card, ErrorText } from "components/displays"
import { DateFilter, LocationSection } from "./components"

// Form
import { ErrorMessage } from "@hookform/error-message"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"

// Utils
import { type FormType, getDateFilter, validationSchema, getCompanyName } from "./utils"

export function DashboardFilter(params: {
  onChangeFilter: (value: FormType) => void
  thirtyDatesMaximum?: boolean
}): JSX.Element {
  // Vars
  const list = getDateFilter()

  // Form
  const onSubmit = (value: FormType) => params.onChangeFilter(value)
  
  const methods = useForm<FormType>({
    defaultValues: {
      date_end: moment().format("YYYY-MM-DD"),
      date_from: moment().startOf("month").format("YYYY-MM-DD"),
      type: list[0].label,
      location_id: ""
    },
    resolver: yupResolver(validationSchema)
  })
  const { control, formState, handleSubmit, setValue } = methods
  const date_end = useWatch({
    control,
    name: "date_end"
  })
  const date_from = useWatch({
    control,
    name: "date_from"
  })
  const type = useWatch({
    control,
    name: "type"
  })
  
  return (
    <Card>
      <Card.Body>
        <section  className="flex flex-wrap items-center justify-between gap-2">
          <section>
            <span className="font-bold text-[20px]">{getCompanyName()}</span>

            <div className="mt-3">
                <span>{moment(date_from).format("DD MMMM YYYY")} <b>-</b> {moment(date_end).format("DD MMMM YYYY")}</span>
            </div>
          </section>

          <section className="w-fit flex gap-5 items-center">
            <section className="flex gap-5 items-end">
              <section>
                <DateFilter
                  thirtyDatesMaximum={params.thirtyDatesMaximum}
                  disabled={type !== "Date"}
                  value={{
                    end_date: date_end,
                    start_date: date_from,
                    type: type
                  }}
                  onChangeEndDate={res => setValue("date_end", res)}
                  onChangeStartDate={res => setValue("date_from", res)}
                  onChangeType={res => setValue("type", res)}
                />

                <ErrorMessage
                  name="type"
                  errors={formState.errors}
                  render={({ message }) => <ErrorText text={message} />}
                />

                <ErrorMessage
                  name="date_from"
                  errors={formState.errors}
                  render={({ message }) => <ErrorText text={message} />}
                />

                <ErrorMessage
                  name="date_end"
                  errors={formState.errors}
                  render={({ message }) => <ErrorText text={message} />}
                />
              </section>

              <FormProvider {...methods}>
                <LocationSection />
              </FormProvider>

              <section>
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </section>
            </section>
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}