// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton, NumberInput } from "components"

// Contexts
import { DataJournalContext } from "contexts"
import { ProductContext } from "pages/Purchase/Transaction/PurchaseOrder/components/DetailBody/contexts"

// Form
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Types
import type { DetailFormType } from "pages/Purchase/Transaction/EnterBill/utils"

// Utils
import { convertNumber } from "utils"
import { FormType, validationSchema } from "./utils"

export function FormSection(params: {
  onSuccess: (value: DetailFormType) => void
  value: DetailFormType
  toggle: () => void
}): JSX.Element {
  // Hooks
  const { data } = useContext(ProductContext)
  const { tax_code } = useContext(DataJournalContext)

  // Form
  const methods = useForm<FormType>({
    defaultValues: params.value,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: DetailFormType) => {
    params.onSuccess(value)
  }

  // Vars
  const selectedData = data.find(item => item.value === methods.getValues("product_id"))
  const tax_name: string | undefined = tax_code.data.find(i => i.value === methods.getValues("tax_id"))?.label

  return (
    <FormProvider {...methods}>
      <tr>
        <td>{methods.getValues("qty_order")}</td>

        <td>
          <Controller
            name="quantity_bill"
            control={methods.control}
            render={({ field, fieldState }) => (
              <NumberInput
                decimalScale={0}
                error={fieldState.error?.message}
                ref={field.ref}
                value={field.value}
                onValueChange={e => {
                  field.onChange(e.floatValue)

                  // Vars
                  const value: number = typeof e.floatValue !== "number" ? 1 : e.floatValue
                  const discount: number = methods.getValues("discount")
                  const price_bill: number = methods.getValues("price_bill")
                  const qty_multiply: number = methods.getValues("qty_multiply")
                  const total: number = price_bill * value

                  methods.setValue("quantity_unit", qty_multiply * value)
                  methods.setValue("total", total - (total * discount / 100))
                }}
              />
            )}
          />
        </td>

        <Product />
        <td>{selectedData?.mr_unit_name}</td>
        <td className="text-right">{convertNumber(methods.getValues("price_bill")).intoCurrency}</td>
        <td>{convertNumber(methods.getValues("discount")).intoNormalAmount}%</td>
        <td>
          <Controller
            name="total"
            control={methods.control}
            render={({ field }) => <div className="text-right">{convertNumber(field.value).intoCurrency}</div>}
          />
        </td>
        <td>{methods.getValues("project_name")}</td>
        <td>{tax_name}</td>

        <td>
          <section className="flex justify-center">
            <ActionButton.Check onClick={methods.handleSubmit(onSubmit)} />

            <ActionButton.Close
              className="text-error"
              onClick={params.toggle}
            />
          </section>
        </td>
      </tr>
    </FormProvider>
  )
}

function Product() {
  // Form
  const { getValues } = useFormContext<FormType>()

  // Hooks
  const { data } = useContext(ProductContext)

  // Vars
  const selectedData = data.find(item => item.value === getValues("product_id"))

  return (
    <Fragment>
      <td>{selectedData?.product_barcode}</td>
      <td>{selectedData?.product_name}</td>
    </Fragment>
  )
}