import { Input, Label, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ListCategoryVendor } from "../../types"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<ListCategoryVendor>()

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="category_vendor_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Code" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="category_vendor_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CATEGORY VENDOR" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Category Vendor" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" error={Boolean(fieldState.error)} /></td>
              <td><Textarea {...field} placeholder="Input Description" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}