import { Select, Button, Textarea } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { AddressList } from "../../types"
import { useCountry, useProvince, useRegency, useSubdistrict, useVillage } from "../../../../../Setup/Preference/Location/components/FormSection/utils"
import { useEffect } from "react"
import { useTypeAddress } from "./utils"

interface AddressProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: string
}

export const FormAddress:React.FC<AddressProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<AddressList>()

  return (
    <div>
      <section>
        <div className="flex">
          <div className="w-[50%]">
            <div className="text-[15px] mb-3"><b>FIRST ADDRESS:</b></div>
            <TypeAddress isDisabled={action === "DETAIL"} />
            <Country isDisabled={Boolean(action === "DETAIL")} />
            <Province isDisabled={Boolean(action === "DETAIL")} />
            <Regency isDisabled={Boolean(action === "DETAIL")} />
            <Subdistrict isDisabled={Boolean(action === "DETAIL")} />
            <Village isDisabled={Boolean(action === "DETAIL")} />

            <Controller
              control={control}
              name="address"
              render={({ field, fieldState }) => <Textarea {...field} label="ADDRESS" placeholder="Input address" value={field.value ?? ""} error={fieldState.error?.message} disabled={action === "DETAIL"} />}
            />
          </div>
          <div className="w-[10px]"></div>
          <div className="w-[50%]">
            <div className="text-[15px] mb-3"><b>SECCOND ADDRESS:</b></div>
            <TypeAddress2 isDisabled={action === "DETAIL"} />
            <Country2 isDisabled={Boolean(action === "DETAIL")} />
            <Province2 isDisabled={Boolean(action === "DETAIL")} />
            <Regency2 isDisabled={Boolean(action === "DETAIL")} />
            <Subdistrict2 isDisabled={Boolean(action === "DETAIL")} />
            <Village2 isDisabled={Boolean(action === "DETAIL")} />

            <Controller
              control={control}
              name="address_2"
              render={({ field, fieldState }) => <Textarea {...field} label="ADDRESS" placeholder="Input address" value={field.value ?? ""} error={fieldState.error?.message} disabled={action === "DETAIL"} />}
            />
          </div>
        </div>
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
            permission={permission}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}

function TypeAddress(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const { data, isLoading } = useTypeAddress()
  console.log(control)

  return (
    <Controller
      control={control}
      name="address_type_id"
      render={({ field, fieldState }) => (
        <Select
          label="ADDRESS TYPE"
          placeholder="Select address type..."
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function TypeAddress2(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const { data, isLoading } = useTypeAddress()

  return (
    <Controller
      control={control}
      name="address_type_id_2"
      render={({ field, fieldState }) => (
        <Select
          label="ADDRESS TYPE"
          placeholder="Select address type..."
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function Country(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const { data, isLoading } = useCountry()

  return (
    <Controller
      control={control}
      name="country_id"
      render={({ field, fieldState }) => (
        <Select
          label="COUNTRY"
          placeholder="Choose country"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Country2(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const { data, isLoading } = useCountry()

  return (
    <Controller
      control={control}
      name="country_id_2"
      render={({ field, fieldState }) => (
        <Select
          label="COUNTRY"
          placeholder="Choose country"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function Province(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const { data, isLoading, refetch } = useProvince()

  useEffect(() => {
    refetch(country_id)
    // eslint-disable-next-line
  }, [country_id])

  return (
    <Controller
      control={control}
      name="province_id"
      render={({ field, fieldState }) => (
        <Select
          label="PROVINCE"
          placeholder="Choose province"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Province2(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const country_id_2 = useWatch({
    control,
    name: "country_id_2"
  })
  const { data, isLoading, refetch } = useProvince()

  useEffect(() => {
    refetch(country_id_2)
    // eslint-disable-next-line
  }, [country_id_2])

  return (
    <Controller
      control={control}
      name="province_id_2"
      render={({ field, fieldState }) => (
        <Select
          label="PROVINCE"
          placeholder="Choose province"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function Regency(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  const { data, isLoading, refetch } = useRegency()

  useEffect(() => {
    refetch(province_id)
    // eslint-disable-next-line
  }, [province_id])

  return (
    <Controller
      control={control}
      name="regency_id"
      render={({ field, fieldState }) => (
        <Select
          label="REGENCY"
          placeholder="Choose regency"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Regency2(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const province_id_2 = useWatch({
    control,
    name: "province_id_2"
  })
  const { data, isLoading, refetch } = useRegency()

  useEffect(() => {
    refetch(province_id_2)
    // eslint-disable-next-line
  }, [province_id_2])

  return (
    <Controller
      control={control}
      name="regency_id_2"
      render={({ field, fieldState }) => (
        <Select
          label="REGENCY"
          placeholder="Choose regency"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function Subdistrict(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const regency_id = useWatch({
    control,
    name: "regency_id"
  })
  const { data, isLoading, refetch } = useSubdistrict()

  useEffect(() => {
    refetch(regency_id)
    // eslint-disable-next-line
  }, [regency_id])

  return (
    <Controller
      control={control}
      name="subdistrict_id"
      render={({ field, fieldState }) => (
        <Select
          label="SUBDISTRICT"
          placeholder="Choose subdistric"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Subdistrict2(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const regency_id_2 = useWatch({
    control,
    name: "regency_id_2"
  })
  const { data, isLoading, refetch } = useSubdistrict()

  useEffect(() => {
    refetch(regency_id_2)
    // eslint-disable-next-line
  }, [regency_id_2])

  return (
    <Controller
      control={control}
      name="subdistrict_id_2"
      render={({ field, fieldState }) => (
        <Select
          label="SUBDISTRICT"
          placeholder="Choose subdistric"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function Village(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const subdistrict_id = useWatch({
    control,
    name: "subdistrict_id"
  })
  const { data, isLoading, refetch } = useVillage()

  useEffect(() => {
    refetch(subdistrict_id)
    // eslint-disable-next-line
  }, [subdistrict_id])

  return (
    <Controller
      control={control}
      name="village_id"
      render={({ field, fieldState }) => (
        <Select
          label="VILLAGE"
          placeholder="Choose village"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Village2(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const subdistrict_id_2 = useWatch({
    control,
    name: "subdistrict_id_2"
  })
  const { data, isLoading, refetch } = useVillage()

  useEffect(() => {
    refetch(subdistrict_id_2)
    // eslint-disable-next-line
  }, [subdistrict_id_2])

  return (
    <Controller
      control={control}
      name="village_id_2"
      render={({ field, fieldState }) => (
        <Select
          label="VILLAGE"
          placeholder="Choose village"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}