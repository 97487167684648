// React
import { Fragment, useContext } from "react"

// Components
import { Loading } from "../../../../components"
import { Item } from "./Item"

// Utils
import { DataContext } from "../utils"
import { permissionProps } from "types"

export function ChildItem(props: {
  parent_id?: number
  group_id?: string
  permission: permissionProps
}) {
  // Hooks
  const { data, refetch } = useContext(DataContext)

  // Vars
  const items = data.sort((a, b) => a.coa_code.localeCompare(b.coa_code)).filter(item => item.parent_id === props.parent_id)

  return (
    <Fragment>
      {items.length === 0 ? (
        <tr>
          <td colSpan={11} className="text-center">
            <Loading errorText="No data available" />
          </td>
        </tr>
      ) : items.map((item, key) => (
        <Item permission={props?.permission} key={key} index={key} item={item} parent_id={props.parent_id} onSuccess={refetch} />
      ))}
    </Fragment>
  )
}