// Components
import { Select } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { SelectOptionType } from "types"
import type { FormType } from "../utils"

// Utils
import { useLocation } from "utils"

export function LocationSection(): JSX.Element {
  // Hooks
  const { data, isLoading } = useLocation()

  // Vars
  const options: SelectOptionType[] = [
    ...data.map(item => {
      return {
        label: item.label,
        value: item.value
      }
    }),
    {
      label: "All",
      value: ""
    }
  ]

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="location_id"
      render={({ field, fieldState }) => (
        <div className="min-w-[200px]">
          <Select
            {...field}
            label="LOCATION"
            placeholder="Select Location"
            error={fieldState.error?.message}
            isLoading={isLoading}
            options={options}
            value={options.find(item => item.value === field.value)}
            onChange={res => {
              if (res) {
                return field.onChange(res.value)
              }
            }}
          />
        </div>
      )}
    />
  )
}