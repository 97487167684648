import { useApi } from "utils"
import { FilterType } from "../../types"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { DataTypePaging } from "types"
import { DetailCustomerQuotation } from "pages/Sales/Transaction/CustomerQuotation/types"

export function useList({
  page,
  limit,
  search,
  start_date,
  end_date,
  approve_status,
  customer_id
}: FilterType) {
  const defaultValue = {
    payload: [],
    pagination_data: {
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }

  const api = useApi()
  const [data, setData] =
    useState<DataTypePaging<DetailCustomerQuotation>>(defaultValue)
  const [isLoading, setLoading] = useState(true)

  const params: { [key: string]: number | string | undefined } = {}
  if (page) params.page = page
  if (limit) params.limit = limit
  if (search) params.search = search
  if (start_date) params.start_date = start_date
  if (end_date) params.end_date = end_date
  if (approve_status) params.approve_status = approve_status
  if (customer_id) params.customer_id = customer_id

  const fetchUrl = () => {
    return api.get("/customer-quotation?", { params })
  }

  const fetchData = () => {
    setLoading(true)

    fetchUrl()
      .then((res: SuccessFetch<DataTypePaging<DetailCustomerQuotation>>) => {
        setData(res.data)
      })
      .catch(() => {
        setData(defaultValue)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [page, limit, search, start_date, end_date, approve_status, customer_id])

  return { data, isLoading, refetch }
}
