// React
import { useContext } from "react"

// Components
import { ActionButton, ErrorText } from "components"
import { FormSection, Product } from "./components"

// Contexts
import { DataJournalContext } from "contexts"
import { DisabledContext } from "pages/Purchase/Transaction/contexts"
import { PaymentCountContext } from "../../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ErrorFetch } from "types"
import { deleteAlert, type FormType } from "../../utils"

// Utils
import { convertNumber, useApi, useToggle } from "utils"

export function Detail(params: {
  index: number
  remove: (key: number) => void
}): JSX.Element {
  // Hooks
  const api = useApi()
  const count = useContext(PaymentCountContext)
  const disabled = useContext(DisabledContext)
  const loadingRemove = useToggle(false)
  const { isActive, toggle } = useToggle(false)
  const { tax_code } = useContext(DataJournalContext)

  // Functions
  const deleteItem = (bill_detail_id: number) => {
    loadingRemove.setActive(true)

    api.delete(
      "/probill/deleteitem",
      { params: { bill_detail_id } }
    ).then(() => {
      params.remove(params.index)
    }).catch((err: ErrorFetch<{ message: string } | null>) => {
      toast.error(err.response.data?.message ?? err.response.message)
    }).finally(() => {
      loadingRemove.setActive(false)
    })
  }

  // Form
  const { control, formState, clearErrors, getValues, setValue } = useFormContext<FormType>()

  if (isActive) {
    return (
      <FormSection
        toggle={toggle}
        value={getValues(`probildetail.${params.index}`)}
        onSuccess={value => {
          setValue(`probildetail.${params.index}`, value)
          clearErrors(`probildetail.${params.index}`)
          toggle()
        }}
      />
    )
  }

  return (
    <Controller
      name={`probildetail.${params.index}`}
      control={control}
      render={({ field }) => {
        // Vars
        const tax_name: string | undefined = tax_code.data.find(i => i.value === field.value.tax_id)?.label

        return (
          <tr>
            <td>{field.value.qty_order}</td>

            <td>
              <div>{field.value.quantity_bill}</div>

              <ErrorMessage
                name={`probildetail.${params.index}.quantity_bill`}
                errors={formState.errors}
                render={({ message }) => <ErrorText text={message} />}
              />
            </td>

            <Product id={field.value.product_id} />
            <td className="text-right">{convertNumber(field.value.price_bill).intoCurrency}</td>
            <td>{convertNumber(field.value.discount).intoNormalAmount}%</td>
            <td className="text-right">{convertNumber(field.value.total).intoCurrency}</td>
            <td>{field.value.project_name}</td>
            <td>{tax_name}</td>

            <td>
              {!disabled && (
                <section className="flex justify-center">
                  <ActionButton.Update onClick={toggle} />

                  {!count.isLoading && !count.data && (
                    <ActionButton.Delete
                      className="text-error"
                      onClick={() => {
                        // Vars
                        const bill_detail_id = field.value.bill_detail_id

                        if (bill_detail_id) {
                          deleteAlert().then(() => deleteItem(bill_detail_id))
                        }

                        params.remove(params.index)
                      }}
                    />
                  )}
                </section>
              )}
            </td>
          </tr>
        )
      }}
    />
  )
}