// React
import { Fragment, createContext, useEffect, useRef, useState } from "react"

// Components
import { Card, Checkbox, CommandButton, DateInput, FooterMenu, HeaderMenu, Input, Label, Modal, NumberInput, Select, Table, Textarea } from "components"
import { FileList } from "../FileList"
import { Accounts, AccTable, AccountTable, Location, Trader, Delete } from "./components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Icons
import { TbFileUpload } from "react-icons/tb"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"

// Types
import { SuccessFetch, permissionProps } from "types"
import type { DataJournalType } from "../../../utils"

// Utils
import { convertNumber, useApi, useToggle } from "utils"
import { type FormType, getDiscount, group_list, useBalance, useCode } from "pages/Finance/Transaction/utils"
import { formParams } from "pages/Finance/Transaction/SpendMoney/utils"
import { Print, useTriggerPrint } from "./components/Print"

type FormParamsType = typeof formParams

interface Type extends FormParamsType {
  isEdit?: boolean
  onSubmit: () => void
  permission: permissionProps
}

export const FormParamContext = createContext({ defaultGroup: "" })

export function FormSection(params: Type) {
  // Form
  const methods = useFormContext<FormType>()
  const printRef = useRef<HTMLDivElement>(null)
  const triggerPrint = useTriggerPrint({ ref: printRef })

  return (
    <section className="container my-5 flex flex-col gap-2">
      <HeaderMenu title={`DATA ENTRY | ${params.title}`}>
        <Code isEdit={params.isEdit} url={params.codeUrl} />
      </HeaderMenu>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6">
          <section className="flex flex-col gap-3">
            <Accounts />

            <section>
              <Label text="TRADER NAME" />

              <section className="flex gap-3">
                <Controller
                  control={methods.control}
                  name="group_card"
                  render={({ field }) => (
                    <Select
                      className="grow"
                      placeholder="Select Group Card"
                      options={group_list}
                      ref={field.ref}
                      value={group_list.find(item => item.value === field.value) ?? null}
                      onChange={item => {
                        field.onChange(item?.value)
                        methods.setValue("card_id", "")
                      }}
                    />
                  )}
                />

                <Trader />
              </section>
            </section>

            <Location />

            <Controller
              control={methods.control}
              name="memo"
              render={({ field, fieldState }) => (
                <Textarea
                  {...field}
                  label="MEMO"
                  placeholder="Enter Memo"
                  error={fieldState.error?.message}
                />
              )}
            />
          </section>

          <section className="flex flex-col gap-3">
            <Balance url={params.balanceUrl} />

            <Controller
              control={methods.control}
              name="transaction_date"
              render={({ field, fieldState }) => (
                <DateInput
                  label="TRANSACTION DATE"
                  error={fieldState.error?.message}
                  ref={field.ref}
                  selected={moment(field.value).toDate()}
                  onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="reference_no"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label="REFERENCE NO"
                  placeholder="Reference No"
                  error={fieldState.error?.message}
                />
              )}
            />

            <Credit title={params.creditTitle} />

            <section className="flex sm:flex-row flex-col justify-between items-end gap-5">
              <Controller
                control={methods.control}
                name="tax_inclusive"
                render={({ field, fieldState }) => (
                  <Checkbox
                    label="TAX"
                    error={fieldState.error?.message}
                    ref={field.ref}
                    value={field.value}
                    onChange={() => field.onChange(!field.value)}
                  >
                    TAX-INCLUSIVE
                  </Checkbox>
                )}
              />

              <Controller
                control={methods.control}
                name="reconsiled"
                render={({ field, fieldState }) => (
                  <Checkbox
                    disabled
                    error={fieldState.error?.message}
                    ref={field.ref}
                    value={field.value}
                  >
                    RECONSILED
                  </Checkbox>
                )}
              />
            </section>
          </section>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-5">
          <FormParamContext.Provider value={{ defaultGroup: params.defaultGroup }}>
            <AccountTable />
          </FormParamContext.Provider>

          <section className="flex justify-end">
            <AccTable />
          </section>
        </Card.Body>
      </Card>

      <FooterMenu
        save={{
          permission: "FT011",
          actiontype: "save",
          type: "button",
          color: "primary",
          loading: methods.formState.isSubmitting ? "true" : undefined,
          onClick: params.onSubmit
        }}

        print={{
          onClick: triggerPrint,
          actiontype: "print"
        }}

        customElement={{
          attachment: <AttachmentModal removeFileUrl={params.removeFileUrl} uploadFileUrl={params.uploadFileUrl} />,
          journal: <JournalModal isSpendMoneyPosition={params.isSpendMoneyPosition} />,
          delete: <Delete />,
        }}
      />
      <div className="hidden">
        <Print ref={printRef} />
      </div>
    </section>
  )
}

function AttachmentModal(params: {
  removeFileUrl: string
  uploadFileUrl: string
}) {
  // Hooks
  const api = useApi()
  const { isActive, toggle } = useToggle(false)

  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  return (
    <Fragment>
      <CommandButton actiontype="attachment" onClick={toggle} />

      {isActive && (
        <Modal isOpen title="ATTACHMENT" closeModal={toggle}>
          <Modal.Body>
            <UploadFile uploadFileUrl={params.uploadFileUrl} onSuccess={result => setValue("imgPath", [...getValues("imgPath"), result])} />

            <Controller
              control={control}
              name="imgPath"
              render={({ field, fieldState }) => (
                <section ref={field.ref}>
                  <FileList
                    label={trx_code}
                    error={fieldState.error?.message}
                    items={field.value}
                    onDelete={(item, key) => {
                      // Vars
                      const data = [...field.value]
                      data.splice(key, 1)

                      field.onChange(data)
                      api.post(`${params.removeFileUrl}?strPath=${item}`)
                    }}
                  />
                </section>
              )}
            />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}

function Balance(params: {
  url: string
}) {
  // Hooks
  const { refetch } = useBalance(params.url)

  // Form
  const { control, setValue } = useFormContext<FormType>()
  const coa_id = useWatch({
    control,
    name: "coa_id"
  })
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })

  useEffect(() => {
    if (coa_id && transaction_date) {
      refetch(coa_id, transaction_date).then((value) => setValue("balance", value))
    }

    // eslint-disable-next-line
  }, [coa_id, transaction_date])

  return (
    <Controller
      control={control}
      name="balance"
      render={({ field, fieldState }) => (
        <NumberInput
          label="BALANCE"
          disabled
          error={fieldState.error?.message}
          ref={field.ref}
          value={field.value}
          thousandSeparator=","
          decimalSeparator="."
        />
      )}
    />
  )
}

function Code(params: {
  isEdit?: boolean
  url: string
}) {
  // Hooks
  const { refetch } = useCode(params.url)
  const { isActive, setActive } = useToggle(Boolean(params.isEdit))

  // Form
  const { control, setValue } = useFormContext<FormType>()
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  useEffect(() => {
    if (isActive) {
      setActive(false)
    } else if (transaction_date || trx_code === "") {
      refetch(transaction_date).then((value) => setValue("trx_code", value))
    }

    // eslint-disable-next-line
  }, [transaction_date, trx_code])

  return (
    <Controller
      control={control}
      name="trx_code"
      render={({ field }) => <Fragment>{field.value}</Fragment>}
    />
  )
}

function Credit(params: { title: string }) {
  // Form
  const { control } = useFormContext<FormType>()
  const balance = useWatch({
    control,
    name: "balance"
  })

  return (
    <Controller
      control={control}
      name="amount"
      render={({ field, fieldState }) => (
        <NumberInput
          label={params.title}
          error={fieldState.error?.message}
          ref={field.ref}
          value={field.value}
          thousandSeparator=","
          decimalSeparator="."
          onValueChange={value => field.onChange(value.floatValue)}
          isAllowed={({ floatValue }) => {
            if (floatValue) {
              if (params.title === "AMOUNT RECEIVE") {
                return floatValue >= 0
              }

              return floatValue >= 0 && floatValue <= balance
            }

            return false
          }}
        />
      )}
    />
  )
}

function JournalModal(params: {
  isSpendMoneyPosition: boolean
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton actiontype="journal" onClick={toggle} />

      {isActive && (
        <Modal isOpen title="JOURNAL" closeModal={toggle} size="full">
          <Modal.Body>
            <JournalTable isSpendMoneyPosition={params.isSpendMoneyPosition} />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}

function JournalTable(params: { isSpendMoneyPosition: boolean }) {
  // Types
  interface Type extends Omit<
    DataJournalType,
    "tax_id" | "memo" | "referensi" | "project_id" | "coa_id" | "rate" | "project_name" | "tax_coa_collect" | "tax_coa_collect_id" | "tax_coa_paid" | "tax_coa_paid_id" | "tax_name"
  > {
    position: 1 | 2
  }

  // Hooks
  const [data, setData] = useState<Type[]>([])

  const sumAmount = (data: Type[], position: 1 | 2) => {
    return data.reduce((acc, item) => {
      if (item.position === position) {
        return acc + item.amount
      }

      return acc
    }, 0)
  }

  // Vars
  const total_amount = {
    debet: sumAmount(data, 1),
    kredit: sumAmount(data, 2)
  }

  // Form
  const { control } = useFormContext<FormType>()
  const coa_name = useWatch({
    control,
    name: "coa_name"
  })
  const amount = useWatch({
    control,
    name: "amount"
  })
  const datajournal = useWatch({
    control,
    name: "datajournal"
  })
  const tax_inclusive = useWatch({
    control,
    name: "tax_inclusive"
  })
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  useEffect(() => {
    // Vars
    const data: Type[] = []

    datajournal.map(item => {
      if (item.rate > 0) {
        data.push({
          amount: getDiscount(item.amount, item.rate),
          coa_name: tax_inclusive ? item.tax_coa_paid : item.tax_coa_collect,
          position: params.isSpendMoneyPosition ? 1 : 2
        })
      }

      data.push({
        amount: item.amount - getDiscount(item.amount, item.rate),
        coa_name: item.coa_name,
        position: params.isSpendMoneyPosition ? 1 : 2
      })

      return null
    })


    if (coa_name) {
      data.unshift({
        amount: amount,
        coa_name: coa_name,
        position: params.isSpendMoneyPosition ? 2 : 1
      })
    }

    setData(data)

    return () => {
      setData([])
    }

    // eslint-disable-next-line
  }, [amount, datajournal, coa_name, tax_inclusive])

  return (
    <Table
      data={data}
      columns={[
        {
          title: "DATE",
          thProps: { className: "w-[10px]" },
          field: () => ""
        },
        {
          title: "IDM",
          field: () => ""
        },
        {
          title: "ACCOUNT",
          field: () => ""
        },
        {
          title: "DEBET",
          field: () => ""
        },
        {
          title: "CREDIT",
          field: () => ""
        }
      ]}
    >
      <tbody>
        {data.map((item, key) => (
          <tr key={key}>
            <td>{moment(transaction_date).format("DD/MM/YYYY")}</td>
            <td>{trx_code}</td>
            <td>{item.coa_name}</td>
            <td className="text-right">{item.position === 1 ? (convertNumber(item.amount).intoCurrency ?? "0") : ""}</td>
            <td className="text-right">{item.position === 2 ? (convertNumber(item.amount).intoCurrency ?? "0") : ""}</td>
          </tr>
        ))}
      </tbody>

      <tfoot>
        <tr className="text-xl font-bold">
          <th colSpan={3}></th>
          <th className="text-right">{convertNumber(total_amount.debet).intoCurrency}</th>
          <th className="text-right">{convertNumber(total_amount.kredit).intoCurrency}</th>
        </tr>
      </tfoot>
    </Table>
  )
}

function UploadFile(props: {
  onSuccess: (result: string) => void
  uploadFileUrl: string
}) {
  // Hooks
  const api = useApi()
  const fileRef = useRef<HTMLInputElement>(null)
  const { isActive, toggle } = useToggle(false)

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      toggle()

      toast.promise(
        api.postForm(props.uploadFileUrl, { filebukti: e.target.files[0] }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then((res: SuccessFetch<{ payload: { namafile: string } }>) => {
        props.onSuccess(res.data.payload.namafile)

        if (fileRef.current) {
          fileRef.current.value = ""
        }
      }).catch(console.error).finally(toggle)
    }
  }

  return (
    <Fragment>
      <input hidden id="file-upload" type="file" ref={fileRef} onChange={onUpload} />

      <label htmlFor="file-upload" className="w-fit btn btn-primary rounded-none di">
        {isActive && <span className="loading loading-spinner" />}
        <TbFileUpload size={24} /> UPLOAD FILE
      </label>
    </Fragment>
  )
}