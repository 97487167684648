// Types
import type { RouteType } from "types"

// Pages
import Home from "pages/Analytics/Home"

export const analyticsRoutes: RouteType[] = [
  {
    path: "",
    element: <Home />,
    permission: "AR" // BELUM
  },
  {
    path: "8-keys",
    element: <div>8 KEYS</div>,
    permission: "AR" // BELUM
  },
  {
    path: "finance",
    element: <div>FINANCE</div>,
    permission: "AR" // BELUM
  },
  {
    path: "sales",
    element: <div>SALES</div>,
    permission: "AR" // BELUM
  },
  {
    path: "purchases",
    element: <div>PURCHASES</div>,
    permission: "AR" // BELUM
  },
  {
    path: "inventory",
    element: <div>INVENTORY</div>,
    permission: "AR" // BELUM
  },
  {
    path: "customers",
    element: <div>CUSTOMERS</div>,
    permission: "AR" // BELUM
  },
  {
    path: "vendors",
    element: <div>VENDORS</div>,
    permission: "AR" // BELUM
  },
  {
    path: "employee",
    element: <div>EMPLOYEE</div>,
    permission: "AR" // BELUM
  },
  {
    path: "marketing",
    element: <div>MARKETING</div>,
    permission: "AR" // BELUM
  },
  {
    path: "production",
    element: <div>PRODUCTION</div>,
    permission: "AR" // BELUM
  },
  {
    path: "retail",
    element: <div>RETAIL</div>,
    permission: "AR" // BELUM
  },
  {
    path: "warehouse",
    element: <div>WAREHOUSE</div>,
    permission: "AR" // BELUM
  },
  {
    path: "locations",
    element: <div>LOCATIONS</div>,
    permission: "AR" // BELUM
  },
  {
    path: "project",
    element: <div>PROJECT</div>,
    permission: "AR" // BELUM
  },
  {
    path: "tasks",
    element: <div>TASKS</div>,
    permission: "AR" // BELUM
  },
  {
    path: "distributions",
    element: <div>DISTRIBUTIONS</div>,
    permission: "AR" // BELUM
  }
]