import { Fragment, useState } from "react"
import { Card, FooterMenu, HeaderMenu, Loading } from "../../../../components"
import { useList } from "./utils"
import { FotherSection, HeaderSection, SearchingSection, TableSection } from "./components"

export default function TransactionJournal() {
  const [selected, setSelected] = useState({ search: "", detail_search: "", date_from: "", date_to: "" })
  const [isSelected, setIsSelected] = useState({ search: "", detail_search: "", date_from: "", date_to: "" })
  const [isSort, setIsSort] = useState(false)

  const { data, isLoading, refetch } = useList()

  return (
    <Fragment>
      <section className="container my-5">
        <section>
          <HeaderMenu title="JOURNAL TRANSACTION"/>
          
          <section className="mt-2">
            <Card>
              <Card.Body>
                <SearchingSection 
                  selected={selected} 
                  isSelected={isSelected} 
                  setSelected={setSelected} 
                  setIsSelected={setIsSelected}
                  setIsSort={setIsSort}
                  refetch={refetch}
                />
              </Card.Body>
            </Card>
          </section>

          <section className="mt-2">
            <Card>
              <Card.Body>
                <Card.Title>
                  {isLoading && isSort === false
                    ? <section><div className="flex justify-center py-12"><Loading loading /></div></section> 
                    : <>
                        <HeaderSection isSelected={isSelected} />
                        <TableSection 
                          data={data} 
                          refetch={refetch} 
                          selected={selected}
                          setIsSort={setIsSort}
                          isLoading={isLoading}
                          isSort={isSort}
                        />
                        <FotherSection data={data} />
                      </>
                  }
                </Card.Title>
              </Card.Body>
            </Card>
          </section>

          <section className="mt-2">
           <FooterMenu/>
          </section>
        </section>
      </section>
    </Fragment>
  )
}