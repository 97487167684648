// React
import { createContext, useEffect, useState } from "react"

// Components
import { BaseTable, Button, Input, Loading, Select } from "../../../components"
import { Item } from "./components"

// Third-Party Libraries
import debounce from "lodash/debounce"

// Types
import type { AccountGroupType, SelectedCoaType } from "../../../types"

// Utils
import { useCoaList } from "../../../utils"

export const CoaContext = createContext<{
  selected: SelectedCoaType | null
  setSelected: (value: SelectedCoaType) => void
}>({
  selected: null,
  setSelected() {}
})

export function CoaList(props: {
  cheque_coa_condition?: boolean
  disabledCoaGroup?: boolean
  data: AccountGroupType[]
  defaultGroup?: string
  onAddCoa: (value: string, label: string, coa: SelectedCoaType) => void
}) {
  // Vars
  const defaultGroup = props.data.find(item => item.group_coa_code === props.defaultGroup ?? "06")?.value

  // Hooks
  const [selected, setSelected] = useState<SelectedCoaType | null>(null)
  const [selectedGroup, setSelectedGroup] = useState(
    props.data.find((item) => item.value === defaultGroup) ? defaultGroup : ""
  )
  const { data, isLoading, search, refetch, setSearch } = useCoaList(
    undefined,
    selectedGroup
  )

  useEffect(() => {
    setSelected(null)
    refetch()

    // eslint-disable-next-line
  }, [selectedGroup, search])

  const onDebounce = debounce((e: any) => setSearch(e.target.value), 500)

  return (
    <section className="flex flex-col gap-3">
      <section className="flex md:flex-row flex-col justify-between items-end">
        <Input
          className="md:max-w-[300px] w-full"
          placeholder="Search..."
          onChange={onDebounce}
        />

        <section className="md:max-w-[300px] w-full">
          <Select
            isDisabled={props.disabledCoaGroup}
            label="COA GROUP"
            options={props.data}
            value={props.data.find((item) => item.value === selectedGroup)}
            onChange={(item) => setSelectedGroup(item?.value!)}
          />
        </section>
      </section>

      <div className="overflow-x-auto">
        <BaseTable className="border-collapse">
          <thead>
            <tr>
              <th className="w-[5px]"></th>
              <th>NAME</th>
              <th>LINKED</th>
              <th>TYPE</th>
              <th>NOTE</th>
              <th>BALANCE</th>
            </tr>
          </thead>

          <CoaContext.Provider value={{ selected, setSelected }}>
            <tbody>
              {isLoading || data.length === 0 ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loading
                      loading={isLoading}
                      errorText="No data available"
                    />
                  </td>
                </tr>
              ) : (
                data.map((item, key) => (
                  <Item
                    cheque_coa_condition={props.cheque_coa_condition}
                    key={key}
                    level={1}
                    index={key}
                    item={item}
                  />
                ))
              )}
            </tbody>
          </CoaContext.Provider>
        </BaseTable>
      </div>

      <div className="bg-base-100 text-right sticky bottom-0">
        <Button
          className="w-fit"
          color="primary"
          disabled={!Boolean(selected)}
          onClick={() => {
            if (selected) {
              props.onAddCoa(
                selected.coa_id.toString(),
                `${selected.coa_code} ${selected.coa_name}`,
                selected
              )
            }
          }}>
          SELECT
        </Button>
      </div>
    </section>
  )
}
