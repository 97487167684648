// Components
import { Loading } from "components"
import { FormSection } from "./components"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import { useNavigate, useParams } from "react-router-dom"

// Types
import type { DataCountType } from "../types"

// Utils
import { type FormType, validationSchema } from "./utils"
import { useDataCountDetail } from "../utils"

export default function EditCountInventory(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading } = useDataCountDetail(id!)

  if (isLoading || !data) {
    return (
      <Loading
        loading={isLoading}
        errorText="No data available"
      />
    )
  }

  return <ViewSection data={data} />
}

function ViewSection(params: {
  data: DataCountType
}): JSX.Element {
  // Vars
  const payload = params.data
  const invcount = params.data.invcount

  // Hooks
  const navigate = useNavigate()

  // Form
  const defaultValues: FormType = {
    countdetail: payload.countdetail,
    count_id: invcount.count_id,
    employee_id: invcount.employee_id.toString(),
    location_id: invcount.location_id.toString(),
    memo: invcount.memo,
    transaction_date: invcount.transaction_date,
    trx_code: invcount.trx_code
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      <FormSection
        redirectToAdjustment
        onSuccess={() => navigate(-1)}
      />
    </FormProvider>
  )
}