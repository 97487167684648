import { useState } from "react"
import { Card, FooterMenu, HeaderMenu } from "../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type { AddressList, PersonalList, ProfileList } from "../types"
import { RenderProvider } from "../utils"
import { Tab } from "./ChildItem/components/Item/components/Tabs"
import { FormPersonalData } from "./FormSection/FormPersonalData"
import { FormProfile } from "./FormSection/FormProfile"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { useApi, usePermission } from "../../../../../utils"
import { FormAddress } from "./FormSection/FormAddress"
import { FormMap } from "./FormSection/FormMap"
import { profileValidationSchema, addressValidationSchema, mapValidationSchema } from "../utils/function"

export function CreatePage(props: {
  asModal?: boolean
  onSuccess?: () => void
}) {
  const api = useApi()
  const tabs = ["PROFILE", "ADDRESS", "MAP"];
  const permission = usePermission(["CT03"])

  const [personalDataPayload, setPersonalDataPayload] = useState<any>(undefined);
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [isActiveSubmit, setIsActiveSubmit] = useState<any>({ 
    personal: false, 
    profile: false,
    address: false,
    map: false
  })
  const [disableTab, setDisableTab] = useState(false);
  const [responseValue, setResponseValue] = useState<any>(undefined);

  type CreatePersonalData = Pick<PersonalList, "Customer_code" | "Customer_name" | "Customer_Foto">;
  type CreateProfile = Omit<ProfileList, "vendor_id" | "price_level_vendor_id" | "payment_term_vendor_id" | "link_map">;
  type CreateMap = Pick<ProfileList, "link_map">;

  // Form Values
  const defaultValues:CreatePersonalData = {
    Customer_code: "",
    Customer_name: "",
    Customer_Foto: ""
  }
  const profileValues: CreateProfile = {
    handphone: "",
    email: "",
    website: "",
    card_label:  undefined,
    group_card: "2",
    category_customer_id: undefined, 
    source_from: "",
    employee_id: undefined,
    media: "",
    price_level_id: undefined,
    payment_term_id: undefined,
    max_balance: undefined,
    bank_id: undefined,
    bank_account: "",
    bank_beneficiary: "",
    bank_branch: ""
  }
  const addressValues: AddressList = {
    address_type_id: "1",
    address_type_id_2: "2",
    country_id: undefined,
    country_id_2: undefined,
    province_id: undefined,
    province_id_2: undefined,
    regency_id: undefined,
    regency_id_2: undefined,
    subdistrict_id: undefined,
    subdistrict_id_2: undefined,
    village_id: undefined,
    village_id_2: undefined,
    address: undefined,
    address_2: undefined,
  }
  const mapValues: CreateMap = { link_map: "" }
  const validationSchema = yup.object().shape({ Customer_name: yup.string().label("Customer name").required().max(30, "Must be 30 characters or less") })

  // Handle Form
  const methods = useForm<CreatePersonalData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const profileMethods = useForm<ProfileList>({ 
    defaultValues: profileValues,
    resolver: yupResolver(profileValidationSchema)
  })
  const addressMethods = useForm<AddressList>({ 
    defaultValues: addressValues,
    resolver: yupResolver(addressValidationSchema)  
  })
  const mapMethods = useForm<ProfileList>({ 
    defaultValues: mapValues,
    resolver: yupResolver(mapValidationSchema) 
  })

  // Handle Submit
  const onSubmit = (value: PersonalList) => {
    const formData = new FormData();
    
    formData.append('Customer_code', value?.Customer_code!);
    formData.append('Customer_name', value?.Customer_name);
    if(value?.Customer_Foto){formData.append(fileValue ? 'Customer_Foto' : "", value?.Customer_Foto)}

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/customer/add", formData),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then((res) => {
        setActiveTab(0)
        setDisableTab(true)
        setPersonalDataPayload(res?.data?.payload?.[0])
        setIsActiveSubmit({ ...isActiveSubmit, personal: true })

        if (props.onSuccess) {
          props.onSuccess()
        }
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onProfileSubmit = (value: ProfileList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/updateprofile?customer_id=${personalDataPayload?.customer_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, profile: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onAddressSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/updateaddress?customer_id=${personalDataPayload?.customer_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, address: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onMapSubmit = (value: ProfileList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/customer/updatealinkmap?customer_id=${personalDataPayload?.customer_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, map: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <RenderProvider>
      <section className="container my-2">
        {!props.asModal && (
          <HeaderMenu title="DATA ENTRY | CREATE NEW CUSTOMER" />
        )}
        <Card>
          <Card.Body>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${methods?.control?._formState?.errors ? "1950" : "1750px"}]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    <FormProvider {...methods}>
                      {/* @ts-ignore */}
                      <FormPersonalData action="CREATE" onSubmit={onSubmit} setFileValue={setFileValue} isActiveSubmit={isActiveSubmit?.personal} permission={permission?.canCreate} />
                    </FormProvider>
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab tabs={tabs} activeTab={activeTab} disabled={!disableTab} setActiveTab={setActiveTab} dataProfile={responseValue} className="text-[11px]" />
                <div className="">
                  <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${methods?.control?._formState?.errors ? "1950" : "1750px"}]`}>
                    <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6 mr-0.5">
                      <FormProvider {...profileMethods}>
                        {activeTab === 0 && <FormProfile permission={"CT031"} onSubmit={onProfileSubmit} />}
                      </FormProvider>
                      <FormProvider {...addressMethods}>
                        {activeTab === 1 && <FormAddress permission={"CT031"} onSubmit={onAddressSubmit} />}
                      </FormProvider>
                      <FormProvider {...mapMethods}>
                        {activeTab === 2 && <FormMap permission={"CT031"} onSubmit={onMapSubmit} />}
                      </FormProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
              <FooterMenu/>
        </section>
      </section>
    </RenderProvider>
  )
}