// Components
import { BaseTable, Loading } from "components"

// Utils
import { useProductLocation } from "../utils"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { DataType } from "../types"

export function DataLocationForm(params: {
  id: number
}) {
  // Hooks
  const { data, isLoading } = useProductLocation(params.id)

  if (isLoading || !data) {
    return (
      <Loading
        loading={isLoading}
        errorText="No data available"
      />
    )
  }

  return (
    <section className="flex flex-col gap-5">
      <BaseTable>
        <thead>
          <tr>
            <th>NAME</th>
            <th>STOCK IN</th>
            <th>STOCK OUT</th>
            <th>SOH</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, key) => (
            <tr key={key}>
              <td>{item.location_name}</td>
              <td>{item.stock_in}</td>
              <td>{item.stock_out}</td>
              <td>{item.soh}</td>
            </tr>
          ))}
        </tbody>
      </BaseTable>

      {data.length && (
        <section>
          <Chart data={data} />
        </section>
      )}
    </section>
  )
}

export function Chart(params: {
  data: DataType[]
}): JSX.Element {
  // Chart.JS
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  return (
    <Bar
      data={{
        labels: params.data.map(item => item.location_name),
        datasets: [
          {
            label: "SOH",
            data: params.data.map(item => item.soh),
            backgroundColor: params.data.map(item => item.location_color)
          }
        ]
      }}
      options={{
        responsive: true,
        plugins: {
          legend: { display: false },
        },
      }}
    />
  )
}