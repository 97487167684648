export const versionList: {
  description: string[]
  version: string
}[] = [
  {
    version: "1.05",
    description: [
      "Menyetarakan tinggi form pada Employee, Item, Vendor & Customer",
      "Selalu tampilkan scroll bar"
    ]
  },
  {
    version: "1.06",
    description: ["Tampilkan status privilege pada navbar layout"]
  },
  {
    version: "1.07",
    description: ["Tampilkan dokumentasi versi pada sistem"]
  },
  {
    version: "1.08",
    description: ["Sembunyikan menu System Setup"]
  },
  {
    version: "1.09",
    description: ["Hapus pop-up detail & pindahkan fungsi soft delete ke update pop-up di semua tampilan menu preference"]
  },
  {
    version: "1.10",
    description: [
      "Sorting list COA berdasarkan Code",
      "Tambah kolom location pada list COA",
      "Semua COA dapat diubah (dengan kondisi tertentu)"
    ]
  },
  {
    version: "1.11",
    description: [
      "Sembunyikan menu COA Group",
      "Hapus pop-up detail & pindahkan fungsi soft delete ke update pop-up di semua tampilan setup"
    ]
  },
  {
    version: "1.12",
    description: [
      "Tambah Inventory & Account Dashboard",
      "Tambah Analytics",
      "Ubah filter tanggal pada Finance Dashboard"
    ]
  },
  {
    version: "1.13",
    description: ["Tambah graph pada Analytics"]
  },
  {
    version: "1.14",
    description: [
      "Hilangkan kolom code & tambah fitur sort pada kolom action di privilege employee",
      "Ganti kolom kode dengan checkbox untuk menghapus banyak data (hanya tampilan)"
    ]
  },
  {
    version: "10.16",
    description: [
      "Penambahan setup - prefences - setting company (type barcode)",
      "Penambahan link account untuk : LA10-Asset Account for Reject  dan LA11- Liability Account for Returns",
      "disable edit pada setup - account - coa type",
      "Penambahan insert data ke table akumulasi Balance dan PNL berdasar lokasi",
      "Penambahan saat register subscribe di setiap company Usernane: TheKing@Skies",
      "Perubahan tampilan akumulasi aged payable (purchase - register - aged payable)",
      "Penambahan POP UP: item profile pada setiap data barcode di forrm data entry : PO dkk dan SO dkk"
    ]
  },
  {
    version: "10.17",
    description: [
      "Buat fungsi pada tombol history untuk melakukan refresh halaman",
      "Hilangkan tombol detail & soft delete pada inventory item",
      "Hilangkan semua status item pada profile bila status item menjadi Inactive"
    ]
  },
  {
    version: "10.18",
    description: [
      "Fitur hapus beberapa & semua data pada department"
    ]
  },
  {
    version: "11.11",
    description: [
      "Hapus kolom kode pada menu Divisi, Group Position & Job Position"
    ]
  },
  {
    version: "11.12",
    description: [
      "Tambah cetak barcode pada inventory register",
      "Ubah urutan & skema form work status pada Employee",
      "Perbaikan tampilan pop-up coa list",
      "Penambahan kolom baru pada form job position"
    ]
  }
]