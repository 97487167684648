import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import * as yup from "yup"
import { Logo } from "../../assets"
import { Button, Input, Select } from "../../components"
import { useApi } from "../../utils"
import { Password } from "./components"
import { useTypeIndustry } from "./hooks"
import type { FormType } from "./types"

export default function Subscribe() {
  return (
    <section className="min-h-screen bg-black flex flex-col justify-center items-center">
      <section className="flex md:flex-row flex-col md:divide-x-2">
        <section className="md:order-1 order-2 md:mr-5">
          <FormSection />
        </section>

        <img src={Logo} alt="Logo" className="my-auto max-h-[250px] lg:max-h-[320px] md:order-2 order-1" />
      </section>
    </section>
  )
}

function FormSection() {
  const api = useApi()
  const { dataTypeIndustry, isLoadingTypeIndustry } = useTypeIndustry()

  const dataPackage = [
    { value: "1", label: "Basic" },
    { value: "2", label: "Standar" },
    { value: "3", label: "Enterprise" },
  ]

  const defaultValues: FormType = {
    company_name: "",
    company_account: "",
    package: "",
    type_industry_id: "",
    phone: "",
    passwd: "",
    confirmPasswd: "",
    subdomain: ""
  }
  const validationSchema = yup.object().shape({
    company_name: yup.string().label("Company name").required(),
    subdomain: yup
      .string()
      .label("Sub domain")
      .required()
      .min(4)
      .max(15)
      .test("checkDuplicateSubdomain", "The subdomain is already in use!", async (value) => api.get(`/companies/check-subdomain/${value}`)
        .then((res) => res?.data?.status === "Error" ? false : true)
        .catch(() => false)),
    company_account: yup
      .string()
      .label("Email")
      .required()
      .test("checkDuplicateEmail", "The email is already in use!", async (value) => api.get(`/companies/check-email/${value}`)
        .then((res) => res?.data?.status === "Error" ? false : true)
        .catch(() => false)),
    package: yup.string().label("Package").required(),
    type_industry_id: yup.string().label("Type industry").required(),
    phone: yup
      .string()
      .label("Phone")
      .required()
      .test("checkDuplicatePhone", "The phone is already in use!", async (value) => api.get(`/companies/check-phone/${value}`)
        .then((res) => res?.data?.status === "Error" ? false : true)
        .catch(() => false)),
    passwd: yup.string().label("Password").required().min(6, 'Minimum code is 6 characters').max(20, 'Maximum code is 20 characters'),
    confirmPasswd: yup.string().label("Confirm password").optional()
  })
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/companies/register", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        window.location.replace(`/${value.subdomain}/login`)
      }).finally(() => resolve())
    })
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3">
      <div className="text-white text-center text-3xl font-bold">SUBSCRIBE</div>
      <div className="lg:flex lg:w-[520px] lg:mt-[-10px]">
        <Controller
          control={methods.control}
          name="company_name"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              textWhite
              label="COMPANY NAME"
              placeholder="Input company name"
              inputClass="lg:w-[250px] lg:mr-3"
              value={field.value ?? ""}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={methods.control}
          name="type_industry_id"
          render={({ field, fieldState }) => (
            <div className="w-full mt-[-10px] lg:mt-0">
              <Select
                textWhite
                label="TYPE INDUSTRY"
                placeholder="Select type industry"
                error={fieldState.error?.message}
                isLoading={isLoadingTypeIndustry}
                options={dataTypeIndustry}
                value={dataTypeIndustry.find(item => item.value === field.value?.toString())}
                onChange={e => field.onChange(e?.value)}
              />
            </div>
          )}
        />
      </div>
      <div className="lg:flex lg:w-[520px] lg:mt-[-10px]">
        <Controller
          control={methods.control}
          name="phone"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              textWhite
              type="number"
              label="PHONE"
              placeholder="Input phone"
              inputClass="lg:w-[250px] lg:mr-3"
              value={field.value ?? ""}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={methods.control}
          name="package"
          render={({ field, fieldState }) => (
            <div className="w-full">
              <Select
                textWhite
                label="PACKAGE"
                placeholder="Select package"
                error={fieldState.error?.message}
                options={dataPackage}
                value={dataPackage.find(item => item.value === field.value?.toString())}
                onChange={e => field.onChange(e?.value)}
              />
            </div>
          )}
        />
      </div>


      <div className="lg:flex lg:w-[520px] lg:mt-[-10px]">
        <Controller
          control={methods.control}
          name="company_account"
          render={({ field, fieldState }) => (
            <div className="mt-[-10px] lg:mt-0">
              <Input
                {...field}
                textWhite
                label="EMAIL"
                type="email"
                placeholder="Input email"
                inputClass="lg:w-[250px]"
                value={field.value ?? ""}
                error={fieldState.error?.message}
              />
            </div>
          )}
        />
        <FormProvider {...methods}>
          <Password label="PASSWORD" textWhite />
        </FormProvider>
      </div>
      <Controller
        control={methods.control}
        name="subdomain"
        render={({ field, fieldState }) => (
          <div className="mt-[-10px] lg:mt-0">
            <Input
              {...field}
              textWhite
              label="SUBDOMAIN"
              type="text"
              placeholder="Input subdomain"
              value={field.value ?? ""}
              error={fieldState.error?.message}
              onChange={(e) => {
                const { value } = e.target;
                const filteredValue = value.replace(/[^a-z0-9]/g, '');
                field.onChange(filteredValue);
              }}
            />
          </div>
        )}
      />

      <Button className="mt-3" type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>SUBSCRIBE</Button>

      <div className="text-xs text-center">By continuing you agree to the Flying HAWK Indonesia<br /><span className="text-white">terms of service</span> and <span className="text-white">privacy policy.</span></div>
    </form>
  )
}