// Components
import { Dropdown } from "components"

// Third-Party Libraries
import ReactDatePicker from "react-datepicker"
import moment from "moment"

// Utils
import { getDateFilter } from "../utils"

export function DateFilter(params: {
  disabled?: boolean
  thirtyDatesMaximum?: boolean
  onChangeEndDate?: (value: string) => void
  onChangeStartDate?: (value: string) => void
  onChangeType?: (value: string) => void
  value: {
    end_date: string
    start_date: string
    type: string
  }
}) {
  // Vars
  const list = getDateFilter()
  const value = params.value
  const startDate = moment(value.start_date)

  // Functions
  const getMaxDate = () => {
    const startDate = moment(value.start_date);
    const dateCount = startDate.daysInMonth();
    const maxEndDate = startDate.clone().add(29, "days").toDate();
  
    if (params?.thirtyDatesMaximum) {
      return maxEndDate;
    } else {
      return startDate.clone().add(dateCount, "days").toDate();
    }
  };

  return (
    <section>
      <label className="label">
        <span className="label-text font-bold">DATE RANGE</span>
      </label>

      <section className="join items-end">
        <Dropdown
          buttonClass="rounded-r-none whitespace-nowrap"
          className="join-item"
          buttonLabel={value.type}
          list={list.map(item => {
            return {
              label: item.label,
              rightLabel: item.fromDate && item.toDate ? `${moment(item.fromDate).format("DD MMM")} - ${moment(item.toDate).format("DD MMM YYYY")}` : ""
            }
          })}
          onClick={(res) => {
            // Vars
            const value = list.find(item => item.label === res)!

            if (params.onChangeType) {
              params.onChangeType(value.label)
            }

            if (params.onChangeStartDate) {
              params.onChangeStartDate(value.fromDate)
            }

            if (params.onChangeEndDate) {
              params.onChangeEndDate(value.toDate)
            }
          }}
        />

        {params.value.type !== "Date" && (
          <ReactDatePicker
            className="input input-bordered dark:border-gray-400 join-item w-[150px]"
            wrapperClassName="w-full"
            dateFormat={params.value.type === "Month" ? "MM/yyyy" : params.value.type === "Quarter" ? "yyyy, QQQ" : params.value.type === "Year" ? "yyyy" : "dd/MM/yyyy"}
            showMonthYearPicker={params.value.type === "Month"}
            showQuarterYearPicker={params.value.type === "Quarter"}
            showYearPicker={params.value.type === "Year"}
            selected={moment(params.value.start_date).toDate()}
            onChange={value => {
              if (value) {
                if (params.onChangeStartDate) {
                  params.onChangeStartDate(moment(value).format("YYYY-MM-DD"))
                }

                if (params.onChangeEndDate) {
                  // Vars
                  let typeList = {
                    "Month": "month",
                    "Quarter": "quarter",
                    "Year": "year"
                  }

                  // @ts-ignore
                  params.onChangeEndDate(moment(value).endOf(typeList[params.value.type]).format("YYYY-MM-DD"))
                }
              }
            }}
          />
        )}

        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          className="input input-bordered dark:border-gray-400 join-item w-[150px]"
          wrapperClassName="w-full"
          disabled={params.disabled}
          selected={moment(params.value.start_date).toDate()}
          onChange={value => {
            // Vars
            const startDate = moment(value)
            const dateCount = startDate.daysInMonth()
            const maxEndDate = startDate.clone().add(30, "days");
            const diffInDays = Math.abs(moment(params.value.end_date).diff(startDate, "days")) + 1;

            if (value && params.onChangeStartDate) {
              params.onChangeStartDate(startDate.format("YYYY-MM-DD"))
            }

            if (params.onChangeEndDate && params?.thirtyDatesMaximum === false && Math.abs(moment(params.value.end_date).diff(startDate, "days") + 1) > dateCount) {
              params.onChangeEndDate(startDate.add(dateCount, "days").format("YYYY-MM-DD"))
            }

            if (params.onChangeEndDate && params?.thirtyDatesMaximum === true && diffInDays > 30) {
              params.onChangeEndDate(maxEndDate.format("YYYY-MM-DD"));
            }
          }}
        />

        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          className="input input-bordered dark:border-gray-400 join-item w-[150px]"
          wrapperClassName="w-full"
          disabled={params.disabled}
          selected={moment(params.value.end_date).toDate()}
          minDate={startDate.toDate()}
          maxDate={getMaxDate()}
          onChange={value => {
            if (value && params.onChangeEndDate) {
              params.onChangeEndDate(moment(value).format("YYYY-MM-DD"))
            }
          }}
        />
      </section>
    </section>
  )
}