import * as yup from "yup"

export const profileValidationSchema = yup.object().shape({ 
    handphone: yup.string().label("Handphone").optional().max(20, "Must be 20 characters or less"),
    email: yup.string().label("Email").optional().max(200, "Must be 200 characters or less").email("Email must be a valid email address"),
    website: yup.string().label("Website").optional().max(200, "Must be 200 characters or less"),
    source_from: yup.string().label("Website").optional().max(100, "Must be 100 characters or less"),
    media: yup.string().label("Media").optional().max(100, "Must be 100 characters or less"),
    max_balance: yup.string().label("Max Balance").optional().max(18, "Must be 18 characters or less"),
    bank_account: yup.string().label("Bank Account").optional().max(20, "Must be 20 characters or less"),
    bank_beneficiary: yup.string().label("Bank Account").optional().max(100, "Must be 100 characters or less"),
    bank_branch: yup.string().label("Bank Account").optional().max(100, "Must be 100 characters or less"),

    card_label:  yup.string().optional(),
    group_card: yup.string().optional(),
    category_vendor_id: yup.string().optional(),
    employee_id: yup.string().optional(),
    price_level_id: yup.string().optional(),
    payment_term_id: yup.string().optional(),
    bank_id: yup.string().optional(),
})