// Third-Party Libraries
import { Link } from "react-router-dom"

export function Dropdown(params: {
  buttonClass?: string
  buttonLabel?: string
  className?: string
  list?: {
    label: string
    rightLabel?: string
  }[]
  onClick?: (value: string) => void
}): JSX.Element {
  // Functions
  const handleClick = (value: string) => {
    // Vars
    const elem = document.activeElement;

    if (params.onClick) {
      params.onClick(value) 
    }

    if (elem) {
      // @ts-ignore
      elem?.blur()
    }
  }

  return (
    <div className="dropdown">
      <div tabIndex={0} role="button" className={`btn btn-primary ${params.buttonClass}`}>{params.buttonLabel}</div>

      <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-[300px] p-2 shadow">
        {params.list?.map((item, key) => (
          <li
            key={key}
            onClick={() => handleClick(item.label)}
          >
            <Link
              to="#"
              className="flex justify-between items-center"
            >
              <div>{item.label}</div>
              {item.rightLabel && <div className="text-right">{item.rightLabel}</div>}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}