import { ActionButton, BaseTable, Button, Loading } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useState } from "react"
import { IoSendSharp } from "react-icons/io5"
import { Link, useNavigate, useParams } from "react-router-dom"
import { convertNumber, useToggle } from "utils"
import { InvoiceListBySO } from "../../../types"
import { useSOInvoiceList } from "../../../utils/hooks"
import { ModalDelete } from "../../ModalDelete"

type TableInvoiceProps = {
  salesOrderId?: number
  approvalView?: boolean
  approvalStatus: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TableInvoice = ({
  salesOrderId,
  approvalView,
  approvalStatus,
  display = true,
  displayCreateButton
}: TableInvoiceProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const isApproved = approvalStatus === APPROVAL.APPROVE
  const modalDelete = useToggle(false)
  const { data, isLoading, refetch } = useSOInvoiceList({
    sales_order_id: salesOrderId?.toString() ?? ""
  })
  const [deleteData, setDeleteData] = useState<InvoiceListBySO>(
    {} as InvoiceListBySO
  )
  const approvedFreight = data?.reduce((acc, cur) => {
    if (cur.approve_status === APPROVAL.APPROVE) {
      return acc + cur.freight
    }

    return acc
  }, 0)

  return (
    <div className={`${display ? "" : "hidden"} overflow-x-auto`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th rowSpan={2}>NO</th>
            <th rowSpan={2} colSpan={2}>
              Invoice Id
            </th>
            <th rowSpan={2}>Status</th>
            <th rowSpan={2}>Date</th>
            <th rowSpan={2}>Delivery</th>
            <th rowSpan={2}>Memo</th>
            <th rowSpan={2}>Subtotal</th>
            <th colSpan={2}>Payment</th>
            <th rowSpan={2}>Balance</th>
            <th className={approvalView ? "hidden" : ""} rowSpan={2}>
              Action
            </th>
          </tr>
          <tr>
            <td>Deposit</td>
            <td>Amount</td>
          </tr>
        </thead>

        <tbody>
          {data?.length && !isLoading ? (
            data.map((item, key) => {
              return (
                <tr key={item.invoice_id}>
                  <td className="text-center">{key + 1}</td>
                  <td className="text-center px-0">
                    <Button
                      size="xs"
                      color="ghost"
                      className={approvalView ? "hidden" : "block"}
                      onClick={() =>
                        navigate(
                          `/sales/transaction/invoice/edit/${item.invoice_id}`,
                          {
                            state: {
                              sales_order_id: item.sales_order_id
                            }
                          }
                        )
                      }>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td>{item.trx_code}</td>
                  <td>{APPROVAL[item.approve_status]}</td>
                  <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>#{item.delivery_no}</td>
                  <td>{item.memo}</td>
                  <td className="text-right">
                    {convertNumber(item.total_invoice).intoCurrency}
                  </td>
                  <td className="text-right">
                    {convertNumber(item.total_deposit).intoCurrency}
                  </td>
                  <td className="text-right">
                    {convertNumber(item.total_payment).intoCurrency}
                  </td>
                  <td className="text-right">
                    {convertNumber(item.balance).intoCurrency}
                  </td>
                  <td className={approvalView ? "hidden" : ""}>
                    <section className="flex justify-center items-center">
                      {item.approve_status !== APPROVAL.APPROVE && (
                        <ActionButton.Delete
                          onClick={() => {
                            modalDelete.setActive(true)
                            setDeleteData(item)
                          }}
                        />
                      )}
                    </section>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={12} className="text-center">
                <Loading
                  loading={isLoading}
                  errorText={isLoading ? "" : "No data available"}
                />
              </td>
            </tr>
          )}
        </tbody>
      </BaseTable>

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          refetch()
        }}
      />

      {displayCreateButton && (
        <div className="flex justify-end mt-4">
          <Link
            to={
              isApproved
                ? `/sales/transaction/invoice/${params.sales_order_id}`
                : "#"
            }
            state={{ approvedFreight }}>
            <Button
              disabled={!isApproved}
              permission="ST121"
              className="uppercase"
              color="primary">
              Create Invoice
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}
