import { Button, DateInput, Input, Modal } from "components"
import moment from "moment"
import { Fragment, useState } from "react"
import { toast } from "react-hot-toast"
import { useApi, useToggle } from "utils"
import { TaskList } from "../types"

type ModalDeleteProps = {
  data: Pick<
    TaskList,
    | "task_id"
    | "trx_code"
    | "subject"
    | "department_name"
    | "date_start"
    | "task_status"
    | "owner_name"
  >
  modalState: ReturnType<typeof useToggle>
  onSuccess?: () => void
}

export const ModalDelete = ({
  data,
  modalState,
  onSuccess
}: ModalDeleteProps) => {
  const [loadingData, setLoadingData] = useState(false)
  const api = useApi()

  const handleDeleteData = async () => {
    setLoadingData(true)
    await toast
      .promise(api.delete(`task/${data?.task_id}`), {
        loading: "Loading...",
        success: (res) => res?.data?.message,
        error: (err) => err?.response?.data?.message
      })
      .then(() => {
        modalState.setActive(false)
        onSuccess?.()
      })
      .finally(() => {
        setLoadingData(false)
      })
  }

  return (
    <Modal
      isOpen={modalState.isActive}
      title="DELETE TASK"
      size="xl"
      closeModal={modalState.toggle}>
      <Modal.Body>
        <Fragment>
          <small className="text-lg font-medium text-red-400">
            Are you sure want to delete this data?
          </small>

          <Input disabled label="TASK ID" defaultValue={data.trx_code} />

          <Input
            disabled
            label="DEPARTMENT"
            defaultValue={data.department_name}
          />

          <Input disabled label="SUBJECT" defaultValue={data.subject} />

          <Input disabled label="STATUS" defaultValue={data.task_status} />

          <Input disabled label="OWNER TASK" defaultValue={data.owner_name} />

          <DateInput
            disabled
            label="DATE"
            selected={moment(data.date_start).toDate()}
            onChange={() => null}
          />
        </Fragment>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          disabled={loadingData}
          loading={loadingData ? "true" : undefined}
          onClick={handleDeleteData}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
