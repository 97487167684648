import { DateInput, Select } from "components"
import { Fragment } from "react"
import { useCustomer } from "../utils"
import { FilterType } from "../types"
import moment from "moment"

export function Filter(props: {
  selected: FilterType
  setSelected: (data: FilterType) => void
}) {
  const { dataCustomer, isLoadingCustomer } = useCustomer()

  const statusDropdown = [
    { value: "1", label: "PENDING" },
    { value: "2", label: "EDIT" },
    { value: "3", label: "REJECT" },
    { value: "4", label: "APPROVE" }
  ]
  const allOption = { value: "", label: "ALL" }

  return (
    <Fragment>
      <section className="grid lg:grid-cols-4 gap-2">
        <DateInput
          label="DATE FROM"
          onChange={(date) => {
            props.setSelected({
              ...props?.selected,
              start_date: moment(date).format("YYYY-MM-DD")
            })
          }}
          selected={
            props.selected?.start_date
              ? moment(props.selected.start_date).toDate()
              : null
          }
        />

        <DateInput
          label="DATE END"
          onChange={(date) => {
            props.setSelected({
              ...props?.selected,
              end_date: moment(date).format("YYYY-MM-DD")
            })
          }}
          selected={
            props.selected?.end_date
              ? moment(props.selected?.end_date).toDate()
              : null
          }
        />

        <Select
          label="CUSTOMER"
          options={[
            allOption,
            ...dataCustomer?.map((item: any) => ({
              value: item?.value,
              label: item?.label
            }))
          ]}
          isLoading={isLoadingCustomer}
          value={
            props.selected.customer_id !== ""
              ? [allOption, ...dataCustomer].find(
                  (item) =>
                    item.value?.toString() ===
                    props.selected.customer_id?.toString()
                )
              : allOption
          }
          onChange={(e) => {
            props.setSelected({ ...props.selected, customer_id: e?.value })
          }}
        />

        <Select
          label="STATUS"
          value={
            props.selected.approve_status !== ""
              ? [allOption, ...statusDropdown].find(
                  (item) =>
                    item.value?.toString() ===
                    props.selected.approve_status?.toString()
                )
              : allOption
          }
          options={[allOption, ...statusDropdown]}
          onChange={(e) => {
            props.setSelected({ ...props.selected, approve_status: e?.value })
          }}
        />
      </section>
    </Fragment>
  )
}
