/* eslint-disable react-hooks/exhaustive-deps */

import { FilterTask, TaskList } from "pages/Task/types"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseTaskList = {
  params: FilterTask
  onSuccess?: (paging: Response["pagination_data"]) => void
}

type Response = {
  payload: TaskList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}

export function useTaskList({ params, onSuccess }: UseTaskList) {
  const api = useApi()
  const [data, setData] = useState<TaskList[]>([])
  const [paging, setPaging] = useState<
    Response["pagination_data"] & { limit: number }
  >()
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const payload = {
      ...params,
      task_status: params.task_status === "0" ? undefined : params.task_status,
      priority: params.priority === "0" ? undefined : params.priority
    }

    api
      .get("/task/", { params: payload })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        const paging = res.data.pagination_data

        setData(data)
        setPaging({ ...paging, limit: params.limit })
        onSuccess?.(paging)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [params.limit, params.page, params.task_status, params.priority])

  return {
    data,
    paging,
    isLoading,
    refetch
  }
}
