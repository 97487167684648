import { Label, Select } from "components"
import { useDropdownGroupTask } from "pages/Setup/Preference/SubGroupTask/utils"
import { Controller, useFormContext } from "react-hook-form"
import { TaskType } from "../types"

type DropdownGrupProps = {
  approvalView?: boolean
  isDisabled?: boolean
}

export const DropdownGrup = ({
  approvalView,
  isDisabled
}: DropdownGrupProps) => {
  const { control } = useFormContext<TaskType>()
  const groupTasks = useDropdownGroupTask()

  return (
    <section>
      <Label text="GROUP" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="mr_group_task_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Group"
              isDisabled={isDisabled || approvalView}
              isLoading={groupTasks?.isLoading}
              options={groupTasks?.data ?? []}
              value={
                field.value
                  ? groupTasks?.data?.find(
                      (item) => item.value === field.value?.toString()
                    )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
