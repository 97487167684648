import { Fragment, useState } from "react"
import { Card, Loading, PaginationData, BaseTable, TableNumber, Button, PermissionLink } from "../../../../components"
import { FilterSection } from "./components";
import { FilterType } from "./types";
import { defaultFilter, useList, } from "./utils";
import moment from "moment";
import { APPROVAL } from "utils";
import { IoSendSharp } from "react-icons/io5";
import { Delete } from "./components/Delete";
// import { useDefaultSize } from "pages/Inventori/Transaction/AddNewItem/components/ChildItem/components/Item/utils";

export function ItemsTransfer() {
  const [pagination, setPagination] = useState<FilterType>(defaultFilter)
  const { data, isLoading, refetch } = useList(pagination)
  // const { dataDefault } = useDefaultSize()

  return (
    <Fragment>
      <section className="container my-2">
        {isLoading
          ? <Loading loading />
          : <>
            <section className="mt-2">
              <Card>
                <Card.Body>
                  <FilterSection selected={pagination} setSelected={setPagination} />
                </Card.Body>
              </Card>
            </section>
            <section className="mt-2">
              <Card>
                <Card.Body>
                  <div className="overflow-x-auto">
                    <BaseTable className="border-collapse">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th colSpan={2} rowSpan={2}>TRANSFER ID#</th>
                          <th rowSpan={2}>DATE</th>
                          <th rowSpan={2}>FROM LOCATION</th>
                          <th rowSpan={2}>TO LOCATION</th>
                          <th rowSpan={2}>TRAIDER</th>
                          <th colSpan={2}>STATUS</th>
                          <th rowSpan={2}>MEMO</th>
                          <th rowSpan={2}>QTY TRANSFERED</th>
                          <th rowSpan={2}>REJECTED</th>
                          <th rowSpan={2}>RECIEVED</th>
                          <th rowSpan={2}>ACTION</th>
                        </tr>
                        <tr>
                          <th>TRANSFER</th>
                          <th>APPROVED</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.payload.length === 0
                          ? <tr><td colSpan={14} className="text-center"><Loading errorText="No data available" /></td></tr>
                          : <Fragment>
                            {data?.payload?.map((item: any, key: any) => (
                              <tr>
                                <td>{TableNumber({
                                  pageCurrent: pagination?.page,
                                  dataLength: pagination?.limit!,
                                  index: key,
                                  limit: pagination?.limit,
                                  totalData: pagination?.total
                                })}</td>
                                <td className="text-center">
                                  <Button
                                    type="button"
                                    color="ghost"
                                    disabled={[APPROVAL.REJECT, APPROVAL.APPROVE].includes(
                                      item?.approval_status
                                    )}>
                                    <PermissionLink
                                      permission="IT103"
                                      to={`/inventory/transaction/transfer-items/edit/${item.transfer_id}`}
                                    >
                                      <IoSendSharp />
                                    </PermissionLink>
                                  </Button>
                                </td>
                                <td>{item.trx_code}</td>
                                <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                                <td>{item.location_from.location_name}</td>
                                <td>{item.location_to.location_name}</td>
                                <td>{item.employee.employee_name}</td>
                                <td>{item.transfer_status === 1 ? 'Close' : (item.transfer_status === 2 ? 'Open' : 'Completed')}</td>
                                <td>{APPROVAL[item.approval_status]}</td>
                                <td>{item.memo}</td>
                                <td className="text-center">{item.qty_transfer}</td>
                                <td className="text-center">{item.qty_received}</td>
                                <td className="text-center">{item.qty_rejected}</td>
                                <td>
                                  <Delete id={item} refetch={refetch} />
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        }
                      </tbody>
                    </BaseTable>
                  </div>
                  <div className="mt-5">
                    <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
                  </div>
                </Card.Body>
              </Card>
            </section>
          </>
        }
      </section>
    </Fragment>
  )
}