import { Input, Button } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { PersonalList } from "../../types"
import { useState } from "react"
import { usePersonalCode } from "../ChildItem/components/Item/utils"
interface PersonalProps {
  action?: string
  data?: PersonalList
  isActiveSubmit?: boolean
  permission?: boolean
  setFileValue: (newState: any) => void
  onSubmit: (newState: any) => void
}

export const FormPersonalData: React.FC<PersonalProps> = ({ action, data, onSubmit, setFileValue, isActiveSubmit, permission }) => {
  const { control, setValue, getValues ,handleSubmit, formState} = useFormContext<PersonalList>()
  const { dataCode } = usePersonalCode()

  const [imageViews, setImageView] = useState<{ file: File | null; view: string | null }>({ file: null, view: null })
  const { REACT_APP_BASE_URL_API } = process.env

  return (
    <section>
      {/* Input Profile */}
      <>
        <div className="avatar flex justify-center mt-6 mb-10">
          <div className="w-[150px] rounded-full">
            {/* eslint-disable-next-line */}
            <img src={imageViews?.view 
              ? imageViews?.view
              : control?._formValues?.vendor_Foto
              ? `${REACT_APP_BASE_URL_API}/getimage/?imgpath=${control?._formValues?.vendor_Foto}`
              : "https://www.zedemy.com/wp-content/plugins/buddyboss-platform/bp-core/images/profile-avatar-buddyboss.png"}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="vendor_Foto"
          render={({ field }) => (
            <input 
              className="absolute mt-[-190px] ml-[100px] w-[150px] h-[150px] rounded-[50%] opacity-0" 
              type="file" 
              disabled={action === "DETAIL"} 
              onChange={(e) => {
                setFileValue(e.target.files?.[0])
                const selectedFile = e.target.files?.[0];
                field.onChange(e.target.files?.[0]);

                if (selectedFile) {
                  setImageView({ ...imageViews, file: selectedFile ?? control?._formValues?.vendor_Foto});
                  const reader = new FileReader();
                  reader.onloadend = () => {setImageView({ ...imageViews, view: reader.result as string})};
                  reader.readAsDataURL(selectedFile);
                } else {
                  setImageView({ ...imageViews, file: null, view:  null});
              }}}
            />
          )}
        />
      </>

      <>
        <Controller
          control={control}
          name="vendor_code"
          render={({ field }) => 
            <Input 
              {...field} 
              label="ID VENDOR" 
              value={data?.vendor_code ?? dataCode?.vendorcode ?? ""} 
              disabled={true} 
            />
          }
        />
      </>

      <div className="flex items-top">
        <Controller
          control={control}
          name={action === "UPDATE" ? "vendor_Name" : "vendor_name"}
          render={({ field, fieldState }) => 
            <Input 
              {...field} 
              label="FULL NAME *" 
              className="w-full" 
              error={fieldState.error?.message}
              // error={action === "UPDATE" 
              //   ? control?._formValues?.vendor_Name ? "" : fieldState.error?.message
              //   : control?._formValues?.vendor_name ? "" : fieldState.error?.message
              // }
              placeholder="Input full name" 
              value={field.value ?? data?.vendor_Name ?? ""} 
              onChange={(e) => {
                if(!data?.vendor_code){setValue("vendor_code", dataCode?.vendorcode!)}
                if(action === "UPDATE"){setValue("vendor_Name", e.target.value)}
                if(action === "CREATE"){setValue("vendor_name", e.target.value)}
              }}
              disabled={action === "DETAIL"} 
            />
          }
        />
      </div>

      {/* Submit Button */}
      {action !== "DETAIL" && <div className="mt-5 flex justify-end">
        <Button 
          type="button"
          color="primary"
          loading={formState.isSubmitting ? "true" : undefined}
          onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
          disabled={isActiveSubmit}
          permission={"CT041"}
          >
          SAVE
        </Button>
      </div>}
    </section>
  )
}