/* eslint-disable react-hooks/exhaustive-deps */

import { TaskActionList } from "pages/Task/types"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { mapAsetPath } from "../functions"

type UseTaskActionList = {
  task_id: string
}

type Response = {
  payload: TaskActionList[]
}

export function useTaskActionList({ task_id }: UseTaskActionList) {
  const api = useApi()
  const [data, setData] = useState<TaskActionList[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      sort: "code:asc",
      task_id: task_id
    }

    api
      .get("/task_action/", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data?.map((item) => ({
          ...item,
          task_action_files: mapAsetPath(item.task_action_files ?? [])
        })))
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (task_id) {
      fetchData()
    }

    return () => {
      setData([])
      setLoading(false)
    }
  }, [task_id])

  return {
    data,
    isLoading,
    refetch
  }
}
