import { Button, Label, Select } from "components";
import { CreateModal as LocationModal } from "pages/Setup/Preference/Location/components";
import { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TbPlus } from "react-icons/tb";
import { useToggle } from "utils";
import { useLocationList } from "../utils/hooks";
import { FormType } from "../types";

export function Location() {
  const { control } = useFormContext<FormType>();
  const { dropdown, refetch } = useLocationList();

  return (
    <section>
      <Label text="LOCATION" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="location_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Location"
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                    (item) => item.value === field.value?.toString()
                  )
                  : null
              }
              onChange={(e) => field.onChange(e?.value)}
              error={fieldState.error?.message}
            />
          )}
        />

        <CreateLocation onSuccess={refetch} />
      </section>
    </section>
  );
}

function CreateLocation(props: { onSuccess: () => void }) {
  // Hooks
  const { isActive, toggle } = useToggle(false);

  return (
    <Fragment>
      <Button color="primary" className="px-2" onClick={toggle}>
        <TbPlus size={24} />
      </Button>

      {isActive && (
        <LocationModal toggle={toggle} onSuccess={props.onSuccess} />
      )}
    </Fragment>
  );
}
