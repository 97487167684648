// Components
import { BaseTable, Card, DashboardFilter, FooterMenu, HeaderMenu, Select } from "components"
import { CardSection } from "./components"

export default function Dashboard() {
    return (
        <section className="container my-5 flex flex-col gap-3">
            <HeaderMenu title="INVENTORY DASHBOARD" />
            <DashboardFilter onChangeFilter={() => {}} />

            <Card>
                <Card.Body>
                    <div className="border-b-2 pb-3 flex gap-2 items-center">
                        <div>
                            <b className="text-lg">INVENTORY DASHBOARD</b>
                        </div>
                        <div>
                            <Select
                                placeholder="Filter Period"
                                className="w-[200px]"
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="grid lg:grid-cols-2 gap-5 gap-y-10">
                            <CardSection
                                label="Inventory Overview"
                                placeholder="Dropdown Group"
                                
                            >
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Item Name</th>
                                            <th>Current Stock</th>
                                            <th>Out of Stock</th>
                                            <th>Over Stock</th>
                                            <th>Slow Moving</th>
                                            <th>Action Required</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </BaseTable>
                            </CardSection>
                            
                            <CardSection
                                label="Supplier Performance"
                            >
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Vendor / Supplier</th>
                                            <th>Lead Time (Days)</th>
                                            <th>On Time Delevery rate (%)</th>
                                            <th>Quality Issue</th>
                                            <th>Action Required</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </BaseTable>
                            </CardSection>

                            <CardSection
                                label="Warehouse Permoformance Matrix"
                                placeholder="Location"
                            >
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Location</th>
                                            <th>Order Fullment (%)</th>
                                            <th>Average Pick Time Minute</th>
                                            <th>Return Rate</th>
                                            <th>Space Ultization Rate (%)</th>
                                            <th>Labour Productivity (Item / hour)</th>
                                            <th>Action Required</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </BaseTable>
                            </CardSection>

                            <CardSection
                                label="Trends and Forecasting"
                                placeholder="Location"
                            >
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Month</th>
                                            <th>Sales Volume</th>
                                            <th>Forcasting Inventory</th>
                                            <th>Action Required</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </BaseTable>
                            </CardSection>

                            <CardSection
                                label="Location Performance"
                                placeholder="Location"
                            >
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Location</th>
                                            <th>Inventory Level</th>
                                            <th>Sales Performance</th>
                                            <th>Stock Variance (%)</th>
                                            <th>Action Required</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </BaseTable>
                            </CardSection>

                            <CardSection
                                label="Warehouse Management KPI"
                                placeholder="Location"
                            >
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>KPI</th>
                                            <th>Value</th>
                                            <th>Action Required</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </BaseTable>
                            </CardSection>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <FooterMenu />
        </section>
    )
}