/* eslint-disable react-hooks/exhaustive-deps */

import { SubGroupTask } from "pages/Task/types"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type Response = {
  payload: SubGroupTask[]
}

export function useSubGroupList() {
  const api = useApi()
  const [data, setData] = useState<SubGroupTask[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("/sub-group-task/")
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      group_task_id: item.mr_group_task_id,
      label: item.mr_group_sub_name,
      value: item.mr_group_sub_id.toString()
    }))
  }
}
