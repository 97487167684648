import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils"
import { DataList, useListParams } from "../../types"

export function useList({ page, keyword, limit, sort }: useListParams) {
  const defaultValue = {
    payload: [],
    pagination_data: {
        page: 0,
        pages: 0,
        size: 0,
        total: 0
    }
  }

  const [sortBy, sortDirection] = sort?.split(":") ?? ""

  const api = useApi()
  const [data, setData] = useState<DataList>(defaultValue)
  const [isLoading, setLoading] = useState(true)

  const params: { [key: string]: number | string | undefined } = {};
  if (page) params.page = page;
  if (keyword) params.keyword = keyword;
  if (limit) params.limit = limit;
  if (sortBy) params.sort_by = sortBy;
  if (sortDirection) params.sort_direction = sortDirection;

  const fetchData = () => {
    setLoading(true)

    api.get("/shop-transaction-access/?only_active=false", { params })
    .then((res: SuccessFetch<DataList>) => setData(res?.data))
    .catch(() => setData(defaultValue))
    .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [page, keyword, limit, sort])

  return { data, isLoading, refetch }
}