// SweetAlert2
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

export function deleteAlert(isDeleteAll: boolean): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    withReactContent(Swal).fire({
      title: `Are you sure want to delete ${isDeleteAll ? "all" : "selected"} items?`,
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-error"
      }
    }).then(res => {
      if (res.isConfirmed) {
        resolve()
      } else {
        reject()
      }
    })
  })
}