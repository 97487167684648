import { BalanceSheetReportType } from "pages/Finance/Report/types"
import { Fragment } from "react"
import { ItemTotal } from "../ItemTotal"
import { convertNumber } from "utils"

type ItemProps = {
  list: Array<BalanceSheetReportType>
  item: BalanceSheetReportType
  displayAccountNumber: boolean
  displaySubHeader: boolean
}

export function Item({
  list,
  item,
  displayAccountNumber,
  displaySubHeader
}: ItemProps) {
  const isNormalFontWeight = [3].includes(item.header_level)
  const isNetAssets = item.coa_name === "NET ASSETS"
  const isNetProfit = item.coa_name === "NET PROFIT"
  const isDisplaySubHeader = item.header_level === 2 && displaySubHeader

  return (
    <Fragment>
      <tr
        key={item.coa_id}
        style={{
          display:
            displaySubHeader || item.header_level !== 2 ? "table-row" : "none"
        }}>
        <td>
          <span
            className={isNormalFontWeight ? "font-normal" : "font-bold"}
            style={{ marginLeft: (item.coa_level - 1) * 24 }}>
            {item.coacode ? (
              <span className="me-2 text-nowrap">
                {displayAccountNumber ? item.coacode : ""}
              </span>
            ) : null}

            <span
              className={
                displayAccountNumber && item.coacode
                  ? "border-s-[1px] pe-3"
                  : "border-none"
              }
            />

            <span className="text-nowrap">{`${item.coa_name}`}</span>
          </span>
        </td>

        {isNetAssets || isNetProfit || item.header_level === 3
          ? item.balance?.map((balance, index) => (
            <td
              key={balance + index}
              className={`text-right ${isNormalFontWeight ? "font-normal" : "font-bold"
                }`}>
              {convertNumber(balance).intoCurrency}
            </td>
          ))
          : item?.balance?.map(balance => (<td>{""}</td>))}
      </tr>

      {list.map((sub, key) => {
        if (sub.parent_id === item.coa_id) {
          return (
            <Item
              key={sub.coa_id + key}
              list={list}
              item={sub}
              displayAccountNumber={displayAccountNumber}
              displaySubHeader={displaySubHeader}
            />
          )
        }
        return <Fragment />
      })}

      {isDisplaySubHeader ? <ItemTotal item={item} /> : null}
    </Fragment>
  )
}
