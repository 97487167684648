import { yupResolver } from "@hookform/resolvers/yup"
import { Button, DateInput, Input, Modal } from "components"
import { Controller, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form"
import { useToggle } from "utils"
import { FormReminderType, FormCustomerQuotationType, formReminderSchema } from "../types"
import { useCustomerQuotationCode } from "../utils/hooks"
import { useMemo } from "react"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import moment from "moment"

type FormReminderProps = {
  approvalView?: boolean
  modalReminder: ReturnType<typeof useToggle>
  isEdit?: boolean
}

export const FormReminder = ({ approvalView, modalReminder, isEdit }: FormReminderProps) => {
  const { control, setValue } = useFormContext<FormCustomerQuotationType>()
  const approval_status = useWatch({ control: control, name: "approval_status" })
  const [customer_id, customer_name, transaction_date, promize_date, memo] = useWatch({
    control,
    name: ["customer_id", "customer_name", "transaction_date", "promize_date", "memo"]
  })

  const defaultValues: FormReminderType = useMemo(() =>
  ({
    card_group: 2,
    card_id: customer_id,
    memo: memo,
    promise_date: promize_date,
    transaction_date: transaction_date,
    vendor_id: customer_id
  }), [customer_id, memo, promize_date, transaction_date])

  const onSubmit = async (reminder: FormReminderType) => {
    setValue('reminder', reminder)
    modalReminder.setActive(false)
  }

  const methods = useForm<FormReminderType>({
    defaultValues,
    resolver: yupResolver(formReminderSchema)
  })

  useCustomerQuotationCode({
    type: "reminder_code",
    trx_date: methods.getValues("transaction_date") ?? '',
    onSuccess: (data) => methods.setValue("trx_code", data),
    enabled: !isEdit
  })

  return (
    <FormProvider {...methods} >
      <Modal
        title="Add Reminder"
        isOpen={modalReminder.isActive}
        closeModal={modalReminder.toggle}>

        <Modal.Body>
          <section className="flex flex-col gap-3">
            <Controller
              control={methods.control}
              name="trx_code"
              render={({ field }) => (
                <Input {...field} disabled label="ID #" value={field.value} />
              )}
            />

            <Input disabled label="TRADER NAME" value={customer_name} />

            <Controller
              control={methods.control}
              name="transaction_date"
              render={({ field, fieldState }) => (
                <DateInput
                  label="DATE"
                  disabled={approval_status !== APPROVAL.PENDING || approvalView}
                  error={fieldState.error?.message}
                  selected={moment(field.value).toDate()}
                  onChange={(date) => {
                    const selectedDate: string = moment(date).format("YYYY-MM-DD")
                    field.onChange(selectedDate)
                  }}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="promise_date"
              render={({ field, fieldState }) => (
                <DateInput
                  label="PROMISED DATE"
                  disabled={approval_status !== APPROVAL.PENDING || approvalView}
                  error={fieldState.error?.message}
                  selected={moment(field.value).toDate()}
                  onChange={(date) => {
                    const selectedDate: string = moment(date).format("YYYY-MM-DD")
                    field.onChange(selectedDate)
                  }}
                />)}
            />

            <Controller
              control={methods.control}
              name="memo"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label="MEMO"
                  defaultValue={field.value}
                  disabled={approval_status !== APPROVAL.PENDING || approvalView}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Input
              disabled
              label="TRANSACTION FROM"
              defaultValue="SALES ORDER"
            />
          </section>
        </Modal.Body>

        <Modal.Footer>
          <Button className={approvalView ? "hidden" : ""} onClick={methods.handleSubmit(onSubmit)} color="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  )
}
