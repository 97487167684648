import * as yup from "yup"

const baseValidation = {
    finance_year: yup.number().label("Finance Year").optional(),
    methode_avg_price: yup.number().label("Metode Average Price").required(),
    barcode_type: yup.number().label("Barcode Type").required(),
}

export const validationSchema = yup.object().shape(baseValidation)

export type FormType = yup.InferType<typeof validationSchema>