import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import 'chartjs-plugin-datalabels';

// Registrasi semua elemen yang diperlukan dari Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function GrossProfitMargin(props: { className?: string }) {
  const totalRevenue = 100; 
//   const grossProfit = 40; 
//   const grossProfitPercentage = (grossProfit / totalRevenue) * 100;

  const data = {
    labels: ['Gross profit'],
    datasets: [
      {
        label: 'Sales revenue',
        data: [100],
        backgroundColor: 'rgb(186, 85, 211)',
        borderColor: 'rgb(128, 0, 128)',
        barThickness: 50, 
        maxBarThickness: 50,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      x: {
        display: true,
        min: 0, 
        max: totalRevenue, 
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: any) => `Sales revenue: ${context.raw.toLocaleString()}%`,
        },
      },
      datalabels: {
        display: true, 
        anchor: 'center',
        align: 'center',
        formatter: (value: number) => `${value.toFixed(0)}%`,
        color: 'white',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className={`${props.className}`} style={{ height: '130px' }}>
      <Bar data={data} options={options} />
    </div>
  );
}
