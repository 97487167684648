import { Fragment, useContext } from "react"
import { ActionType, ListActionTask } from "../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"
import { Render } from "../../utils"

export function IsActive({ data, refetch }: ActionType) {
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: ListActionTask) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put("/action-task/changestatus",
                    {
                        mr_action_task_id: value.mr_action_task_id,
                        action_task_del: !value.is_deleted
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            )
            .then(() => {
                toggle()
                refetch && refetch()
            })
            .catch(() => {})
            .finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle label="Soft Delete" isChecked={data?.is_deleted} onClick={() => { onSubmit(data!) }} />
        </Fragment>
    )
}