import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { menuTitle } from "./MenuTitle"
import { IsActive } from "./IsActive"

export function Update(props: { id: DataList, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
  refetch: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title={`EDIT ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  id: DataList
  toggle: () => void
  refetch: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const validationSchema = yup.object().shape({
    smp_code: yup.string().label("SMP code").required().max(3, 'Maximum code is 3 characters'),
    smp_name: yup.string().label("SMP name").required().max(100, "Must be 100 characters or less"),
    description: yup.string().label("Description").optional().max(250, "Must be 250 characters or less"),
  })
  const onSubmit = ( value: DataList ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/smp/edit?smp_id=${props.id.smp_id}`, 
        { 
          smp_code: value.smp_code, 
          smp_name: value.smp_name, 
          description: value.description, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: props.id,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormSection isUpdate />
        </Modal.Body>

        <Modal.Footer className="flex justify-between gap-1">
          <IsActive id={props.id} refetch={props.refetch} />
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}