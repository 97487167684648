import moment from "moment"
import { Fragment } from "react"
import { TbHistory, TbPlus, TbTrash } from "react-icons/tb"
import { useToggle } from "utils"
import { TaskActionList, TaskList } from "../types"
import { generateAsetFile } from "../utils/functions"
import { useTaskActionList, useTaskProgress } from "../utils/hooks"
import { ACTION_STATUS } from "../utils/vars"
import { ModalActionLine } from "./ModalActionLine"
import { ModalDeleteActionLine } from "./ModalDeleteActionLine"
import { ModalHistoryActionLine } from "./ModalHistoryActionLine"
import { PicturesSection } from "./PictureSection"

type CardTaskProps = {
  task: Partial<TaskList>
  no: number
  actionTask: TaskActionList
  taskAction: ReturnType<typeof useTaskActionList>
  taskProgress: ReturnType<typeof useTaskProgress>
}

export const CardTask = ({ task, no, actionTask, taskAction, taskProgress }: CardTaskProps) => {
  const modalUpdate = useToggle(false)
  const modalHistory = useToggle(false)
  const modalDelete = useToggle(false)
  const bgColor =
    actionTask.status === 1
      ? "bg-notstarted"
      : actionTask.status === 2
        ? "bg-inprogress"
        : actionTask.status === 3
          ? "bg-completed"
          : "bg-cancelled"

  return (
    <Fragment>
      <div className={`${bgColor} p-3 text-black`}>
        <div className="w-full flex gap-2 justify-end items-center ">
          <div className="btn btn-sm btn-ghost p-1" onClick={modalUpdate.toggle}>
            <TbPlus size={16} />
          </div>

          <div className="btn btn-sm btn-ghost p-1" onClick={modalHistory.toggle}>
            <TbHistory size={16} />
          </div>

          <div
            className="btn btn-sm btn-ghost p-1"
            onClick={modalDelete.toggle}>
            <TbTrash size={16} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <section>
            <table>
              <tbody>
                <tr>
                  <td>No</td>
                  <td>: {no}</td>
                </tr>
                <tr>
                  <td>Action ID</td>
                  <td>: {actionTask.trx_code}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>: {ACTION_STATUS[actionTask.status ?? 1]}</td>
                </tr>
                <tr>
                  <td>Action</td>
                  <td>: {actionTask.mr_action_task_name ?? ""}</td>
                </tr>
                <tr>
                  <td>Assign To</td>
                  <td>: {actionTask.assign_to_name}</td>
                </tr>
                <tr>
                  <td>Action Date</td>
                  <td>
                    :{" "}
                    <span className="text-red-500">
                      {actionTask?.date_start
                        ? moment(actionTask?.date_start).format("ddd, DD MMM YYYY")
                        : ""}
                    </span>{" "}
                    |{" "}
                    <span className="text-blue-500">
                      {actionTask?.date_end
                        ? moment(actionTask?.date_end).format("ddd, DD MMM YYYY")
                        : ""}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="font-bold mt-5">Description: </div>
            <p className="mb-3">{actionTask.description}</p>
          </section>

          <section className="mt-3">
            {actionTask?.task_action_files?.length ? (
              <PicturesSection
                height="250px"
                dataPicture={
                  generateAsetFile(actionTask?.task_action_files ?? []) ?? []
                }
              />
            ) : null}
          </section>
        </div>

        <div className="w-full flex flex-col gap-1 justify-end items-end mt-3 text-sm">
          <span>
            C:{" "}
            {actionTask.create_at
              ? moment(actionTask.create_at).format("DD/MM/YYYY")
              : "-"}{" "}
            {actionTask.create_by_name ? `| ${actionTask.create_by_name}` : ""}
          </span>
          <span>
            M:{" "}
            {actionTask.update_at
              ? moment(actionTask.update_at).format("DD/MM/YYYY")
              : "-"}{" "}
            {actionTask.update_by_name ? `| ${actionTask.update_by_name}` : ""}{" "}
          </span>
        </div>


      </div>

      {modalUpdate.isActive && (
        <ModalActionLine
          task={task}
          id={actionTask.task_action_id}
          modalState={modalUpdate}
          taskAction={taskAction}
          taskProgress={taskProgress}
        />
      )}

      <ModalHistoryActionLine
        taskId={actionTask.task_id}
        taskActionId={actionTask.task_action_id}
        modalState={modalHistory}
      />

      <ModalDeleteActionLine
        data={actionTask}
        modalState={modalDelete}
        onSuccess={() => {
          modalUpdate.setActive(false)
          taskAction.refetch()
          taskProgress.refetch()
        }}
      />
    </Fragment>
  )
}
