/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";
import { DetailCustomerQuotation } from "../../types";

type UseDetailCustomerQuotation = {
  customer_quotation_id: string;
  onSuccess?: (data: DetailCustomerQuotation) => void;
};

type Response = {
  payload: DetailCustomerQuotation
};

export function useDetailCustomerQuotation({
  customer_quotation_id,
  onSuccess
}: UseDetailCustomerQuotation) {
  const api = useApi();
  const [data, setData] = useState<DetailCustomerQuotation | null>(null);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    const params = {
      customer_quotation_id: customer_quotation_id
    }

    api
      .get(`/customer-quotation/${params.customer_quotation_id}`, { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data);
        setData(data);
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    if (customer_quotation_id) {
      fetchData();
    }

    return () => {
      setData(null);
      setLoading(false);
    };
  }, [customer_quotation_id]);

  return { data, isLoading, refetch };
}
