import { Button, Modal } from "components"
import { FormProvider, useForm } from "react-hook-form"
import { DataList } from "../types"
import { FormSection } from "./FormSection"
import { menuTitle } from "./ChildItem/components/Item/components"
import { useApi } from "utils"
import { useContext, useEffect } from "react"
import { Render, useCode, validationSchema } from "../utils"
import toast from "react-hot-toast"
import { yupResolver } from "@hookform/resolvers/yup"

export function CreateModal(props: { mr_comparation_code?: string; mr_comparation_name?: string; description?: string; toggle: () => void; refetch?: () => void }) {
  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_comparation_code", dataCode?.code)
    }
  // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_comparation_id">

      const onSubmit = (value: CreateData) => {
        return new Promise<void>((resolve) => {
          toast.promise(
            api.post("/comparation/add", value),
            {
              loading: "Loading...",
              success: (res) => res.data.message,
              error: (err) => err.response.data.message ?? err.response.message
            }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => { }).finally(resolve)
    })
  }

  const methods = useForm<CreateData>({
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`SETUP | ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}