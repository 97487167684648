import { useEffect, useState } from "react";
import { useApi } from "../../../../../../utils";
import { LinkAccountType } from "../../types";
import { SuccessFetch } from "types";

export function useList() {
    const api = useApi()
    const [dataAccount, setData] = useState<LinkAccountType[]>([])
    const [isLoading, setLoading] = useState(true)

    const fetchUrl = () => {
        return api.get("/linkedaccountpos/")
    }

    const fetchData = () => {
        fetchUrl().then((res: SuccessFetch<{
            payload: {
                linked_account_pos_id: number
                location_id: number,
                coa_id: number
                coa_code: string
                coa_name: string
                group_coa_code: string
                group_coa_name: string
                trx: number
            }[]
        }>) => {
            setData(res.data.payload)
        }).catch(() => {
            setData([])
        }).finally(() => {
            setLoading(false)
        })
    }

    const refetch = () => fetchData()

    useEffect(() => {
        fetchData()

        return () => {
            setData([])
            setLoading(true)
        }

        // eslint-disable-next-line
    }, [])

    return { dataAccount, isLoading, refetch }
}