import { Fragment, useState } from "react"
import { Card, Loading, Select, PaginationData, TableNumber, Button, TableHeaderSort, Input, BaseTable, PermissionLink, Checkbox } from "../../../../components"
import { useList } from "../../Transaction/AddNewItem/utils"
import { Delete, Update } from "../../Transaction/AddNewItem/components/ChildItem/components/Item/components"
import { useDefaultSize } from "../../Transaction/AddNewItem/components/ChildItem/components/Item/utils"
import { ProductList } from "../../Transaction/AddNewItem/types"
import { convertNumber, default_pagination_value, useMultipleSelect } from "utils"
import { useProductGroup } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"
import { ReactBarcode } from 'react-jsbarcode';
import debounce from "lodash/debounce"
import { TbChevronRight } from "react-icons/tb"
import { PicturesSection, Print } from "./components"
import { IoSendSharp } from "react-icons/io5"
import { PrintItemContext } from "./contexts"

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  pages: number;
  keyword: string;
  group_id?: string;
  sort?: string;
}
export function ItemsGroup() {
  // Hooks
  const multiple = useMultipleSelect()
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const dataList: ProductList[] = data.items

  const dataLength = data.pagination_data.size
  const pageCurrent = pagination.page
  const limit = pagination.limit
  const totalData = pagination.total
  const onDebounce = debounce((e: any) => setPagination(prev => { return { ...prev, keyword: e.target.value } }))

  const header: any[] = [
    { title: "BUILD", sort: false },
    { title: "ITEM CODE", sort: true },
    { title: "PICTURES", sort: false },
    { title: "ITEM NAME", sort: true },
    { title: "BUY PRICE", sort: true, align: 'right' },
    { title: "BUILD PRICE", sort: true, align: 'right' },
    { title: "SELLING PRICE", sort: true, align: 'right' },
    { title: "SOH", sort: true, align: 'right' },
    { title: "UNIT", sort: true, align: 'right' }
  ]

  const sortMapping: Record<string, string> = {
    'ITEM CODE': 'code',
    'ITEM NAME': 'name',
    'BUY PRICE': 'buyprice',
    'BUILD PRICE': 'buildprice',
    'SELLING PRICE': 'sellprice',
    'SOH': 'soh',
    'UNIT': 'unitconv'
  }

  const { dataDefault } = useDefaultSize()
  const { dataProduct, isLoadingProduct } = useProductGroup()

  const refreshPage = () => {
    setPagination({ ...pagination, page: 1, group_id: '', sort: '' })
    refetch()
  }

  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }

  return (
    <Fragment>
      <section className="container my-2"> <>
        <section className="mt-2">
          <Card>
            <Card.Body>
              <div className="flex justify-end gap-3">
                <PrintItemContext.Provider
                  value={{
                    items: dataList,
                    selectedItem: multiple.item
                  }}
                >
                  <Print />
                </PrintItemContext.Provider>

                <PermissionLink permission="IR011" to={"/inventory/transaction/add-new-item"}>
                  <Button permission="IR011" color="primary">CREATE NEW ITEM <TbChevronRight /> </Button>
                </PermissionLink>
              </div>
              <div className="lg:flex lg:justify-between items-center">
                <div>
                  <Input
                    label="SEARCH"
                    className="lg:w-[300px] md:w-full"
                    placeholder="Search data..."
                    onChange={onDebounce}
                  />
                </div>
                <div className="lg:flex lg:items-end">
                  <Select
                    label="PRODUCT GROUP"
                    placeholder="Select product group here..."
                    className="lg:w-[300px] w-full me-2 mb-2"
                    width={true}
                    defaultValue={{ value: '0', label: 'All' }}
                    isLoading={isLoadingProduct}
                    options={[{ label: "All", value: "" }].concat(dataProduct)}
                    value={dataProduct.find(item => item.value === pagination?.group_id)}
                    onChange={e => setPagination({ ...pagination, group_id: e?.value ?? '', page: 1 })}
                  />
                  <div className="w-full">
                    <Button
                      className="uppercase sm:rounded-lg border-white hover:border-primary hover:bg-primary hover:text-black mb-2 w-full"
                      onClick={() => refreshPage()}
                    >
                      Lastest Item
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </section>
        <section className="mt-2">
          <Card>
            <Card.Body>
              <div className="overflow-x-auto">
                <BaseTable className="border-collapse">
                  <thead>
                    <tr>
                      <th>NO</th>
                      <th></th>
                      <th colSpan={2}>BSI</th>
                      <TableHeaderSort
                        title={header}
                        onSort={(item, sortType) => handleSort(item, sortType)}
                      />
                      <th>ACTION</th>
                    </tr>
                  </thead>

                  <tbody>
                    {isLoading
                      ? <tr><td colSpan={13} className="text-center"><Loading loading /></td></tr>
                      : <>
                        {dataList.length === 0
                          ? <tr><td colSpan={13} className="text-center"><Loading errorText="No data available" /></td></tr>
                          : <Fragment>
                            {dataList.map((item: any, key: any) => (
                              <tr>
                                <td>{TableNumber({ dataLength: dataLength, pageCurrent: pageCurrent, index: key, limit: limit, totalData: totalData })}</td>
                                <td>
                                  <Checkbox
                                    value={Boolean(multiple.item.find(i => i === item.product_id.toString()))}
                                    onClick={() => multiple.handleData(item.product_id.toString())}
                                  />
                                </td>
                                <td>
                                  <PermissionLink
                                    permission="IR012"
                                    to="/inventory/transaction/add-new-item/update"
                                    state={{ dataId: item, dataSize: dataDefault! }}
                                  >
                                     <IoSendSharp />
                                  </PermissionLink>
                                </td>
                                <td className="text-center">{`${item.buy ? 'B' : ''}${item.sell ? 'S' : ''}${item.inventory ? 'I' : ''}`}</td>
                                <td className="text-center"><input type="checkbox" className="checkbox checkbox-primary" checked={item.build_item} disabled /></td>
                                <td>
                                  <ReactBarcode options={{ width: 0.5, fontSize: 11, height: 23 }} value={`${item.product_barcode}`} />
                                </td>
                                <td>
                                  {item?.pictures && (
                                    <div className="w-[400px] lg:w-[200px]">
                                      <PicturesSection dataPicture={item?.pictures} items={1} className="h-[100px] w-full" />
                                    </div>
                                  )}
                                </td>
                                <td>{item.product_name}</td>
                                <td className="text-right">{!item.build_item && item?.price_unit_convention ? convertNumber(parseFloat(item?.price_unit_convention))?.intoCurrency : "-"}</td>
                                <td className="text-right">{item.build_item && item.price_unit_convention ? convertNumber(parseFloat(item?.price_unit_convention))?.intoCurrency : "-"}</td>
                                <td className="text-right">{item.price_retail ? convertNumber(parseFloat(item?.price_retail))?.intoCurrency : "-"}</td>
                                <td className="text-right">{item.soh ?? '-'}</td>
                                <td className="text-right">{item.unit_convention}</td>
                                <td>
                                  <section className="join flex justify-center">
                                    <Update id={item} size={dataDefault!} />
                                    <Delete id={item} refetch={refetch} />
                                  </section>
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        }
                      </>
                    }
                  </tbody>
                </BaseTable>
              </div>
              <div className="mt-5">
                <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
              </div>
            </Card.Body>
          </Card>
        </section>
      </>
      </section>
    </Fragment>
  )
}