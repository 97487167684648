import { Fragment } from "react"
import { ActionButton, Button, Modal } from "./../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useApi, useToggle } from "./../../../../../utils"
import { IoIosWarning } from "react-icons/io"; 
import { TransferType } from "../types"

type DeleteType = {
  id: TransferType 
  refetch?: () => void
  permission?: boolean
}
type ModalSectionType = {
  id: TransferType
  toggle: () => void
  refetch?: () => void
}
type DataFormType = {
  data?: TransferType
  id: TransferType
  toggle: () => void
  refetch?: () => void
}

export function Delete({ id, refetch }: DeleteType) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete permission="IR014" className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={id} toggle={toggle} refetch={refetch} />}
    </Fragment>
  )
}

function ModalSection({ id, toggle, refetch }: ModalSectionType) {
  return (
    <Modal isOpen title="DELETE ITEM TRANSFER" size="xl" closeModal={toggle}>
      <DataForm id={id} toggle={toggle} refetch={refetch} />
    </Modal>
  )
}

function DataForm({ data, id, toggle, refetch }: DataFormType) {
  const api = useApi()

  const onSubmit = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete(`/transfer/delete/${id.transfer_id}`),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        toggle()
        refetch && refetch()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({ defaultValues: data })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h5 className="text-error px-3">Are you sure want to delete this data?</h5>

        <IoIosWarning className="mx-auto mt-5 text-[200px] text-primary" />

        <Modal.Footer className="text-end">
          <Button type="submit" color="error" permission="IR014" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}