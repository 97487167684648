import { PropsWithChildren } from "react";
import { PiArrowDownBold, PiArrowUpBold } from "react-icons/pi";

export function CardSection(props: PropsWithChildren<{
    label: string,
    className?: string,
    value?: string | number | undefined,
    subLabel?: string,
    persentase?: number | undefined,
    icon?: JSX.Element
}>) {


    return (
        <div className={`${props.className}`}>
            <div>
                <div className="text-md text-slate-600 font-bold flex items-center gap-2">
                    <div>
                        {props.label}
                    </div>
                    {props.icon && 
                    <div className="text-[18px]">
                        {props.icon}
                    </div>}
                </div>
                {props.value && <div className="text-2xl font-bold">{props.value}</div>}
                {props.persentase !== undefined && (
                    <div className="flex items-center gap-1">
                    {props.persentase < 0 ?
                        <PiArrowDownBold className="text-red-500" />
                        : (props.persentase && props.persentase > 0 ? <PiArrowUpBold className="text-green-500" />
                            : '-')
                    }
                    <span>{props.subLabel}</span>
                </div>
                )}
            </div>
            {props.children}
        </div>
    )
}