// Third-Party Libraries
import moment from "moment"

export function getDateFilter(): {
  fromDate: string
  label: string
  toDate: string
}[] {
  return [
    {
      label: "Date",
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    },
    {
      label: "Month",
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().endOf("month").format("YYYY-MM-DD")
    },
    {
      label: "Quarter",
      fromDate: moment().startOf("quarter").format("YYYY-MM-DD"),
      toDate: moment().endOf("quarter").format("YYYY-MM-DD")
    },
    {
      label: "Year",
      fromDate: moment().startOf("year").format("YYYY-MM-DD"),
      toDate: moment().endOf("year").format("YYYY-MM-DD")
    }
  ]
}