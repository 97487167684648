import { useApi } from "utils";
import { ApproveType, FilterState } from "../../types";
import { useEffect, useState } from "react";
import { SuccessFetch } from "types";

interface DataType {
    payload: {
        listpaymentbill: ApproveType[],
        pagination_data: {
            total: number
            page: number
            size: number
            pages: number
        }
    }
}

export function useApprovalList({ approval_status, page, limit }: FilterState) {

    const defaultValue = {
        payload: {
            listpaymentbill: [],
            pagination_data: {
                page: 0,
                pages: 0,
                size: 0,
                total: 0
            }
        }
    }

    const api = useApi();
    const [data, setData] = useState<DataType>(defaultValue);
    const [isLoading, setLoading] = useState(true)

    const fetchUrl = () => {
        return api.post(`/paymentbill/getdata?Approval=${approval_status}&page=${page}&limit=${limit}`)
    }

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<DataType>) => {
            setData(res.data);
        }).catch(() => {
            setData(defaultValue);
        }).finally(() => {
            setLoading(false);
        });
    }

    const refetch = () => fetchData();

    useEffect(() => {
        fetchData();

        return () => {
            setData(defaultValue);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [approval_status, page, limit]);

    return { data, isLoading, refetch }
}