import { Input, NumberInput, Select } from "components"
import { SellingList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { DisableField } from "./DisableField"
import { useProductUnit, useTax } from "../../../../../FormSection/utils"
// import { usePriceUnit } from "../../utils"
import { useEffect } from "react"
import { convertNumber } from "utils"

interface DiskonProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    totalValueProduction: number 
    checkLoading?: boolean
    discountAmound?: string
    productId: number
    setCheckLoading: ( newState: boolean ) => void
}
export const Diskon = ({ action, totalValueProduction, checkLoading, discountAmound, productId, setCheckLoading }: DiskonProps) => {
    const { control, setValue } = useFormContext<SellingList>()

    const { dataTax, isLoadingTax } = useTax()
    const { dataUnit, isLoadingUnit, refetchUnit } = useProductUnit("name")
    // const { dataPriceUnit, isLoadingPriceUnit } = usePriceUnit(productId!)

    const discount_amound = useWatch({control, name: "discount_amound"})
    const price_retail = useWatch({control, name: "price_retail"})
    const overhead = useWatch({control,name: "overhead"})
    const salesTax = useWatch({control, name: "sales_tax"})
    const otherCahnges = useWatch({control, name: "other_changes"})
    const quantity = useWatch({control, name: 'quantity'})
    const quantity_unit_sell_convention = useWatch({control, name: 'quantity_unit_sell_convention'})

    const retailPrice = parseFloat(price_retail ?? 0) * (quantity_unit_sell_convention ?? 0)
    const amoundPrice = (parseFloat(discountAmound ?? discount_amound!)/100) * parseFloat(price_retail)
    const valueOverhead =  (overhead) ? totalValueProduction * parseFloat(String(overhead)) / 100 : 0
    const valueSalesTax = (salesTax) ? totalValueProduction * parseFloat(String(salesTax)) / 100 : 0
    const valueOtherChanges = (otherCahnges) ? totalValueProduction * parseFloat(String(otherCahnges)) / 100 : 0
    const cogm = (valueOverhead ?? 0) + (valueSalesTax ?? 0) + (valueOtherChanges ?? 0) + totalValueProduction
    const persentasePriceRetail = Math.round(price_retail ? ((parseFloat(String(price_retail)) - cogm ) / cogm) * 100 : 0)
    const sellingProfit = (parseFloat(price_retail) - cogm) * (quantity_unit_sell_convention ?? 0)

    useEffect(() => {
        if (checkLoading) {
          const timeout = setTimeout(() => {
            setCheckLoading(false);
            checkLoading && refetchUnit()
          }, 1000);
    
          return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line
    }, [checkLoading, isLoadingUnit])

    return (
        <div className="w-[560px]">
            <section>
                {/* SELLING UNIT OF MEASURE */}
                <div className="flex justify-between items-center mb-2">
                    <div><b>SELLING UNIT MEASURE</b></div>
                    <div>
                        <Controller
                            control={control}
                            name="mr_unit_id"
                            render={({ field, fieldState }) => {
                                return(
                                    <Select
                                        className="w-[220px]"
                                        placeholder="Select Selling Unit"
                                        isLoading={isLoadingUnit}
                                        error={fieldState.error?.message}
                                        options={dataUnit}
                                        value={dataUnit.find(item => item.value === field.value?.toString())} 
                                        onChange={e => {
                                            field.onChange(e?.value)
                                            setValue("unit_sell_measure_id", Number(e?.value))
                                        }}
                                        isDisabled={action === "DETAIL"}
                                    />
                                )
                            }}
                        />
                    </div>
                </div>

                {/* NUMBER OF UNIT */}
                <div className="flex justify-between items-center mb-2">
                    <div><b>NUMBER OF UNIT</b></div>
                    <div>
                        <Controller
                            control={control}
                            name="quantity_unit_sell_convention"
                            render={({ field }) =>
                                <NumberInput
                                    inputClass="w-[220px]"
                                    decimalSeparator=','
                                    value={field.value ?? ""} 
                                    onValueChange={value => {
                                        field.onChange(value.floatValue)
                                        setValue("quantity_unit_sell_measure", Number(quantity))
                                    }}
                                    disabled={action === "DETAIL"}
                                />
                            }
                        />
                    </div>
                </div>

                {/* RETAIL PRICE */}
                <div className="flex justify-between items-center mb-2">
                    <div><b>RETAIL PRICE</b></div>
                    <div>
                        {/* <NumberInput
                            inputClass="w-[220px]"
                            decimalSeparator=','
                            value={retailPrice ?? ""}
                            disabled
                        /> */}
                        <DisableField width="220px" className="text-[19px]"><b>{retailPrice ? convertNumber(retailPrice)?.intoCurrency : "0"}</b></DisableField>
                    </div>
                </div>

                {/* SELLING PROFIT */}
                <div className="flex justify-between items-center mb-2">
                    <div><b>SELLING PROFIT</b></div>
                    <div className="join">
                        {action === "DETAIL" 
                            ? <Input inputClass="w-[110px] !rounded-r-none" value={`${persentasePriceRetail ?? ''} %`} disabled />
                            : <DisableField width="110px" className="!rounded-r-none">{`${persentasePriceRetail ? persentasePriceRetail : '0'} %`}</DisableField>
                        }
                        {action === "DETAIL" 
                            ? <Input inputClass="w-[110px] !rounded-l-none" value={sellingProfit ? convertNumber(sellingProfit)?.intoCurrency : "0"} disabled />
                            : <DisableField width="110px" className="!rounded-l-none">{sellingProfit ? convertNumber(sellingProfit)?.intoCurrency : "0"}</DisableField>
                        }
                    </div>
                </div>

                {/* DISCONTED */}
                <div className="flex justify-between items-center mb-2">
                    <div><b>DISCONTED</b></div>
                    <div className="join">
                        <Controller
                            control={control}
                            name="discount_amound"
                            render={({ field }) =>  <> { action === "DETAIL" 
                                ? <Input {...field} value={`${discountAmound?.toString().replace('.', ',') ?? field?.value?.toString().replace('.', ',') ?? ''} %`} inputClass="w-[110px] !rounded-r-none" disabled /> 
                                : <DisableField width="110px" className="!rounded-r-none">{`${discountAmound?.toString().replace('.', ',') ?? field?.value?.toString().replace('.', ',') ?? ''} %`}</DisableField>
                            } </> }
                        />
                        {action === "DETAIL" 
                            ? <Input inputClass="w-[110px] !rounded-l-none" value={amoundPrice ? convertNumber(amoundPrice)?.intoCurrency : "0"}  disabled />
                            : <DisableField width="110px" className="!rounded-l-none">{amoundPrice ? convertNumber(amoundPrice)?.intoCurrency : "0"}</DisableField>
                        }
                    </div>
                </div>

                {/* NUMBER OF ITEMS PER SELLING UNIT */}
                {/* <div className="flex justify-between items-center mb-2">
                    <div><b>NUMBER OF ITEMS PER SELLING UNIT</b></div>
                    <div>
                        <Controller
                            control={control}
                            name="quantity"
                            render={({ field }) =>
                                <NumberInput
                                    decimalScale={0}
                                    value={field.value}
                                    inputClass="w-[220px]"
                                    onChange={(e) => field.onChange(e.target.value)}
                                    disabled={action === "DETAIL"}
                                />
                            }
                        />
                    </div>
                </div> */}

                {/* SOH UNIT OF CONVERTION - */}
                {/* <div className="flex justify-between items-center mb-2">
                    <div><b>SOH UNIT OF CONVERTION</b></div>
                    <div>
                        <Select
                            className="w-[220px]"
                            placeholder="Select Unit"
                            isLoading={isLoadingUnit || isLoadingPriceUnit}
                            options={dataUnit}
                            value={dataPriceUnit?.unit_convention_id ? dataUnit.find(item => item.value === dataPriceUnit?.unit_convention_id?.toString()) : undefined} 
                            isDisabled
                        />
                    </div>
                </div> */}

                {/* TAX CODE WHEN SOLD */}
                <div className="flex justify-between items-center mb-2">
                    <div><b>TAX CODE WHEN SOLD</b></div>
                    <div>
                        <Controller
                            control={control}
                            name="tax_id"
                            render={({ field, fieldState }) => {
                                return(
                                <Select
                                    className="w-[220px]"
                                    placeholder="Select Tax"
                                    isLoading={isLoadingTax}
                                    error={fieldState.error?.message}
                                    options={dataTax}
                                    value={dataTax.find(item => item.value === field.value?.toString())}
                                    onChange={e => field.onChange(e?.value)}
                                    isDisabled={action === "DETAIL"}
                                />
                            )}}
                            />
                    </div>
                </div>
            </section>
        </div>
    )
}