/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { today, useApi } from "utils"
import { useDetailInvoice } from "../Invoice/utils/hooks/useDetailInvoice"
import { FormSection } from "./components"
import { paymentInvoiceSchema, PaymentInvoiceType } from "./types"
import { submitMap } from "./utils/functions"

export default function PaymentInvoice() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const invoice = useDetailInvoice({
    invoice_id: params?.invoice_id ?? ""
  })
  const defaultValues: PaymentInvoiceType = useMemo(() => {
    const balanceInvoice = invoice.data?.datainvoice.balance ?? 0
    const totalDeposit = invoice.data?.datainvoice.total_deposit ?? 0

    return {
      invoice_id: invoice.data?.datainvoice.invoice_id,
      customer_id: invoice.data?.datainvoice.customer_id ?? NaN,
      location_id: invoice.data?.datainvoice.location_id ?? NaN,
      coa_id: NaN,
      coa_name: "",
      coa_balance: 0,
      balance_invoice: balanceInvoice,
      balance: 0,
      link: invoice.data?.datainvoice?.trx_code ?? "",
      trx_code: "",
      transaction_date: today(),
      deposit_amount: totalDeposit,
      payment_amount: 0,
      memo: "Payment Invoice transaction",
      shipped: invoice.data?.datainvoice?.address ?? "",
      referensi: ""
    }
  }, [invoice.data])

  const onSubmit = async (value: PaymentInvoiceType) => {
    await toast
      .promise(api.post("/paymentinvoice/add", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate(-1)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<PaymentInvoiceType>({
    defaultValues,
    resolver: yupResolver(paymentInvoiceSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
