import { Button, DateInput, Input, Modal } from "components"
import moment from "moment"
import { Fragment, useState } from "react"
import { toast } from "react-hot-toast"
import { useApi, useToggle } from "utils"

type ModalDeleteProps = {
  data: {
    approval_status: number,
    customer_quotation_id: number
    transaction_date: string
    trx_code: string
    customer_id: number
    customer_name: string
  }
  modalState: ReturnType<typeof useToggle>
  onSuccess?: () => void
}

export const ModalDelete = ({
  data,
  modalState,
  onSuccess
}: ModalDeleteProps) => {
  const [loadingData, setLoadingData] = useState(false)
  const api = useApi()

  const handleDeleteData = async () => {
    setLoadingData(true)
    await toast
      .promise(api.delete(`customer-quotation/${data?.customer_quotation_id}`), {
        loading: "Loading...",
        success: (res) => res?.data?.message,
        error: (err) => err.response?.data?.detail?.message
      })
      .then(() => {
        modalState.setActive(false)
        onSuccess?.()
      })
      .finally(() => {
        setLoadingData(false)
      })
  }

  return (
    <Modal
      isOpen={modalState.isActive}
      title="DELETE CUSTOMER QUOTATION"
      size="xl"
      closeModal={modalState.toggle}>
      <Modal.Body>
        <Fragment>
          <small className="text-lg font-medium text-red-400">
            Are you sure want to delete this data?
          </small>

          <Input disabled label="CUSTOMER QUOTATION ID" defaultValue={data.trx_code} />

          <DateInput
            disabled
            label="DATE"
            selected={moment(data.transaction_date).toDate()}
            onChange={() => null}
          />

          <Input disabled label="CUSTOMER" defaultValue={data.customer_name} />
        </Fragment>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          disabled={loadingData}
          loading={loadingData ? "true" : undefined}
          onClick={handleDeleteData}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
