// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { FetchType, PurchaseOrderType } from "pages/Purchase/Transaction/PurchaseOrder/types"

// Utils
import { useApi } from "utils"

export function useDetail(id: string) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<PurchaseOrderType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/purchaseorder/detailpo", {
      params: { po_id: id }
    }).then((res: SuccessFetch<{
      payload: FetchType
    }>) => {
      // Vars
      const result = res.data.payload

      setData({
        ...result.datapo,
        _vendor_name: result.datapo.vendor_name,
        location_id: result.datapo.location_id.toString(),
        payment_term: result.datapo.payment_term.toString(),
        vendor_id: result.datapo.vendor_id.toString(),
        podetail: result.detailpo.map(item => {
          return {
            ...item,
            mr_unit_id: item.mr_unit_id.toString(),
            location_id: item.location_id.toString(),
            product_id: item.product_id.toString(),
            project_id: item.project_id.toString(),
            tax_id: item.tax_id.toString()
          }
        }),
        reminder: result.reminder
      })
    }).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}