/* eslint-disable react-hooks/exhaustive-deps */
import { Label, Select } from "components"
import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TaskType } from "../types"
import { useSubGroupList } from "../utils/hooks/useSubGroupTaskList"

type DropdownSubGrupProps = {
  approvalView?: boolean
  isDisabled?: boolean
  groupTaskId?: string
}

export const DropdownSubGrup = ({
  approvalView,
  isDisabled,
  groupTaskId
}: DropdownSubGrupProps) => {
  const { control } = useFormContext<TaskType>()
  const subGroup = useSubGroupList()

  const dropdown = useMemo(
    () =>
      groupTaskId
        ? subGroup?.dropdown.filter(
            (item) => item.group_task_id.toString() === groupTaskId
          )
        : subGroup?.dropdown ?? [],
    [groupTaskId]
  )

  return (
    <section>
      <Label text="SUBGROUP" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="mr_group_sub_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Sub Group"
              isLoading={subGroup?.isLoading}
              isDisabled={isDisabled || approvalView}
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                      (item) => item.value === field.value?.toString()
                    )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
