import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  mr_product_group_code?: string
  mr_product_group_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_group_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_group_id"> 

  const defaultValues:CreateData= {
    mr_product_group_code: "",
    mr_product_group_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productgroup/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`When setting up data attributes for "Product/Item Group" in an ERP system, it's important to clearly define these attributes to effectively categorize and manage different groups of products or items. Grouping products helps streamline inventory management, enhances reporting, and supports targeted marketing and sales strategies. Here's a comprehensive explanation of the data attributes for "Product/Item Group":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each product group."
                      },
                      {
                        label: "Purpose",
                        value: "The code facilitates quick identification and reference of product groups within the ERP system. It simplifies data entry, search, and analysis by providing a concise way to access group-specific information, ensuring consistency and preventing duplication across the system."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "PG01" for "Electronics," "PG02" for "Clothing," or "PG03" for "Furniture" enable users to quickly differentiate between major product categories.`
                      }
                    ]
                  },
                  {
                    label: "Product Group",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the group that categorizes similar types of products or items together."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute helps organize inventory into logical groups based on similarities in product type, use, or other characteristics. It is essential for managing inventory levels, streamlining purchasing and sales processes, and facilitating targeted marketing and promotions."
                      },
                      {
                        label: "Example",
                        value: `"Electronics" — Includes all electronic devices such as smartphones, laptops, and televisions. 
                                "Clothing" — Comprises all apparel items, including dresses, shirts, and trousers. 
                                "Furniture" — Encompasses items such as sofas, beds, and tables. 
                                "Tools and Hardware" — Contains all tools and hardware supplies like drills, hammers, and nails.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product group."
                      },
                      {
                        label: "Purpose",
                        value: "The description elaborates on the characteristics, common uses, or specific considerations of the product group. It assists users in understanding the scope of each group and helps ensure that products are categorized correctly for operational and strategic purposes."
                      },
                      {
                        label: "Example",
                        value: `For "Electronics," the description might be: "All electronic goods, including but not limited to communication devices, computers, and entertainment systems. Products in this group typically require specific storage conditions and have high value." 
                                For "Clothing," it could read: "Apparel items for all ages and genders. Includes seasonal and fashion-dependent products that require frequent updates." 
                                For "Furniture," it might state: "Large household items designed for residential and commercial spaces. Includes a variety of materials and styles, often requiring special handling and storage conditions." 
                                For "Tools and Hardware," it might note: "Comprehensive range of manual and power tools, as well as necessary installation and construction hardware. Essential for maintenance, repair, and construction activities."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are crucial for managing product groups effectively in the ERP system. They ensure that products are organized in a manner that supports efficient inventory management, facilitates accurate reporting, and enhances operational decision-making."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}