// Utils
import { usePermissionRoute } from "utils"

export function Toggle(params: {
  className?: string
  isChecked?: boolean
  label?: string
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  permission?: string
}) {
  // Hooks
  const permissionRoute = usePermissionRoute()

  return (
    <label className="label flex items-center gap-1 cursor-pointer tooltip"  title="Soft Delete">
      <input
        type="checkbox" 
        className={`toggle ${params.className}`}
        checked={params.isChecked}
        onClick={(e) => {
          if (Boolean(!params.permission || Boolean(params.permission && permissionRoute.checkPermission(params.permission))) && params.onClick) {
            params.onClick(e)
          }
        }}
      />

      {params.label && <span className="label-text">{params.label}</span>}
    </label>
  )
}