// React
import { useContext } from "react"

// Components
import { CreatePage } from "pages/Card/Transaction/CreateNewEmployee/components"

// Contexts
import { IframeContext } from "contexts"

export function CreateNewEmployee(): JSX.Element {
  // Hooks
  const { onClose } = useContext(IframeContext)

  return (
    <CreatePage
      asModal
      onSuccess={onClose}
    />
  )
}