import { HeaderMenu } from "components";
import { Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormAction } from "../FormAction";
import { FormSection } from "../FormSection";
import { today } from "utils";
import { useDetailOpeningStock } from "../../utils";
import { FormType } from "../../utils/vars";

export type FormSectionProps = {
    opening_stock_id: number,
    isEdit?: boolean
}

export type DefaultValues = Pick<FormType, 'transaction_date' | 'location_id' | 'product_id' | 'mr_unit_id' | 'av_price_unit' | 'soh'>

export function DetailOpeningStock({
    opening_stock_id,
    isEdit = false
}: FormSectionProps){

    const openingDetail = useDetailOpeningStock({
        id_opening_stock: Number(opening_stock_id)
    })

    const defaultValues: DefaultValues = {
        transaction_date: today(),
        location_id: '',
        product_id:  '',
        mr_unit_id:  '',
        av_price_unit: '',
        soh: ''
    }

    const methods = useForm<DefaultValues>({
        defaultValues: defaultValues
    })

    useEffect(() => {
        if (openingDetail.data[0]) {
            methods.setValue('location_id', openingDetail.data[0].location_id)
            methods.setValue('product_id', openingDetail.data[0].product_id)
            methods.setValue('mr_unit_id', openingDetail.data[0].mr_unit_id)
            methods.setValue('transaction_date', openingDetail.data[0].transaction_date)
            methods.setValue('av_price_unit', openingDetail.data[0]?.av_price_unit ?? '')
            methods.setValue('soh', Number(openingDetail.data[0].soh) % 1 === 0 ? Math.floor(Number(openingDetail.data[0].soh)).toLocaleString("id-ID") : openingDetail.data[0].soh.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''))
        }
        // eslint-disable-next-line
    }, [openingDetail.data[0]])



    return (
        <Fragment>
            <section className="container my-5">
                <section>
                    <HeaderMenu title="DATA ENTRY | OPENING STOCK" />
                    <section className="mt-2">
                        <section className="mt-2">
                            <FormProvider {...methods}>
                                <FormAction
                                    isEdit={isEdit}
                                >
                                    <FormSection />
                                </FormAction>
                            </FormProvider>
                        </section>
                    </section>
                </section>
            </section>
        </Fragment>
    )
}