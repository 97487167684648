// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import axios from "axios"

// Types
import type { SuccessFetch } from "types"
import type { DataCountType } from "pages/Inventori/Transaction/types"
import type { FormType } from "../vars"

// Utils
import { useApi } from "utils"
import { getValuesByDifferent } from "pages/Inventori/Transaction/utils"

export function useDataAdjustmentDetail(adjustment_id: string): {
  data: DataCountType | null
  formData: FormType | null
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataCountType | null>(null)
  const [formData, setFormData] = useState<FormType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)

    api.get(
      "/invadjustment/detailinvadjustment",
      { params: { adjustment_id } }
    ).then((res: SuccessFetch<{
      payload: {
        invcount: {
          count_id: number
          memo: string
          location_id: string
          transaction_date: string
          trx_code: string
        }[]
      }
    }>) => {
      // Vars
      const payload = res.data.payload
      const invCount = payload.invcount[0]

      axios.all([
        api.get(
          `/invcount/detailinvcount`,
          { params: { count_id: invCount.count_id } }
        ),
        api.get("/linkedaccount/bycode", {
          params: { linked_account_code: "LA10" }
        })
      ]).then(axios.spread((res, laRes) => {
        // Vars
        const data: SuccessFetch<{
          payload: {
            countdetail: {
              avg_price_use: string
              coa_asset_code: string
              coa_asset_id: number
              coa_asset_name: string
              coa_expense_code: string
              coa_expense_id: number
              coa_expense_name: string
              count_detail_id: number
              different: string
              mr_unit_id: number
              product_id: number
              quantity_unit_sell_convention: string
              real_count: number
              soh_count: number
            }[]
            invcount: {
              count_id: number
              employee_id: number
              employee_name: string
              location_id: number
              location_name: string
              memo: string
              transaction_date: string
              trx_code: string
            }[]
          }
        }> = res
        const laData: SuccessFetch<{
          payload: {
            coa_id: number
            coa_code: string
            coa_name: string
          }[]
        }> = laRes
        const payload = data.data.payload
        const laPayload = laData.data.payload[0]

        setFormData(invCount)
        setData({
          invcount: payload.invcount[0],
          countdetail: payload.countdetail.map(item => {
            // Vars
            const quantity_unit_sell_convention: number = parseInt(item.quantity_unit_sell_convention)
            const different: number = parseInt(item.different)
            const differentValue = getValuesByDifferent(different)
            const isCredit: boolean = differentValue.position === "credit"
  
            return {
              ...item,
              different,
              quantity_unit_sell_convention: quantity_unit_sell_convention > 0 ? quantity_unit_sell_convention : 1,
              avg_price_use: parseInt(item.avg_price_use),
              coa_inventory_code: item.coa_asset_code,
              coa_inventory_id: item.coa_asset_id,
              coa_inventory_name: item.coa_asset_name,
              coa_coex_code: isCredit ? laPayload.coa_code! : item.coa_expense_code,
              coa_coex_id: isCredit ? laPayload.coa_id! : item.coa_expense_id,
              coa_coex_name: isCredit ? laPayload.coa_name! : item.coa_expense_name,
              position: differentValue.position,
              stock_in: differentValue.stock_in,
              stock_out: differentValue.stock_out
            }
          })
        })
      })).catch(() => {
        setData(null)
        setFormData(null)
      }).finally(() => {
        setLoading(false)
      })
    }).catch(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setFormData(null)
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return {
    data,
    formData,
    isLoading
  }
}