// Types
import type { DataCountDetail } from "pages/Inventori/Transaction/types"

export function getAccValues(item: DataCountDetail): {
  amount_adjustment: number
  stock_in_unit: number
  stock_out_unit: number
} {
  return {
    amount_adjustment: item.avg_price_use * item.different * item.quantity_unit_sell_convention,
    stock_in_unit: item.stock_in * item.quantity_unit_sell_convention,
    stock_out_unit: item.stock_out * item.quantity_unit_sell_convention,
  }
}