export const getBasenamePath = () => {
  const pathName = window.location.pathname

  const splitPathName = pathName.split("/")

  const companyNamePath = splitPathName[1] // -> get company name in url path

  const isSubscribePath = splitPathName.includes("subscribe") // -> check if 'subscribe' in path

  return {
    basename: isSubscribePath ? '/guest' : `/${companyNamePath}`,
    companyName: companyNamePath
  }
}