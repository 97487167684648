import { useEffect, useState } from "react"
import type { SuccessFetch } from "types"
import { useApi } from "utils"

export function useAllId(): { data: number[], isLoading: boolean } {
  const api = useApi()
  const [data, setData] = useState<number[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get(
      "/sma/all-id"
    ).then((res: SuccessFetch<{ payload: number[] }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}