import { ErrorMessage } from "@hookform/error-message"
import { ActionButton, BaseTable } from "components"
import {
  useProjectList,
  useTaxCodeList
} from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useState } from "react"
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form"
import toast from "react-hot-toast"
import { convertNumber } from "utils"
import { FormSalesOrderType } from "../../types"
import { useDropdownProduct } from "../../utils/hooks"
import { DeleteConfirmation } from "../DeleteConfirmation"
import { JobsHeader } from "../JobsHeader"
import { TaxHeader } from "../TaxHeader"
import { RowCustomerOrder } from "./RowCustomerOrder"

type TableCustomerOrderProps = {
  approvalView?: boolean
  display?: boolean
  showActionButton?: boolean
}

export const TableCustomerOrder = ({
  approvalView = false,
  display = true,
  showActionButton = false
}: TableCustomerOrderProps) => {
  const methods = useFormContext<FormSalesOrderType>()
  const [editField, setEditField] = useState<number | null>(null)
  const [approval_status, price_level_name, location_id] = useWatch({
    control: methods.control,
    name: ["approval_status", "price_level_name", "location_id"]
  })
  const tax = useTaxCodeList()
  const jobs = useProjectList()
  const products = useDropdownProduct({ location_id })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "sodetail"
  })

  return (
    <div className={`${display ? "" : "hidden"} overflow-x-auto`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>-</th>
            <th>Order</th>
            <th>Received</th>
            <th>Backorder</th>
            <th>Item Code</th>
            <th>Description</th>
            <th>SOH</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Disc (%)</th>
            <th>Total</th>
            <JobsHeader approvalView={approvalView} project={jobs} />
            <TaxHeader approvalView={approvalView} tax={tax} />
            <th className={approvalView ? "hidden" : ""}>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {fields.map((arrField, key) => {
            return (
              <Controller
                key={arrField.id}
                control={methods.control}
                name={`sodetail.${key}`}
                render={({ field }) => {
                  if (editField === key) {
                    return (
                      <RowCustomerOrder
                        key={arrField.id}
                        tax={tax}
                        jobs={jobs}
                        products={products}
                        priceLevelName={price_level_name}
                        index={editField}
                        action="edit"
                        isHideForm={false}
                        defaultValues={field.value}
                        onCancelEditing={() => setEditField(null)}
                        onSubmit={(value) => {
                          field.onChange(value)
                          setEditField(null)
                        }}
                      />
                    )
                  } else {
                    return (
                      <tr>
                        <td className="text-center px-0">
                          {key + 1}
                        </td>
                        <td className="text-right">{field.value.qty_order}</td>
                        <td className="text-right">{field.value.received}</td>
                        <td className="text-right">
                          {field.value.qty_order - field.value.received}
                        </td>
                        <td>{field.value.product_barcode}</td>
                        <td>{field.value.product_name}</td>
                        <td className="text-right">{field.value.soh}</td>
                        <td>{field.value.mr_unit_name}</td>
                        <td className="text-right">
                          {
                            convertNumber(field.value.price_measure)
                              .intoCurrency
                          }
                        </td>
                        <td className="text-right">{field.value.discount}</td>
                        <td className="text-right">
                          {convertNumber(field.value.total).intoCurrency}
                        </td>
                        <td>{field.value.project_name}</td>
                        <td>{field.value.tax_name}</td>
                        <td className={approvalView ? "hidden" : ""}>
                          {Boolean(methods.getValues("approval_status") !== APPROVAL.APPROVE || showActionButton) && (
                            <div className="flex justify-center items-center">
                              <ActionButton.Update
                                onClick={() => setEditField(key)}
                              />

                              <DeleteConfirmation onDelete={() => remove(key)} />
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  }
                }}
              />
            )
          })}

          {Boolean(approval_status === APPROVAL.PENDING || showActionButton) && !approvalView && (
            <RowCustomerOrder
              key={fields.length}
              tax={tax}
              jobs={jobs}
              products={products}
              priceLevelName={price_level_name}
              action="add"
              onSubmit={(value, methods) => {
                if (!fields.find(item => item.product_id === value.product_id)) {
                  return append(value)
                }

                toast.error("Product is already on Sales Order detail List")

                methods?.setError("product_id",
                  { message: "Product is already on Sales Order detail List" },
                  { shouldFocus: true }
                )
              }}
            />
          )}
        </tbody>
      </BaseTable>

      <ErrorMessage
        errors={methods.formState.errors}
        name="sodetail"
        render={({ message }) =>
          <div className="mt-2">
            <p className="text-red-500" >{message}</p>
          </div>
        }
      />
    </div >
  )
}
