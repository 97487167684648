import { Fragment, useContext } from "react"
import type { DataList } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"
import { Render } from "../../../../../utils"


export function IsActive(props: {
    id: DataList
    refetch?: () => void
}) {
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: DataList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put("/productsize/changestatus",
                    {
                        mr_product_size_id: value.mr_product_size_id,
                        statusdel: !value.is_deleted
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
                toggle()
                props?.refetch && props.refetch()
            }).catch(() => {}).finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle 
                label="Soft Delete"
                isChecked={props.id.is_deleted} 
                onClick={() => {
                    onSubmit(props.id);
                }} />
        </Fragment>
    )
}