// React
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react"

interface Type extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string
  label?: string
  type?: string
  className?: string
  inputClass?: string
  textRight?: boolean
  textWhite?: boolean
}

export const Input = forwardRef((props: Type, ref) => {
  return (
    <label className={`form-control ${props.className}`}>
      {props.label && (
        <label className="label">
          <span className={`label-text font-bold ${props?.textWhite && "!text-[#cacccb]"} ${props.error && "text-error"}`}>{props.label}</span>
        </label>
      )}

      <input
        {...props}
        // @ts-ignore
        ref={ref}
        type={props?.type}
        placeholder={props.disabled ? "-" : props.placeholder}
        className={`input input-bordered dark:border-gray-400 ${props.inputClass} ${props.error && "!input-error"} ${props.textRight ? "text-right" : ""}`}
      />

      {props.error && (
        <label className="label">
          <span className={`label-text-alt font-bold ${props.error && "text-error"}`}>{props.error}</span>
        </label>
      )}
    </label>
  )
})