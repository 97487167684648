import { Button, Select, DateInput, Label, Texteditor } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { WorkStatusList } from "../../types"
import { useDepartement, useDivisi, useEmployeeStatus, useGroupPosition, useJobsPosition, useMainLocation, useSalaryType } from "./utils"
import { useEffect, useState } from "react"
import { FaPlus } from "react-icons/fa";
import { CreateModal as CreateModalGroupPosition } from "../../../../../Setup/Preference/GroupPosition/components";
import { CreateModal as CreateModalJobPosition } from "../../../../../Setup/Preference/JobPosition/components";
import { ModalSection as UpdateModalJobPosition } from "../../../../../Setup/Preference/JobPosition/components/ChildItem/components/Item/components/Update";
import { CreateModal as CreateModalMainLocation } from "../../../../../Setup/Preference/Location/components";
import { CreateModal as CreateModalDepartement } from "../../../../../Setup/Preference/Departement/components";
import { CreateModal as CreateModalDivisi } from "../../../../../Setup/Preference/Divisi/components";
import { CreateModal as CreateModalSalaryType } from "../../../../../Setup/Preference/SalaryType/components";
import { CreateModal as CreateModalEmployeeStatus } from "../../../../../Setup/Preference/EmployeeStatus/components";
import moment from "moment"
import { TbPencil } from "react-icons/tb"
import { useDetail } from "pages/Setup/Preference/JobPosition/components/ChildItem/components/Item/utils"
type MapProps = {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: boolean
}
type ModalType = { type: "description" | "kpi" | "qualification" | null; isOpen: boolean }

export const FormWorkStatus = ({ action, onSubmit, isActiveSubmit }: MapProps) => {
  const [modalConfig, setModalConfig] = useState<ModalType>({ type: null, isOpen: false })

  const { control, getValues, handleSubmit, formState } = useFormContext<WorkStatusList>()
  const job_position_id = useWatch({ control, name: "job_position_id" })
  const { data, isLoading, refetch } = useDetail(job_position_id!)

  return (
    <div>
      <table className="table">
        <tr>
          <td className="pl-0 w-[180px]"><Label text="EMPLOYEE STATUS" /></td>
          <td>
            <EmployeeStatus isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="DATE START" /></td>
          <td>
            <Controller
              control={control}
              name="date_end"
              render={({ field, fieldState }) => 
                <DateInput
                  className="w-full" 
                  error={control?._formValues?.date_end ? "" : fieldState.error?.message}
                  disabled={action === "DETAIL"}
                  placeholderText="Input date start" 
                  ref={field.ref}
                  selected={field.value ? moment(field.value).toDate() : null}
                  onChange={value => {
                    if (value) {
                      return field.onChange(moment(value).format("YYYY-MM-DD"))
                    }

                    return field.onChange("")
                  }}
                />
              }
            />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="DATE END" /></td>
          <td>
            <Controller
              control={control}
              name="date_start"
              render={({ field, fieldState }) => 
                <DateInput
                  className="w-full" 
                  error={control?._formValues?.date_start ? "" : fieldState.error?.message}
                  placeholderText="Input date end" 
                  disabled={action === "DETAIL"}
                  ref={field.ref}
                  selected={field.value ? moment(field.value).toDate() : null}
                  onChange={value => {
                    if (value) {
                      return field.onChange(moment(value).format("YYYY-MM-DD"))
                    }

                    return field.onChange("")
                  }}
                />
              }
            />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="MAIN LOCATION" /></td>
          <td>
            <MainLocation isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="DIVISI" /></td>
          <td>
            <Divisi isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="DEPARTEMENT" /></td>
          <td>
            <Departement isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="JOB POSITION" /></td>
          <td>
            <JobPosition isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]" />
          <td>
            {(action !== "DETAIL" && job_position_id) && 
              <div className={`flex justify-end cursor-pointer mb-[-28px] ${modalConfig?.isOpen ? "hidden" : ""}`}>
                <TbPencil className="size-5 z-[100000]" onClick={() => setModalConfig({ type: "description", isOpen: true })} />
              </div>
            }
            <Texteditor
              label="JOB DESCRIPTION"
              value={isLoading ? "Memuat..." : data?.description}
              disabled
              onChange={() => {}}
            />
            {modalConfig?.type === "description" && modalConfig?.isOpen === true && 
              <UpdateModalJobPosition 
                id={job_position_id!} 
                toggle={() => setModalConfig({ type: null, isOpen: false })}
                refetch={refetch}
              />
            }
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]" />
          <td>
            {(action !== "DETAIL" && job_position_id) && 
              <div className={`flex justify-end cursor-pointer mb-[-28px] ${modalConfig?.isOpen ? "hidden" : ""}`}>
                <TbPencil className="size-5 z-[100000]" onClick={() => setModalConfig({ type: "kpi", isOpen: true })} />
              </div>
            }
            <Texteditor
              label="KPI"
              value={isLoading ? "Memuat..." : data?.kpi}
              disabled
              onChange={() => {}}
            />
            {modalConfig?.type === "kpi" && modalConfig?.isOpen === true && 
              <UpdateModalJobPosition 
                id={job_position_id!} 
                toggle={() => setModalConfig({ type: null, isOpen: false })}
                refetch={refetch}
              />
            }
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]" />
          <td>
            {(action !== "DETAIL" && job_position_id) && 
              <div className={`flex justify-end cursor-pointer mb-[-28px] ${modalConfig?.isOpen ? "hidden" : ""}`}>
                <TbPencil className="size-5 z-[100000]" onClick={() => setModalConfig({ type: "qualification", isOpen: true })} />
              </div>
            }
            <Texteditor
              label="SKILL QUALIFICATION"
              value={isLoading ? "Memuat..." : data?.qualification}
              disabled
              onChange={() => {}}
            />
            {modalConfig?.type === "qualification" && modalConfig?.isOpen === true && 
              <UpdateModalJobPosition 
                id={job_position_id!} 
                toggle={() => setModalConfig({ type: null, isOpen: false })}
                refetch={refetch}
              />
            }
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="GROUP POSITION" /></td>
          <td>
            <GroupPosition isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="GENDER" /></td>
          <td>
            <Gender isDisabled={action === "DETAIL"} />
          </td>
        </tr>
        <tr>
          <td className="pl-0 w-[180px]"><Label text="SALARY TYPE" /></td>
          <td>
            <SalaryType isDisabled={action === "DETAIL"} />
          </td>
        </tr>
      </table>
        
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            permission={"CR011"}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}

function EmployeeStatus(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useEmployeeStatus()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="employee_status_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select employee status..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalEmployeeStatus toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function GroupPosition(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useGroupPosition()
  const [modalConfig, setModalConfig] = useState(false)
  const job_position_id = useWatch({
    control,
    name: "job_position_id"
  })

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="group_position_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select group position..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled || !job_position_id}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString()) ?? null}
            onChange={e => {
              field.onChange(e?.value)
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalGroupPosition toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function JobPosition(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useJobsPosition()
  const [modalConfig, setModalConfig] = useState(false)
  const divisi_id = useWatch({
    control,
    name: "divisi_id"
  })

  useEffect(() => {
    refetch(divisi_id?.toString())
    // eslint-disable-next-line
  }, [divisi_id])

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="job_position_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select job position..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled || !divisi_id}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString()) ?? null}
            onChange={e => {
              if (e) {
                field.onChange(e.value)
              }
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalJobPosition toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function MainLocation(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { dataMainLocation, isLoadingMainLocation, refetch } = useMainLocation()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="location_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select main location..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoadingMainLocation}
            error={fieldState.error?.message}
            options={dataMainLocation}
            value={dataMainLocation.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalMainLocation toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Departement(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { dataDepartement, isLoadingDepartement, refetch } = useDepartement()
  const [modalConfig, setModalConfig] = useState(false)
  const divisi_id = useWatch({
    control,
    name: "divisi_id"
  })

  useEffect(() => {
    refetch(divisi_id?.toString())
    // eslint-disable-next-line
  }, [divisi_id])

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="department_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select departement..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled || !divisi_id}
            isLoading={isLoadingDepartement}
            error={fieldState.error?.message}
            options={dataDepartement}
            value={dataDepartement.find(item => item.value === field.value?.toString()) ?? null}
            onChange={e => {
              if (e) {
                field.onChange(e.value)
              }
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalDepartement toggle={() => {
        setModalConfig(false)
        refetch(divisi_id?.toString())
      }} />}
    </div>
  )
}
function Divisi(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useDivisi()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="divisi_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select divisi..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString()) ?? null}
            onChange={e => {
              if (e) {
                field.onChange(e.value)
                setValue("department_id", null)
                setValue("job_position_id", null)
                setValue("group_position_id", null)
                refetch()
                console.log(e.value)
              }
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalDivisi toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Gender(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const dropdownGender = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ]

  return (
    <Controller
      control={control}
      name="gender"
      render={({ field, fieldState }) => (
        <Select
          placeholder="Select gender..."
          isDisabled={props.isDisabled}
          error={fieldState.error?.message}
          options={dropdownGender}
          value={dropdownGender.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
    
  )
}
function SalaryType(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useSalaryType()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="salary_type_id"
        render={({ field, fieldState }) => (
          <Select
            placeholder="Select salary type..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div>
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalSalaryType toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}