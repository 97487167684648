// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

interface FetchType {
  coa_code: string
  coa_name: string
}

export function useHistoryCoa(): FetchType {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<FetchType>({
    coa_code: "",
    coa_name: ""
  })

  useEffect(() => {
    const promise = new Promise<FetchType>((resolve) => {
      api.get("/linkedaccount/bycode", {
        params: { linked_account_code: "LA03" }
      }).then((res: SuccessFetch<{ payload: FetchType[] }>) => {
        // Vars
        const payload = res.data.payload[0]
  
        resolve({
          coa_code: payload.coa_code,
          coa_name: payload.coa_name
        })
      }).catch(() => {
        resolve({
          coa_code: "",
          coa_name: ""
        })
      })
    })

    promise.then(setData)

    return () => {
      setData({
        coa_code: "",
        coa_name: ""
      })
    }

    // eslint-disable-next-line
  }, [])

  return data
}