// React
import { PropsWithChildren, createContext } from "react"

// Types
import type { SelectOptionType } from "types"

// Utils
import { useProductUnit } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"

export const ProductUnitContext = createContext<{
  data: SelectOptionType[]
  isLoading: boolean
}>({
  data: [],
  isLoading: false
})

export function ProductUnitProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Hooks
  const { dataUnit, isLoadingUnit } = useProductUnit()

  return (
    <ProductUnitContext.Provider
      value={{
        data: dataUnit,
        isLoading: isLoadingUnit
      }}
    >
      {params.children}
    </ProductUnitContext.Provider>
  )
}