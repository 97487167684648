/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { TaskProgress } from "../../types"

type UseTaskProgress = {
  task_id: string
  onSuccess?: (data: TaskProgress) => void
}

type Response = {
  payload: TaskProgress
}

export function useTaskProgress({ task_id, onSuccess }: UseTaskProgress) {
  const api = useApi()
  const [data, setData] = useState<TaskProgress | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get(`/task/task-progress/${task_id}`)
      .then((res: SuccessFetch<Response>) => {
        const data: TaskProgress = {
          ...res.data.payload,
          progress: Number(
            parseFloat(
              parseFloat(res.data?.payload.progress.toString()).toFixed(2)
            ).toPrecision()
          )
        }
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (task_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [task_id])

  return { data, isLoading, refetch }
}
