// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useProductPictures(product_id: string): {
  data: string[],
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<string[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get(
      "/product/picture",
      { params: { product_id } }
    ).then((res: SuccessFetch<{
      payload: { path: string }[]
    }>) => {
      setData(res.data.payload.map(item => item.path))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}