import { AxiosResponse } from "axios"
import { Button } from "components"
import { deleteAlert } from "pages/Setup/utils"
import { Fragment } from "react"
import toast from "react-hot-toast"
import { PiBroomBold } from "react-icons/pi"
import { useApi, useToggle } from "utils"

type DeleteAlltype = {
  deleteList: number[]
  isDeleteAll: boolean
  onSuccess: () => void
}
export const DeleteAll = ({ deleteList, isDeleteAll, onSuccess }: DeleteAlltype): JSX.Element => {
    const api = useApi()
    const { isActive, setActive } = useToggle(false)
  
    const getUrl = (): Promise<AxiosResponse<any, any>> => {
      if (isDeleteAll) { return api.delete("/actioon-task/all") }
  
      return api.delete("/action-task/delete-multiple", { data: deleteList })
    }
  
    if (deleteList.length) {
      return (
        <Button
          className="tooltip tooltip-error flex gap-3"
          color="ghost"
          loading={isActive ? "true" : undefined}
          onClick={() => {
            deleteAlert(isDeleteAll).then(() => {
              setActive(true)
  
              toast.promise(
                getUrl(),
                {
                  loading: "Loading...",
                  success: (res) => res.data.message,
                  error: (err) => err.response.data?.message ?? err.response.message
                }
              )
              .then(() => onSuccess())
              .catch(() => {})
              .finally(() => setActive(false))
            })
            .catch(() => {})
          }}
        >
          {isDeleteAll ? "DELETE ALL DATA" : "DELETE SELECTED DATA"} <PiBroomBold className="w-[20px] h-[20px]" />
        </Button>
      )
    }
  
    return <Fragment />
  } 