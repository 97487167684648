// React
import { useState } from "react"

export function useMultipleSelect(): {
  item: string[]
  handleData: (value: string) => void
} {
  // Hooks
  const [item, setItem] = useState<string[]>([])

  // Functions
  const handleData = (value: string): void => {
    // Vars
    const list = [...item]
    const index = item.findIndex(i => value === i)
    const data = item.find(i => value === i)

    if (data) {
      list.splice(index, 1)
    } else {
      list.push(value)
    }

    setItem(list)
  }

  return { item, handleData }
}