import { Button, Input, ActionButton, Modal, Textarea, Loading, BaseTable } from "../../../../../../components"
import { Controller, useForm, FormProvider, useFormContext, useWatch } from "react-hook-form"
import { Fragment, useEffect, useState } from "react"
import { Toggle } from "components/actions/Toggle"
import { useApi } from "../../../../../../utils"
import { PictureList } from "../../types"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { useImages, usePictureCode } from "../ChildItem/components/Item/utils"

interface ModalProps { 
  action?: "CREATE" | "UPDATE" | "DETAIL" 
  pictureCode: PictureList
  modalConfig: any
  imageViews: any
  setFileValue: (newState: any) => void
  setImageView: (newState: any) => void
}
const ModalForm:React.FC<ModalProps> = ({ action, pictureCode, modalConfig, setFileValue, imageViews, setImageView }) => {
  const { control, setValue } = useFormContext<PictureList>()
  const { REACT_APP_BASE_URL_API } = process.env

  const code = useWatch({ control, name: "product_picture_code" })
  const name = useWatch({ control, name: "product_picture_name" })
  const description = useWatch({ control, name: "description" })

  return (
    <section>
      <Controller
        control={control}
        name="product_picture_code"
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="ID PICTURE" 
            value={field.value ?? pictureCode?.productpicturecode ?? ""}
            error={code ? "" : fieldState.error?.message} 
            disabled
          />
        }
      />
      <Controller
        control={control}
        name="product_picture_name"
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="FILE NAME" 
            value={field.value ?? ""} 
            onChange={(e) => {
              if(modalConfig?.type === "CREATE") { setValue("product_picture_code", pictureCode?.productpicturecode!) }
              setValue("product_picture_name", e?.target?.value)
            }}
            error={name ? "" : fieldState.error?.message} 
            disabled={modalConfig?.type === "DETAIL" ? true : false}
          />
        }
      />
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => 
          <Textarea 
            {...field} 
            label="DESCRIPTION" 
            value={field.value ?? ""} 
            onChange={(e) => setValue("description", e?.target?.value)}
            error={description ? "" : fieldState.error?.message} 
            disabled={modalConfig?.type === "DETAIL" ? true : false}
          />
        }
      />
      <div className="my-3">
        {modalConfig?.type !== "DETAIL" && 
          <div className="w-full !rounded-lg bg-primary h-[50px]">
            <Controller
              control={control}
              name="Product_Foto"
              render={({ field }) => (
                <input 
                  className="w-full h-full flex justify-center items-center opacity-0 cursor-pointer" 
                  type="file" 
                  disabled={action === "DETAIL"}
                  onChange={(e) => {
                    const selectedFile = e.target.files?.[0];

                    if (selectedFile) {
                      if (selectedFile.size > 300 * 1024) {
                        alert("Image size maximum 300KB");
                        return;
                      }
                  
                      setFileValue(selectedFile);
                      field.onChange(selectedFile);
                      
                      setImageView({ ...imageViews, file: selectedFile ?? control?._formValues?.Product_Foto });
                      const reader = new FileReader();
                      reader.onloadend = () => setImageView({ ...imageViews, view: reader.result as string });
                      reader.readAsDataURL(selectedFile);
                    } else {
                      setFileValue(null);
                      setImageView({ ...imageViews, file: null, view:  null });
                    }
                  }}
                />
              )}
            />
            <div className="mt-[-35px] text-black flex justify-center"><b>PICTURE</b></div>
          </div>
        }
        <img 
          className="w-full mt-3 rounded-lg"
          alt="thumbnail" 
          src={imageViews?.view 
          ? imageViews?.view
          : control?._formValues?.Product_Foto
          ? `${REACT_APP_BASE_URL_API}/getimage/?imgpath=${control?._formValues?.Product_Foto}`
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2sRHjqz3RQ4yNTs7OKrn_JP0aRuLjMCHGPXoWUZriow&s"}
        />
      </div>
    </section>
  )
}

function IsActivePicture(props: {
  data: PictureList
  refetch?: () => void
}) {
  const api = useApi()
  const onSubmit = (value: PictureList) => {
      return new Promise<void>((resolve) => {
          toast.promise(
              api.put("/product/changestatuspicture", { product_picture_id: value.product_picture_id, statusdel: !value.is_deleted }),
              {
                  loading: "Loading...",
                  success: (res) => res.data.message,
                  error: (err) => err.response.data.message ?? err.response.message
              }
          )
          .then(() => props?.refetch && props.refetch())
          .catch(() => {})
          .finally(resolve)
      })
  }

  return (
      <Fragment>
          <Toggle isChecked={props.data.is_deleted} onClick={() => onSubmit(props.data)} />
      </Fragment>
  )
}

interface PicturesProps { 
  action?: "CREATE" | "UPDATE" | "DETAIL" 
  dataPicture: any
  refetchPicture: () => void
  id?: number 
  permission?: string
}
export const FormPictures:React.FC<PicturesProps> = ({ action, dataPicture, refetchPicture, id, permission }) => {
  const api = useApi()
  const { pictureCode } = usePictureCode()
  
  const [onRowsClick, setOnRowsClick] = useState<any>({})
  const [modalConfig, setModalConfig] = useState<any>({ show: false, type: "", data: {} })
  const [searchResults, setSearchResults] = useState<any>(dataPicture);
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [imageViews, setImageView] = useState<{ file: File | null; view: string | null }>({ file: null, view: null })
  const { dataImages, isLoadingImages, refetchImages } = useImages()

  useEffect(() => {
    if(dataPicture?.[0]?.path) {
      refetchImages(dataPicture?.[0]?.path) 
    }
    // eslint-disable-next-line
  }, [dataPicture?.[0]?.path])
  

  const searchData = (query: string): any => {
    return dataPicture.filter((data: any) => {
      return data.product_picture_code.toLowerCase().includes(query.toLowerCase()) ||
             data.product_picture_name.toLowerCase().includes(query.toLowerCase()) ||
             data.description.toLowerCase().includes(query.toLowerCase()) ||
             data.path.toLowerCase().includes(query.toLowerCase())
    });
  }

  type CreatePicturelData = Pick<PictureList, "product_id" | "product_picture_code" | "product_picture_name" | "description" | "Product_Foto">;
  const pictureValues: CreatePicturelData = {
    product_id : id ?? "",
    product_picture_code: pictureCode?.productpicturecode ?? "",
    product_picture_name: "",
    description : "",
    Product_Foto : ""
  }
  const validationSchema = yup.object().shape({
    product_picture_code: yup.string().label("File code").required(),
    product_picture_name: yup.string().label("File name").required(),
    description: yup.string().label("Description").required()
  })
  const methods = useForm<CreatePicturelData>({ 
    defaultValues: pictureValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: CreatePicturelData) => {
    const formData = new FormData();
    
    if(modalConfig?.type === "UPDATE" && modalConfig?.data?.product_picture_id){formData.append('product_picture_id', modalConfig?.data?.product_picture_id)}
    formData.append('product_id', value?.product_id);
    formData.append('product_picture_code', value?.product_picture_code);
    formData.append('product_picture_name', value?.product_picture_name);
    formData.append('description', value?.description);
    if(value?.Product_Foto){formData.append(fileValue ? 'Product_Foto' : "", value?.Product_Foto!)}

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/product/picture", formData),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 402) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {
        methods.reset()
        setModalConfig({ ...modalConfig, show: false })
      })
      .catch(() => {})
      .finally(() => {
        resolve()
        refetchPicture() 
      })
    })
  }

  const ThumbnailSection = () => (
    <div className="border-[1px] border-inherit mb-5 flex items-center justify-center">
      <div className="flex justify-center">
        <div className="avatar my-4">
          <div className="w-[250px] rounded">
            {isLoadingImages 
              ? <div className="mt-[120px]"><Loading loading={isLoadingImages} /></div> 
              : <img src={dataImages! ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2sRHjqz3RQ4yNTs7OKrn_JP0aRuLjMCHGPXoWUZriow&s"} alt="thumbnail" />
            }
          </div>
        </div>
        <div className="ml-5 text-[20px] max-w-[450px] overflow-x-auto mt-4">
          <div className="mb-2">{onRowsClick?.product_picture_code ? onRowsClick?.product_picture_code : dataPicture ? dataPicture?.[0]?.product_picture_code : ""}</div>
          <div className="mb-2">{onRowsClick?.product_picture_name ? onRowsClick?.product_picture_name : dataPicture ? dataPicture?.[0]?.product_picture_name : ""}</div>
          <div className="mb-2">{onRowsClick?.description ? onRowsClick?.description : dataPicture ? dataPicture?.[0]?.description : ""}</div>
          {/* <div className="mb-2">{onRowsClick?.path ? onRowsClick?.path : ""}</div> */}
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <section>
        <div>
          <ThumbnailSection />
          {action !== "DETAIL" && <div className="flex justify-between mt-3">
            <Input inputClass="w-[300px]" placeholder="Search data..." onChange={(e) => setSearchResults(searchData(e?.target?.value))} />
            <Button permission={"IT011"} type="button" className="!none mb-5" color="primary" onClick={() => setModalConfig({ ...modalConfig, show: true, type: "CREATE" })} >
              INSERT PICTURE
            </Button>
          </div>}
          <div className="overflow-x-auto">
            <BaseTable className="border-collapse">
              <thead>
                <tr>
                  <th>NO</th>
                  <th>PICTURE ID</th>
                  <th>FILE NAME</th>
                  <th>DESCRIPTION</th>
                  <th>FILE PATH</th>
                  {action !== "DETAIL" && <th>ACTION</th>}
                </tr>
              </thead>
              <tbody>
                {searchResults?.length > 0
                  ? dataPicture?.map((field: any, key: any) => {
                      return (
                        <tr onClick={() => {
                          setOnRowsClick(field)
                          refetchImages(field?.path) 
                        }}>
                          <td className="w-[50px] text-center">{key + 1}</td>
                          <td className="cursor-pointer">{field?.product_picture_code ?? "-"}</td>
                          <td>{field?.product_picture_name ?? "-"}</td>
                          <td>{field?.description ?? "-"}</td>
                          <td>{field?.path ?? "-"}</td>
                          {action !== "DETAIL" && <td className="text-center w-[100px]">
                            <div className="flex justify-center">
                              <ActionButton.Detail onClick={() => {
                                setModalConfig({ ...modalConfig, show: true, type: "DETAIL" })
                                methods.setValue("product_picture_code", field?.product_picture_code)
                                methods.setValue("product_picture_name", field?.product_picture_name)
                                methods.setValue("description", field?.description)
                                methods.setValue("Product_Foto", field?.path)
                                setOnRowsClick(field)
                              }} />
                              <ActionButton.Update onClick={() => {
                                setModalConfig({ ...modalConfig, show: true, type: "UPDATE", data: field })
                                methods.setValue("product_picture_code", field?.product_picture_code)
                                methods.setValue("product_picture_name", field?.product_picture_name)
                                methods.setValue("description", field?.description)
                                methods.setValue("Product_Foto", field?.path)
                                setOnRowsClick(field)
                              }} />
                              <ActionButton.Delete onClick={() => {
                                field?.product_picture_id && 
                                api.delete(`/product/deletepicture?product_picture_id=${field?.product_picture_id}`)
                                .then(() => refetchPicture())
                              }} />
                              <IsActivePicture data={field} refetch={refetchPicture} />
                            </div>
                          </td>}
            
                        </tr>
                      )
                    })
                  : <tr><td colSpan={7} className="text-center">No Data Available</td></tr>
                }
              </tbody>
            </BaseTable>
          </div>
        </div>
      </section>

      {modalConfig?.show && 
        <Modal 
          isOpen 
          title={`${modalConfig?.type === "CREATE" ? "INSERT" : modalConfig?.type === "UPDATE" ? "UPDATE" : "DETAIL"} PICTURE`} 
          size="xl" 
          closeModal={() => {
            setModalConfig({ ...modalConfig, show: false })
            setImageView({ ...imageViews, view: "" })
            methods.reset()
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Modal.Body>
                <ModalForm 
                  action={action}
                  pictureCode={pictureCode!} 
                  modalConfig={modalConfig} 
                  setFileValue={setFileValue}
                  imageViews={imageViews}
                  setImageView={setImageView}
                />
              </Modal.Body>

              <Modal.Footer className="text-end">
                <Button type="submit" color="primary" disabled={!Boolean(methods.control._formValues?.Product_Foto)} loading={methods.formState.isSubmitting ? "true" : undefined}>
                  Save
                </Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      }
    </div>
  )
}