/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DateInput, Modal } from "components"
import { Fragment, useContext, useState } from "react"
import toast from "react-hot-toast"
import { ContextApproveInvoice } from ".."
import { useDropdownEmployee } from "../../CustomerOrder/utils/hooks/useDropdownEmployee"
import InvoiceDetail from "../../Invoice/components/Detail"
import { InvoiceList } from "../../Invoice/utils/hooks/useInvoiceList"
import { IoSendSharp } from "react-icons/io5"
import moment from "moment"
import { useApi, useToggle } from "utils"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
// import { FormSection } from "./FormSection"

type ApproveActionProps = {
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  data: InvoiceList
}

export function ApproveAction({ employees, data }: ApproveActionProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        className="w-full"
        type="button"
        color="ghost"
        permission="ST11"
        onClick={toggle}
      disabled={[APPROVAL.REJECT, APPROVAL.APPROVE].includes(data?.approval_status)}
      >
        <IoSendSharp />
      </Button>

      {isActive && (
        <ModalSection employees={employees} toggle={toggle} data={data} />
      )}
    </Fragment>
  )
}

type ModalSectionProps = {
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  toggle: ReturnType<typeof useToggle>["toggle"]
  data: ApproveActionProps["data"]
}

function ModalSection({toggle, data }: ModalSectionProps): JSX.Element {
  const api = useApi()
  const context = useContext(ContextApproveInvoice)
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"))

  const onSubmit = (date: string, status: number) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post(`/invoice/approve/${data.invoice_id}`, { 
          approval_status: status,
          approve_date: date
         }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        context.refetchInvoice()
        toggle()
      }).catch(() => { }).finally(resolve)
    })
  }

  return (
    <Modal isOpen title="APPROVE INVOICE" closeModal={toggle} size="lg">

      <Modal.Body>
        <InvoiceDetail
          invoice_id={data.invoice_id.toString()}
          sales_order_id={data.sales_order.sales_order_id.toString()}
        />
      </Modal.Body>

      <Modal.Footer className="flex md:flex-row flex-col justify-between md:items-end gap-3">
        <DateInput
          placeholderText="Select Date"
          selected={moment(date).toDate()}
          className="w-full"
          label="APPROVAL DATE"
          onChange={(date) => {
            const selectDate = moment(date).format("YYYY-MM-DD")
            setDate(selectDate)
          }}
        />

        <section className="flex gap-3">
          <Button onClick={toggle}>CANCEL</Button>
          <Button color="error" onClick={() => onSubmit(date, 3)}>VOID</Button>
          <Button color="primary" onClick={() => onSubmit(date, 4)} >APPROVED</Button>
        </section>
      </Modal.Footer>
    </Modal>
  )
}
