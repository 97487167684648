import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"

export function CreateModal(props: {
  price_level_code?: string
  price_level_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("price_level_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "price_level_id"> 

  const defaultValues:CreateData= {
    price_level_code: "",
    price_level_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/pricelevel/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE PRICE LEVEL CUSTOMER" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for "Customer Pricing Level" in an ERP system, it's crucial to provide a clear structure that allows users to manage and apply different pricing strategies for various customer segments. This functionality helps organizations tailor their pricing to specific customer groups, thereby optimizing sales, improving customer relationships, and increasing revenue. Here is a comprehensive explanation of the data attributes for "Customer Pricing Level":`}
                detail={`These data attributes are crucial for setting up and managing customer pricing levels in the ERP system. They provide a structured way to handle diverse pricing strategies, enhance customer satisfaction, and maximize profitability.`}
                title=""
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each pricing level."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a quick and consistent reference to identify different pricing levels. It simplifies data entry, searching, and reporting by providing a concise way to manage and apply the appropriate pricing levels across various customers."
                      },
                      {
                        label: "Example",
                        value: `Codes like "PL01" for "Standard Pricing," "PL02" for "Wholesale Pricing," or "PL03" for "VIP Pricing" help distinguish between different pricing levels.`
                      }
                    ]
                  },
                  {
                    label: "Pricing Level",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the pricing level, indicating the type or category of pricing applied to a particular group of customers."
                      },
                      {
                        label: "Purpose",
                        value: "The pricing level defines the specific pricing strategy or structure applied to a set of customers. This allows the organization to segment customers based on their purchase behavior, volume, loyalty, or other criteria and to offer appropriate pricing accordingly."
                      },
                      {
                        label: "Example",
                        value: `-"Standard Pricing" — The default pricing for most customers, typically based on the company's regular retail price list. 
                                -"Wholesale Pricing" — A discounted pricing level offered to customers who purchase in bulk or are resellers. 
                                -"VIP Pricing" — A special pricing level for high-value or loyal customers who are eligible for exclusive discounts or offers. 
                                -"Seasonal Pricing" — Special pricing applied during certain periods, such as holidays or promotional events.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the pricing level."
                      },
                      {
                        label: "Purpose",
                        value: "The description helps clarify the criteria, conditions, or rules associated with each pricing level. It ensures that users understand the purpose and application of different pricing levels, which aids in accurate pricing, invoicing, and sales negotiations."
                      },
                      {
                        label: "Example",
                        value: `-For "Standard Pricing," the description might be: "Applies to all customers who do not qualify for special pricing levels; based on the standard price list." 
                                -For "Wholesale Pricing," it could read: "Available to wholesale customers purchasing a minimum order quantity of 500 units; provides a 20% discount off the standard price."
                                -For "VIP Pricing," it might state: "Reserved for customers who have exceeded annual purchases of $50,000; includes a 15% discount on all products and access to exclusive promotions." 
                                -For "Seasonal Pricing," it might note: "Special promotional prices applied during holiday seasons or sales events; discounts vary by product category."`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}