import { Button } from "components/actions"

export function DeleteConfirm(params: {
    label?: string,
    onClick: () => void,
    closeModal?: () => void
}): JSX.Element {
    return (
        <div>
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
            {/* Modal Header */}
            <div className="flex justify-center items-center mb-4">
              <h2 className="text-xl font-semibold">Delete all data</h2>
            </div>
            {/* Modal Content */}
            <div className="mb-4 text-center">
              <p>Are you sure that you want to delete all data? All data will be irreversibly lost.</p>
            </div>
            {/* Modal Footer */}
            <div className="flex justify-between">
              <Button onClick={params.closeModal} className="!rounded-[1px solid #8EACCD] w-[49%]">Cancel</Button>
              <Button type="submit" color="error" className="w-[49%]" onClick={params.onClick}>Delete</Button>
            </div>
          </div>
        </div>
        </div>
    )
}