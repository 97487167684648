import { BaseTable, Loading } from "components"
import { InfoSection } from "./InfoSection"
import moment from "moment"
import { convertNumber } from "utils"
import { AccountPayableList } from "../types"

type PayableList = {
    data: {
        data: AccountPayableList[]
        isLoading?: boolean
    }
}

export const PayableTable = ({ data }: PayableList) => (
    <div className="mt-5">
        <div><b>ACCOUNT PAYABLE</b></div>
        <div className="lg:w-[45%] ml-5"><InfoSection className="!justify-start" labelClass="lg:ml-5" label="Turnover Ratio" value="-" /></div>

        <div className="overflow-x-auto mt-5">
            <BaseTable>
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>BILL ID#</th>
                        <th>REF PO ID#</th>
                        <th>DATE</th>
                        <th>VENDOR</th>
                        <th>BALANCE</th>
                        <th>7 DAYS</th>
                        <th>15 DAYS</th>
                        <th>21 DAYS</th>
                        <th>30 DAYS</th>
                    </tr>
                </thead>
                <tbody>
                    {data.isLoading || !data.data.length ? (
                        <tr>
                        <td colSpan={11}>
                            <Loading errorText="No data available" loading={data.isLoading} />
                        </td>
                        </tr>
                    ) : data?.data?.map((val, index) => {
                        const getBalanceByAge = (
                            num1: number,
                            num2?: number
                        ): string => {
                            // Vars
                            let result = ""

                            if (num2) {
                            if (val?.aged_payable >= num1 && val?.aged_payable <= num2) {
                                result = convertNumber(val?.balance).intoCurrency
                            }
                            } else {
                            if (val?.aged_payable >= num1) {
                                result = convertNumber(val?.balance).intoCurrency
                            }
                            }

                            return result
                        }

                        return (
                            <tr>
                                <td className="text-center">{index + 1}</td>
                                <td className="w-[100px]">{val?.trx_code ?? "-"}</td>
                                <td className="w-[100px]">{val.purchase_order?.trx_code ?? "-"}</td>
                                <td className="text-nowrap">{moment(val?.transaction_date).format("DD MMM YYYY")}</td>
                                <td className="w-[200px]">{val?.vendor?.vendor_name ?? "-"}</td>
                                <td className="w-[200px]">{convertNumber(val?.balance).intoCurrency}</td>
                                <td>{getBalanceByAge(1, 7)}</td>
                                <td>{getBalanceByAge(8, 15)}</td>
                                <td>{getBalanceByAge(16, 21)}</td>
                                <td>{getBalanceByAge(21, 30)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </BaseTable>
        </div>
    </div>
)