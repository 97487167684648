import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { ListCategoryCustomer } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"

export function CreateModal(props: {
  category_customer_code?: string
  category_customer_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("category_customer_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateListCategoryCustomer = Omit<ListCategoryCustomer, "category_customer_id"> 

  const defaultValues:CreateListCategoryCustomer= {
    category_customer_code: "",
    category_customer_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateListCategoryCustomer) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/categorycustomer/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListCategoryCustomer>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="SETUP | CATEGORY CUSTOMER" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for the "Customer Category" in an ERP system, it's essential to clearly outline the information that helps categorize and manage different types of customers. These attributes allow businesses to group customers based on specific characteristics, enabling better segmentation, targeted marketing, and personalized service. Here's a comprehensive explanation of the data attributes for "Customer Category": `}
                detail={`These data attributes ensure that the "Customer Category" is set up accurately in the ERP system, allowing businesses to effectively segment and manage their customer base.`}
                title=""
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each customer category."
                      },
                      {
                        label: "Purpose",
                        value: "The code simplifies data entry and retrieval by providing a concise reference for each customer category. It ensures consistency across records and helps avoid duplication or ambiguity when categorizing customers."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "CUST01" for "Retail," "CUST02" for "Wholesale," or "CUST03" for "VIP" help quickly identify customer categories.`
                      }
                    ]
                  },
                  {
                    label: "Category Name",
                    list: [
                      {
                        label: "Definition",
                        value: "The full name or title of the customer category."
                      },
                      {
                        label: "Purpose",
                        value: "The category name provides a descriptive label that clearly identifies the type of customers within that category. It is used in reports, dashboards, and user interfaces to help users easily understand and manage customer segmentation."
                      },
                      {
                        label: "Example",
                        value: `"Retail Customers," "Wholesale Customers," "Corporate Clients," "VIP Clients," "Non-profit Organizations."`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the customer category."
                      },
                      {
                        label: "Purpose",
                        value: "The description offers clarity on the criteria used for categorizing customers, such as sales volume, type of business, geographic location, or purchasing behavior. It helps users understand the purpose and characteristics of each category, enabling more informed decision-making and effective customer management."
                      },
                      {
                        label: "Example",
                        value: `-For "Retail Customers," the description might be: "End consumers who purchase goods in small quantities for personal use." 
                                -For "Wholesale Customers," it could state: "Businesses or entities that purchase goods in bulk for resale or large-scale use." 
                                -For "VIP Clients," it could read: "Customers with high purchase frequency and volume, eligible for special discounts and priority service.""`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}