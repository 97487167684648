/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HeaderMenu, Input, Label, Textarea } from "components"
import { useProjectList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import {
  DropdownEmployee,
  DropdownJobs,
  DropdownLocation
} from "pages/Sales/Transaction/CustomerOrder/components"
import { ChangeEvent } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { useToggle } from "utils"
import { TaskType } from "../types"
import { useTaskActionList, useTaskProgress } from "../utils/hooks"
import { CardTask } from "./CardTask"
import { CodeTask } from "./CodeTask"
import { CommandButtons } from "./CommandButtons"
import { DateTask } from "./DateTask"
import { DropdownDepartment } from "./DropdownDepartment"
import { DropdownGrup } from "./DropdownGrup"
import { DropdownPriority } from "./DropdownPriority"
import { DropdownStatus } from "./DropdownStatus"
import { DropdownSubGrup } from "./DropdownSubGroup"
import { ModalActionLine } from "./ModalActionLine"
import { ModalDelete } from "./ModalDelete"
import { TableStatusActionLine } from "./Tables/StatusActionLine"

export type FormSectionProps = {
  approvalView?: boolean
  isEdit?: boolean
  onSubmit: () => void
}

export function FormSection({
  approvalView,
  isEdit = false,
  onSubmit
}: FormSectionProps) {
  const navigate = useNavigate()
  const methods = useFormContext<TaskType>()
  const modalActionLine = useToggle(false)
  const modalDelete = useToggle(false)
  const jobs = useProjectList()
  const [
    mr_group_task_id,
    task_id,
    trx_code,
    subject,
    date_start,
    date_end,
    department_name,
    task_status,
    owner_name
  ] = useWatch({
    control: methods.control,
    name: [
      "mr_group_task_id",
      "task_id",
      "trx_code",
      "subject",
      "date_start",
      "date_end",
      "department_name",
      "task_status",
      "owner_name"
    ]
  })
  const taskAction = useTaskActionList({ task_id: task_id?.toString() ?? "" })

  const taskProgress = useTaskProgress({ task_id: task_id?.toString() ?? "" })

  const deleteData = {
    task_id: task_id ?? NaN,
    trx_code: trx_code ?? "",
    subject: subject ?? "",
    date_start: date_start ?? "",
    task_status: task_status ?? 1,
    owner_name: owner_name ?? "",
    department_name: department_name ?? ""
  }

  return (
    <section className="container my-5 flex flex-col gap-5">
      <section>
        <HeaderMenu title="DATA ENTRY | TASK">
          <CodeTask isEdit={isEdit} />
        </HeaderMenu>

        <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="w-full">
            <DropdownLocation showCreate={false} approvalView={approvalView} />

            <DropdownDepartment />

            <Label className="w-full">PROJECTS</Label>
            <DropdownJobs jobs={jobs} />

            <DropdownGrup />

            <DropdownSubGrup groupTaskId={mr_group_task_id?.toString()} />

            <DropdownStatus />

            <DropdownPriority />

            <DateTask />

            <DropdownEmployee
              showCreate={false}
              label="OWNER TASK"
              name="owner_task_id"
              onChange={(e) => methods.setValue("owner_name", e?.label)}
            />

            <Controller
              control={methods.control}
              name="subject"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  disabled={approvalView}
                  label="SUBJECT"
                  defaultValue={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="description"
              render={({ field, fieldState }) => (
                <Textarea
                  {...field}
                  disabled={approvalView}
                  label="DESCRIPTION"
                  defaultValue={field.value}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    field.onChange(e.target.value)
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </section>

          <section className="mb-3 w-full">
            <section className="w-full flex justify-between items-end mt-3 mb-3">
              <div className="w-full flex flex-wrap md:flex-row justify-center items-center md:w-2/3">
                <progress
                  className="progress progress-primary w-full"
                  value={taskProgress.data?.progress ?? 0}
                  max="100"></progress>
                <span className="mx-2 text-xs font-bold">
                  {taskProgress.data?.progress ?? 0}%
                </span>
              </div>

              <div className="flex justify-end items-center">
                <div
                  className={task_id ? "" : "tooltip tooltip-left"}
                  data-tip="Save Record to Create New Action Line">
                  <Button
                    className="bg-primary"
                    disabled={Boolean(!methods.getValues().task_id)}
                    onClick={modalActionLine.toggle}>
                    <TbPlus /> NEW ACTION LINE
                  </Button>
                </div>
                <div className="btn btn-ghost bg-slate-300 hover:bg-slate-300 rounded-none border-none ">
                  {taskProgress.data?.all_task ?? 0}
                </div>
              </div>
            </section>

            <section className="mb-3 flex flex-col gap-y-3">
              <TableStatusActionLine taskProgress={taskProgress} />
              {taskAction.data?.map((item, index) => (
                <CardTask
                  key={item.task_action_id}
                  no={index + 1}
                  taskAction={taskAction}
                  taskProgress={taskProgress}
                  actionTask={item}
                  task={{
                    task_id,
                    trx_code,
                    subject,
                    date_start,
                    date_end,
                    owner_name
                  }}
                />
              ))}
            </section>
          </section>
        </section>
      </section>

      <CommandButtons
        approvalView={approvalView}
        showDeleteButton={isEdit}
        showSaveButton={!isEdit && Boolean(task_id) ? false : true}
        onSave={onSubmit}
        onDelete={() => modalDelete.setActive(true)}
      />

      {modalActionLine.isActive && (
        <ModalActionLine
          id={null}
          task={methods.getValues()}
          modalState={modalActionLine}
          taskAction={taskAction}
          taskProgress={taskProgress}
        />
      )}

      <ModalDelete
        data={deleteData}
        modalState={modalDelete}
        onSuccess={() => navigate("/")}
      />
    </section>
  )
}
