import { ErrorMessage } from "@hookform/error-message"
import { ActionButton, BaseTable, Button } from "components"
import {
  useProjectList,
  useTaxCodeList
} from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { FormSalesOrderDetailType } from "pages/Sales/Transaction/CustomerOrder/types"
import { useDropdownProduct } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { IoSendSharp } from "react-icons/io5"
import { convertNumber, useApi } from "utils"
import { FormInvoiceDetailType } from "../../../types"
import { FormInvoiceType } from "../../../types/FormInvoice"
import { RowSalesOrder } from "./RowSalesOrder"
import { RowSalesOrderInvoice } from "./RowSalesOrderInvoice"

type TableSalesOrderInvoiceProps = {
  approvalView?: boolean
  data:
  | Array<FormInvoiceType & { id: string }>
  | Array<FormSalesOrderDetailType & { id: string }>
  display?: boolean
  displayArrowNavigation?: boolean
  displayStockOnHand?: boolean
  onClickArrowNavigation?: (item: FormInvoiceDetailType) => void
  onClickDeleteSOInvoice?: (key: number) => void,
  isDetailShop?: boolean
}

export const TableSalesOrderInvoice = ({
  approvalView,
  data,
  display = true,
  displayArrowNavigation = false,
  displayStockOnHand,
  onClickArrowNavigation,
  onClickDeleteSOInvoice,
  isDetailShop
}: TableSalesOrderInvoiceProps) => {
  const methods = useFormContext<FormInvoiceType>()
  const [editField, setEditField] = useState<number | null>(null)
  const [price_level_name, location_id] = useWatch({
    control: methods.control,
    name: ["price_level_name", "location_id", "sodetail"]
  })
  const tax = useTaxCodeList()
  const jobs = useProjectList()
  const products = useDropdownProduct({ location_id })
  const api = useApi()

  return (
    <div className={`${display ? "" : "hidden"} overflow-x-auto`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th
              colSpan={displayArrowNavigation ? 2 : 1}
              className={displayArrowNavigation ? "" : "hidden"}>
              Order
            </th>
            {displayArrowNavigation && <th>Received</th>}
            <th>Backorder</th>
            {!displayArrowNavigation && <th>Delivered</th>}
            {displayStockOnHand && !isDetailShop && <th>SOH</th>}
            <th>Unit</th>
            <th>Item Code</th>
            <th>Description</th>
            <th>Price</th>
            <th>Disc (%)</th>
            <th>Total</th>
            <th>Jobs</th>
            <th>Tax</th>
            <th className={approvalView ? "hidden" : ""}>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {data?.map((d, key) => {
            return (
              <Controller
                key={d.id}
                control={methods.control}
                name={
                  displayArrowNavigation
                    ? `sodetail.${key}`
                    : `invoice_detail.${key}`
                }
                render={({ field }) => {
                  if (editField === key) {
                    return displayArrowNavigation ? (
                      <RowSalesOrder
                        key={d.id}
                        tax={tax}
                        jobs={jobs}
                        action="edit"
                        priceLevelName={price_level_name}
                        products={products}
                        defaultValues={field.value}
                        onSubmit={async (value) => {
                          const params = {
                            sales_order_detail_id: value.sales_order_detail_id
                          }

                          await api
                            .put("/salesorder/editdetailso", value, {
                              params
                            })
                            .then(() => {
                              field.onChange(value)
                              setEditField(null)
                            })
                        }}
                        onCancel={() => setEditField(null)}
                      />
                    ) : (
                      <RowSalesOrderInvoice
                        key={d.id}
                        tax={tax}
                        jobs={jobs}
                        action="edit"
                        priceLevelName={price_level_name}
                        products={products}
                        defaultValues={field.value}
                        onSubmit={(value) => {
                          field.onChange(value)
                          setEditField(null)
                        }}
                        onCancel={() => setEditField(null)}
                      />
                    )
                  } else {
                    const isOrderEqualReceived =
                      (field.value?.qty_order ?? 0) <= field.value?.received

                    if (displayArrowNavigation) {
                      return (
                        <tr
                          key={d.id}
                          className={
                            isOrderEqualReceived
                              ? "text-red-500 line-through"
                              : ""
                          }>
                          <td className="text-center px-0">
                            <Button
                              size="xs"
                              disabled={isOrderEqualReceived}
                              className={approvalView ? "hidden" : ""}
                              color="ghost"
                              onClick={() =>
                                onClickArrowNavigation?.({
                                  ...field.value,
                                  qty_order: field.value?.qty_order ?? 0,
                                  total:
                                    field.value.quantity_invoice ??
                                    0 * field.value.price_measure ??
                                    (0 * (100 - field.value.discount)) / 100
                                })
                              }>
                              <IoSendSharp className="size-4 -rotate-90" />
                            </Button>
                          </td>
                          <td className="text-right">
                            {field.value?.qty_order}
                          </td>
                          <td className="text-right">
                            {field.value?.received}
                          </td>
                          <td className="text-right">
                            {(field.value?.qty_order ?? 0) -
                              field.value?.received}
                          </td>
                          <td>{field.value?.mr_unit_name}</td>
                          <td>{field.value?.product_barcode}</td>
                          <td>{field.value?.product_name}</td>
                          <td className="text-right">
                            {
                              convertNumber(field.value?.price_measure ?? 0)
                                .intoCurrency
                            }
                          </td>
                          <td className="text-right">
                            {field.value?.discount}
                          </td>
                          <td className="text-right">
                            {
                              convertNumber(field.value?.total ?? 0)
                                .intoCurrency
                            }
                          </td>
                          <td>{field.value?.project_name}</td>
                          <td>{field.value?.tax_name}</td>
                          <td className={approvalView ? "hidden" : ""}>
                            <section className="flex justify-center">
                              <ActionButton.Update
                                disabled={isOrderEqualReceived}
                                onClick={() => setEditField(key)}
                              />
                            </section>
                          </td>
                        </tr>
                      )
                    }

                    return (
                      <tr key={d.id}>
                        <td className="text-right">
                          {(field.value?.qty_order ?? 0) -
                            field.value?.received}
                        </td>
                        <td className="text-right">
                          {field.value?.quantity_invoice ?? 0}
                        </td>
                        {
                          !isDetailShop && (
                            <td className="text-right">{field.value?.soh ?? 0}</td>
                          )
                        }
                        <td>{field.value?.mr_unit_name}</td>
                        <td>{field.value?.product_barcode}</td>
                        <td>{field.value?.product_name}</td>
                        <td className="text-right">
                          {
                            convertNumber(field.value?.price_measure ?? 0)
                              .intoCurrency
                          }
                        </td>
                        <td className="text-right">{field.value?.discount}</td>
                        <td className="text-right">
                          {convertNumber(field.value?.total ?? 0).intoCurrency}
                        </td>
                        <td>{field.value?.project_name}</td>
                        <td>{field.value?.tax_name}</td>
                        <td className={approvalView ? "hidden" : ""}>
                          <section className="flex justify-center">
                            <ActionButton.Update
                              onClick={() => setEditField(key)}
                            />

                            <ActionButton.Delete
                              type="button"
                              onClick={() => {
                                onClickDeleteSOInvoice?.(key)
                              }}
                            />
                          </section>
                        </td>
                      </tr>
                    )
                  }
                }}
              />
            )
          })}
        </tbody>
      </BaseTable>

      {!displayArrowNavigation && (
        <ErrorMessage
          errors={methods.formState.errors}
          name="invoice_detail"
          render={({ message }) => <p className="text-red-500">{message}</p>}
        />
      )}
    </div>
  )
}
