// Third-Party Libraries
import { capitalize } from "lodash"

export const generateAddress = (data: {
  address?: string
  village_name?: string
  subdistrict_name?: string
  regency_name?: string
  province_name?: string
  country_name?: string
}): string => {
  let shippedAddress = ""

  if (data.address) {
    shippedAddress += data.address
  }

  if (data.village_name) {
    shippedAddress += ", " + data.village_name
  }

  if (data.subdistrict_name) {
    shippedAddress += ", " + data.subdistrict_name
  }

  if (data.regency_name) {
    shippedAddress += ", " + data.regency_name
  }

  if (data.province_name) {
    shippedAddress += ", " + data.province_name
  }

  if (data.country_name) {
    shippedAddress += " - " + data.country_name
  }

  return shippedAddress
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ")
}
