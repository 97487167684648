// Types
import type { MenuType } from "../types"

export const analyticsMenu = {
  menu: [
    {
      label: "8 KEYS",
      link: "/analytics/8-keys",
      permission: "AR" // BELUM
    },
    {
      label: "FINANCE",
      link: "/analytics/finance",
      permission: "AR" // BELUM
    },
    {
      label: "SALES",
      link: "/analytics/sales",
      permission: "AR" // BELUM
    },
    {
      label: "PURCHASES",
      link: "/analytics/purchases",
      permission: "AR" // BELUM
    },
    {
      label: "INVENTORY",
      link: "/analytics/inventory",
      permission: "AR" // BELUM
    },
    {
      label: "CUSTOMERS",
      link: "/analytics/customers",
      permission: "AR" // BELUM
    },
    {
      label: "VENDORS",
      link: "/analytics/vendors",
      permission: "AR" // BELUM
    },
    {
      label: "EMPLOYEE",
      link: "/analytics/employee",
      permission: "AR" // BELUM
    },
    {
      label: "MARKETING",
      link: "/analytics/marketing",
      permission: "AR" // BELUM
    },
    {
      label: "PRODUCTION",
      link: "/analytics/production",
      permission: "AR" // BELUM
    },
    {
      label: "RETAIL",
      link: "/analytics/retail",
      permission: "AR" // BELUM
    },
    {
      label: "WAREHOUSE",
      link: "/analytics/warehouse",
      permission: "AR" // BELUM
    },
    {
      label: "LOCATIONS",
      link: "/analytics/locations",
      permission: "AR" // BELUM
    },
    {
      label: "PROJECT",
      link: "/analytics/project",
      permission: "AR" // BELUM
    },
    {
      label: "TASKS",
      link: "/analytics/tasks",
      permission: "AR" // BELUM
    },
    {
      label: "DISTRIBUTIONS",
      link: "/analytics/distributions",
      permission: "AR" // BELUM
    }
  ] as MenuType[]
}