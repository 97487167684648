// Components
import { BaseTable, Card, DashboardFilter, HeaderMenu, Loading, } from "components"
import { ExpensesActivites, InfoSection, PayableTable, StatementInfo } from "./components"

// Third-Party Libraries
import moment from "moment"

// Utils
import { convertNumber } from "utils"
import { usePayableList, useReceivableList } from "./utils"
import { FormType } from "../../../../components/widgets/DashboardFilter/utils"

export default function Dashboard() {
  // Hooks
  const receivableList = useReceivableList() 
  const payableList = usePayableList()

  return (
    <section className="container my-5">
      <section className="flex flex-col gap-3">
        <HeaderMenu title="FINANCE DASHBOARD" />
        <DashboardFilter 
          thirtyDatesMaximum 
          onChangeFilter={(value: FormType) => {
            receivableList.refetch(value);
            payableList.refetch(value);
          }} 
        />

        <Card>
          <Card.Body>
            <Card.Title>
              <div className="lg:flex">
                <StatementInfo data={[]} />

                <div className="label-text lg:w-[66%]">
                  <section>
                    <b>ACCOUNT RECEIVABLE</b>

                    <div className="lg:w-[45%] ml-5">
                      <InfoSection
                        className="!justify-start"
                        labelClass="lg:ml-5"
                        label="Turnover Ratio"
                        value="-"
                      />
                    </div>

                    <BaseTable>
                      <thead>
                        <tr>
                          <th>NO</th>
                          <th colSpan={2}>INVOICE ID#</th>
                          <th>REF SO ID#</th>
                          <th>DATE</th>
                          <th>CUSTOMER</th>
                          <th>BALANCE</th>
                          <th>7 DAYS</th>
                          <th>15 DAYS</th>
                          <th>21 DAYS</th>
                          <th>30 DAYS</th>
                          {/* <th>30+ DAYS</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {receivableList.isLoading || !receivableList.data.length ? (
                          <tr>
                            <td colSpan={11}>
                            {/* <td colSpan={12}> */}
                              <Loading
                                errorText="No data available"
                                loading={receivableList.isLoading}
                              />
                            </td>
                          </tr>
                        ) : receivableList.data.map((item, key) => {
                          // Functions
                          const getBalanceByAge = (
                            num1: number,
                            num2?: number
                          ): string => {
                            // Vars
                            let result = ""

                            if (num2) {
                              if (item.aged_receivable >= num1 && item.aged_receivable <= num2) {
                                result = convertNumber(item.balance).intoCurrency
                              }
                            } else {
                              if (item.aged_receivable >= num1) {
                                result = convertNumber(item.balance).intoCurrency
                              }
                            }

                            return result
                          }

                          return (
                            <tr key={key}>
                              <td className="w-[200px]">{key + 1}</td>
                              <td className="w-[100px]">{item.trx_code}</td>
                              <td className="w-[100px]">{item.sales_order.trx_code}</td>
                              <td>{moment(item.transaction_date).format("DD MMM YYYY")}</td>
                              <td className="w-[200px]">{item.customer.customer_name}</td>
                              <td className="w-[200px]">{convertNumber(item.balance).intoCurrency}</td>
                              <td>{getBalanceByAge(1, 7)}</td>
                              <td>{getBalanceByAge(8, 15)}</td>
                              <td>{getBalanceByAge(16, 21)}</td>
                              <td>{getBalanceByAge(21, 30)}</td>
                              {/* <td>{getBalanceByAge(30)}</td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </BaseTable>
                  </section>

                  <PayableTable data={payableList} />
                </div>
              </div>

              <ExpensesActivites data={[]} />
            </Card.Title>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body className="h-[100px]">
            <div className="font-bold">CHART</div>
          </Card.Body>
        </Card>
      </section>
    </section>
  )
}