import { FormCustomerQuotationDetailType } from "../../types"

export const calculateTotalCQDetail = (data: FormCustomerQuotationDetailType) => {
  const decimalDiscount = data?.discount ?? 0

  const total = (data?.qty_order ?? 0) * (data?.price_measure ?? 0)

  const totalDiscount = (total * decimalDiscount) / 100

  const totalAfterDiscount = total - totalDiscount

  const totalTax = (totalAfterDiscount * (data?.tax_rate ?? 0)) / 100

  const totalAfterTax = totalAfterDiscount + totalTax

  return {
    totalPrice: total,
    discount: decimalDiscount + "%",
    discountAmount: totalDiscount,
    finalPrice: totalAfterDiscount,
    totalTax: totalTax,
    totalAfterTax: totalAfterTax
  }
}
