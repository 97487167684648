import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

interface code { code: string }
export function useCode() {
  const api = useApi()
  const [dataCode, setDataCode] = useState<code | null>(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = () => api.get("/sub-group-task/gencode")

    fetchData()
    .then((res: SuccessFetch<{ payload: code }>) => setDataCode(res.data.payload))
    .catch(() => setDataCode(null))
    .finally(() => setLoading(false))

    return () => {
      setDataCode(dataCode)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataCode, isLoading, setDataCode }
}