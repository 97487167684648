import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartOptions } from 'chart.js';
import { GraphValueType } from '../../types';

// register chart
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function ProfitOrLose(props: { 
    className?: string 
    list?: GraphValueType
  }) {
  const data = {
    labels: [`${props.list?.label}`],
    datasets: [
      {
        label: `Sales revenue: ${props.list?.value}`,
        data: [props.list?.value],
        backgroundColor: ['rgb(167, 230, 255)', 'rgb(216, 191, 216)'],
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
        ticks: {
          display: true, 
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true, 
        grid: {
          color: (context) => context.tick.value === 0 ? 'rgba(0, 0, 0, 0.6)' : 'transparent',
        },
        ticks: {
          display: false,
        },
        border: {
          display: false, 
        },
      },
    },
  };

  return (
    <div className={props.className || "chart-container"}>
      <Bar data={data} options={options} />
    </div>
  );
};
