// React
import { PropsWithChildren, createContext } from "react"

// Types
import { BillType, DepositVendorType } from "../types"

// Utils
import { useDepositVendorList, useProbillList } from "../utils"

export const PurchaseTabContext = createContext<{
  bill: {
    data: BillType[]
    isLoading: boolean
    refetch: () => void
  }
  deposit: {
    data: DepositVendorType[]
    isLoading: boolean
    refetch: () => void
  }
  isLoading: boolean
}>({
  bill: {
    data: [],
    isLoading: false,
    refetch() {},
  },
  deposit: {
    data: [],
    isLoading: false,
    refetch() {},
  },
  isLoading: false
})

export function PurchaseTabProvider(params: PropsWithChildren<{
  purchase_order_id: string
}>): JSX.Element {
  // Hooks
  const probill = useProbillList(params.purchase_order_id)
  const depositVendor = useDepositVendorList(params.purchase_order_id)

  return (
    <PurchaseTabContext.Provider
      value={{
        bill: probill,
        deposit: depositVendor,
        isLoading: probill.isLoading || depositVendor.isLoading
      }}
    >
      {params.children}
    </PurchaseTabContext.Provider>
  )
}