import { Select } from "components";
import { PropsWithChildren } from "react";

export function CardSection(props: PropsWithChildren<{
    label: string,
    className?: string,
    placeholder?: string
}>) {
    return (
        <div className="overflow-x-auto">
            <div className="flex gap-2 items-center justify-between">
                <div className="text-lg">
                    <b>{props.label}</b>
                </div>
                <div className={props.placeholder ? "" : "invisible"}>
                    <Select
                        placeholder={props.placeholder}
                        className="w-[200px]"
                    />
                </div>
            </div>
            <div className="overflow-x-auto max-h-[200px] mt-5">
                {props.children}
            </div>
        </div>
    )
}
