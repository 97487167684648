import { Fragment} from "react"
import type { PersonalList } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"

export function IsActive(props: {
    id: PersonalList
    refetch?: () => void
}) {
    const api = useApi()
    const onSubmit = (value: PersonalList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put("/customer/changestatus", { customer_id: value.customer_id, customerdel: !value.is_deleted }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            )
            .then(() => props?.refetch && props.refetch())
            .catch(() => {})
            .finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle permission={"CR025"} isChecked={props.id.is_deleted} onClick={() => onSubmit(props.id)} />
        </Fragment>
    )
}