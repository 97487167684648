import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"

export function CreateModal(props: {
  payment_term_code?: string
  payment_term_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("payment_term_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "payment_term_id"> 

  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/paymentterm/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE PAYMENT TERM CUSTOMER" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When setting up data attributes for "Customer Payment Term" in an ERP system, it is important to define the conditions under which customers are expected to make payments. These attributes provide a structured way to manage payment terms, which helps in cash flow management, maintaining healthy customer relationships, and reducing payment-related disputes. Here is a comprehensive explanation of the data attributes for "Customer Payment Term":`}
                detail={`These data attributes are essential for defining customer payment terms in the ERP system, allowing the organization to effectively manage its receivables, optimize cash flow, and maintain strong customer relationships.`}
                title=""
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each payment term."
                      },
                      {
                        label: "Purpose",
                        value: "The code simplifies the identification and management of payment terms by providing a concise reference. It ensures consistency across transactions, making it easier for users to apply the correct payment terms to customer invoices and contracts."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "PT01" for "Net 30," "PT02" for "Net 60," or "PT03" for "Cash on Delivery (COD)" provide a quick way to reference different payment terms.`
                      }
                    ]
                  },
                  {
                    label: "Payment Term",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the payment term that specifies the duration or conditions under which payment is expected."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute clearly defines the payment expectations for both the company and the customer, including the number of days until payment is due, any early payment discounts, or other terms of payment. It is critical for setting expectations and reducing misunderstandings regarding payment timelines."
                      },
                      {
                        label: "Example",
                        value: `-"Net 30" — Payment is due 30 days from the invoice date. 
                                -"2/10, Net 30" — Payment is due in 30 days, but a 2% discount is offered if paid within 10 days. 
                                -"Cash on Delivery (COD)" — Payment is due at the time of delivery of goods or services. 
                                -"Advance Payment" — Full payment is required before the goods or services are delivered.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the payment term."
                      },
                      {
                        label: "Purpose",
                        value: "The description helps users understand the specific conditions, exceptions, or additional details associated with the payment term. This can include special discounts, interest charges for late payments, or specific instructions relevant to the payment process. It aids in communication and documentation, ensuring all parties are aware of the payment conditions."
                      },
                      {
                        label: "Example",
                        value: `-For "Net 30," the description might be: "Payment is required within 30 days from the date of the invoice. Late payments are subject to a 1.5% monthly interest charge." 
                                -For "2/10, Net 30," it could read: "A 2% discount applies if payment is made within 10 days; otherwise, the full amount is due within 30 days." 
                                -For "Cash on Delivery (COD)," it might state: "Full payment is required upon delivery of goods. No credit terms are extended."`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}