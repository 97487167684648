// React
import { Fragment } from "react"

// Carousel
import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css'

// Components
import { Button, HeaderMenu, Loading, Modal, PermissionLink } from "components"
import { CardSection, StockAnalysis } from "pages/Inventori/Transaction/AddNewItem/components/ChildItem/components/Item/components"
import { Chart } from "pages/Inventori/Transaction/AddNewItem/components/ChildItem/components/Item/components/DataLocationForm"

// Utils
import { convertNumber, useToggle } from "utils"
import { usePerformence } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"
import { useDefaultSize, useDetailPersonalData, useProductLocation } from "pages/Inventori/Transaction/AddNewItem/components/ChildItem/components/Item/utils"
import { useProductPictures } from "../utils"

export function DetailProduct(params: {
  barcode: string
  id: string
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <div
        className="cursor-pointer"
        onClick={toggle}
      >
        {params.barcode}
      </div>

      {isActive && (
        <Modal
          isOpen
          closeModal={toggle}
          title="DETAIL PRODUCT"
        >
          <Modal.Body>
            <Header id={params.id} />
            <Stock id={params.id} />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}

function Header(params: { id: string }): JSX.Element {
  // Hooks
  const product = useDetailPersonalData(parseInt(params.id))
  const { dataDefault } = useDefaultSize()

  return (
    <HeaderMenu title="ITEM PROFILE">
      {dataDefault && product.dataDetail && (
        <PermissionLink
          permission="IR012"
          to="/inventory/transaction/add-new-item/update"
          state={{ dataId: product.dataDetail, dataSize: dataDefault }}
        >
          <Button color="primary">EDIT ITEM</Button>
        </PermissionLink>
      )}
    </HeaderMenu>
  )
}

function Stock(params: { id: string }): JSX.Element {
  // Hooks
  const pictures = useProductPictures(params.id)
  const { data, isLoading } = usePerformence({ product_id: parseInt(params.id) })

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <section className="flex flex-col gap-3">
      <section className="flex gap-2">
        <section>
          <div className="text-[16px] mb-5"><b>STOCK ANALYSIS</b></div>
          <StockAnalysis label="Stock In" value={data?.stoct_id ? convertNumber(data?.stoct_id)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="Stock Out" value={data?.stoct_out ? convertNumber(data?.stoct_out)?.intoNormalAmount! : "0"} textError />
          <StockAnalysis label="Quantity On Hand :" value={data?.stoct_on_hand ? convertNumber(data?.stoct_on_hand)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="COGM Current Value :" value={data?.cogm_current_value ? convertNumber(data?.cogm_current_value)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="Average Cost :" value={data?.average_cost ? convertNumber(data?.average_cost)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="Committed :" value={data?.commited ? convertNumber(data?.commited)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="On Vendor Order :" value={data?.on_vendor ? convertNumber(data?.on_vendor)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="Available :" value={data?.available ? convertNumber(data?.available)?.intoNormalAmount! : "0"} />
          <StockAnalysis label="Retail Current Value :" value={data?.retail_current_value ? convertNumber(data?.retail_current_value)?.intoNormalAmount! : "0"} />
        </section>

        {pictures.isLoading || !pictures.data.length ? (
          <Loading loading={pictures.isLoading} />
        ) : (
          <Carousel
            additionalTransfrom={0}
            arrows={true}
            autoPlaySpeed={3000}
            centerMode={false}
            className="max-w-[380px] w-full text-center"
            containerClass=""
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 1
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 1
              }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            customLeftArrow={<div className='absolute top-[50%] transform translate-y-[-50%] left-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg ml-2'>❮</div>}
            customRightArrow={<div className='absolute top-[50%] transform translate-y-[-50%] right-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg mr-2'>❯</div>}
          >
            {pictures.data.map((item, key) => (
              <div key={key} className="p-2">
                <img
                  src={`${process.env.REACT_APP_BASE_URL_API}/getimage/?imgpath=${item}`}
                  alt={item}
                  className="cursor-pointer w-full object-cover"
                />
              </div>
            ))}
          </Carousel>
        )}
      </section>

      <section className="flex gap-3">
        <section className="grow overflow-x-auto flex flex-col gap-3">
          <section className="flex gap-2">
            <CardSection className="!bg-[#758694] !border-[#758694]" label="Inventory Adjustment" value="1" text="Pcs" />
            <CardSection className="!bg-[#FFAF00] !border-[#FFAF00]" label="On Order" value="0" text="Box" />
            <CardSection className="!bg-[#387F39] !border-[#387F39]" label="Delivered" value="1800" text="Box" />
            <CardSection className="!bg-[#5B99C2] !border-[#5B99C2]" label="Commited" value="" text="Pcs" />
            <CardSection className="!bg-[#03346E] !border-[#03346E]" label="Soid" value="" text="Pcs" />
          </section>

          <section className="flex gap-2">
            <CardSection className="!bg-[#399918] !border-[#399918]" label="On Customer Quotation" value="" text="Pcs" />
            <CardSection className="!bg-[#FD9B63] !border-[#FD9B63]" label="Build Component" value="0" text="Pcs" />
            <CardSection className="!bg-[#1A5319] !border-[#1A5319]" label="Total Stock Incoming" value="1800" text="Pcs" />
            <CardSection className="!bg-[#A02334] !border-[#A02334]" label="Total Stock Out Going" value="" text="Pcs" />
            <CardSection className="!bg-[#658147] !border-[#658147]" label="Total Stock On Hard" value="" text="Pcs" />
          </section>
        </section>
      </section>

      <ChartSection id={params.id} />
    </section>
  )
}

function ChartSection(params: { id: string }): JSX.Element {
  // Hooks
  const { data, isLoading } = useProductLocation(parseInt(params.id))

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return <Chart data={data} />
}