// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { FilterType } from "../../types"

// Utils
import { useApi } from "utils"

export function useCoaGroup(): {
  data: FilterType[],
  isLoading: boolean
} {
  // Vars
  const permissionGroupList: {
    [key: string]: string
  } = {
    "": "AR01",
    "01": "AR02",
    "02": "AR03",
    "03": "AR04",
    "04": "AR05",
    "05": "AR06",
    "06": "AR07",
    "08": "AR08",
    "09": "AR09"
  }
  const show_all = {
    label: "Show All",
    value: ""
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<FilterType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/groupcoa/").then((res: SuccessFetch<{
      payload: {
        group_coa_code: string
        group_coa_name: string
      }[]
    }>) => {
      setData([
        {
          ...show_all,
          permission: permissionGroupList[show_all.value]
        },
        ...res.data.payload.map(item => {
          return {
            label: item.group_coa_name,
            value: item.group_coa_code,
            permission: permissionGroupList[item.group_coa_code]
          }
        })
      ])
    }).catch(() => {
      setData([{
        ...show_all,
        permission: permissionGroupList[show_all.value]
      }])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}