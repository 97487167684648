import { ActionButton } from "components";
import toast from "react-hot-toast";
import { useApi } from "utils";

export function Delete(props: {
    id: number
    refetch: () => void
}){
    const api = useApi()
    const onSubmit = ( id: number ) => {
        return new Promise<void>((resolve) => {
          toast.promise(
            api.delete("/opening-stock/delete", { params: { opening_stock_id: id} }),
            {
              loading: "Loading...",
              success: (res) => res.data.message,
              error: (err) => err.response.data.message ?? err.response.message
            }
          ).then(() => {
            props?.refetch && props.refetch()
          }).catch(() => {}).finally(resolve)
        })
      }

    return (
        <ActionButton.Delete permission={"IT084"} className="join-item" onClick={() => onSubmit(props.id)}  />
    )
}