// React
import { useEffect } from "react"

// Components
import { BaseTable, Card, FooterMenu, Loading, RegisterLayout } from "components"
import { Create, Item } from "./components"

// Contexts
import { LocationProvider } from "contexts"

// Utils
import { usePermission } from "utils"
import { DataProvider, useCoaGroup, useData, useFilter } from "./utils"

interface SelectOptionType {
  label: string
  value: string
  permission?: string
}

export default function Register() {
  const { data, isLoading } = useCoaGroup()

  if (isLoading) {
    return (
      <section className="min-h-[500px]">
        <Loading loading />
      </section>
    )
  } else {
    return <ViewSection dataFilter={data} />
  }
}

export function ViewSection(props: { dataFilter: SelectOptionType[] }) {
  const { value, setValue } = useFilter(props.dataFilter[0].value)
  const { data, isLoading, refetch } = useData()
  
  const permissionData = props?.dataFilter.find(element => element.value === value);
  const permission = usePermission([permissionData?.permission?.toString()!]);

  const items = data.sort((a, b) => a.coa_code.localeCompare(b.coa_code)).filter(item => {
    const isParent: boolean = Boolean(item.parent_id === -1)

    if (value !== "") {
      return isParent && item.group_coa_code === value
    }

    return isParent
  })

  useEffect(() => {
    refetch()

    // eslint-disable-next-line
  }, [value])

  return (
    <LocationProvider>
      <section className="my-5 mx-5">
        {isLoading ? (
          <Loading loading />
        ) : (
          <DataProvider data={data} refetch={refetch}>
            <RegisterLayout.Header title="CHART OF ACCOUNTS REGISTER" />

            <RegisterLayout.Filter
              list={props.dataFilter}
              value={value}
              onChangeFilter={setValue}
            />

              <section className="mt-2">
                <Card>
                  <Card.Body className="flex-row justify-between items-center">
                    <Card.Title>CHART OF ACCOUNT</Card.Title>
                    {Boolean(value === "" || data.length === 0) && <Create onSuccess={refetch} />}
                  </Card.Body>

                  <Card.Body>
                    <div className="flex flex-col min-h-[calc(100vh-520px)]">
                      <BaseTable className="border-collapse">
                        <thead>
                          <tr>
                            <th>NO</th>
                            <th className="h-[1px]">CONTENT</th>
                            <th className="h-[1px]">HIERARCHY</th>
                            <th className="h-[1px]">CHQ</th>
                            <th>ACCOUNT</th>
                            <th>NAME</th>
                            <th>LOCATION</th>
                            <th>LINKED</th>
                            <th>LEVEL</th>
                            <th>ACC TYPE</th>
                            <th>BALANCE</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        
                        <tbody>
                          {items.length === 0 ? (
                            <tr>
                              <td colSpan={11} className="text-center">
                                <Loading errorText="No data available" />
                              </td>
                            </tr>
                          ) : items.map((item, key) => (
                            <Item permission={permission} key={key} index={key} item={item} onSuccess={refetch} />
                          ))}
                        </tbody>
                      </BaseTable>
                    </div>
                  </Card.Body>
                </Card>
              </section>

              <section className="mt-2">
                <FooterMenu />
              </section>
          </DataProvider>
        )}
      </section>
    </LocationProvider>
  )
}