import * as yup from "yup"

export const addressValidationSchema = yup.object().shape({ 
    address_type_id: yup.string().label("Address").optional(),
    address_type_id_2: yup.string().label("Address").optional(),

    country_id: yup.string().label("Address").optional(),
    country_id_2: yup.string().label("Address").optional(),

    province_id: yup.string().label("Address").optional(),
    province_id_2: yup.string().label("Address").optional(),

    regency_id: yup.string().label("Address").optional(),
    regency_id_2: yup.string().label("Address").optional(),

    subdistrict_id: yup.string().label("Address").optional(),
    subdistrict_id_2: yup.string().label("Address").optional(),

    village_id: yup.string().label("Address").optional(),
    village_id_2: yup.string().label("Address").optional(),

    address: yup.string().label("Address").optional().max(255, "Must be 255 characters or less"),
    address_2: yup.string().label("Address").optional().max(255, "Must be 255 characters or less")
})