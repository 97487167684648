import { BaseTable, Card, CommandButton, Loading } from "components"
import { Fragment, useState } from "react"
import { Filter, Item } from "./components"
import { FilterType } from "./types"
import { defaultFilter, useList } from "./utils"
import { DetailCustomerQuotation } from "pages/Sales/Transaction/CustomerQuotation/types"

export function TabQuotation() {
  const [selected, setSelected] = useState<FilterType>(defaultFilter)
  const { data, isLoading, refetch } = useList(selected)

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <Filter selected={selected} setSelected={setSelected} />
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <Card>
          <Card.Body>
            <div className="overflow-x-auto">
              <BaseTable>
                <thead>
                  <tr>
                    <th>No</th>
                    <th colSpan={2}>QUOTATION ID#</th>
                    <th>REF SO ID#</th>
                    <th>DATE</th>
                    <th>SALES</th>
                    <th>CUSTOMER</th>
                    <th>LOCATION</th>
                    <th>STATUS</th>
                    <th>QTY. ORDER</th>
                    <th>TOTAL SALES</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading || data?.payload?.length === 0 ? (
                    <tr>
                      <td colSpan={15} className="text-center">
                        <Loading
                          loading={isLoading}
                          errorText={isLoading ? "" : "No data available"}
                        />
                      </td>
                    </tr>
                  ) : (
                    data?.payload?.map(
                      (item: DetailCustomerQuotation, key: number) => (
                        <Item
                          key={item.customer_quotation_id}
                          data={item}
                          index={key}
                          dataPaging={selected}
                          onDeleteSuccess={refetch}
                        />
                      )
                    )
                  )}
                </tbody>
              </BaseTable>
            </div>
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <Card>
          <Card.Body>
            <section className="flex flex-wrap gap-3">
              <CommandButton actiontype="help" />
              <CommandButton actiontype="print" />
              <CommandButton actiontype="email" />
              <CommandButton actiontype="export" />
            </section>
          </Card.Body>
        </Card>
      </section>
    </Fragment>
  )
}
