import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { FormBank } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: { bank_code?: string; bank_name?: string; description?: string; toggle: () => void; refetch?: () => void }) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) { methods.setValue("bank_code", dataCode?.code) }
    // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateListBank = Omit<FormBank, "bank_id"> 

  const defaultValues:CreateListBank= {
    bank_code: "",
    bank_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    bank_code: yup.string().label("Bank code").required().min(2).max(3),
    bank_name: yup.string().label("Bank name").required().max(100, "Must be 100 characters or less"),
    description: yup.string().label("Category name name").optional().max(250, "Must be 250 characters or less"),
  })
  const onSubmit = (value: CreateListBank) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/bank/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListBank>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="SETUP | CREATE BANK" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                title="Creating a Bank"
                bottomList={[
                  {
                    label: "Button: Save Record",
                    list: [
                      {
                        label: "Purpose",
                        value: "To save the bank details after entering all necessary information."
                      },
                      {
                        label: "Action",
                        value: "Click this button to store the bank data and make it available for use in the system."
                      },
                    ]
                  },
                  {
                    label: "Button: Tutorial",
                    list: [
                      {
                        label: "Purpose",
                        value: "Provides guidance or a step-by-step walkthrough on how to create and manage bank information."
                      },
                      {
                        label: "Action",
                        value: "Click this button to access a tutorial or help section that explains the bank setup process in detail."
                      },
                    ]
                  },
                ]}
                list={[
                  {
                    label: "Bank Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique identifier or code assigned to the bank."
                      },
                      {
                        label: "Purpose",
                        value: "Helps in easily referencing and distinguishing between different banks within the system."
                      },
                      {
                        label: "Example",
                        value: "BNK001, HSBC-UK, WELLS-FARGO."
                      }
                    ]
                  },
                  {
                    label: "Bank Name",
                    list: [
                      {
                        label: "Definition",
                        value: "The official name of the bank."
                      },
                      {
                        label: "Purpose",
                        value: "Provides a clear and recognizable name for the bank."
                      },
                      {
                        label: "Example",
                        value: "HSBC, Wells Fargo, Chase Bank."
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief description of the bank."
                      },
                      {
                        label: "Purpose",
                        value: "Offers additional details or context about the bank."
                      },
                      {
                        label: "Example",
                        value: "Global banking and financial services, Regional bank with a focus on retail banking."
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}