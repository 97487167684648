// React
import { Fragment } from "react"

// Components
import { ActionButton, Button, Loading, Modal } from "../../../../../../components"
import { FormSection } from "../../FormSection"

// Contexts
import { RestrictedUpdateContext } from "pages/Accounts/Register/contexts"

// Form
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { FormType } from "../../../types"

// Utils
import { regex_validation, useApi, useToggle } from "../../../../../../utils"
import { useDetail } from "../utils"

export function Update(props: {
  id: number
  isChildNotEmpty: boolean
  onSuccess: () => void
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update permission={"AR013"} className="join-item" onClick={toggle} />

      {isActive && (
        <ModalSection
          id={props.id}
          isChildNotEmpty={props.isChildNotEmpty}
          toggle={toggle}
          onSuccess={props.onSuccess}
        />
      )}
    </Fragment>
  )
}

function ModalSection(props: {
  id: number
  isChildNotEmpty: boolean
  onSuccess: () => void
  toggle: () => void
}) {
  // Hooks
  const { data, dataError, isLoading } = useDetail(props.id)

  return (
    <Modal isOpen title="EDIT CHART OF ACCOUNT" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <RestrictedUpdateContext.Provider
          value={!Boolean(props.isChildNotEmpty && Boolean(data!.opening_balance === null || data!.opening_balance === 0))}
        >
          <DataForm data={data!} id={props.id} toggle={props.toggle} onSuccess={props.onSuccess} />
        </RestrictedUpdateContext.Provider>
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: FormType
  id: number
  onSuccess: () => void
  toggle: () => void
}) {
  // Vars
  const number_only = regex_validation.number_only

  // Hooks
  const api = useApi()

  // Form
  const validationSchema = yup.object().shape({
    coa_code: yup.string().label("Account Number").required(),
    coa_name: yup.string().label("Account Name").required(),
    group_coa_id: yup.string().label("Account Group").required().matches(regex_validation.number_only.value, regex_validation.number_only.description),
    header_level: yup.string().label("Header Level").required().matches(number_only.value, number_only.description),
    location_id: yup.string().label("Location").required(),
    cheque_coa: yup.boolean().label("Cheque Account").required(),
    note: yup.string().label("Note").required().nullable(),
  })
  const onSubmit = (
    // @ts-ignore
    value: FormType
  ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.putForm(`/coa/edit?coa_id=${props.id}`, {
          coa_code: value.coa_code,
          coa_name: value.coa_name,
          group_id: value.group_coa_id,
          header_level: value.header_level,
          cheque_coa: value.cheque_coa,
          note: value.note,
          location_id: value.location_id
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props.onSuccess()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: props.data,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormSection isUpdate />

        <Modal.Footer className="text-end">
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}