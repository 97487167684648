import { CardSection } from "../CardSection";
import { Card, Toggle } from "components";
import { AreaChart } from "../GrafikSection";
import { PiWarningCircleFill } from "react-icons/pi";
import { GraphValueType } from "../../types";

export function FinancialRatio(params: { list?: GraphValueType[] }) {
    return (
        <section>
            <div>
                <span className="font-bold text-[15px] mr-2">Financial ratio</span>
                <span>On 3 Oct 2024</span>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 mt-2 gap-2 h-full">
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Current ratio"
                                value="1.43"
                                subLabel="down 4.01 from 1 Jan - 3 Oct 2023"
                                persentase={-5.6}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[20px]">
                                    <AreaChart
                                        label={params.list?.map(item => item.label) ?? []}
                                        value={params.list?.map(item => item.value) ?? []}
                                        labelContent="current ratio"
                                        backgroundColor="blue"
                                        borderColor="blue"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1.00 in current liabilities, you have Rp 1.43 of current assets
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Debt ratio"
                                value="0.04"
                                subLabel="Up 0.03 from 1 Jan - 3 Oct 2023"
                                persentase={1}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[20px]">
                                    <AreaChart
                                        label={params.list?.map(item => item.label) ?? []}
                                        value={params.list?.map(item => item.value) ?? []}
                                        labelContent="Debt ratio"
                                        backgroundColor="blue"
                                        borderColor="blue"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1.00 of assets, you have Rp 0.04 of debt
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Current liabilities to net worth"
                                value="0.04"
                                subLabel="Up 0.03 from 1 Jan - 3 Oct 2023"
                                persentase={1}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[20px]">
                                    <AreaChart
                                        label={params.list?.map(item => item.label) ?? []}
                                        value={params.list?.map(item => item.value) ?? []}
                                        labelContent="Current liabilities to net worth"
                                        backgroundColor="blue"
                                        borderColor="blue"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1.00 of equity, you business is financing Rp 0.04 in short-term debts
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Debt to equity ratio"
                                value="0.04"
                                subLabel="Up 0.03 from 1 Jan - 3 Oct 2023"
                                persentase={1}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[20px]">
                                    <AreaChart
                                        label={params.list?.map(item => item.label) ?? []}
                                        value={params.list?.map(item => item.value) ?? []}
                                        labelContent="Debt to equity ratio"
                                        backgroundColor="blue"
                                        borderColor="blue"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1.00 of equity, you business is has Rp 0.04 of debt
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Working capital to total assets"
                                value="0.02"
                                subLabel="Down 0.02 from 1 Jan - 3 Oct 2023"
                                persentase={-1}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[20px]">
                                    <AreaChart
                                        label={params.list?.map(item => item.label) ?? []}
                                        value={params.list?.map(item => item.value) ?? []}
                                        labelContent="Working capital to total assets"
                                        backgroundColor="blue"
                                        borderColor="blue"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1.00 of the total assets a bussiness has, Rp 0.02 of these are working capital
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Fixed assets to net worth"
                                value="0.98"
                                subLabel="Up 0.02 from 1 Jan - 3 Oct 2023"
                                persentase={1}
                                icon={<PiWarningCircleFill />}
                            >
                                <div className="mt-[20px]">
                                    <AreaChart
                                        label={params.list?.map(item => item.label) ?? []}
                                        value={params.list?.map(item => item.value) ?? []}
                                        labelContent="Fixed assets to net worth"
                                        backgroundColor="blue"
                                        borderColor="blue"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1.00 equity, your bussiness has Rp 0.98 tied up in fixed assets
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div>
                <div className="flex justify-center mt-3">
                    <Toggle
                       label="Show Financial Ratio"
                    />
                </div>
            </div>
        </section>
    );
}