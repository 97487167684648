/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { nextWeek, today, useApi, useHandleAuth } from "utils"
import { FormSection } from "./components"
import { taskSchema, TaskType } from "./types"
import { submitMap } from "./utils/functions"

export default function TaskCreate() {
  const api = useApi()
  const { userTokenData } = useHandleAuth()
  const userToken = userTokenData()
  const defaultValues: TaskType = useMemo(() => {
    return {
      location_id: NaN,
      department_id: NaN,
      description: "",
      project_id: NaN,
      task_status: 1,
      mr_group_task_id: NaN,
      mr_group_sub_id: NaN,
      subject: "",
      owner_task_id: userToken?.user_id ?? NaN,
      trx_code: "",
      priority: 1,
      date_start: today(),
      date_end: nextWeek()
    }
  }, [])

  const methods = useForm<TaskType>({
    defaultValues,
    resolver: yupResolver(taskSchema)
  })

  const onSubmit = async (value: TaskType) => {
    await toast
      .promise(api.post("/task", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then((res) => {
        methods.setValue("task_id", res.data.payload?.task_id)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection
        approvalView={false}
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  )
}
