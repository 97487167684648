import { BaseTable } from "components";
import { PiArrowDownBold, PiArrowUpBold } from "react-icons/pi";

export function Expenses() {
    return (
        <BaseTable>
            <thead>
                <tr>
                    <th>Largest expenses</th>
                    <th>1 jan - 3 oct 2024</th>
                    <th>1 jan - 3 oct 2023</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Legal Expenses - Notaris</td>
                    <td className="text-right">
                        896,633,000 <PiArrowUpBold className="text-green-500 inline" />
                    </td>
                    <td className="text-right">72,292,239</td>
                </tr>
                <tr>
                    <td>Management Fee</td>
                    <td className="text-right">562,800,000 <span><PiArrowDownBold className="text-red-500 inline" /></span></td>
                    <td className="text-right">723,489,600</td>
                </tr>
                <tr>
                    <td>PEX - CE Labour</td>
                    <td className="text-right">423,056,300 <span ><PiArrowUpBold className="text-green-500 inline" /></span></td>
                    <td className="text-right">0</td>
                </tr>
                <tr>
                    <td>Travel - National</td>
                    <td className="text-right">361,778,395 <span><PiArrowUpBold className="text-green-500 inline" /></span></td>
                    <td className="text-right">142,934,678</td>
                </tr>
                <tr>
                    <td>Temporary Transactions</td>
                    <td className="text-right">272,176,887 <span><PiArrowUpBold className="text-green-500 inline" /></span></td>
                    <td className="text-right">0</td>
                </tr>
            </tbody>
        </BaseTable>
    )
}