import { Button, Modal } from "components"
import { FormProvider, useForm } from "react-hook-form"
import { DataList } from "../types"
import { FormSection } from "./FormSection"
import { menuTitle } from "./ChildItem/components/Item/components"
import { useApi } from "utils"
import { useContext, useEffect } from "react"
import { Render, useCode } from "../utils"
import * as yup from "yup"
import toast from "react-hot-toast"
import { yupResolver } from "@hookform/resolvers/yup"

export function CreateModal(props: {
    mr_periode_code?: string
    mr_periode_name?: string
    description?: string
    toggle: () => void
    refetch?: () => void
}) {
    const api = useApi()
    const { toggle } = useContext(Render)
    const { dataCode} = useCode()

    useEffect(() => {
      if (dataCode?.code) {
        methods.setValue("mr_periode_code", dataCode?.code)
      }
   // eslint-disable-next-line
    }, [dataCode?.code])
    

    type CreateData = Omit<DataList, "mr_periode_id">

    const defaultValues: CreateData = {
        mr_periode_code: "",
        mr_periode_name: "",
        description: ""
      }

      const validationSchema = yup.object().shape({
        mr_periode_code: yup.string().label("Periode code").required().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3),
        mr_periode_name: yup.string().label("Periode name").required(),
        description: yup.string().label("Description").optional(),
      })


      const onSubmit = (value: CreateData) => {
        return new Promise<void>((resolve) => {
          toast.promise(
            api.post("/periode/add", value),
            {
              loading: "Loading...",
              success: (res) => res.data.message,
              error: (err) => err.response.data.message ?? err.response.message
            }
          ).then(() => {
            props.toggle()
            props?.refetch && props.refetch()
            toggle()
          }).catch(() => { }).finally(resolve)
        })
      }

      const methods = useForm<CreateData>({
        defaultValues,
        resolver: yupResolver(validationSchema)
      })


    return (
        <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormSection />

                    <Modal.Footer className="text-end">
                        <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
                    </Modal.Footer>
                </form>
            </FormProvider>
        </Modal>
    )
}