// React
import { Fragment, useContext, useEffect } from "react"

// Components
import { BaseTable, Card, DateInput, HeaderMenu, Input, Select, Textarea } from "components"

// Contexts
import { DisabledContext } from "pages/Purchase/Transaction/contexts"
import { ProductUnitContext, ProductUnitProvider } from "pages/Purchase/Transaction/PurchaseOrder/components/DetailBody/contexts"
import { DetailDataContext, ProductContext, ProductProvider } from "../contexts"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"

// Types
import type { DataCountDetail } from "../../types"

// Utils
import { convertNumber, useLocation } from "utils"
import { type FormType, useDataCode } from "../utils"

export function FormSection(params: {
  footerElement?: JSX.Element
}): JSX.Element {
  // Form
  const disabled = useContext(DisabledContext)
  const { control } = useFormContext<FormType>()
  const { invcount, countdetail } = useContext(DetailDataContext)

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | ADJUSTMENT INVENTORY">
        <Code />
      </HeaderMenu>

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <Input
            disabled
            label="LOCATION"
            value={invcount.location_name}
          />

          <Input
            disabled
            label="REQUEST BY"
            placeholder="Select Employee"
            value={invcount.employee_name}
          />

          <DateInput
            disabled
            label="COUNT DATE"
            selected={moment(invcount.transaction_date).toDate()}
            onChange={() => {}}
          />

          <Textarea
            disabled
            label="COUNT MEMO"
            value={invcount.memo}
          />

          <Input
            disabled
            label="LINK COUNT ID#"
            value={invcount.trx_code}
          />
        </section>

        <section className="flex flex-col gap-3">
          <Location />

          <Controller
            name="transaction_date"
            control={control}
            render={({ field, fieldState }) => (
              <DateInput
                label="ADJUSTMENT DATE"
                disabled={disabled}
                error={fieldState.error?.message}
                ref={field.ref}
                selected={moment(field.value).toDate()}
                onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
              />
            )}
          />

          <Controller
            name="memo"
            control={control}
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label="ADJUSTMENT MEMO"
                disabled={disabled}
                error={fieldState.error?.message}
              />
            )}
          />
        </section>
      </section>

      <Card>
        <Card.Body className="flex flex-col gap-3">
          <div className="text-xl font-bold">LIST ITEM TRANSFERED</div>

          <BaseTable>
            <thead>
              <tr>
                <th rowSpan={2}>#</th>
                <th rowSpan={2}>ITEM</th>
                <th rowSpan={2}>DESCRIPTION</th>
                <th rowSpan={2}>UNIT</th>
                <th rowSpan={2}>STOCK IN</th>
                <th rowSpan={2}>STOCK OUT</th>
                <th colSpan={2}>RECAP IN CONVERTION UNIT</th>
                <th rowSpan={2}>AVERAGE UNIT PRICE</th>
                <th rowSpan={2}>AMOUNT ADJUSTMENT</th>
                <th rowSpan={2}>POSITION</th>
                <th rowSpan={2}>COA INVENTORY</th>
                <th rowSpan={2}>COA EXPENCES/COGS</th>
              </tr>

              <tr>
                <th>IN</th>
                <th>OUT</th>
              </tr>
            </thead>

            <ProductProvider>
              <ProductUnitProvider>
                <tbody>
                  {!countdetail.length ? (
                    <tr>
                      <td colSpan={13} />
                    </tr>
                  ) : countdetail.map((item, key) => (
                    <Item
                      key={key}
                      index={key}
                      item={item}
                    />
                  ))}
                </tbody>
              </ProductUnitProvider>
            </ProductProvider>
          </BaseTable>
        </Card.Body>
      </Card>

      {params.footerElement}
    </section>
  )
}

function Code(): JSX.Element {
  // Hooks
  const { fetchCode } = useDataCode()

  // Form
  const { control, formState, setValue } = useFormContext()
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  useEffect(() => {
    if (!formState.defaultValues?.trx_code) {
      fetchCode(transaction_date).then(res => {
        setValue("trx_code", res)
      }).catch(err => {
        setValue("trx_code", err)
      })
    }

    // eslint-disable-next-line
  }, [transaction_date])

  return <Fragment>{trx_code}</Fragment>
}

function Item(params: {
  index: number
  item: DataCountDetail
}): JSX.Element {
  // Hooks
  const products = useContext(ProductContext)
  const units = useContext(ProductUnitContext)

  // Vars
  const item = params.item
  const product = products.data.find(i => i.product_id === item.product_id)!
  const unit = units.data.find(i => i.value === item.mr_unit_id.toString())!

  return (
    <tr>
      <td>{params.index + 1}</td>
      <td>{product?.product_barcode}</td>
      <td>{product?.product_name}</td>
      <td>{unit?.label}</td>
      <td className="text-right">{item.stock_in}</td>
      <td className="text-right">{item.stock_out}</td>
      <td className="text-right">{item.stock_in * item.quantity_unit_sell_convention}</td>
      <td className="text-right">{item.stock_out * item.quantity_unit_sell_convention}</td>
      <td className="text-right">{convertNumber(item.avg_price_use).intoCurrency}</td>
      <td className="text-right">{item.avg_price_use}</td>
      <td className="uppercase">{item.position}</td>
      <td>{item.coa_inventory_code} {item.coa_inventory_name}</td>
      <td>{item.coa_coex_code} {item.coa_coex_name}</td>
    </tr>
  )
}

function Location(): JSX.Element {
  // Hooks
  const disabled = useContext(DisabledContext)
  const { data, isLoading } = useLocation()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      name="location_id"
      control={control}
      render={({ field, fieldState }) => (
        <Select
          label="ADJUSTMENT BY"
          placeholder="Select Location"
          isDisabled={disabled}
          error={fieldState.error?.message}
          isLoading={isLoading}
          options={data}
          value={data.find(item => item.value === field.value)}
          ref={field.ref}
          onChange={res => {
            if (res) {
              field.onChange(res.value)
            }
          }}
        />
      )}
    />
  )
}