import { useTaskProgress } from "pages/Task/utils/hooks"

type TableStatusActionLineProps = {
  taskProgress: ReturnType<typeof useTaskProgress>
}

export const TableStatusActionLine = ({ taskProgress }: TableStatusActionLineProps) => {
  return (
    <table className="text-xs border-black border-[1px] text-black mt-3">
      <thead className="border-black border-[1px]">
        <tr className="uppercase text-center">
          <th className="border-black border-[1px] bg-notstarted">
            Not Started
          </th>
          <th className="border-black border-[1px] bg-inprogress">
            In Progress
          </th>
          <th className="border-black border-[1px] bg-completed">Completed</th>
          <th className="border-black border-[1px] bg-cancelled">Canceled</th>
        </tr>
      </thead>
      <tbody className="border-black border-[1px]">
        <tr className="text-center">
          <td className="border-black border-[1px] bg-notstarted">{taskProgress.data?.not_started ?? "0"}</td>
          <td className="border-black border-[1px] bg-inprogress">{taskProgress.data?.in_progress ?? "0"}</td>
          <td className="border-black border-[1px] bg-completed">{taskProgress.data?.completed ?? "0"}</td>
          <td className="border-black border-[1px] bg-cancelled">{taskProgress.data?.canceled ?? "0"}</td>
        </tr>
      </tbody>
    </table>
  )
}