import { useState } from "react"
import type { SuccessFetch } from "types"
import type { FormType } from "components/widgets/DashboardFilter/utils"
import { useApi } from "utils"
import { AccountPayableList } from "../../types"

export function usePayableList() {
  const api = useApi()
  const [data, setData] = useState<AccountPayableList[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const refetch = (params: FormType): void => {
    setLoading(true)

    api.get("/dashboard/transaction/account-payable/",
      {
        params: {
          ...params,
          location_id: params.location_id !== "" ? params.location_id : null
        }
      }
    ).then((res: SuccessFetch<{ payload: AccountPayableList[] }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}