// Components
import { BaseTable, Button, Card, Checkbox, DateInput, FooterMenu, HeaderMenu, Input, Select, Textarea } from "components"
import { TableSection } from "../components"

// Icons
import { TbChevronRight } from "react-icons/tb"

export default function EditRecordReject(): JSX.Element {
  return (
    <div className="container my-5 flex flex-col gap-3">
      <HeaderMenu title="DATA ENTRY | REJECT ITEM">ABC123</HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
        <Select
          label="SUPPLIER"
          placeholder="Select Supplier"
        />

        <div className="mb-3 flex flex-wrap">
          TRADING TERM <span className="mt-1"><TbChevronRight /></span> Net 30 days after OEM
        </div>

        <section className="lg:justify-self-end mb-3">
          <Checkbox readOnly>TAX-INCLUSIVE</Checkbox>
        </section>
      </section>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="flex flex-col gap-3">
            <section>
              <Select
                label="DELIVER TO"
                placeholder="Select Deliver"
              />

              <Textarea disabled />
            </section>

            <Input label="MEMO" />
            <Input label="LINK TO BILL ID" />
          </section>

          <section className="flex flex-col gap-3">
            <DateInput
              label="DATE"
              onChange={() => {}}
            />

            <Input label="SUPPLIER ID #" />

            <DateInput
              label="PROMISED DATE"
              onChange={() => {}}
            />
            
            <Input
              disabled
              label="DELIVERY"
            />
          </section>
        </Card.Body>
      </Card>

      <BaseTable>
        <thead>
          <tr>
            <th>QTY REJECTED</th>
            <th>UNIT</th>
            <th>ITEM CODE</th>
            <th>DESCRIPTION</th>
            <th>PRICE</th>
            <th>DISC (%)</th>
            <th>TOTAL</th>
            <th>JOB</th>
            <th>TAX</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>10</td>
            <td>LUSIN</td>
            <td>1100223355</td>
            <td>GELAS KACA</td>
            <td>10.000</td>
            <td>10%</td>
            <td>90.000</td>
            <td>VILLA</td>
            <td>PPN (11%)</td>
          </tr>
        </tbody>
      </BaseTable>

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <TableSection>
            <TableSection.Row
              label="REJECT STATUS"
              value="OPEN"
            />

            <TableSection.Row
              label="APPROVE STATUS"
              value="APPROVE"
            />

            <TableSection.Row
              label="APPROVED BY"
              value="KOMANG"
            />
          </TableSection>
        </section>

        <section className="flex flex-col lg:items-end gap-3">
          <BaseTable
            plain
            className="w-full min-w-[100px] md:max-w-[400px] text-right"
          >
            <tbody>
              <tr>
                <td className="w-[1px] whitespace-nowrap">SUBTOTAL</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="w-[1px] whitespace-nowrap">TAX</td>
                <td>0</td>
              </tr>
              <tr className="font-bold">
                <td className="w-[1px] whitespace-nowrap">TOTAL REJECT</td>
                <td>0</td>
              </tr>
            </tbody>
          </BaseTable>
        </section>
      </section>

      <section className="flex flex-wrap gap-3">
        <Button color="primary">RECEIVE ITEM</Button>
        <Button color="primary">RECEIVE MONEY</Button>
      </section>

      <FooterMenu
        attachment={{ actiontype: "attachment" }}
        delete={{ actiontype: "delete" }}
        journal={{ actiontype: "journal" }}
        save={{ actiontype: "save" }}
      />
    </div>
  )
}