import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement,PointElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartOptions } from 'chart.js';

// register chart
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement);

export function BalanceSheet(props: { className?: string }) {
  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Assets',
        data: [100],
        backgroundColor: ['rgb(216, 191, 216)'],
        stack: '1',
      },
      {
        label: 'Liabilities',
        data: [50],
        backgroundColor: ['rgb(167, 230, 255, 0.5)'],
        stack: '2',
    },
    {
        label: 'Equity',
        data: [40],
        backgroundColor: ['rgb(58, 190, 249, 0.6)'],
        stack: '2',
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
      },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: 'grey', 
        anchor: 'center',
        align: 'center',
        formatter: function (value, context) {
          return context.dataset.label; 
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
        ticks: {
          display: true, 
        },
        border: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true, 
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false, 
        },
        stacked: true,
      },
    },
  };

  return (
    <div className={props.className || "chart-container"}>
      <Bar data={data} options={options} />
    </div>
  );
};
