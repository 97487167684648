import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  mr_product_category_code?: string
  mr_product_category_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_category_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_category_id"> 

  const defaultValues:CreateData= {
    mr_product_category_code: "",
    mr_product_category_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productcategory/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`Setting up data attributes for "Product/Item Category" in an ERP system is crucial for organizing products into more specific sub-groups within larger product groups. This categorization allows for detailed management and analysis of inventory, aids in tailored marketing efforts, and supports specific customer needs. Here is a comprehensive explanation of the data attributes for "Product/Item Category":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each product category."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a quick and consistent reference for identifying and managing different product categories within the ERP system. It streamlines data processing, enhances searchability, and supports accurate reporting by providing a standardized method for referencing categories."
                      },
                      {
                        label: "Example",
                        value: `Codes like "PC01" for "Smartphones," "PC02" for "Laptops," or "PC03" for "Dresses" help to quickly differentiate among categories within broader product groups.`
                      }
                    ]
                  },
                  {
                    label: "Product Category",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the category that groups similar products or items under a more specific classification than the product group."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute helps further segment products within a group based on more detailed similarities, such as their function, design, or target market. It enhances inventory control, marketing strategies, and sales efforts by allowing for targeted product management and promotions."
                      },
                      {
                        label: "Example",
                        value: `Within the "Electronics" group: 
                                "Smartphones" — Includes all types of smartphones. 
                                "Laptops" — Includes various brands and types of laptops. 
                                Within the "Clothing" group: 
                                "Dresses" — Includes all styles and types of dresses. 
                                "Outerwear" — Consists of items like jackets and coats.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product category."
                      },
                      {
                        label: "Purpose",
                        value: "The description offers more detailed information about the nature and use of the products in the category. It assists users in understanding the specific characteristics of the category, which is crucial for accurate product placement, marketing, and inventory management."
                      },
                      {
                        label: "Example",
                        value: `For "Smartphones," the description might be: "Portable communication devices featuring a range of capabilities including internet access, camera functions, and mobile applications. Includes models from various brands and operating systems." 
                                For "Laptops," it could read: "Personal computers designed for portability with features suitable for both personal and professional use. Includes different operating systems and specifications catering to various user needs." 
                                For "Dresses," it might state: "Garments varying in length, style, and material, designed for various occasions from casual wear to formal events." 
                                For "Outerwear," it might note: "Includes garments designed for outdoor use. Offers features such as insulation and water resistance, suitable for various climates and weather conditions."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are essential for effectively managing product categories within the ERP system. They allow for precise control and differentiation of inventory, facilitate detailed marketing and sales strategies, and enhance overall operational efficiency."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}