// React
import { Fragment, useContext } from "react"

// Components
import { CommandButton, Loading } from "components"
import { ActionButton } from "../components"
import { DeleteModal, InputSection, JournalModals } from "./components"

// Contexts
import { DataJournalContext } from "contexts"
import { DataContext, DataBillContext, ProductContext, PaymentCountContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Types
import type { FormType, DetailFormType } from "./utils"

// Utils
import { useApi, useProject, useTaxCode, useToggle } from "utils"
import { usePaymentVendorList } from "../PurchaseOrder/utils"
import { useProduct } from "../PurchaseOrder/components/DetailBody/utils"
import { validationSchema, useBillDetail, useDetail } from "./utils"

export default function EditEnterBill(): JSX.Element {
  // Hooks
  const { id, bill_id } = useParams()
  const po = useDetail(id!)
  const { billData, data, isLoading } = useBillDetail(id!, bill_id!)

  if (isLoading || !data || po.isLoading || !po.data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading || po.isLoading}
      />
    )
  }

  return (
    <DataBillContext.Provider value={billData!}>
      <DataContext.Provider value={po.data!}>
        <FormSection
          freight={data.freight}
          probildetail={data.probildetail}
          trx_code={data.trx_code}
        />
      </DataContext.Provider>
    </DataBillContext.Provider>
  )
}

export function FormSection(params: {
  freight: number
  probildetail: DetailFormType[]
  trx_code: string
}) {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const dataBill = useContext(DataContext)
  const { approval_status } = useContext(DataBillContext)
  const { id, bill_id } = useParams()
  const { data, isLoading } = usePaymentVendorList(id!)

  // Vars
  const datapo = dataBill

  // Form
  const defaultValues: FormType = {
    delivery_no: datapo.delivered.toString(),
    trx_code: params.trx_code,
    freight: params.freight,
    probildetail: params.probildetail,
    address: datapo.address,
    location_id: datapo.location_id,
    memo: datapo.memo,
    payment_term: datapo.payment_term,
    promise_date: datapo.promize_date,
    purchase_order_id: id!,
    referensi: datapo.referensi,
    reminder: dataBill.reminder,
    tax_inclusive: datapo.tax_inclusive,
    transaction_date: datapo.transaction_date,
    vendor_id: datapo.vendor_id
  }
  const onSubmit = (value: FormType) => {
    // Vars
    const final_value = {
      bill_id,
      purchase_order_id: id,
      trx_code: value.trx_code,
      vendor_id: value.vendor_id,
      location_id: value.location_id,
      address: value.address,
      memo: value.memo,
      transaction_date: value.transaction_date,
      referensi: value.referensi,
      delivery_no: value.delivery_no,
      freight: value.freight,
      promise_date: value.promise_date,
      reminder: value.reminder,
      probildetail: value.probildetail.map(item => {
        return {
          bill_detail_id: item.bill_detail_id,
          quantity_bill: item.quantity_bill,
          quantity_unit: item.quantity_unit,
          product_id: item.product_id,
          price_bill: item.price_bill,
          discount: item.discount,
          total: item.total,
          tax_id: item.tax_id
        }
      })
    }

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/probill/edit", final_value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        navigate(-1)
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      <PaymentCountContext.Provider
        value={{
          isLoading,
          data: data.length
        }}
      >
        <InputSection
          actionsection={(
            <ActionButton>
              <section className="flex flex-wrap gap-3">
                <CommandButton actiontype="help" />
                <CommandButton actiontype="print" />
                <CommandButton actiontype="email" />
                <CommandButton actiontype="export" />
                <Journal />
                <CommandButton actiontype="attachment" />
              </section>

              <section className="flex flex-wrap gap-3">
                {approval_status === "Pending" && <DeleteSection />}

                {approval_status !== "Approved" && (
                  <CommandButton
                    actiontype="save"
                    loading={methods.formState.isSubmitting ? "true" : undefined}
                    onClick={methods.handleSubmit(onSubmit)}
                    // permission="PT051"
                  />
                )}
              </section>
            </ActionButton>
          )}
        />
      </PaymentCountContext.Provider>
    </FormProvider>
  )
}

function DeleteSection(): JSX.Element {
  // Hooks
  const navigate = useNavigate()
  const { id, bill_id } = useParams()
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton
        actiontype="delete"
        onClick={toggle}
      />

      {isActive && (
        <DeleteModal
          purchase_order_id={id!}
          bill_id={bill_id!}
          toggle={toggle}
          onSuccess={() => navigate(-1)}
        />
      )}
    </Fragment>
  )
}

function Journal(): JSX.Element {
  // Hooks
  const project = useProject()
  const tax_code = useTaxCode()
  const { data, isLoading } = useProduct()

  return (
    <DataJournalContext.Provider value={{ project, tax_code }}>
      <ProductContext.Provider value={{ data, isLoading }}>
        <JournalModals />
      </ProductContext.Provider>
    </DataJournalContext.Provider>
  )
}