// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  date_from: yup.string().label("Start Date").required(),
  date_end: yup.string().label("End Date").required(),
  location_id: yup.string().label("Location").defined(),
  type: yup.string().required()
})

export type FormType = yup.InferType<typeof validationSchema>