import { Card, DateInput, Input, Textarea } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import {
  DropdownEmployee,
  DropdownLocation
} from "pages/Sales/Transaction/CustomerOrder/components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { FormReturnItemsType } from "../types/FormReturnItems"

type FormReturnItemsProps = {
  approvalView?: boolean
}

export const FormReturnItems = ({ approvalView }: FormReturnItemsProps) => {
  const { control } = useFormContext<FormReturnItemsType>()
  const approval_status = useWatch({
    control: control,
    name: "approval_status"
  })

  return (
    <Card>
      <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <section>
            <section className="flex gap-3">
              <div className="grow">
                <Controller
                  control={control}
                  name="shipped"
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      disabled
                      label="SHIPPED"
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
            </section>
          </section>

          <Controller
            control={control}
            name="memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label="MEMO"
                defaultValue={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <DropdownLocation isDisabled={approval_status !== APPROVAL.PENDING} approvalView={approvalView} />

          <Controller
            control={control}
            name="link"
            render={({ field }) => (
              <Input disabled label="LINK" defaultValue={field.value} />
            )}
          />
        </section>

        <section className="flex flex-col gap-3">
          <Controller
            control={control}
            name="transaction_date"
            render={({ field, fieldState }) => (
              <DateInput
                label="DATE"
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="delivery_no"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                disabled
                label="DELIVERED"
                defaultValue={field.value}
                value={`#${field.value}`}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="referensi"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="REFERENCE"
                defaultValue={field.value}
                value={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                onChange={(date) => {
                  field.onChange(date)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <DropdownEmployee
            disabled={approval_status !== APPROVAL.PENDING}
            approvalView={approvalView}
          />
        </section>
      </Card.Body>
    </Card>
  )
}
