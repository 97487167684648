import * as yup from "yup"

export const validationSellingSchema = yup.object().shape({
  mr_unit_id: yup.string().label("Unit").required(),
  tax_id: yup.string().label("Tax").required(),
  wholesales_price: yup.string().label("Wholesale Price").required(),
  distributor_price: yup.string().label("Distributor Price").required(),
  factory_price: yup.string().label("Factory Price").required(),
  online_price: yup.string().label("Online Price").required(),
  special_arrangement_price: yup.string().label("Special Arangement Price").required(),
  overhead: yup.string().label("Overhead").required(),
  sales_tax: yup.string().label("Sales Tax").required(),
  other_changes: yup.string().label("Other Changes").required(),
  price_retail: yup.string().label("Price Retail").required(),
})