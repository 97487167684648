import { Label, Select } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import { useJobsPosition } from "pages/Card/Transaction/CreateNewEmployee/components/FormSection/utils"
import { ListShopTransactionAccess } from "../../types"
import { useShopTransaction } from "../../utils"

type FormType = { action?: string }
export const FormSection = ({ action }: FormType) => {
  const isDisable = action === "DETAIL" || action === "DELETE"

  return (
    <table className="table">
      <tbody>
        <ShopTransaction isDisabled={isDisable} />
        <JobPosition isDisabled={isDisable} />
      </tbody>
    </table>
  )
}

const ShopTransaction = ({ isDisabled }: { isDisabled: boolean }) => {
  const { control } = useFormContext<ListShopTransactionAccess>()
  const { data, isLoading, refetch } = useShopTransaction()

  return (
    <Controller
      control={control}
      name="shop_transaction_id"
      render={({ field, fieldState }) => (
        <tr>
          <td><Label text="SHOP TRANSACTION" error={Boolean(fieldState.error)} /></td>
          <td>
            <Select
              placeholder="Select shop transaction..."
              isDisabled={isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => {
                field.onChange(e?.value)
                refetch()
              }}
            />
          </td>
        </tr>
      )}
    />
  )
}
const JobPosition = ({ isDisabled }: { isDisabled: boolean }) => {
  const { control } = useFormContext<ListShopTransactionAccess>()
  const { data, isLoading, refetch } = useJobsPosition()

  return (
    <Controller
      control={control}
      name="job_position_id"
      render={({ field, fieldState }) => (
        <tr>
          <td><Label text="JOB POSITION" error={Boolean(fieldState.error)} /></td>
          <td>
            <Select
              placeholder="Select job position..."
              isDisabled={isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => {
                field.onChange(e?.value)
                refetch()
              }}
            />
          </td>
        </tr>
      )}
    />
  )
}