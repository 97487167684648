import { BaseTable, Button, Loading } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useSOInvoiceList } from "pages/Sales/Transaction/Invoice/utils/hooks"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate, useParams } from "react-router-dom"
import { convertNumber } from "utils"

type TableInvoiceProps = { display?: boolean }

export const TableInvoice = ({ display = true }: TableInvoiceProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const { data, isLoading } = useSOInvoiceList({
    sales_order_id: params?.sales_order_id!
  })

  return (
    <div className={`${display ? "" : "hidden"} overflow-x-auto`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th rowSpan={2}>NO</th>
            <th rowSpan={2} colSpan={2}>
              Invoice Id
            </th>
            <th rowSpan={2}>Status</th>
            <th rowSpan={2}>Date</th>
            <th rowSpan={2}>Delivery</th>
            <th rowSpan={2}>Memo</th>
            <th rowSpan={2}>Subtotal</th>
            <th rowSpan={2}>Action</th>
          </tr>
        </thead>

        <tbody>
          {data?.length && !isLoading ? (
            data.map((item, key) => {
              const isLunas =
                item.balance === 0 || item.total_payment >= item.total_invoice

              return (
                <tr key={item.invoice_id}>
                  <td className="text-center">{key + 1}</td>
                  <td className="text-center px-0">
                    <Button
                      size="xs"
                      color="ghost"
                      onClick={() =>
                        navigate(
                          `/sales/transaction/invoice/edit/${item.invoice_id}`,
                          {
                            state: {
                              sales_order_id: item.sales_order_id
                            }
                          }
                        )
                      }>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td>{item.trx_code}</td>
                  <td>{APPROVAL[item.approve_status]}</td>
                  <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>#{item.delivery_no}</td>
                  <td>{item.memo}</td>
                  <td className="text-right">
                    {convertNumber(item.total_invoice).intoCurrency}
                  </td>
                  <td>
                    <div className="flex justify-center items-center">
                      {item.approve_status === APPROVAL.APPROVE ? (
                        <Button
                          className="w-full"
                          type="button"
                          color="primary"
                          permission="ST17"
                          disabled={isLunas}
                          onClick={() =>
                            navigate(
                              `/sales/transaction/payment-invoice/${
                                item.invoice_id ?? 1
                              }`
                            )
                          }>
                          {isLunas ? "LUNAS" : "PAYMENT"}
                        </Button>
                      ) : null}
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={12} className="text-center">
                <Loading
                  loading={isLoading}
                  errorText={isLoading ? "" : "No data available"}
                />
              </td>
            </tr>
          )}
        </tbody>
      </BaseTable>
    </div>
  )
}
