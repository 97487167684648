import { useEffect, useState } from "react"
import type { ColumnConfigType, SuccessFetch } from "../../../../../../types"
import type { PersonalList } from "../../types"
import { useApi } from "../../../../../../utils"

interface FilteredParams {
  page?: number
  keyword?: string
  limit?: number
  department?: string;
  jobpos?: string;
  location?: string;
  sort?: string;
}
interface DataType {
  items: PersonalList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}
export function useList({ page, keyword, limit, department, jobpos, location, sort }: FilteredParams) {
  const defaultValue = {
    items: [],
    pagination_data: {
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }

  const api = useApi()
  const [data, setData] = useState<DataType>(defaultValue)
  const [isLoading, setLoading] = useState(true)

  const params: { [key: string]: number | string | undefined } = {};
  if (page) params.page = page;
  if (keyword) params.keyword = keyword;
  if (limit) params.limit = limit;
  if (department) params.department = department;
  if (jobpos) params.jobpos = jobpos;
  if (location) params.location = location;
  if (sort) params.sort = sort;

  const fetchingData = () => api.get("/employee/all", { params })
  const fetchData = () => {
    setLoading(true)

    fetchingData()
    .then((res: SuccessFetch<{ payload: DataType }>) => setData(res.data.payload))
    .catch(() => setData(defaultValue))
    .finally(() => setLoading(false))
  }
  const refetch = () => fetchData()

  useEffect(() => {
    refetch()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    fetchData()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [page, keyword, limit, department, jobpos, location, sort])

  return { data, isLoading, refetch }
}


export function useColumnConfig() {
  const api = useApi()
  const [dataColumn, setDataColumn] = useState<ColumnConfigType[] | []>([])
  const [isLoading, setLoading] = useState(true)

  const fetchingData = () => api.get(`/columnconfig/bymodule?module=employee`)

  const fetchData = () => {
    setLoading(true)

    fetchingData().then((res: SuccessFetch<{
      payload: ColumnConfigType[]
    }>) => {
      setDataColumn(res.data.payload)
    }).catch(() => {
      setDataColumn([])
    }).finally(() => {
      setLoading(false)
    })
  }
  const refetchColumn = () => fetchData()

  useEffect(() => {
    refetchColumn()

    return () => {
      setDataColumn([])
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataColumn, setDataColumn, isLoading, refetchColumn }
}



export function useExportList() {

  const api = useApi()
  const [data, setData] = useState<PersonalList[]>([]);
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {

    const fetchData = () => api.get('/employee/export')

    fetchData().then((res: SuccessFetch<{
      payload: PersonalList[]
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    // eslint-disable-next-line
  }, [])

  return { data, setData, isLoading }
}