import { Checkbox } from "components"
import { arraysEqual } from "pages/Setup/utils"
import { Fragment, useEffect } from "react"
import { useAllId } from "../../utils"

type SelectAllType = {
  deleteList: number[]
  isDeleteAll: boolean
  toggle: (value: number[]) => void
  toggleDeleteAll: (value: boolean) => void
}
export const SelectAll = ({ deleteList, isDeleteAll, toggle, toggleDeleteAll }: SelectAllType): JSX.Element => {
    const { data } = useAllId()
  
    useEffect(() => {
      if (!deleteList.length) { return toggleDeleteAll(false) }
  
      return toggleDeleteAll(arraysEqual(deleteList, data))
  
      // eslint-disable-next-line
    }, [deleteList])
  
    return (
      <th>
        {data.length ? (
          <Checkbox
            value={isDeleteAll}
            onClick={() => {
              if (!arraysEqual(deleteList, data)) { return toggle(data) }
              return toggle([])
            }}
          />
        ) : (
          <Fragment />
        )}
      </th>
    )
  }