// React
import { useEffect, useState } from "react"

// Types
import type { ErrorFetch, SuccessFetch } from "../../../../../../../types"
import { FormType } from "../../../../types"

// Utils
import { useApi } from "../../../../../../../utils"

export function useDetail(id: number) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<FormType | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/coa/byid", { params: { coa_id: id } }).then((res: SuccessFetch<{
      payload: {
        coa_code: string
        coa_name: string
        group_coa_id: number
        group_coa_code: string
        location_id: number | null
        account_type_id: number | null
        coa_level: number
        header_level: number
        normal_pos: number
        opening_balance: number | null
        cheque_coa: boolean
        note: string
        report_type_name: string
        parent_name: string
      }
    }>) => {
      // Vars
      const data = res.data.payload

      setData({
        ...data,
        location_id: data.location_id?.toString() ?? "",
        account_type_id: data.account_type_id?.toString() ?? "",
        group_coa_id: data.group_coa_id.toString(),
        coa_level: data.coa_level.toString(),
        header_level: data.header_level.toString(),
        normal_pos: data.normal_pos.toString(),
        opening_balance: data.opening_balance ?? 0,
        report_type: data.report_type_name
      })
    }).catch((err: ErrorFetch<{ detail: { message: string } }>) => {
      setError(err.response.data.detail.message)
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}