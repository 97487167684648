/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "components"
import { useToggle } from "utils"
import {
  TaskActionList
} from "../types"
import { useHistoryTaskActionList, useNumberTaskAction } from "../utils/hooks"
import { CardHistoryTask } from "./CardHistoryTask"

type ModalHistoryActionLineProps = {
  taskId: TaskActionList['task_action_id'] | null
  taskActionId: TaskActionList['task_id'] | null
  modalState: ReturnType<typeof useToggle>
}

export const ModalHistoryActionLine = ({ taskId, taskActionId, modalState }: ModalHistoryActionLineProps) => {
  const histories = useHistoryTaskActionList({ task_action_id: taskActionId?.toString() ?? "", enabled: modalState.isActive })
  const numberTaskAction = useNumberTaskAction({
    task_id: taskId?.toString() ?? "",
    task_action_id: taskActionId?.toString() ?? ""
  })

  return (
    <Modal
      title="HISTORY | ACTION LINE "
      isOpen={modalState.isActive}
      closeModal={modalState.toggle}>
      <Modal.Body>
        <section className="flex flex-col gap-y-3">
          {!histories.data.length || (histories.isLoading || numberTaskAction.isLoading) ? (
            <div className="text-center">{histories.isLoading ? "Loading..." : "No history found"}</div>
          ) : histories.data?.map((item) => (
            <CardHistoryTask
              key={item.task_action_id}
              actionTask={item}
              no={numberTaskAction.data}
            />
          ))}
        </section>
      </Modal.Body>
    </Modal>
  )
}