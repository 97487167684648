import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"

export function CreateModal(props: {
  price_level_vendor_code?: string
  price_level_vendor_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("price_level_vendor_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "price_level_vendor_id"> 

  const defaultValues:CreateData= {
    price_level_vendor_code: "",
    price_level_vendor_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/pricelevelvendor/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE PRICE LEVEL VENDOR" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for "Vendor Pricing Level" in an ERP system, it's important to structure these attributes to clearly delineate the different pricing strategies or structures applied to various vendors. These attributes help organizations manage vendor pricing agreements, optimize procurement, and ensure accurate cost control. Here is a comprehensive explanation of the data attributes for "Vendor Pricing Level":`}
                detail={`These data attributes are essential for effectively managing vendor pricing levels in the ERP system. They enable organizations to apply and track different pricing strategies, optimize procurement processes, and maintain clear agreements with vendors.`}
                title=""
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each vendor pricing level."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a concise reference for different pricing levels, making it easier to manage and apply pricing terms across various vendors. It ensures consistency in data entry and retrieval, reducing the risk of errors or confusion."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "VL01" for "Standard Pricing," "VL02" for "Volume Discount," or "VL03" for "Negotiated Rate" help differentiate between various pricing arrangements.`
                      }
                    ]
                  },
                  {
                    label: "Pricing Level",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the pricing level that indicates the specific pricing strategy or structure applied to a vendor."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute defines the type of pricing agreement in place with the vendor, such as standard rates, discounted rates for bulk purchases, or special negotiated terms. It helps in categorizing vendors based on their pricing arrangements, facilitating better procurement and cost management."
                      },
                      {
                        label: "Example",
                        value: `-"Standard Pricing" — The default pricing level for vendors, based on the standard list prices. 
                                -"Volume Discount" — Pricing level that offers reduced rates based on the quantity of goods purchased. 
                                -"Negotiated Rate" — Custom pricing agreed upon through negotiations, often reflecting unique terms or special conditions. 
                                -"Promotional Pricing" — Temporary pricing levels offered during promotional periods or sales events.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the vendor pricing level."
                      },
                      {
                        label: "Purpose",
                        value: "The description provides clarity on the specific terms, conditions, or criteria associated with each pricing level. It helps users understand the rationale behind the pricing structure and any relevant details that impact cost management, such as discount conditions or volume thresholds."
                      },
                      {
                        label: "Example",
                        value: `-For "Standard Pricing," the description might be: "Applies to all standard purchases based on the vendor's regular list prices. No discounts or special terms are included." 
                                -For "Volume Discount," it could read: "Offers a 10% discount on orders of 100 units or more. Discount applied automatically at checkout." 
                                -For "Negotiated Rate," it might state: "Custom pricing based on a specific contract or agreement with the vendor. Terms and rates are tailored to the needs of the organization." 
                                -For "Promotional Pricing," it might note: "Special pricing available during promotional periods, with discounts varying based on the product and promotion duration."`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}