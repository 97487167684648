// React
import { Fragment, useContext } from "react"

// Components
import { BaseTable, Button, Checkbox, Loading, Modal } from "components"
import { PicturesSection } from "pages/Inventori/Register/ItemGroup/components"

// Contexts
import { LocationContext, LocationProductContext } from "../contexts"

// Form
import { Controller, useForm, useFormContext } from "react-hook-form"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { SuccessFetch } from "types"
import type { DetailFormType, FormType } from "../utils"

// Utils
import { useApi, useToggle } from "utils"
import { getDifference } from "../utils"

export function Item(): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  // Form
  const { trigger } = useFormContext<FormType>()

  return (
    <Fragment>
      <Button
        type="button"
        color="primary"
        onClick={() => {
          trigger(["employee_id", "location_id", "memo"]).then((res) => {
            if (res) {
              return toggle()
            }

            toast.error("All fields must be required")
          })
        }}
      >
        SELECT ITEM
      </Button>

      {isActive && <ModalSection toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(params: {
  toggle: () => void
}): JSX.Element {
  // Hooks
  const api = useApi()
  const productList = useContext(LocationProductContext)
  const { data } = useContext(LocationContext)

  // Form
  const methods = useFormContext<FormType>()
  const onSubmit = (value: DetailFormType) => {
    // Vars
    const oldDetail = methods.getValues("countdetail")
    const countdetail = value.countdetail.map(item => {
      // Vars
      const data = productList.data.find(i => i.product_id === item)!
      const olditem = oldDetail.find(i => i.product_id === item)
      const soh_count = olditem?.soh_count ?? data.soh_count
      const real_count = olditem?.real_count ?? 0

      return {
        real_count,
        soh_count,
        count_detail_id: olditem?.count_detail_id ?? null,
        mr_unit_id: olditem?.mr_unit_id ?? data.mr_unit_id,
        product_id: item,
        different: getDifference(soh_count, real_count)
      }
    })
    const { count_id, ...rest } = methods.getValues()

    // Functions
    const callApi = () => {
      if (count_id) {
        return api.put("/invcount/update_inv_count", {
          ...rest,
          count_id,
          invcountdetail: countdetail.map(item => {
            return {
              ...item,
              count_detail_id: item.count_detail_id
            }
          })
        })
      }

      return api.post("/invcount/add", {
        ...rest,
        countdetail: countdetail
      })
    }

    return new Promise<void>((resolve, reject) => {
      toast.promise(
        callApi(),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then((res: SuccessFetch<{
        payload: { count_id: number }
      }>) => {
        methods.setValue("count_id", res.data.payload.count_id)
        methods.setValue("countdetail", countdetail)
        params.toggle()
      }).catch(() => {
        reject(null)
      }).finally(() => {
        resolve()
      })
    })
  }
  
  const { control, handleSubmit } = useForm<DetailFormType>({
    defaultValues: {
      countdetail: methods.getValues("countdetail").map(item => item.product_id)
    }
  })

  // Vars
  const location: string = data.find(item => item.value === methods.getValues("location_id"))?.label ?? "-"

  return (
    <Modal
      isOpen
      title="LIST ITEM"
      closeModal={params.toggle}
    >
      <Modal.Body>
        <section className="flex flex-col gap-3">
          <section className="flex md:flex-row flex-col justify-between md:items-end gap-3">
            <div className="grow w-full">
              <span className="font-bold">LOCATION: </span> {location}
            </div>

            <div className="self-end">
              <Controller
                name="countdetail"
                control={control}
                render={({ field }) => {
                  // Vars
                  const allChecked: boolean = productList.data.length === field.value.length

                  return (
                    <Checkbox
                      value={allChecked}
                      onClick={() => {
                        if (allChecked) {
                          return field.onChange([])
                        }

                        return field.onChange(productList.data.map(item => item.product_id))
                      }}
                    >
                      All
                    </Checkbox>
                  )
                }}
              />
            </div>
          </section>

          <BaseTable>
            <thead>
              <tr>
                <th>#</th>
                <th>ITEM</th>
                <th>DESCRIPTION</th>
                <th>PICTURE</th>
                <th>SOH</th>
                <th>UNIT</th>
              </tr>
            </thead>

            <Controller
              name="countdetail"
              control={control}
              render={({ field }) => (
                <tbody>
                  {productList.isLoading || !productList.data.length ? (
                    <tr>
                      <td colSpan={6}>
                        <Loading
                          errorText="No data available"
                          loading={productList.isLoading}
                        />
                      </td>
                    </tr>
                  ) : productList.data.map((item, key) => {
                    // Vars
                    const findItem: number = field.value.findIndex(i => i === item.product_id)
                    const isChecked: boolean = findItem !== -1

                    return (
                      <tr key={key}>
                        <td>
                          <Checkbox
                            labelClassName="!justify-center"
                            value={isChecked}
                            onClick={() => {
                              // Vars
                              const value = [...field.value]

                              if (isChecked) {
                                value.splice(findItem, 1)
                              } else {
                                value.push(item.product_id)
                              }

                              return field.onChange(value)
                            }}
                          />
                        </td>
      
                        <td>{item.product_barcode}</td>
                        <td>{item.product_name}</td>
      
                        <td>
                          <section className="flex justify-center">
                            <div className="w-[400px] lg:w-[200px]">
                              <PicturesSection
                                className="h-[100px] w-full"
                                items={1}
                                dataPicture={[
                                  {
                                    description: "",
                                    is_deleted: false,
                                    product_picture_code: "",
                                    product_picture_id: 0,
                                    product_picture_name: "",
                                    path: item.product_foto
                                  }
                                ]}
                              />
                            </div>
                          </section>
                        </td>
      
                        <td>{item.soh_count}</td>
                        <td>{item.mr_unit_name}</td>
                      </tr>
                    )
                  })}
                </tbody>
              )}
            />
          </BaseTable>
        </section>
      </Modal.Body>

      <Modal.Footer>
        <Button
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          SAVE
        </Button>
      </Modal.Footer>
    </Modal>
  )
}