import toast from "react-hot-toast"
import { Product } from "../../types"

type GetPriceLevel = {
  product: Product
  price_level_name: string
}

export const getPriceLevel = ({ product, price_level_name }: GetPriceLevel) => {
  switch (price_level_name.toLowerCase()) {
    case "retail price":
      if (!product.price_retail) {
        toast.error("Retail Price not found")
        return 0
      }
      return product.price_retail
    case "wholesales price":
      if (!product.wholesales_price) {
        toast.error("Wholesales Price not found")
        return 0
      }
      return product.wholesales_price ?? 0
    case "distributor price":
      if (!product.distributor_price) {
        toast.error("Distributor Price not found")
        return 0
      }
      return product.distributor_price ?? 0
    case "online price":
      if (!product.online_price) {
        toast.error("Online Price not found")
        return 0
      }
      return product.online_price ?? 0
    case "factory price":
      if (!product.factory_price) {
        toast.error("Factory Price not found")
        return 0
      }
      return product?.factory_price ?? 0
    case "special arrangement":
      if (!product.special_arrangement_price) {
        toast.error("Special Arrangement Price not found")
        return 0
      }
      return product?.special_arrangement_price ?? 0
    default:
      return 0
  }
}
