import { Input, Label, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { FormBank } from "../../types"

export function FormSection(props: { isDetail?: boolean; isUpdate?: boolean; action?: string }) {
  const { control } = useFormContext<FormBank>()

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="bank_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td>
                <Input {...field} 
                  placeholder="Input Bank Code" 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message} 
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="bank_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="BANK" error={Boolean(fieldState.error)} /></td>
              <td>
                <Input 
                  {...field} 
                  placeholder="Input Bank Name" 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message} 
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" /></td>
              <td>
                <Textarea 
                  {...field} 
                  placeholder="Input Description" 
                  value={field.value ?? ""}
                  disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  error={fieldState.error?.message} 
                />
              </td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}