import { useLocation } from "utils";
import { LinkAccountType } from "../../types";

interface AccountListType {
    header: string,
    linkAccount: Pick<LinkAccountType, 'location_id' | 'location_name' | 'trx' | 'account_coa'>[] 
}

export const useLinkAccountList = (): AccountListType[] => {
    const { data } = useLocation();

    const headers = [
        {name: "LINK ASSET ACCOUNT POS FOR TRACKING AR ON LOCATION", trx: 1},
        {name: "LINK ASSET CASH PAYMENT ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 2},
        {name: "LINK ASSET CREDIT CARD PAYMENT ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 3},
        {name: "LINK ASSET DEBIT CARD PAYMENT ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 4},
        {name: "LINK ASSET QRIS PAYMENT ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 5},
        {name: "LINK ASSET E-MONEY PAYMENT ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 6},
        {name: "LINK ASSET VOUCHER PAYMENT ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 7},
        {name: "LINK LIABILITY EXCHANGE ACCOUNT POS FOR CUSTOMER RECEIVABLE", trx: 8},
    ];

    return headers.map(head => ({
            header: head.name,
            linkAccount: data.map(item => ({
                location_id: item.value,
                location_name: item.label,
                trx: head.trx,
                account_coa: 1
            }))
        }))
};
