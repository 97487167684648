// React
import { useContext } from "react"

// Components
import { BaseTable, Loading } from "components"
import { DetailProduct } from "pages/Purchase/components"

// Contexts
import { DataJournalContext } from "contexts"
import { DataContext, ProbilContext } from "../../../contexts"
import { DisabledContext } from "pages/Purchase/Transaction/contexts"
import { ProductContext } from "pages/Purchase/Transaction/PurchaseOrder/components/DetailBody/contexts"

// Form
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { DetailBillType } from "./types"

// Utils
import { convertNumber } from "utils"
import { useConvertionData } from "./utils"

export function Purchases() {
  // Hooks
  const data = useContext(DataContext)

  // Form
  const methods = useForm<DetailBillType>({
    defaultValues: { detailbill: data.podetail }
  })
  const { fields } = useFieldArray({
    control: methods.control,
    name: "detailbill"
  })

  return (
    <BaseTable>
      <thead>
        <tr>
          <th></th>
          <th>ORDER</th>
          <th>RECEIVED</th>
          <th>BACKORDER</th>
          <th>ITEM CODE</th>
          <th>DESCRIPTION</th>
          <th>PRICE</th>
          <th>DISC (%)</th>
          <th>TOTAL</th>
          <th>JOBS</th>
          <th>TAX</th>
        </tr>
      </thead>

      <FormProvider {...methods}>
        <tbody>
          {!fields.length ? (
            <tr>
              <td colSpan={10}>
                <Loading errorText="No data available" />
              </td>
            </tr>
          ) : fields.map((_, key) => {
            return (
              <List
                key={key}
                index={key}
              />
            )
          })}
        </tbody>
      </FormProvider>
    </BaseTable>
  )
}

function List(params: {
  index: number
}) {
  // Hooks
  const disabled = useContext(DisabledContext)
  const { append } = useContext(ProbilContext)
  const { control } = useFormContext<DetailBillType>()
  const { data } = useContext(ProductContext)
  const { isLoading, getData } = useConvertionData()
  const { tax_code } = useContext(DataJournalContext)

  return (
    <Controller
      name={`detailbill.${params.index}`}
      control={control}
      render={({ field }) => {
        // Vars
        const item = field.value
        const product_id = item.product_id
        const qty_order = item.qty_order
        const delivered = item.delivered
        const backorder: number = qty_order - delivered
        const noBackOrder: boolean = Boolean(backorder <= 0)
        const selectedData = data.find(i => i.value === product_id)
        const tax_name: string | undefined = tax_code.data.find(i => i.value === item.tax_id)?.label

        // Functions
        const submitDetail = (): void => {
          getData(product_id).then((res: number) => { 
            append({
              bill_detail_id: null,
              discount: item.discount,
              qty_multiply: res,
              qty_order: backorder,
              mr_unit_id: item.mr_unit_id,
              price_bill: item.price_measure,
              product_id: item.product_id,
              project_name: item.project_name,
              project_id: item.project_id,
              quantity_bill: 0,
              quantity_unit: 0,
              tax_id: item.tax_id,
              total: 0
            })
          })
        }

        return (
          <tr className={`${noBackOrder && "strikethrough-text"}`}>
            <td>
              {isLoading ? (
                <Loading loading />
              ) : !disabled && (
                <IoSendSharp
                  className="cursor-pointer -rotate-90"
                  onClick={() => {
                    if (noBackOrder) {
                      return toast.error("No Backorder Qty available")
                    }

                    submitDetail()
                  }}
                />
              )}
            </td>

            <td>{qty_order}</td>
            <td>{delivered}</td>
            <td>{backorder}</td>

            <td>
              <DetailProduct
                barcode={selectedData?.product_barcode ?? ""}
                id={item.product_id}
              />
            </td>

            <td>{selectedData?.product_name}</td>
            <td className="text-right">{convertNumber(item.price_measure).intoCurrency}</td>
            <td>{convertNumber(item.discount).intoNormalAmount}%</td>
            <td className="text-right">{convertNumber(item.total).intoCurrency}</td>
            <td>{item.project_name}</td>
            <td>{tax_name}</td>
          </tr>
        )
      }}
    />
  )
}