import { Fragment, useContext } from "react"
import { IoSendSharp } from "react-icons/io5"
import { useAccountGroup, useApi, useToggle } from "utils"
import { LinkAccountType } from "../types"
import { Render } from "../utils"
import { CoaList, Loading, Modal } from "components"
import { SelectedCoaType } from "types"
import toast from "react-hot-toast"

export function Create(props: { 
    dataItem: Pick<LinkAccountType, "location_id" | "trx" >
    refetch: () => void
}) {
    const { isActive, toggle } = useToggle(false)
    return (
        <Fragment>
            <button onClick={toggle}>
                <IoSendSharp className="text-center" />
            </button>
            {isActive && <ModalSection toggle={toggle} dataItem={props.dataItem} refetch={props.refetch} />}
        </Fragment>
    )
}

function ModalSection(props: {
    toggle: () => void;
    dataItem: Pick<LinkAccountType, "location_id" | "trx" >
    refetch: () => void
}
) {
    // Hooks
    const api = useApi()
    const { data, isLoading } = useAccountGroup()
    const { toggle } = useContext(Render)

    const onSubmit = (value: SelectedCoaType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post(`/linkedaccountpos/upsert`, {
                    location_id: props.dataItem.location_id,
                    coa_id: value.coa_id,
                    trx: props.dataItem.trx
                }), {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
                props.toggle()
                toggle()
                props?.refetch && props.refetch()
            }).catch(() => {}).finally(resolve)
        })
    }

    return (
        <Modal isOpen title="" closeModal={props.toggle}>
            <Modal.Body contentClassName="!py-0">
                {isLoading || data.length === 0 ? (
                    <section className="my-5">
                        <Loading loading={isLoading} />
                    </section>
                ) : (
                    <Fragment>
                        <section className="flex justify-center mb-3">
                            <div className="border-b text-xl font-bold">ACCOUNT</div>
                        </section>

                        <CoaList
                            data={data}
                            onAddCoa={(_, __, coa) => {
                                onSubmit(coa)
                            }}
                            defaultGroup={'1'}
                        />
                    </Fragment>
                )}
            </Modal.Body>
        </Modal>
    )
}