import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  mr_product_style_code?: string
  mr_product_style_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_style_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_style_id"> 

  const defaultValues:CreateData= {
    mr_product_style_code: "",
    mr_product_style_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productstyle/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for "Product/Item Style" in an ERP system, it's essential to clearly distinguish products based on their style. This categorization facilitates efficient inventory management, enhances the customer shopping experience, and supports marketing efforts. "Product/Item Style" attributes are especially important in industries like fashion, furniture, and consumer goods, where style can significantly influence buyer preferences. Here’s a comprehensive explanation of the data attributes for "Product/Item Style":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each style."
                      },
                      {
                        label: "Purpose",
                        value: "The code serves as a quick reference for identifying different styles within the ERP system, ensuring consistent data entry, enhancing searchability, and maintaining data integrity. This helps avoid confusion and ensures products are tracked accurately across various business operations."
                      },
                      {
                        label: "Example",
                        value: `Codes like "ST01" for "Contemporary," "ST02" for "Vintage," or "ST03" for "Modern" enable users to efficiently categorize and manage inventory according to distinct styles.`
                      }
                    ]
                  },
                  {
                    label: "Product Style",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title that indicates the style of the product."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the aesthetic or design theme of the product, which is crucial for marketing, customer segmentation, and inventory management. Understanding the style helps in planning product ranges, targeting specific customer groups, and creating promotional materials that resonate with those groups."
                      },
                      {
                        label: "Example",
                        value: `"Contemporary" — Features modern, sleek designs with minimalistic elements. 
                                "Vintage" — Includes products that embody or replicate historical or retro design features. 
                                "Rustic" — Characterized by natural, rugged, and often unfinished materials.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product style."
                      },
                      {
                        label: "Purpose",
                        value: "The description elaborates on the characteristics and unique elements of the style, offering insights into its inspiration, target audience, and typical use scenarios. This helps users understand the distinctive features of each style and how they appeal to different market segments."
                      },
                      {
                        label: "Example",
                        value: `For "Contemporary," the description might be: "Focused on simplicity and functionality. Utilizes clean lines and neutral colors. Popular in urban settings for its space efficiency." 
                                For "Vintage," it could read: "Emphasizes classic designs and motifs from the 1920s to 1970s. Often incorporates ornate details and rich, deep colors." 
                                For "Rustic," it might state: "Inspired by rural life, these items often use raw and unpolished materials like wood and stone. Ideal for country homes or nature-themed interiors."`
                      },
                    ]
                  }
                ]}
                detail={`This setup of "Product/Item Style" is unique and crucial for expressing each of the company's products distinctively. It allows the organization to precisely define and communicate the aesthetic and functional aspects of its products, which is fundamental in differentiating product offerings and enhancing brand identity. By meticulously defining the style attributes, the company can ensure that its product catalog is diverse and tailored to meet the varied preferences of its customer base, ultimately aiding in strategic placement in the market, targeted marketing, and improved sales effectiveness.`}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}