import { useContext, useEffect } from "react"
import { Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { FormData } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: {
  bank_code?: string
  bank_name?: string
  description?: string 
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

    useEffect(() => {
      if (dataCode?.code) {
        methods.setValue("mr_bin_code", dataCode?.code)
      }
   // eslint-disable-next-line
    }, [dataCode?.code])

  type CreateListBin = Omit<FormData, "mr_bin_id"> 

  const defaultValues:CreateListBin= {
    mr_bin_code: "",
    mr_bin_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    mr_bin_code: yup.string().label("Bin code").required().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3),
    mr_bin_name: yup.string().label("Bin name").required(),
    description: yup.string().label("Description").optional(),
  })
  const onSubmit = (value: CreateListBin) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/bank/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListBin>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE BIN" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormSection />

          <Modal.Footer className="text-end">
            {/* <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button> */}
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}