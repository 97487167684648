import { Label, Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { TaskType } from "../types"
import { TASK_STATUS } from "../utils/vars"
type DropdownStatusProps = {
  value?: TASK_STATUS | null
}

export const DropdownStatus = ({ value }: DropdownStatusProps) => {
  const { control } = useFormContext<TaskType>()
  const dropdown = Object.values(TASK_STATUS)
    .filter((item) => typeof item === "number")
    .map((item) => ({
      value: (item as unknown as number).toString(),
      label: TASK_STATUS[item as number]
    }))

  return (
    <section>
      <Label text="STATUS" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="task_status"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Status"
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                      (item) => item.value === field.value?.toString()
                    )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
