import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle" 

export function CreateModal(props: {
  mr_unit_code?: string
  mr_unit_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_unit_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_unit_id"> 

  const defaultValues:CreateData= {
    mr_unit_code: "",
    mr_unit_name: "",
    description: ""
  }
 
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productunit/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                info={`When setting up data attributes for "Product/Item Unit" in an ERP system, it’s essential to clearly define these attributes to manage inventory effectively and facilitate accurate transactions. Product units help standardize measurements across products, which is crucial for inventory control, purchasing, sales, and production processes. Here is a comprehensive explanation of the data attributes for "Product/Item Unit":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each product unit."
                      },
                      {
                        label: "Purpose",
                        value: "The code provides a shorthand reference for quickly identifying and referencing different units of measure in transactions, inventory records, and reports. It ensures data consistency and simplifies the data entry and retrieval process."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "UN01" for "Piece," "UN02" for "Kilogram," or "UN03" for "Liter" facilitate clear and consistent recording across the system.`
                      }
                    ]
                  },
                  {
                    label: "Product Unit",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the unit of measure used for the product or item."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the standard measurement unit for quantifying inventory items, which is essential for purchasing, stocking, selling, and producing goods. It ensures all stakeholders in the supply chain use a consistent measurement system, reducing confusion and errors."
                      },
                      {
                        label: "Example",
                        value: `- "Piece" — Used for items that are counted numerically. 
                                - "Kilogram" — Used for items that are measured by weight. 
                                - "Liter" — Used for items measured by volume. 
                                - "Pack" — Used for items sold in pre-defined bundles or sets.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product unit."
                      },
                      {
                        label: "Purpose",
                        value: "The description helps clarify the use and application of the unit, specifying any special considerations or details that might affect how the unit is used within the ERP system. It provides context that can assist users in understanding how to apply the unit in various operational processes."
                      },
                      {
                        label: "Example",
                        value: `- For "Piece," the description might be: "Used for single, countable items that are not divided into smaller units. Applicable for products like screws, books, or bottles." 
                                - For "Kilogram," it could read: "Used for bulk items that are weighed, such as grains, chemicals, or produce. Weight must be accurately measured to ensure inventory and sales accuracy." 
                                - For "Liter," it might state: "Used for liquids or fluid substances. Measurements should align with volume standards to ensure proper billing and stocking." 
                                - For "Pack," it might note: "Units bundled in pre-defined quantities, such as a pack of 12 units. Each 'pack' is treated as a single item for transaction purposes."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are critical for managing product units effectively in the ERP system. They allow the organization to standardize how items are measured, tracked, and traded, ensuring accuracy and consistency across all business functions."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}