import { Card, DateInput, Input, Textarea } from "components"
import moment from "moment"
import { DropdownLocation } from "pages/Inventori/Transaction/TransferItem/components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { DropdownEmployee } from "pages/Sales/Transaction/CustomerOrder/components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { FormReceivedType } from "../types/FormReceived"

type FormReceivedProps = {
  approvalView?: boolean
}

export const FormReceived = ({ approvalView }: FormReceivedProps) => {
  const { control } = useFormContext<FormReceivedType>()
  const approval_status = useWatch({
    control: control,
    name: "approval_status"
  })

  return (
    <Card className="border-transparent">
      <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-1">
          <DropdownLocation
            isDisabled
            label="FROM LOCATION"
            name="location_from_id"
          />

          <DropdownEmployee
            disabled
            name="transfer_employee_id"
            label="REQUEST BY"
          />

          <Controller
            control={control}
            name="transfer_date"
            render={({ field, fieldState }) => (
              <DateInput
                disabled
                label="TRANSFER DATE"
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="transfer_memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                disabled
                label="TRANSFER MEMO"
                defaultValue={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="transfer_link"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="LINK TRANSFER ID#"
                error={fieldState.error?.message}
                defaultValue={field.value}
              />
            )}
          />
        </section>

        <section className="flex flex-col gap-1">
          <DropdownLocation
            isDisabled
            label="TO LOCATION"
            name="location_to_id"
          />

          <DropdownEmployee
            disabled={approval_status !== APPROVAL.PENDING}
            label="RECEIVED BY"
            approvalView={approvalView}
          />

          <Controller
            control={control}
            name="transaction_date"
            render={({ field, fieldState }) => (
              <DateInput
                label="RECEIVED DATE"
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label="RECEIVED MEMO"
                defaultValue={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </section>
      </Card.Body>
    </Card>
  )
}
