import { useToggle } from "../../../../../../../../../utils"
import { Fragment, useContext} from "react"
import { IoSendSharp } from "react-icons/io5"
import { Modal, Loading, HintList } from "../../../../../../../../../components"
import { LinkAccountPurchaseType } from "../../../../../types"
import { useAccountGroup } from "../../../../../../../../../utils"
import { SelectedCoaType } from "../../../../../../../../../types"
import { toast } from "react-hot-toast"
import { useApi } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils" 
import { CoaList } from "../../../../../../../../../components/widgets/CoaList"

export function Create(props: {
    dataItem: LinkAccountPurchaseType
    coaType: string | undefined
}) {
    // hooks
    const { isActive, toggle } = useToggle(false)

    return (
        <Fragment>
            <button onClick={toggle}>
                <IoSendSharp className="text-center" />
            </button>
            {isActive && <ModalSection toggle={toggle} dataItem={props.dataItem} coaType={props.coaType}/>}
        </Fragment>
    )
}

function ModalSection(props: {
    toggle: () => void
    dataItem: LinkAccountPurchaseType
    coaType: string | undefined
}) {

    // hooks
    const { data, isLoading } = useAccountGroup()
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: SelectedCoaType, dataItem: LinkAccountPurchaseType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post(`/linkedaccount/update`, {
                    linked_account_code: dataItem.linked_account_code,
                    linked_account_name: dataItem.linked_account_name,
                    description: dataItem.description,
                    coa_id: value.coa_id
                }), {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
                props.toggle()
                toggle()
            }).catch(() => {}).finally(resolve)
        })
    }

   return (
        <Modal isOpen title="" closeModal={props.toggle}>
                <Modal.Body>
                    {isLoading || data.length === 0 ? (
                        <section className="my-5">
                            <Loading loading={isLoading} />
                        </section>
                    ) : (
                        <Fragment>
                            <section className="flex justify-center mb-3">
                                <div className="border-b text-xl font-bold">ACCOUNT</div>
                            </section>

                            <CoaList
                                data={data}
                                onAddCoa={(_,__,coa) => {
                                    onSubmit(coa, props.dataItem)
                                }}
                                defaultGroup={props.coaType}
                            />

                            <HintList
                                title=""
                                list={[
                                {
                                    label: "Account Group",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "A category or classification that groups similar types of accounts together, such as assets, liabilities, equity, income, and expenses."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "Account groups help in organizing accounts systematically for easier navigation and reporting. They allow users to categorize accounts based on their function in the financial statements, which aids in generating summaries and detailed reports."
                                    },
                                    {
                                        label: "Example",
                                        value: `"Assets," "Liabilities," "Income," "Expenses," "Equity."`
                                    }
                                    ]
                                },
                                {
                                    label: "Account Type",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "Specifies the nature of the account, detailing its role within the financial system. It provides a more specific classification within the broader account group."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To define the behavior of the account within transactions and financial reports. It helps in defining how the account is treated for accounting purposes (e.g., is it a debit or credit account)."
                                    },
                                    {
                                        label: "Example",
                                        value: `"Current Asset," "Fixed Asset," "Current Liability," "Long-Term Liability," "Revenue," "Cost of Goods Sold," "Operating Expense."`
                                    },
                                    ]
                                },
                                {
                                    label: "Account Number",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "A unique numeric or alphanumeric code assigned to each account in the chart of accounts."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To uniquely identify each account and facilitate quick entry, lookup, and management. It also ensures consistency and prevents duplication of accounts."
                                    },
                                    {
                                        label: "Example",
                                        value: `"1001" for "Cash," "2002" for "Accounts Receivable," "3001" for "Equity Capital."`
                                    },
                                    ]
                                },
                                {
                                    label: "Account Name",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "The full name or title of the account."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To provide a clear and descriptive name that identifies the purpose or use of the account. It is used in all system interfaces, reports, and transaction records for human readability."
                                    },
                                    {
                                        label: "Example",
                                        value: `"Cash in Hand," "Accounts Payable," "Sales Revenue."`
                                    },
                                    ]
                                },
                                {
                                    label: "Cheque Account: Checkbox Yes/No",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "An attribute that indicates whether the account is associated with cheque (check) transactions."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To identify which accounts are allowed to be used for cheque payments. This is important for cash management, bank reconciliations, and controlling the flow of funds."
                                    },
                                    {
                                        label: "Example",
                                        value: `A checkbox that can be marked "Yes" if the account is a cheque account, "No" if it is not.`
                                    },
                                    ]
                                },
                                {
                                    label: "Header Level: Dropdown (Subheader & Detail)",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "Specifies whether the account is a high-level summary (header) or a detailed transaction account (subheader or detail)."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To define the structure of the chart of accounts by differentiating between accounts used for summarizing data (header) and those that contain actual transaction data (detail). This attribute supports the hierarchical organization of accounts."
                                    },
                                    {
                                        label: "Example",
                                        value: `"Subheader" could be used for "Current Assets," while "Detail" could be used for specific accounts like "Cash" or "Inventory."`
                                    },
                                    ]
                                },
                                {
                                    label: "Parent Account",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "The reference to the account that is hierarchically above the current account in the chart of accounts structure."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To define the relationship between accounts, allowing for a nested structure where detailed accounts roll up into summary accounts. This helps in generating consolidated financial reports."
                                    },
                                    {
                                        label: "Example",
                                        value: `The "Cash" account (Detail) might have a parent account "Current Assets" (Subheader).`
                                    },
                                    ]
                                },
                                {
                                    label: "Note",
                                    list: [
                                    {
                                        label: "Definition",
                                        value: "Additional information or comments related to the account."
                                    },
                                    {
                                        label: "Purpose",
                                        value: "To provide context, instructions, or specific details that might be important for accountants, auditors, or other users of the ERP system. Notes can highlight special considerations, restrictions, or account-specific policies."
                                    },
                                    {
                                        label: "Example",
                                        value: `"This account is used exclusively for international transactions" or "Reconcile this account weekly."`
                                    },
                                    ]
                                },
                                ]}
                            />

                            <div className="my-5">These data attributes ensure the "Chart of Accounts" is set up comprehensively, providing clarity, consistency, and ease of use for end-users managing financial data within the ERP system.</div>
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
    )
}