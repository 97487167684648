import TaskCreate from "pages/Task"
import TaskEdit from "pages/Task/Edit"
import type { RouteType } from "types"

export const tasksRoutes: RouteType[] = [
  {
    path: "/tasks/create",
    element: <TaskCreate />,
    permission: "PR"
  },
  {
    path: "/tasks/edit/:task_id",
    element: <TaskEdit />,
    permission: "PR"
  }
]
