import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode, validationSchema } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: {
  mr_product_material_code?: string
  mr_product_material_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("mr_product_material_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "mr_product_material_id"> 

  const defaultValues:CreateData= {
    mr_product_material_code: "",
    mr_product_material_name: "",
    description: ""
  }
  
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/productmaterial/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`CREATE ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`When defining data attributes for "Product/Item Material" in an ERP system, it’s crucial to accurately categorize products based on the materials from which they are made. This categorization helps manage product specifications, compliance, quality control, and procurement. Here is a comprehensive explanation of the data attributes for "Product/Item Material":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each type of material."
                      },
                      {
                        label: "Purpose",
                        value: "The code serves as a concise reference for quickly identifying and referencing various materials within the ERP system. It simplifies data entry, enhances searchability, and ensures consistency in how materials are recorded and managed."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "MAT01" for "Cotton," "MAT02" for "Polyester," or "MAT03" for "Stainless Steel" help users differentiate materials efficiently.`
                      }
                    ]
                  },
                  {
                    label: "Product Material",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the material used in the manufacture or construction of a product."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the primary material composition of a product, which is crucial for tracking inventory, ensuring product quality, and meeting regulatory requirements. Understanding the material is also key for determining the product’s applications, durability, and care instructions."
                      },
                      {
                        label: "Example",
                        value: `"Cotton" — Commonly used in clothing and textiles. 
                                "Polyester" — Frequently used for clothing, especially in applications requiring high durability. 
                                "Stainless Steel" — Used in appliances, tools, and construction materials.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the product material."
                      },
                      {
                        label: "Purpose",
                        value: "The description offers insights into the qualities, characteristics, and common uses of the material. It assists users in understanding why a particular material is chosen for a product and how it affects the product's performance, maintenance, and environmental impact."
                      },
                      {
                        label: "Example",
                        value: `For "Cotton," the description might be: "A natural fiber known for its breathability and softness, ideal for clothing and bedding. Requires gentle care and is susceptible to shrinkage." 
                                For "Polyester," it could read: "A synthetic fiber recognized for its durability and resistance to shrinking and stretching. Widely used for performance wear and durable textiles." 
                                For "Stainless Steel," it might state: "An alloy of iron, chromium, and often nickel, known for its strength and resistance to rust and corrosion. Used in environments where both strength and appearance are important."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are essential for managing the materials aspect of products in the ERP system. They enable organizations to track and control inventory more effectively, ensure products meet quality and compliance standards, and optimize procurement and manufacturing processes."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}